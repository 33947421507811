import { createUseStyles } from 'react-jss'
import { ReconciliationListItemModel } from '../../redux/types'
import Pagination from '../Pagination'
import { Link } from 'react-router-dom'
import Card from '../Card'
import { Colors } from '../../constants/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { formatDateString } from '../../utils/dateHelper'

interface Props { 
  reconciliationsOnPage: ReconciliationListItemModel[]
  pageIndex: number
  pageSize: number
  totalCount: number
  onPageChange: (pageIndex: number) => void
}
const ReconciliationList = ({ reconciliationsOnPage, pageIndex, pageSize, totalCount, onPageChange }: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        <div className={styles.cell}>
          <h6>Date</h6>
        </div>
        <div className={styles.cell}>
          <h6>Issue count</h6>
        </div>
        <div className={`${styles.cell} ${styles.hasUnhandledIssueIndicator}`}>
          <h6>Issues</h6>
        </div>
      </Card>
    )    
  }

  const renderListItems = () => {
    return reconciliationsOnPage?.map((item, index) => {
      return (
        <Link to={{pathname: `/reconciliations/${item.id}`}} key={`reconciliationListItem${index}`}>
          <Card className={`${styles.container} card`}>
            <div className={styles.cell}>
              {formatDateString(item.date)}
            </div>
            <div className={styles.cell}>
              {item.totalIssueCount}
            </div>
            <div className={`${styles.cell} ${styles.hasUnhandledIssueIndicator}`}>
              {renderHasUnhandledIssueIndicator(item)}
            </div>
          </Card>
        </Link>
      )
    })
  }

  const renderHasUnhandledIssueIndicator = (reconciliationListItem: ReconciliationListItemModel) => {
    let color = Colors.euro_600
    
    if (reconciliationListItem.hasUnhandledIssues) 
      color = Colors.red

    return (
      <FontAwesomeIcon
        icon={faCircle}
        color={color}
        title={(reconciliationListItem.hasUnhandledIssues || false).toString()}
      />) 
  }

  return (
    <div>
       {renderHeader()}
       {renderListItems()}
       <div className={styles.pagination}>
          <Pagination
            pageIndex={pageIndex}
            itemCount={totalCount}
            pageSize={pageSize}
            siblingCount={1}
            onPageChange={onPageChange}
          />
        </div>
    </div>
  )
}

export default ReconciliationList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    margin: '10px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    margin: '30px 0 20px 0',
  },
  cell: {
    flex: 1,
    padding: '0px 10px'
  },
  hasUnhandledIssueIndicator: {
    textAlign: 'center',
    flex: '0 0 90px'
  },
  pagination: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center'
  }
})
