import {createUseStyles} from "react-jss"
import {Language, LearningModuleManagementModel, LearningModuleType} from "../../redux/types"
import {Link} from "react-router-dom";
import Card from "../Card";
import {multilingual as m} from "../../utils/multilingualStringHelper";
import {Colors} from "../../constants/colors";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileCode, faPen, faUserCheck, faClock} from '@fortawesome/free-solid-svg-icons'
import Badge from "react-bootstrap/Badge";


interface Props {
  modules: LearningModuleManagementModel[]
  language: Language
}

interface ListItemProps {
  module: LearningModuleManagementModel
  language: Language
}

const ModuleListItem = ({module, language}: ListItemProps) => {
  const styles = useStyles()

  const getIcon = () => {
    switch (module.moduleType) {
      case LearningModuleType.Markdown:
        return faFileCode
      case LearningModuleType.Quiz:
        return faPen
      case LearningModuleType.Action:
        return faUserCheck
      default:
        return faFileCode
    }
  }


  return (
    <Card className={`${styles.moduleListItem} card`}>
      <h5>{m(language, module.title)}</h5>
      <h5>
        <div className={styles.badges}>
          {module.isPublished ?
            <Badge variant={"success"}>Published</Badge> :
            <Badge variant={"secondary"}>Unpublished</Badge>
          }
          <Badge variant={"primary"}>
            <FontAwesomeIcon icon={getIcon()}/>
            <span style={{marginLeft: 4, textTransform: 'capitalize'}}>{module.moduleType}</span>
          </Badge>
          <Badge variant={"info"}>Order: {module.orderNumber}</Badge>
          <Badge variant={"info"}>
            <FontAwesomeIcon icon={faClock}/>
            <span style={{marginLeft: 4}}>{module.estimatedCompletionTime}</span>
          </Badge>
        </div>
      </h5>
    </Card>
  )
}


const ModuleList = ({modules, language}: Props) => {
  const styles = useStyles()

  const sortedModules = [...modules].sort((a, b) => {
    return a.orderNumber - b.orderNumber
  })

  return (
    <div className={styles.moduleList}>
      {
        sortedModules.map((item) => {
          return (
            <Link key={item.id} to={`/learning/course/${item.learningCourseId}/module/${item.id}`}>
              <ModuleListItem module={item} language={language}/>
            </Link>
          )
        })
      }
    </div>
  )
}

export default ModuleList

const useStyles = createUseStyles({
  moduleList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  moduleListItem: {
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    padding: 14,
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  badges: {
    display: 'flex',
    marginTop: 10,
    gap: 8,
    flexDirection: 'row-reverse',
  }
})
