import { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { titleMarginBottom } from '../constants/layout'
import UserList from '../components/UserList/UserList'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import useUserActions from '../hooks/useUserActions'
import useDidUpdateEffect from '../hooks/useDidUpdateEffect'
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator'
import UserFilter from '../components/UserList/UserFilter'
import { filterChanged, pageIndexChanged } from '../redux/slices/userSlice'

const Users = () => {
  const styles = useStyles()
  const dispatch = useAppDispatch()

  const { getUsers } = useUserActions()

  const loading = useAppSelector(state => state.user.loading)
  const usersOnPage = useAppSelector(state => state.user.usersOnPage)
  const totalCount = useAppSelector(state => state.user.totalCount)
  const searchTerm = useAppSelector(state => state.user.searchTerm)
  const pageIndex = useAppSelector(state => state.user.pageIndex)
  const pageSize = useAppSelector(state => state.user.pageSize)

  useEffect(() => {
    if (usersOnPage.length === 0) {
      getUsers(searchTerm ?? '', pageIndex, pageSize)
    }
  }, [])

  useDidUpdateEffect(() => {
    getUsers(searchTerm ?? '', pageIndex, pageSize)
  }, [pageIndex, searchTerm])

  const onPageChange = (pageIndex: number) => {
    dispatch(pageIndexChanged(pageIndex))
  }

  const onFilterChange = (value: string) => {
    dispatch(filterChanged(value.toLowerCase()))
  }

  return (
    <div className={styles.container}>
      <h1>Users</h1> 
      <UserFilter 
        filterValue={searchTerm || ''}
        onFilterChange={onFilterChange}
      /> 
      <UserList
        usersOnPage={usersOnPage}
        pageIndex={pageIndex}
        pageSize={pageSize} 
        totalCount={totalCount}
        onPageChange={onPageChange} 
      />
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default Users

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    }
  },
})
