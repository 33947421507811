import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ProtectCustomerListItem, ProtectCustomerModel, ProtectRule} from '../types'

interface ProtectCustomerState {
  loading: boolean
  customerDetails: ProtectCustomerModel | null
  allCustomers: ProtectCustomerListItem[]
  error: Error | string | null
}

const initialState: ProtectCustomerState = {
  loading: false,
  customerDetails: null,
  allCustomers: [],
  error: null
}

export const protectCustomerSlice = createSlice({
  name: 'protectCustomerSlice',
  initialState,
  reducers: {
    loading: state => {
      state.loading = true
      state.error = null
    },
    protectCustomerFetched: (state, action: PayloadAction<ProtectCustomerModel>) => {
      state.loading = false
      state.customerDetails = action.payload
      state.error = null
    },
    protectCustomerFetchFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
    protectCustomersFetched: (state, action: PayloadAction<ProtectCustomerListItem[]>) => {
      state.loading = false
      state.allCustomers = action.payload
      state.error = null
    },
    protectCustomersFetchFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
    ruleCreated: (state, action: PayloadAction<ProtectRule>) => {
      state.loading = false
      state.error = null

      if (state.customerDetails) {
        state.customerDetails = {
          ...state.customerDetails as ProtectCustomerModel,
          rules: [...state.customerDetails.rules ?? [], action.payload]
        }
      }
    },
    ruleCreationFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
  }
})

export const {
  loading,
  protectCustomerFetched,
  protectCustomerFetchFailure,
  protectCustomersFetched,
  protectCustomersFetchFailure,
  ruleCreated,
  ruleCreationFailure
} = protectCustomerSlice.actions

export default protectCustomerSlice.reducer