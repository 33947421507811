import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TokenModel } from '../types'

interface FortnoxState {
    loading: boolean
    tokens: TokenModel[]
    activationSuccess: boolean
    authenticationUrl: string
    error: Error | string | null
  }

const initialState: FortnoxState = {
    loading: false,
    tokens: [],
    activationSuccess: false,
    authenticationUrl: '',
    error: null
}

export const fortnoxSlice = createSlice({
    name: 'fortnox',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        fortnoxTokensFetched: (state, action: PayloadAction<TokenModel[]>) => {
            state.loading = false
            state.tokens = action.payload
            state.error = null
        },
        fortnoxTokensFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        fortnoxAuthenticationUrlFetched: (state, action: PayloadAction<string>) => {
            state.loading = false
            state.authenticationUrl = action.payload
            state.error = null
        },
        fortnoxAuthenticationUrlFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        fortnoxIntegrationActivated: state => {
            state.loading = false
            state.activationSuccess = true
            state.tokens = []
            state.error = null
        },
        fortnoxIntegrationActivationFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        fortnoxIntegrationActivationHandled: state => {
            state.loading = false
            state.activationSuccess = false
            state.error = null
        },
    }
})

export const { 
    loading, 
    fortnoxTokensFetched, 
    fortnoxTokensFetchFailure, 
    fortnoxAuthenticationUrlFetched, 
    fortnoxAuthenticationUrlFetchFailure, 
    fortnoxIntegrationActivated, 
    fortnoxIntegrationActivationFailure,
    fortnoxIntegrationActivationHandled 
} = fortnoxSlice.actions

export default fortnoxSlice.reducer