import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    CustomerListItemModel,
    CustomerModel,
    CustomerPortalUser,
    PagedListItems,
} from '../types'

interface CustomerState {
    loading: boolean
    customerDetails: CustomerModel | null
    customersOnPage: CustomerListItemModel[]
    createdCustomerId: string | null
    apiKey: string | null
    pageIndex: number
    totalCount: number
    pageSize: number
    searchTerm: string | null
    error: Error | string | null
}

const initialState: CustomerState = {
    loading: false,
    customerDetails: null,
    customersOnPage: [],
    createdCustomerId: null,
    apiKey: null,
    totalCount: 0,
    pageIndex: 1,
    pageSize: 20,
    searchTerm: null,
    error: null
}

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        customersFetched: (state, action: PayloadAction<PagedListItems<CustomerListItemModel>>) => {
            state.loading = false
            state.customersOnPage = action.payload.items
            state.totalCount = action.payload.totalCount
            state.error = null
        },
        customersFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        customerDetailsFetched: (state, action: PayloadAction<CustomerModel>) => {
            state.loading = false
            state.customerDetails = action.payload
            state.error = null
        },
        customerDetailsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        customerCreated: (state, action: PayloadAction<string>) => {
            state.loading = false
            state.createdCustomerId = action.payload
            state.customersOnPage = []
            state.pageIndex = 1
            state.error = null
        },
        customerCreationHandled: state => {
            state.createdCustomerId = null
        },
        customerCreateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        customerInfoUpdated: (state, action: PayloadAction<CustomerModel>) => {
            state.loading = false
            state.customerDetails = action.payload
            state.error = null
        },
        customerInfoUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        customerLockStateSet: (state, action: PayloadAction<boolean>) => {
            state.loading = false
            state.customerDetails = {...state.customerDetails as CustomerModel, state: action.payload ? 'locked' : 'active' }
            state.error = null
        },
        customerLockStateSetFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        apiKeyGenerated: (state, action: PayloadAction<string>) => {
            state.loading = false
            state.apiKey = action.payload
            state.error = null
        },
        apiKeyGenerationFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        apiKeyCleanup: (state) => {
            state.apiKey = null
        },
        portalUserAdded: (state, action: PayloadAction<CustomerPortalUser>) => {
            state.loading = false
            state.customerDetails = {
                ...state.customerDetails as CustomerModel,
                portalUsers: [action.payload, ...state.customerDetails?.portalUsers ?? []]
              }
            state.error = null
        },
        portalUserAddFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        portalUserUpdated: (state, action: PayloadAction<CustomerPortalUser>) => {
            const index = state.customerDetails?.portalUsers.findIndex((item) => {
                return item.portalUserId === action.payload.portalUserId
            });
            const portalUsers = [...state.customerDetails?.portalUsers ?? []]
            portalUsers[index as number] = action.payload

            state.loading = false
            state.customerDetails = {
                ...state.customerDetails as CustomerModel,
                portalUsers: portalUsers
              }
            state.error = null
        },
        portalUserUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        portalUserDeleted: (state, action: PayloadAction<string>) => {
            var index = state.customerDetails?.portalUsers.findIndex((item) => {
                return item.portalUserId === action.payload
            })
            const portalUsers = [...state.customerDetails?.portalUsers ?? []]
            portalUsers.splice(index ?? 0, 1)

            state.loading = false
            state.customerDetails = {
                ...state.customerDetails as CustomerModel,
                portalUsers: portalUsers
              }
            state.error = null
        },
        portalUserDeleteFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        filterChanged: (state, action: PayloadAction<string>) => {
            state.searchTerm = action.payload
            state.pageIndex = 1
        }
    },
    extraReducers: {
        'employer/employerCreationHandled': (state) => {
            state.customerDetails = null
        },
        'invoicePayoutProvider/invoicePayoutProviderCreationHandled': (state) => {
            state.customerDetails = null
        }
    }
})

export const {
    loading,
    customersFetched,
    customersFetchFailure,
    customerDetailsFetched,
    customerDetailsFetchFailure,
    customerCreated,
    customerCreationHandled,
    customerCreateFailure,
    customerInfoUpdated,
    customerInfoUpdateFailure,
    customerLockStateSet,
    customerLockStateSetFailure,
    apiKeyGenerated,
    apiKeyGenerationFailure,
    apiKeyCleanup,
    portalUserAdded,
    portalUserAddFailure,
    portalUserUpdated,
    portalUserUpdateFailure,
    portalUserDeleted,
    portalUserDeleteFailure,
    pageIndexChanged,
    filterChanged
} = customerSlice.actions
export default customerSlice.reducer
