import { createUseStyles } from 'react-jss'

interface Props {
  height?: number
}

const Divider = ({ height = 20 }: Props) => {
  const useStyles = createUseStyles({
    container: {
      height,
    },
  })

  const classes = useStyles()

  return (
    <div className={classes.container} />
  )
}

export default Divider

