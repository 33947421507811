import {useState} from 'react'
import Modal from '../../Modal'
import {CountryCode} from '../../../redux/types'
import InputField from '../../InputField'
import Select from '../../Select'
import {createUseStyles} from 'react-jss'
import {Colors} from '../../../constants/colors'
import {isValidBirthDate} from "../../../utils/dateHelper";
import DatePicker from "../../DatePicker";
import { AddRepresentativeModel } from '../../../redux/companyMonitorTypes'

interface Props {
    onAddClick?: (representative: AddRepresentativeModel) => void
    onCancelClick?: () => void
    visible: boolean
}

const AddCompanyRepresentativeModal = ({ onAddClick, onCancelClick, visible }: Props) => {
    const styles = useStyles()
    const [representative, setRepresentative] = useState<AddRepresentativeModel>({country: CountryCode.se, dateOfBirth: '', givenName: '', familyName: '', email: '', positions: []})
    const [isDateOfBirthValid, setIsDateOfBirthValid] = useState<boolean>(true)
    const [isGivenNameValid, setIsGivenNameValid] = useState<boolean>(true)
    const [isFamilyNameValid, setIsFamilyNameValid] = useState<boolean>(true)
    const [isPositionsValid, setIsPositionsValid] = useState<boolean>(true)

    const validateBeforeAdd = () => {
        if (!isValidBirthDate(representative.dateOfBirth)) {
            setIsDateOfBirthValid(false);
            return
        } else {
            setIsDateOfBirthValid(true)
        }

        if (!representative.givenName) {
            setIsGivenNameValid(false);
            return
        } else {
            setIsGivenNameValid(true);
        }

        if (!representative.familyName) {
            setIsFamilyNameValid(false);
            return
        } else {
            setIsFamilyNameValid(true);
        }

        if (representative.positions?.length <= 0) {
            setIsPositionsValid(false);
            return
        } else {
            setIsPositionsValid(true);
        }

        handleOnAdd()
    }

    const handleOnAdd = () => {
        onAddClick && onAddClick(representative)
        setRepresentative({country: CountryCode.se, dateOfBirth: '', givenName: '', familyName: '', email: '', positions: []})
    }

    const handleOnCancel = () => {
        onCancelClick && onCancelClick()
        setRepresentative({country: CountryCode.se, dateOfBirth: '', givenName: '', familyName: '', email: '', positions: []})
    }

    const handleOnChange = (propName: string, value: any) => {
        setRepresentative({...representative, [propName]: value})
    }

    const setPositions = (value: string) => {
        let positions = value
          .split(',')
          .map(p => p.trim())
          .filter(p => p)
        setRepresentative({...representative, positions: positions})
    }

    const getCountryOptions = () => {
        return Object.keys(CountryCode).filter(v => isNaN(Number(v)))
          .map((value) => { return { name: value, value: value }
          })
    }

    return (
        <Modal
            title='Add new representative'
            show={visible}
            okButtonTitle='Add'
            cancelButtonTitle='Cancel'
            onHide={() => handleOnCancel()}
            onOkClick={() => validateBeforeAdd()}
            onCancelClick={() => handleOnCancel()}
        >
            <Select
              id='country'
              key='country'
              label='Country'
              options={getCountryOptions()}
              selectedValue={representative.country?.toString()}
              onSelect={(value) => handleOnChange('country', CountryCode[value as keyof typeof CountryCode])}
            />
            <DatePicker
              id='dateOfBirth'
              label='Date of birth'
              valid={isDateOfBirthValid}
              includeTime={false}
              value={representative.dateOfBirth}
              onChange={value => handleOnChange('dateOfBirth', value)}
            />
            <InputField
                id='givenName'
                valid={isGivenNameValid}
                value={representative.givenName}
                onChange={(event) => handleOnChange('givenName', event.target.value)}
                label='Given name'
                placeholder=''
              />
            <InputField
                id='familyName'
                valid={isFamilyNameValid}
                value={representative.familyName}
                onChange={(event) => handleOnChange('familyName', event.target.value)}
                label='Family name'
                placeholder=''
              />
            <InputField
                id='email'
                value={representative.email ?? ''}
                onChange={(event) => handleOnChange('email', event.target.value)}
                label='Email'
                placeholder=''
              />
            <InputField
              id='positions'
              valid={isPositionsValid}
              value={representative.positions.join(', ') ?? ''}
              onChange={(event) => setPositions(event.target.value)}
              label='Positions'
              placeholder='Comma-separated list of positions'
            />
            {!isDateOfBirthValid &&
              <div className={styles.errorMessage}>
                  Invalid date of birth
              </div>
            }
        </Modal>
    )
}

export default AddCompanyRepresentativeModal

const useStyles = createUseStyles({
    errorMessage: {
        marginTop: 20,
        color: Colors.rubel_700
    }
})