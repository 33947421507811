import { createUseStyles } from 'react-jss'
import { FormCheckProps } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'

interface Props extends FormCheckProps {
  id?: string
}

const Switch = ({ id = 'switch', ...rest }: Props) => {
  const styles = useStyles()

  return (
    <div className={styles.container}>
      <Form.Switch id={id} {...rest} />
    </div>
  )
}

export default Switch

const useStyles = createUseStyles({
  container: {
    marginBottom: 4,
    marginTop: 17
  }
})