import { useEffect, useState } from 'react'
import Card from '../components/Card'
import CardSection from '../components/CardSection'
import DetailsCard from '../components/DetailsCard'
import { createUseStyles } from 'react-jss'
import { titleMarginBottom } from '../constants/layout'
import { formatDateTimeString } from '../utils/dateHelper'
import Button from '../components/Button'
import ConfirmModal from '../components/ConfirmModal'
import { useAppSelector } from '../redux/hooks'
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator'
import useFortnoxActions from '../hooks/useFortnoxActions'

const Fortnox = () => {
  const styles = useStyles()

  const [confirmInititateIntegrationVisible, setConfirmInititateIntegrationVisible] = useState(false)
  const { fetchTokens, fetchAuthenticationUrl, activateIntegration } = useFortnoxActions()

  const loading = useAppSelector(state => state.fortnox.loading)
  const tokens = useAppSelector(state => state.fortnox.tokens)
  const authenticationUrl = useAppSelector(state => state.fortnox.authenticationUrl)

  useEffect(() => {
    if (tokens.length === 0) {
      fetchTokens()
    }

    if (authenticationUrl.length === 0) {
      fetchAuthenticationUrl()
    }
  }, [])

  const handleInitiateIntegrationConfirmed = () => {
    setConfirmInititateIntegrationVisible(false)
    window.location.replace(authenticationUrl)
  }

  const renderTokenHeader = () => {
    return (
      <Card className={styles.listContainer}>
        <div className={styles.listCell}>
          <p>Key</p>
        </div>
        <div className={styles.listCell}>
          <p>Expires</p>
        </div>
      </Card>
    )
  }

  const renderTokens = () => {
    return tokens?.map((token, index) => {
      return (
        <Card className={styles.listContainer} key={`token${index}`}>
          <div className={styles.listCell}>
            <p><span>{token.key}</span></p>
          </div>
          <div className={styles.listCell}>
            <p><span>{token.expires ? formatDateTimeString(token.expires) : 'Never'}</span></p>
          </div>
        </Card>
      )
    })
  }

  return (
    <div className={styles.container}>
       <h1>Fortnox bookkeeping integration</h1>
      <div className={styles.actionBar}>
        <Button title='Initiate integration' onClick={() => setConfirmInititateIntegrationVisible(true)} /> 
      </div>
      <CardSection>
        <DetailsCard>
          {renderTokenHeader()}
          {renderTokens()}
        </DetailsCard>
      </CardSection>
      <ConfirmModal  
        key='confirmInitiateIntegration'    
        visible={confirmInititateIntegrationVisible}
        confirmText={`Are you sure you want to initiate the Fortnox integration? You can only do this if you are a system administrator in Fortnox.`}
        onConfirmClick={handleInitiateIntegrationConfirmed}
        onCancelClick={() => setConfirmInititateIntegrationVisible(false)}
      />
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default Fortnox

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    }
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    transition: '0.4s'
  },
  listCell: {
    flex: 1
  },
  actionBar: {
    display: 'flex',
    marginTop: -40,
    flexDirection: 'row',
    columnGap: 10,
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
})