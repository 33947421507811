import { createSlice } from '@reduxjs/toolkit'

interface AppState {
    initialDataFetchTriggered: boolean
}

const initialState: AppState = {
    initialDataFetchTriggered: false
}

export const appStateSlice = createSlice({
    name: 'appState',
    initialState,
    reducers: {
        initialDataFetchTriggered: state => {
            state.initialDataFetchTriggered = true
        },
    }
})

export const { initialDataFetchTriggered } = appStateSlice.actions
export default appStateSlice.reducer