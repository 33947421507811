import {useLocation} from "react-router-dom";
import React from "react";


const useRouterQuery = () => {
  const {search} = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search])

  return {
    query
  }
}

export default useRouterQuery

