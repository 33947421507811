import { useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { createUseStyles } from 'react-jss'
import { titleMarginBottom } from '../constants/layout'
import { formatDateTimeString, formatDateString } from '../utils/dateHelper'
import CardSection from '../components/CardSection'
import DetailsCard from '../components/DetailsCard'
import Card from '../components/Card'
import { Link } from 'react-router-dom'
import { roundToMaxTwoDecimals } from '../utils/numberHelper'
import PayRunListComponent from '../components/PayRunList';
import { Colors } from '../constants/colors';
import usePaydayActions from '../hooks/usePaydayActions';
import { useAppSelector } from '../redux/hooks';
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator';
import NavLink from '../components/NavLink';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import DefaultLink from '../components/Link';

const PaydayDetails = () => {
  const styles = useStyles()
  const params = useParams<{id: string}>()
  const history = useHistory();

  const { getPaydayDetails } = usePaydayActions()

  const loading = useAppSelector(state => state.payday.loading)
  const payday = useAppSelector(state => state.payday.paydayDetails)

  useEffect(() => {
    if (payday == null || payday.id !== params.id) {
      getPaydayDetails(params.id)
    } 
  }, [])

  const renderBankTransferHeader = () => {
    return (
      <Card className={styles.listContainer}>
        <div className={styles.listCell}>
          <p>Type</p>
        </div>
        <div className={styles.listCell}>
          <p>Amount</p>
        </div>
        <div className={styles.listCell}>
          <p>State</p>
        </div>
      </Card>
    )  
  }

  const renderBankTransfers = () => {
    return payday?.employerPayRunDetails?.bankTransfers?.map((bankTransfer) => {
      return (
        <Card className={styles.listContainer}>
           <div className={styles.listCell}>
            <p><span>{bankTransfer.type}</span></p>
          </div>
          <div className={styles.listCell}>
            <p><span>{roundToMaxTwoDecimals(bankTransfer.amount.amount).toLocaleString('sv-SE')} {bankTransfer.amount.currencyCode.toLocaleUpperCase()}</span></p>
          </div>
          <div className={styles.listCell}>
          <p><span>{bankTransfer.state}</span></p>
          </div>
        </Card>
      )  
    })
  }

  const renderPayrollFileHeader = () => {
    return (<Card className={styles.listContainer}>
      <div className={styles.listCell}>
        <p>External id</p>
      </div>
      <div className={styles.listCell}>
        <p>Submitted</p>
      </div>
    </Card>)
  }

  const renderPayrollFiles = () => {
    return payday?.payrollDetails?.payrollFiles?.map((file) => {
      return (<Card className={styles.listContainer}>
        <div className={styles.listCell}>
          <p><span>{file.externalId}</span></p>
        </div>
        <div className={styles.listCell}>
          <p><span>{formatDateTimeString(file.submittedDate)}</span></p>
        </div>
    </Card>)
    })
  }

  const renderValidationErrorHeader = () => {
    return (<Card className={styles.listContainer}>
      <div className={styles.listCell}>
        <p>Type</p>
      </div>
      <div className={styles.listCell}>
        <p>Parameters</p>
      </div>
    </Card>)
  }

  const renderValidationErrors = () => {
    return payday?.payrollDetails?.validationErrors?.map((validationError) => {
      return (<Card className={styles.listContainer}>
        <div className={styles.listCell}>
          <p><span>{validationError.error}</span></p>
        </div>
        <div className={styles.listCell}>
          <p><span>{validationError.parameters}</span></p>
        </div>
    </Card>)
    })
  }

  return (
    <div className={styles.container}>
      <h1>{payday && `${payday.employerName} ${formatDateString(payday.paymentDate)}`}</h1>
      <div className={styles.actionBar}>
        <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()} />
      </div>
      <CardSection>
        <DetailsCard>
          <h6>General</h6>
          <p>Employer: <DefaultLink to={`/employers/${payday?.employerId}`}>{payday?.employerName}</DefaultLink></p>
          <p>Type: <span>{payday && payday.offCycle ? 'Off-cycle' : 'On-cycle'}</span></p>
          <p>Payment date: <span>{payday && formatDateString(payday.paymentDate)}</span></p>
          {payday?.completed && <p>Completed date: <span>{formatDateTimeString(payday.completedAt)}</span></p>}
        </DetailsCard>
      </CardSection>
      <CardSection>
        <DetailsCard>
          <h6>Deductions made by employer</h6>
          {!payday?.employerDeductionDetails && <p>Not calculated yet</p> }
          {payday?.employerDeductionDetails && 
            <div>
              <p>Amount: <span>{roundToMaxTwoDecimals(payday?.employerDeductionDetails.amount.amount).toLocaleString('sv-SE')} {payday?.employerDeductionDetails.amount.currencyCode.toLocaleUpperCase()}</span></p>
            </div>
          }
        </DetailsCard>
      </CardSection>
      <CardSection>
        <DetailsCard>
          <h6>Payroll file(s)</h6>
          {!payday?.payrollDetails && <p>No payroll file submitted</p> }
          {payday?.payrollDetails && 
            <div>
              <p>Payment date: <span>{formatDateString(payday.payrollDetails.paymentDate)}</span></p>
              {payday.payrollDetails.payrollFiles && <p>Payroll files:</p>}
              {payday.payrollDetails.payrollFiles && renderPayrollFileHeader()}
              {payday.payrollDetails.payrollFiles && renderPayrollFiles()}

              {!payday.payrollDetails.validationErrors || payday.payrollDetails.validationErrors.length == 0 && 
                <p>Validation status: <span>success</span></p>
              }
              {payday.payrollDetails.validationErrors && <p>Validation errors:</p>}
              {payday.payrollDetails.validationErrors && renderValidationErrorHeader()}
              {payday.payrollDetails.validationErrors && renderValidationErrors()}
            </div>
          }
        </DetailsCard>
      </CardSection>
      <CardSection>
        <DetailsCard>
          <h6>Bank transfers</h6>
          {!payday?.employerPayRunDetails && <p>No bank transfers created </p> }
          {payday?.employerPayRunDetails && renderBankTransferHeader()}
          {payday?.employerPayRunDetails && renderBankTransfers()}
        </DetailsCard>
      </CardSection>
      <h2>Pay runs</h2>
      {payday?.id && <PayRunListComponent paydayId={payday.id} />}
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default PaydayDetails

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    },
    '& h2': {
      paddingTop: '30px',
      paddingBottom: '20px'
    }
  },
  actionBar: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between', 
    alignItems: 'center'
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    margin: '10px 0',
    transition: '0.4s',
    border: '1px solid',
    borderColor: Colors.darkGray5
  },
  listCell: {
    flex: 1,
    padding: '0px 10px'
  },
})
