import {Language, MultilingualString} from "../../redux/types";
import {createUseStyles} from "react-jss";
import {Colors} from "../../constants/colors";
import InputField from "../InputField";
import {useState} from "react";

interface Props {
  id?: string
  label: string
  translations: MultilingualString | undefined | null
  placeholders?: MultilingualString
  onChange: (translations: MultilingualString) => void
  onLanguageChange?: (language: Language) => void
  className?: string
  disabled?: boolean
}

const MultilingualInputField = ({
                                  id = 'multilingual',
                                  label,
                                  translations,
                                  placeholders,
                                  className,
                                  onChange,
                                  onLanguageChange,
                                  disabled
                                }: Props) => {
  const styles = useStyles()
  const [currentEditLanguage, setCurrentEditLanguage] = useState<Language>()

  const handleOnChange = (language: Language, value: any) => {
    if (currentEditLanguage !== language) {
      setCurrentEditLanguage(language)
      onLanguageChange && onLanguageChange(language)
    }
    onChange({...translations, [language]: value})
  }

  return (
    <div>
      <h5>{label}</h5>
      <div className={styles.container}>
        {
          Object.keys(Language).map(key => {
            const translation = translations ? translations[key as Language] : ''
            const placeholder = placeholders ? placeholders[key as Language] : ''
            return (
              <InputField
                id={`${id}_${key}`}
                key={`${label}_${key}`}
                disabled={disabled}
                value={translation || ''}
                placeholder={placeholder}
                onChange={(event) => {
                  handleOnChange(key as Language, event.target.value)
                }}
                label={key}
                className={className}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default MultilingualInputField

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 4,
    borderRadius: 8,
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: Colors.gray3,
  }
})
