import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface TaxTableState {
    loading: boolean
    importSuccess: boolean
    error: Error | string | null
  }

const initialState: TaxTableState = {
    loading: false,
    importSuccess: false,
    error: null
}

export const taxTableSlice = createSlice({
    name: 'taxTable',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        taxTablesImported: state => {
            state.loading = false
            state.importSuccess = true
        },
        taxTableImportFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.importSuccess = false
            state.error = action.payload
        },
    }
})

export const { 
    loading, 
    taxTablesImported, 
    taxTableImportFailure 
} = taxTableSlice.actions

export default taxTableSlice.reducer