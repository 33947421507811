import { FC, useEffect } from 'react'
import ReconciliationList from '../components/ReconciliationList'
import { createUseStyles } from 'react-jss'
import { titleMarginBottom } from '../constants/layout'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import useReconciliationActions from '../hooks/useReconciliationActions'
import useDidUpdateEffect from '../hooks/useDidUpdateEffect'
import { pageIndexChanged } from '../redux/slices/reconciliationSlice'
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator'

interface ReconciliationsProps { }

const Reconciliations: FC<ReconciliationsProps> = ({  }) => {
  const styles = useStyles()
  const dispatch = useAppDispatch()

  const { getReconciliations } = useReconciliationActions()

  const loading = useAppSelector(state => state.reconciliation.loading)
  const reconciliationsOnPage = useAppSelector(state => state.reconciliation.reconciliationsOnPage)
  const totalCount = useAppSelector(state => state.reconciliation.totalCount)
  const pageIndex = useAppSelector(state => state.reconciliation.pageIndex)
  const pageSize = useAppSelector(state => state.reconciliation.pageSize)

  useEffect(() => {
    if (reconciliationsOnPage.length === 0) {
      getReconciliations(pageIndex, pageSize)
    }
  }, [])

  useDidUpdateEffect(() => {
    getReconciliations(pageIndex, pageSize)
  }, [pageIndex])

  const onPageChange = (pageIndex: number) => {
    dispatch(pageIndexChanged(pageIndex))
  }

  return (
    <div className={styles.container}>
      <h1>Reconciliations</h1>
      <ReconciliationList 
        reconciliationsOnPage={reconciliationsOnPage}
        pageIndex={pageIndex}
        pageSize={pageSize} 
        totalCount={totalCount}
        onPageChange={onPageChange}
      />
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default Reconciliations

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    }
  },
})