import {
  CreditorConnectionModel,
  InvoicePayoutProviderModel,
  CreateNewInvoicePayoutProviderModel,
  UpdateInvoicePayoutProviderModel,
} from '../redux/types';
import useFetchClient from "./useFetchClient";
import { useAppDispatch } from "../redux/hooks";
import { restConfig } from "../authConfig";
import { 
  invoicePayoutProviderCreateFailure, 
  invoicePayoutProviderCreated, 
  invoicePayoutProviderCreditSpaceWarningSilenceFailure, 
  invoicePayoutProviderCreditSpaceWarningSilenced, 
  invoicePayoutProviderCreditorAddFailure, 
  invoicePayoutProviderCreditorAdded, 
  invoicePayoutProviderCreditorDeleteFailure, 
  invoicePayoutProviderCreditorDeleted, 
  invoicePayoutProviderDetailsFetchFailure, 
  invoicePayoutProviderDetailsFetched, 
  invoicePayoutProviderLockStateSet, 
  invoicePayoutProviderLockStateSetFailure, 
  invoicePayoutProviderUpdateFailure, 
  invoicePayoutProviderUpdated, 
  loading
} from '../redux/slices/invoicePayoutProviderSlice';

const useInvoicePayoutProviderActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getInvoicePayoutProviderDetails = (invoicePayoutProviderId: string) => {
    dispatch(loading())
    const url = restConfig.routes.invoicePayoutProviders + `/${invoicePayoutProviderId}`

    return fetchClient.get(url)
      .then(response => {
          dispatch(invoicePayoutProviderDetailsFetched(response.data as InvoicePayoutProviderModel))
      })
      .catch(error => {
          console.log('getInvoicePayoutProviderDetails error:', error)
          dispatch(invoicePayoutProviderDetailsFetchFailure(error))
      })
  }

  const createInvoicePayoutProvider = (provider: CreateNewInvoicePayoutProviderModel) => {
    dispatch(loading())
    return fetchClient.post(restConfig.routes.invoicePayoutProviders, provider)
      .then(response => {
          dispatch(invoicePayoutProviderCreated(response.data as string))
      })
      .catch(error => {
          console.log('createInvoicePayoutProvider error:', error)
          dispatch(invoicePayoutProviderCreateFailure(error))
      })
  }

  const updateInvoicePayoutProvider = (providerId: string, updateModel: UpdateInvoicePayoutProviderModel) => {
    dispatch(loading())
    const url = restConfig.routes.invoicePayoutProviders + '/' + providerId

    return fetchClient.post(url, updateModel)
      .then(response => {
          dispatch(invoicePayoutProviderUpdated(response.data as InvoicePayoutProviderModel))
      })
      .catch(error => {
          console.log('updateInvoicePayoutProvider error:', error)
          dispatch(invoicePayoutProviderUpdateFailure(error))
      })
  }

  const silenceCreditSpaceWarning = (providerId: string, days: number) => {
    dispatch(loading())
    const url = restConfig.routes.invoicePayoutProviders + `/${providerId}/creditspacewarning`

    return fetchClient.post(url, { days })
      .then(response => {
          dispatch(invoicePayoutProviderCreditSpaceWarningSilenced(response.data as InvoicePayoutProviderModel))
      })
      .catch(error => {
          console.log('silenceCreditSpaceWarning error:', error)
          dispatch(invoicePayoutProviderCreditSpaceWarningSilenceFailure(error))
      })
  }

  const setLockState = (providerId: string, locked: boolean) => {
    dispatch(loading())
    const url = restConfig.routes.invoicePayoutProviders + `/${providerId}/${locked ? 'lock' : 'unlock'}`

    return fetchClient.post(url)
      .then(response => {
          dispatch(invoicePayoutProviderLockStateSet(locked))
      })
      .catch(error => {
          console.log('setLockState error:', error)
          dispatch(invoicePayoutProviderLockStateSetFailure(error))
      })
  }

  const addCreditor = (providerId: string, creditor: CreditorConnectionModel) => {
    dispatch(loading())
    const url = restConfig.routes.invoicePayoutProviders + `/${providerId}/creditors`

    return fetchClient.post(url, { creditorId: creditor.id, priority: creditor.priority})
      .then(response => {
          dispatch(invoicePayoutProviderCreditorAdded(creditor))
      })
      .catch(error => {
          console.log('addCreditor error:', error)
          dispatch(invoicePayoutProviderCreditorAddFailure(error))
      })
  }

  const deleteCreditor = (providerId: string, creditorId: string) => {
    dispatch(loading())
    const url = restConfig.routes.invoicePayoutProviders + `/${providerId}/creditors/${creditorId}`

    return fetchClient.delete(url)
      .then(response => {
          dispatch(invoicePayoutProviderCreditorDeleted(creditorId))
      })
      .catch(error => {
          console.log('deleteCreditor error:', error)
          dispatch(invoicePayoutProviderCreditorDeleteFailure(error))
      })
  }

  return {
    getInvoicePayoutProviderDetails,
    createInvoicePayoutProvider,
    updateInvoicePayoutProvider,
    silenceCreditSpaceWarning,
    setLockState,
    addCreditor,
    deleteCreditor
  }
}

export default useInvoicePayoutProviderActions
