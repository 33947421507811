import Card from '../../Card'
import {createUseStyles} from 'react-jss'
import {Colors} from '../../../constants/colors'
import {faBuilding, faExclamationTriangle, faCheck, faComment} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../../Button";
import { formatDateString, formatDateTimeString } from '../../../utils/dateHelper'
import { CompanyReportChangeModel } from '../../../redux/companyMonitorTypes';

interface Props {
  enableHandling: boolean
  changes: CompanyReportChangeModel[]
  onHandleClick?: (change: CompanyReportChangeModel) => void
  onCommentClick?: (change: CompanyReportChangeModel) => void
}

const CompanyReportChangeList = ({enableHandling, onHandleClick, onCommentClick, changes}: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        {enableHandling &&
        <div className={styles.smallCell}>
          <p><FontAwesomeIcon
            icon={faBuilding}
            color={Colors.white}/></p>
        </div>
        }
        <div className={styles.smallCell}>
          <p><span>Date</span></p>
        </div>
        <div className={styles.cell}>
          <p><span>Change</span></p>
        </div>
        {enableHandling && 
        <div className={styles.rightCell}>
          <p><span></span></p>
        </div>
        }
      </Card>
    )
  }

  const renderListItems = () => {
    return changes?.map((item, index) => {
      return (
        <Card className={`${styles.container} card`} key={`change_${index}`}>
          {enableHandling &&
          <div className={styles.smallCell}>
            {renderIcon(item)}
          </div>
          }
          <div className={styles.smallCell}>
            <p>{formatDateString(item.date)}</p>
          </div>
          <div className={styles.cell}>
            <p>{item.description}</p>
          </div>
          {enableHandling &&
          <div className={styles.rightCell}>
            {item.handledDate && <p title={`By: ${item.handledById}`}><span>Handled: </span>{formatDateString(item.handledDate)}</p>}
            {item.comments.map((comment, index) => {
              return <p key={index} title={`${formatDateTimeString(comment.commentDate)} by: ${comment.commentById}`}><span>Comment: </span>{comment.comment} </p>
            })}
            {renderHandleButton(item)}
            {renderCommentButton(item)}
          </div>
          }
        </Card>
      )
    })
  }

  const renderIcon = (item: CompanyReportChangeModel) => {
    const color = item.needsHandling && !item.handled 
      ? Colors.yellow
      : Colors.green

    const icon = item.needsHandling && !item.handled
      ? faExclamationTriangle
      : faCheck

    return <FontAwesomeIcon
      icon={icon}
      color={color}
    />
  }

  const renderHandleButton = (item: CompanyReportChangeModel) => {
    if (!item.handled)
      return (
        <Button
          onClick={() => onHandleClick && onHandleClick(item) }
          title={'Handle'}
        />)
  }

  const renderCommentButton = (item: CompanyReportChangeModel) => {
    if (item.handled) {
      return (
        <FontAwesomeIcon
          className={styles.commentIcon}
          icon={faComment}
          onClick={() => onCommentClick && onCommentClick(item) }
          title={'Comment'}
        />)
      }
  }

  return (
    <div>
      {renderHeader()}
      {renderListItems()}
    </div>
  )
}

export default CompanyReportChangeList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 3px',
    margin: '5px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    margin: '30px 0 20px 0',
  },
  '& p': {
    fontSize: 12,
    margin: 0,
    '& span': {
      fontFamily: 'Aestetico-Bold',
      color: Colors.euro_600,
    }
  },
  flagged: {
    backgroundColor: Colors.red,
  },
  cell: {
    flex: 1,
    textAlign: 'left',
    padding: '0px 10px'
  },
  smallCell: {
    textAlign: 'center',
    padding: '0px 10px'
  },
  rightCell: {
    justifySelf: 'right',
    textAlign: 'left',
    padding: '0px 10px'
  },
  commentIcon: {
    cursor: 'pointer'
  }
})
