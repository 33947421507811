import { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'
import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'
import Card from './Card'

interface Props {
  children?: ReactNode
  iconType?: 'Edit' | 'Add'
  onClick?: () => void
  title?: string
  detailsObject?: {}
}

const DetailsCard = ({
  children,
  iconType = 'Edit',
  onClick,
  title
}: Props) => {
  const styles = useStyles()

  const renderIcon = () => {
    if (!onClick) {
      return
    }

    return (
      <FontAwesomeIcon
        onClick={onClick}
        className={styles.icon}
        icon={iconType == 'Add' ? faPlus : faEdit}
        color={Colors.euro_600}
      />
    )
  }

  return (
    <Card className={styles.container}>
      {renderIcon()}
      {title && <h6>{title}</h6> }
      {children}
    </Card>
  )
}

export default DetailsCard

const useStyles = createUseStyles({
  container: {
    flex: 1,
    minWidth: 300,
    position: 'relative',
    padding: '15px 25px',
    '& h6': {
      marginBottom: 10,
    },
    '& p': {
      fontSize: 12,
      margin: 0,
      '& span': {
        fontFamily: 'Aestetico-Bold',
        color: Colors.darkGray2,
      }
    }
  },
  icon: {
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',
    transition: '0.4s',
    '&:hover': {
      color: Colors.euro5,
    }
  }
})
