import { restConfig } from "../authConfig"
import { useAppDispatch } from "../redux/hooks"
import { loading, paydayDetailsFetched, paydayDetailsFetchFailure, paydaysFetched, paydaysFetchFailure } from "../redux/slices/paydaySlice"
import { PaydayListItemModel, PaydayModel } from '../redux/types'
import { getPaginationDataFromResponse } from "../utils/responseHelper"
import { createUrlWithQueryString } from "../utils/url"
import useFetchClient from "./useFetchClient"

const usePaydayActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getPaydays = (employerId: string | null, includeCompleted: boolean, pageIndex: number, pageSize: number) => {
    dispatch(loading())   

    const params = {
      employerId: employerId,
      includeCompletedPayDays: includeCompleted,
      pageNumber: pageIndex,
      pageSize: pageSize
    }
    const url = createUrlWithQueryString(restConfig.routes.paydays, params)

    return fetchClient.get(url)
      .then(response => {
          const paginationData = getPaginationDataFromResponse(response.raw)
          dispatch(paydaysFetched({ items: response.data as PaydayListItemModel[], totalCount: paginationData?.totalItemCount ?? 0}))
      })
      .catch(error => {
          console.log('getPaydays error:', error)
          dispatch(paydaysFetchFailure(error))
      })
  }

  const getPaydayDetails = async (paydayId: string) => {
    dispatch(loading())   

    const url = restConfig.routes.paydays + `/${paydayId}`

    return fetchClient.get(url)
      .then(response => {
          dispatch(paydayDetailsFetched(response.data as PaydayModel))
      })
      .catch(error => {
          console.log('getPaydayDetails error:', error)
          dispatch(paydayDetailsFetchFailure(error))
      })
  }

  return {
    getPaydays,
    getPaydayDetails
  }
}

export default usePaydayActions