import {useEffect, useState} from 'react'
import {createUseStyles} from 'react-jss'
import {useHistory, useParams} from "react-router-dom";
import {Colors} from '../constants/colors'
import CardSection from '../components/CardSection'
import DetailsCard from '../components/DetailsCard'
import ConfirmModal from '../components/ConfirmModal'
import AddCompanyRepresentativeModal
  from '../components/CompanyMonitor/CompanyRepresentative/AddCompanyRepresentativeModal'
import {formatDateString} from '../utils/dateHelper'
import {useAppSelector} from '../redux/hooks';
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator';
import ManualCompanyRepresentativeList
  from '../components/CompanyMonitor/CompanyRepresentative/ManualCompanyRepresentativeList';
import CompanyReportChangeList from '../components/CompanyMonitor/CompanyReportChange/CompanyReportChangeList';
import CompanyReportChangeHandlingModal
  from '../components/CompanyMonitor/CompanyReportChange/CompanyReportChangeHandlingModal';
import Divider from '../components/Divider';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import NavLink from '../components/NavLink';
import useCompanyMonitorActions from '../hooks/useCompanyMonitorActions';
import useRouterQuery from '../hooks/useRouterQuery';
import {
  AddRepresentativeModel,
  CompanyReportChangeModel,
  ManualRepresentativeModel
} from '../redux/companyMonitorTypes';
import CompanyEventList from '../components/CompanyMonitor/CompanyEvent/CompanyEventList';
import Select from '../components/Select';
import CompanyReport from "../components/CompanyMonitor/CompanyReport";

const CompanyMonitoring = () => {
  const styles = useStyles()
  const params = useParams<{ marketId: string, orgNumber: string }>()
  const {query} = useRouterQuery()
  const history = useHistory();

  const [confirmDeleteRepresentativeModalVisible, setConfirmDeleteRepresentativeModalVisible] = useState(false)
  const [addRepresentativeModalVisible, setAddRepresentativeModalVisible] = useState(false)

  const [companyRepresentativeDeleteCandidate, setCompanyRepresentativeDeleteCandidate] = useState<ManualRepresentativeModel | null>(null)
  const [companyChangeHandlingModalVisible, setCompanyChangeHandlingModalVisible] = useState(false)
  const [companyChangeCommentModalVisible, setCompanyChangeCommentModalVisible] = useState(false)
  const [selectedCompanyChange, setSelectedCompanyChange] = useState<CompanyReportChangeModel | null>(null)

  const loading = useAppSelector(state => state.companyMonitor.loading)
  const details = useAppSelector(state => state.companyMonitor.details)

  const {
    getDetailsForCompany,
    addRepresentative,
    deleteRepresentative,
    handleCompanyReportChange,
    commentCompanyReportChange
  } = useCompanyMonitorActions()

  useEffect(() => {
    const reportId = query.get('reportId') || undefined

    if (details == null ||
      details.company.marketId !== params.marketId ||
      details.company.companyRegistrationNumber !== params.orgNumber ||
      details.report?.id != reportId) {
      getDetailsForCompany(params.marketId, params.orgNumber, reportId)
    }
  }, [])

  const handleDeleteCompanyRepresentative = (representative: ManualRepresentativeModel) => {
    setCompanyRepresentativeDeleteCandidate(representative)
    setConfirmDeleteRepresentativeModalVisible(true)
  }

  const handleConfirmDeleteCompanyRepresentative = () => {
    deleteRepresentative(details?.company.id as string, companyRepresentativeDeleteCandidate?.id || '')
    setCompanyRepresentativeDeleteCandidate(null)
    setConfirmDeleteRepresentativeModalVisible(false)
  }

  const handleAddCompanyRepresentative = (representative: AddRepresentativeModel) => {
    addRepresentative(details?.company.id as string, representative)
    setAddRepresentativeModalVisible(false)
  }

  const handleCompanyChangeClicked = (companyChange: CompanyReportChangeModel) => {
    setSelectedCompanyChange(companyChange);
    setCompanyChangeHandlingModalVisible(true);
  }

  const onCompanyChangeHandled = (comment: string) => {
    handleCompanyReportChange(details?.company.id as string, selectedCompanyChange?.id as string, comment)
    setCompanyChangeHandlingModalVisible(false)
  }

  const commentCompanyChangeClicked = (companyChange: CompanyReportChangeModel) => {
    setSelectedCompanyChange(companyChange);
    setCompanyChangeCommentModalVisible(true);
  }

  const onCompanyChangeComment = (comment: string) => {
    commentCompanyReportChange(details?.company.id as string, selectedCompanyChange?.id as string, comment)
    setCompanyChangeCommentModalVisible(false)
  }

  const onReportSelected = (id: string) => {
    if (details) {
      history.push(`/companymonitor/${details?.company.marketId}/${details?.company.companyRegistrationNumber}?reportId=${id}`)
      getDetailsForCompany(details?.company.marketId, details?.company.companyRegistrationNumber, id)
    }
  }

  const renderReportSelector = () => {
    return (
      <Select
        id='reportSelector'
        key='reportSelector'
        label=''
        options={details?.companyReportHistory.map((report) => {
          return {name: formatDateString(report.date), value: report.id}
        }) ?? []}
        selectedValue={details?.report?.id}
        onSelect={(value) => onReportSelected(value)}
      />
    )
  }

  return (
    <div className={styles.container}>
      <h1>{details?.company.name} ({details?.company.companyRegistrationNumber})</h1>
      <div className={styles.actionBar}>
        <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()}/>
      </div>

      <CardSection>
        <DetailsCard>
          <h6>Report</h6>
          {renderReportSelector()}
        </DetailsCard>
        <DetailsCard>
          <h6>Changes since last report</h6>
          <CompanyReportChangeList
            enableHandling={false}
            changes={details?.report?.changesSinceLastReport ?? []}/>
        </DetailsCard>
      </CardSection>

      <Divider height={50}/>

      {details?.report && <DetailsCard><CompanyReport report={details.report}/></DetailsCard>}

      <Divider height={50}/>

      <CardSection>
        <DetailsCard>
          <h6>Change handling changes</h6>
          <CompanyReportChangeList
            enableHandling={true}
            changes={details?.changeHandlingChanges ?? []}
            onHandleClick={handleCompanyChangeClicked}
            onCommentClick={commentCompanyChangeClicked}
          />
        </DetailsCard>
      </CardSection>

      <CardSection>
        <DetailsCard>
          <h6>Company events</h6>
          <CompanyEventList companyEvents={details?.events ?? []}/>
        </DetailsCard>
      </CardSection>

      <CardSection>
        <DetailsCard iconType='Add' onClick={() => setAddRepresentativeModalVisible(true)}>
          <h6>Manually handled representatives</h6>
          <ManualCompanyRepresentativeList
            representatives={details?.manuallyHandledRepresentatives ?? []}
            onDeleteClick={(companyRep) => handleDeleteCompanyRepresentative(companyRep)}
          />
        </DetailsCard>
      </CardSection>

      <Divider height={50}/>
      <ConfirmModal
        visible={confirmDeleteRepresentativeModalVisible}
        confirmText={`Are you sure you want to remove ${companyRepresentativeDeleteCandidate?.givenName} ${companyRepresentativeDeleteCandidate?.familyName}?`}
        onConfirmClick={handleConfirmDeleteCompanyRepresentative}
        onCancelClick={() => setConfirmDeleteRepresentativeModalVisible(false)}
      />
      <AddCompanyRepresentativeModal
        visible={addRepresentativeModalVisible}
        onAddClick={handleAddCompanyRepresentative}
        onCancelClick={() => setAddRepresentativeModalVisible(false)}
      />
      <CompanyReportChangeHandlingModal
        title='Handle change'
        visible={companyChangeHandlingModalVisible}
        companyChangeId={selectedCompanyChange?.id}
        onHideClick={() => setCompanyChangeHandlingModalVisible(false)}
        onSaveClick={onCompanyChangeHandled}
        onCancelClick={() => setCompanyChangeHandlingModalVisible(false)}
      />
      <CompanyReportChangeHandlingModal
        title='Comment'
        visible={companyChangeCommentModalVisible}
        companyChangeId={selectedCompanyChange?.id}
        onHideClick={() => setCompanyChangeCommentModalVisible(false)}
        onSaveClick={onCompanyChangeComment}
        onCancelClick={() => setCompanyChangeCommentModalVisible(false)}
      />
      <FullScreenLoadingIndicator visible={loading}/>
    </div>
  )
}

export default CompanyMonitoring

const useStyles = createUseStyles({
  container: {
    '& h5': {
      fontFamily: 'Aestetico-Regular',
      color: Colors.darkDefault,
    }
  },
  actionBar: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10
  },
  largeButton: {
    fontSize: "xxx-large",
    alignSelf: "center",
    margin: "20px",
    flexGrow: 1,
    transition: '0.4s',
    '&:hover': {
      color: Colors.euro5,
      cursor: 'pointer',
    }
  },
  icon: {
    marginRight: "5px",
  }
})
