import { ChangeEvent, FocusEvent, useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'
import { cardPadding } from '../constants/layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import S2 from './typography/S2'

interface Props {
  className?: string
  id: string
  value: string
  error?: string
  placeholder?: string
  rows?: number
  iconName?: string | IconDefinition
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (value: string) => void
  valid?: boolean
  disabled?: boolean
  focused?: boolean
  label?: string
  numeric?: boolean
}

const TextAreaField = ({
  id,
  className,
  value = '',
  placeholder = 'Placeholder',
  rows = 4,
  iconName,
  onChange = () => '',
  onBlur = () => '',
  valid = true,
  disabled = false,
  label
}: Props) => {
  const styles = useStyles()
  const [textAreaFieldValue, setTextAreaFieldValue] = useState(value)

  useEffect(() => {
    setTextAreaFieldValue(value)
  }, [value])

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaFieldValue(event.target.value)
    onChange(event)
  }

  const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    onBlur(event.target.value)
  }

  const renderIcon = () => {
    return iconName && (
      <FontAwesomeIcon
        className={styles.icon}
        icon={iconName as IconDefinition}
        color={Colors.darkDefault}
      />
    )
  }

  const renderLabel = () => {
    return label && (
      <S2 className={styles.label}>{label}</S2>
    )
  }

  const paddingLeft = iconName ? cardPadding * 2.5 : cardPadding

  return (
    <>
      {renderLabel()}
      <div className={`${styles.container} ${className}`}
           style={{borderColor: valid ? 'transparent' : Colors.rubel_700, borderWidth: valid ? 0 : 1}}>
        {renderIcon()}
        <textarea
          id={id}
          className={styles.textarea}
          style={{paddingLeft}}
          rows={rows}
          onBlur={handleBlur}
          onChange={handleChange}
          value={textAreaFieldValue}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
    </>
  )
}

export default TextAreaField

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    borderRadius: 5,
    borderStyle: 'solid',
    backgroundColor: Colors.darkGray2,
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  textarea: {
    width: '100%',
    border: 'none',
    borderRadius: 5,
    backgroundColor: 'transparent',
    fontFamily: 'Aestetico-Regular',
    fontSize: 16,
    padding: {
      top: cardPadding * 0.5,
      bottom: cardPadding * 0.5,
      right: cardPadding * 2.5,
      // left: cardPadding * 2.5,
    },
    '&:focus': {
      border: 'none',
      backgroundColor: Colors.darkGray3,
      outline: 'none',
    },
    '&:active': {
      backgroundColor: Colors.darkGray3,
    },
  },
  icon: {
    position: 'absolute',
    left: cardPadding,
  },
  label: {
    marginBottom: 8,
    marginTop: 8
  }
})
