import {useEffect, useState} from 'react'
import Modal from '../Modal'
import {
  CampaignRecipient,
} from '../../redux/types'
import TextAreaField from "../TextAreaField";

interface Props {
  initialValue: CampaignRecipient[] | undefined
  onSaveClick?: (recipients: string[]) => void
  onCancelClick?: () => void
  visible: boolean
}

const UpdateCampaignRecipientsModal = ({initialValue, onSaveClick, onCancelClick, visible}: Props) => {
  const [recipients, setRecipients] = useState<string[]>([])

  useEffect(() => {
    if (initialValue) {
      setRecipients(initialValue.map(x => x.id))
    }
    else {
      setRecipients([])
    }
  }, [initialValue])

  const handleOnSave = () => {
    onSaveClick && onSaveClick(recipients)
  }

  const handleOnCancel = () => {
    onCancelClick && onCancelClick()
  }

  const handleOnChange = (value: string) => {
    setRecipients(value.split(',').map(x => x.trim()).filter(x => !!x))
  }

  return (
    <Modal
      title='Edit recipients'
      show={visible}
      okButtonTitle='Save'
      cancelButtonTitle='Cancel'
      onHide={() => handleOnCancel()}
      onOkClick={() => handleOnSave()}
      onCancelClick={() => handleOnCancel()}
    >
      <TextAreaField
        id='recipients'
        label='Comma separated list of User Ids'
        placeholder='090D4CFD-D807-4FA7-89C1-792DA4B3E0EB, 10B2A02A-2A8E-4909-B801-61F3FF4C608E'
        value={recipients.join(', ')}
        onChange={(event) => handleOnChange(event.target.value)}
      />
    </Modal>
  )
}

export default UpdateCampaignRecipientsModal
