import { restConfig } from "../authConfig";
import { useAppDispatch } from "../redux/hooks";
import { creditorsFetched, creditorsFetchFailure, loading } from "../redux/slices/creditorSlice";
import { CreditorConnectionModel } from '../redux/types';
import useFetchClient from "./useFetchClient";

const useCreditorActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getCreditors = async (marketId: string) => {
    dispatch(loading())   

    const url = restConfig.routes.creditors + '?marketId=' + marketId

    return fetchClient.get(url)
      .then(response => {
          dispatch(creditorsFetched(response.data as CreditorConnectionModel[]))
      })
      .catch(error => {
          console.log('getCreditors error:', error)
          dispatch(creditorsFetchFailure(error))
      })  
  }

  return {
    getCreditors
  }
}

export default useCreditorActions
