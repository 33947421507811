import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from "react-router-dom"

import App from './App'
import './index.scss'
import { msalInstance } from './services/msalService'
import reduxStore from './redux/store'
import { Provider } from 'react-redux'

// import i18n (needs to be bundled ;))
import './localization/i18n'

const { store } = reduxStore

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App pca={msalInstance} />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
