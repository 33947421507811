import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { FC, ReactNode } from 'react'
import { loginRequest } from '../authConfig'
import AuthencticatedWrapper from './AuthenticatedWrapper'

interface PrivateRouteProps {
  children?: ReactNode
}

const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {

  const authRequest = {
    ...loginRequest
  }

  const LoadingComponent = () => {
    return <h1>Loading...</h1>
  }

  const ErrorComponent = () => {
    return <h1>Error</h1>
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent} 
    >
      <AuthencticatedWrapper>
        {children}
      </AuthencticatedWrapper>
    </MsalAuthenticationTemplate>
  )
}

export default PrivateRoute
