import { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { TransactionFilterParams } from '../../redux/types'
import TransactionFilter from './TransactionFilter'
import { filterChanged, pageIndexChanged } from '../../redux/slices/transactionSlice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import useTransactionActions from '../../hooks/useTransactionActions'
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect'
import FullScreenLoadingIndicator from '../FullScreenLoadingIndicator'
import TransactionList from './TransactionList'


const Transactions = () => {
  const styles = useStyles()
  const dispatch = useAppDispatch()
  
  const { getTransactions } = useTransactionActions()

  const loading = useAppSelector(state => state.transaction.loading)
  const transactionsOnPage = useAppSelector(state => state.transaction.transactionsOnPage)
  const totalCount = useAppSelector(state => state.transaction.totalCount)
  const pageIndex = useAppSelector(state => state.transaction.pageIndex)
  const pageSize = useAppSelector(state => state.transaction.pageSize)
  const filter = useAppSelector(state => state.transaction.filter)
  const error = useAppSelector(state => state.transaction.error)

  useEffect(() => {
    if (transactionsOnPage.length === 0) {
      getTransactions(filter, pageIndex, pageSize)
    }  
  }, [])

  useDidUpdateEffect(() => {
    getTransactions(filter, pageIndex, pageSize)
  }, [filter, pageIndex])

  const handleFilterChange = (filter: TransactionFilterParams) => {
    dispatch(filterChanged(filter))
  }

  const onPageChange = (pageIndex: number) => {
    dispatch(pageIndexChanged(pageIndex))
  }

  return (
    <div className={styles.container}>
      <TransactionFilter 
        initialFilterValue={filter ?? undefined}
        onFilterChange={handleFilterChange} 
      />
      <TransactionList
        transactionsOnPage={transactionsOnPage}
        pageIndex={pageIndex}
        pageSize={pageSize} 
        totalCount={totalCount}
        onPageChange={onPageChange}  
      />
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default Transactions

const useStyles = createUseStyles({
  container: {},
})
