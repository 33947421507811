import {restConfig} from "../../authConfig";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {
  frozenPayRunsFetched,
  frozenPayRunsFetchFailure,
  frozenPayRunsFilterChanged,
  frozenPayRunsLoading,
  frozenPayRunsPageIndexChanged,
  incompleteEmployerPayRunsFetched,
  incompleteEmployerPayRunsFetchFailure,
  incompleteEmployerPayRunsLoading,
  incompletePayRunsFetched,
  incompletePayRunsFetchFailure,
  incompletePayRunsFilterChanged,
  incompletePayRunsLoading,
  incompletePayRunsPageIndexChanged
} from "../../redux/slices/payRunDashboardSlice";
import {EmployerPayRunDashboardModel, PayRunModel} from "../../redux/types";
import useFetchClient from "../../hooks/useFetchClient"
import {createUrlWithQueryString} from "../../utils/url";
import {getPaginationDataFromResponse} from "../../utils/responseHelper";
import {useEffect} from "react";
import useDidUpdateEffect from "../../hooks/useDidUpdateEffect";

const usePayRunDashboardActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const incompleteEmployerPayRunPage = useAppSelector(state => state.payRunDashboard.incompleteEmployerPayRunPage)
  const incompletePayRunsPage = useAppSelector(state => state.payRunDashboard.incompletePayRuns)
  const frozenPayRunsPage = useAppSelector(state => state.payRunDashboard.frozenPayRuns)

  const setIncompletePayRunsPageIndex = (pageIndex: number) => {
    dispatch(incompletePayRunsPageIndexChanged(pageIndex))
  }

  const setIncompletePayRunsFilter = (filter: string) => {
    dispatch(incompletePayRunsFilterChanged(filter))
  }

  const setFrozenPayRunsPageIndex = (pageIndex: number) => {
    dispatch(frozenPayRunsPageIndexChanged(pageIndex))
  }

  const setFrozenPayRunsFilter = (filter: string) => {
    dispatch(frozenPayRunsFilterChanged(filter))
  }

  // Fetch the incomplete pay runs and frozen pay runs when the component mounts
  useEffect(() => {
    if (incompleteEmployerPayRunPage.employerPayRuns.length === 0 && !incompleteEmployerPayRunPage.loading) {
      getIncompleteEmployerPayRuns()
    }

    if (incompletePayRunsPage.payRuns.length === 0 && !incompletePayRunsPage.loading) {
      getIncompletePayRuns(
        incompletePayRunsPage.searchTerm,
        incompletePayRunsPage.pageIndex,
        incompletePayRunsPage.pageSize
      )
    }

    if (frozenPayRunsPage.payRuns.length === 0 && !frozenPayRunsPage.loading) {
      getFrozenPayRuns(
        frozenPayRunsPage.searchTerm,
        frozenPayRunsPage.pageIndex,
        frozenPayRunsPage.pageSize
      )
    }
  }, []);

  // When the incomplete pay runs page index or search term changes, fetch the incomplete pay runs
  useDidUpdateEffect(() => {
    getIncompletePayRuns(
      incompletePayRunsPage.searchTerm,
      incompletePayRunsPage.pageIndex,
      incompletePayRunsPage.pageSize
    )
  }, [incompletePayRunsPage.pageIndex, incompletePayRunsPage.searchTerm])

  // When the frozen pay runs page index or search term changes, fetch the frozen pay runs
  useDidUpdateEffect(() => {
    getFrozenPayRuns(
      frozenPayRunsPage.searchTerm,
      frozenPayRunsPage.pageIndex,
      frozenPayRunsPage.pageSize
    )
  }, [frozenPayRunsPage.pageIndex, frozenPayRunsPage.searchTerm])

  const getIncompleteEmployerPayRuns = () => {
    dispatch(incompleteEmployerPayRunsLoading())

    const url = restConfig.routes.payRun + '/employerPayRuns'

    return fetchClient.get(url)
      .then(response => {
        dispatch(incompleteEmployerPayRunsFetched(response.data as EmployerPayRunDashboardModel[]))
      })
      .catch(error => {
        console.log('incompleteEmployerPayRunsFetchFailure:', error)
        dispatch(incompleteEmployerPayRunsFetchFailure(error))
      })
  }

  const getIncompletePayRuns = (searchTerm: string, pageIndex: number, pageSize: number) => {
    dispatch(incompletePayRunsLoading())

    const params = {
      query: searchTerm,
      pageNumber: pageIndex,
      pageSize: pageSize,
      frozen: false
    }

    const url = createUrlWithQueryString(restConfig.routes.payRun + '/incompletePayRuns', params)

    return fetchClient.get(url)
      .then(response => {
        const paginationData = getPaginationDataFromResponse(response.raw)
        dispatch(incompletePayRunsFetched({
          items: response.data as PayRunModel[],
          totalCount: paginationData?.totalItemCount ?? 0
        }))
      })
      .catch(error => {
        console.log('incompletePayRunsFetchFailure:', error)
        dispatch(incompletePayRunsFetchFailure(error))
      })
  }

  const getFrozenPayRuns = (searchTerm: string, pageIndex: number, pageSize: number) => {
    dispatch(frozenPayRunsLoading())

    const params = {
      query: searchTerm,
      pageNumber: pageIndex,
      pageSize: pageSize,
      frozen: true
    }
    const url = createUrlWithQueryString(restConfig.routes.payRun + '/incompletePayRuns', params)

    return fetchClient.get(url)
      .then(response => {
        const paginationData = getPaginationDataFromResponse(response.raw)
        dispatch(frozenPayRunsFetched({
          items: response.data as PayRunModel[],
          totalCount: paginationData?.totalItemCount ?? 0
        }))
      })
      .catch(error => {
        console.log('frozenPayRunsFetchFailure: ', error)
        dispatch(frozenPayRunsFetchFailure(error))
      })
  }

  const pausePayRun = (payRunId: string) => {
    const url = restConfig.routes.payRun + '/' + payRunId + '/pause'

    return fetchClient.post(url)
      .then(response => {
        console.log('pausePayRun response:', response)
      })
      .catch(error => {
        console.log('pausePayRun error:', error)
      })
  }

  const resumePayRun = (payRunId: string) => {
    const url = restConfig.routes.payRun + '/' + payRunId + '/resume'

    return fetchClient.post(url)
      .then(response => {
        console.log('resumePayRun response:', response)
      })
      .catch(error => {
        console.log('resumePayRun error:', error)
      })
  }

  const completePayRun = (payRunId: string) => {
    const url = restConfig.routes.payRun + '/' + payRunId + '/complete'

    return fetchClient.post(url)
      .then(response => {
        console.log('completePayRun response:', response)
      })
      .catch(error => {
        console.log('completePayRun error:', error)
      })
  }

  return {
    incompleteEmployerPayRunPage,
    incompletePayRunsPage,
    frozenPayRunsPage,
    setIncompletePayRunsPageIndex,
    setIncompletePayRunsFilter,
    setFrozenPayRunsPageIndex,
    setFrozenPayRunsFilter,
    getIncompleteEmployerPayRuns,
    getIncompletePayRuns,
    getFrozenPayRuns,
    pausePayRun,
    resumePayRun,
    completePayRun
  }
}

export default usePayRunDashboardActions
