import { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator'
import useFortnoxActions from '../hooks/useFortnoxActions'
import { useHistory } from 'react-router-dom'
import { fortnoxIntegrationActivationHandled } from '../redux/slices/fortnoxSlice'

const FortnoxActivation = () => {
  const styles = useStyles()
  const history = useHistory();
  const dispatch = useAppDispatch()

  const { activateIntegration } = useFortnoxActions()

  const loading = useAppSelector(state => state.fortnox.loading)
  const activationSuccess = useAppSelector(state => state.fortnox.activationSuccess)

  const code = (new URLSearchParams(window.location.search)).get("code")
  const state = (new URLSearchParams(window.location.search)).get("state")

  useEffect(() => {
    if (code && state) {
      activateIntegration(code, state)  
    }
  }, [])

  useEffect(() => {
    if (activationSuccess) {
      dispatch(fortnoxIntegrationActivationHandled())   
      history.push(`/fortnox`)  
    }
  }, [activationSuccess])

  return (
    <div className={styles.container}>
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default FortnoxActivation

const useStyles = createUseStyles({
  container: {
  }
})