import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PagedListItems, PayRunFilterParams, PayRunListItemModel, PayRunModel } from '../types'

interface PayRunState {
    loading: boolean
    payRunDetails: PayRunModel | null
    payRunsOnPage: PayRunListItemModel[]
    pageIndex: number
    totalCount: number
    pageSize: number 
    filter: PayRunFilterParams | null
    error: Error | string | null
}

const initialState: PayRunState = {
    loading: false,
    payRunDetails: null,
    payRunsOnPage: [],
    totalCount: 0,
    pageIndex: 1,
    pageSize: 10,
    filter: null,
    error: null
}

export const payRunSlice = createSlice({
    name: 'payRun',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        payRunsFetched: (state, action: PayloadAction<PagedListItems<PayRunListItemModel>>) => {
            state.loading = false
            state.payRunsOnPage = action.payload.items
            state.totalCount = action.payload.totalCount
            state.error = null
        },
        payRunsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        payRunDetailsFetched: (state, action: PayloadAction<PayRunModel>) => {
            state.loading = false
            state.payRunDetails = action.payload
            state.error = null
        },
        payRunDetailsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        filterChanged: (state, action: PayloadAction<PayRunFilterParams>) => {
            state.filter = action.payload
            state.pageIndex = 1
        },
    }
})

export const { 
    loading, 
    payRunsFetched, 
    payRunsFetchFailure, 
    payRunDetailsFetched, 
    payRunDetailsFetchFailure, 
    pageIndexChanged, 
    filterChanged 
} = payRunSlice.actions

export default payRunSlice.reducer