import {createUseStyles} from "react-jss"
import {Colors} from "../../constants/colors"
import {ImageModel} from "../../redux/types"
import Card from "../Card"
import {titleMarginBottom} from "../../constants/layout";
import {Toolbar, ToolbarItem} from "./Toolbar";
import {faDownload, faEdit, faEraser, faPaste} from "@fortawesome/free-solid-svg-icons";
import {Image} from "react-bootstrap";

interface Props {
  image: ImageModel,
  onClick?: (image: ImageModel) => void,
  onCopyUri?: (image: ImageModel) => void,
  onEdit?: (image: ImageModel) => void,
  onDelete?: (image: ImageModel) => void,
}

const ImageAssetCard = ({image, onClick, onCopyUri, onEdit, onDelete}: Props) => {
  const styles = useStyles()

  const handleCopyUri = () => {
    navigator.clipboard.writeText(image.uri)
    onCopyUri && onCopyUri(image)
  }

  const handleOpenExternal = () => {
    window.open(image.uri)
  }

  return (
    <Card className={`${styles.imageAssetCard} card`} onClick={() => onClick && onClick(image)}>
      <div className={styles.titleBar}>
        <h6 style={{flex: '1 1', overflowWrap: 'anywhere' }}>{image.name}</h6>
        <Toolbar>
          <ToolbarItem icon={faDownload} title={"Download"} display={"icon"} onClick={handleOpenExternal}/>
          {onCopyUri && <ToolbarItem icon={faPaste} title={"Copy URI"} display={"icon"} onClick={handleCopyUri}/>}
          {onEdit && <ToolbarItem icon={faEdit} title={"Edit"} display={"icon"} onClick={() => onEdit(image)}/>}
          {onDelete && <ToolbarItem icon={faEraser} title={"Delete"} display={"icon"} onClick={() => onDelete(image)}/>}
        </Toolbar>
      </div>
      <Image src={image.uri} rounded fluid/>
    </Card>
  )
}

export default ImageAssetCard

const useStyles = createUseStyles({
  imageAssetCard: {
    borderRadius: 8,
    display: 'flex',
    flex: 1,
    flexBasis: '30%',
    minWidth: 250,
    maxWidth: 400,
    padding: 14,
    gap: 8,
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  image: {
    backgroundColor: Colors.grayBackground,
    height: '130px',
    borderRadius: 8,
    objectFit: 'contain',
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: titleMarginBottom,
  }
})
