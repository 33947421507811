import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BankAccountModel } from '../types'

interface BankAccountState {
    loading: boolean
    bankAccounts: BankAccountModel[]
    error: Error | string | null
  }

const initialState: BankAccountState = {
    loading: false,
    bankAccounts: [],
    error: null
}

export const bankAccountSlice = createSlice({
    name: 'bankAccount',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        bankAccountsFetched: (state, action: PayloadAction<BankAccountModel[]>) => {
            state.loading = false
            state.bankAccounts = action.payload
        },
        bankAccountsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        bankAccountTransactionSyncRequested: (state) => {
            state.loading = false
        },
        bankAccountTransactionSyncRequestFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
    }
})

export const { 
    loading, 
    bankAccountsFetched, 
    bankAccountsFetchFailure,
    bankAccountTransactionSyncRequested,
    bankAccountTransactionSyncRequestFailure
} = bankAccountSlice.actions

export default bankAccountSlice.reducer