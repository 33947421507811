import { FC } from 'react'
import Card from '../Card'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../constants/colors'
import Button from '../Button'
import { ReconciliationIssueModel } from '../../redux/types'

interface Props {
  reconciliationIssues: ReconciliationIssueModel[]
  onHandleClick?: (reconciliationIssue: ReconciliationIssueModel) => void
}

const ReconciliationIssueList = ({ reconciliationIssues, onHandleClick }: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        <div className={styles.cell}>
          <h6>Details</h6>
        </div>
        <div className={styles.cell}>
          <h6>Comment</h6>
        </div>
        <div className={`${styles.cell} ${styles.handleButtonCell}`}>
          <h6>Handle</h6>
        </div>
      </Card>
    )
  }

  const renderListItems = () => {
    return reconciliationIssues?.map((item, index) => {
      return (
        <Card className={`${styles.container} card`} key={`reconciliationIssueListItem${index}`}>
          <div className={styles.cell}>
            {item.details}
          </div>
          <div className={styles.cell}>
            {item.handledComment}
          </div>
          <div className={`${styles.cell} ${styles.handleButtonCell}`}>
            {renderHandleButton(item)} 
          </div>
        </Card>
      )
    })
  }

  const renderHandleButton = (issueModel: ReconciliationIssueModel) => {
     if (!issueModel.handled)  
      return (
        <Button
            onClick={() => onHandleClick && onHandleClick(issueModel) }
            title='Set as handled'
            disabled={issueModel.handled}
          />) 
  }

  return (
    <div>
      {renderHeader()}
      {renderListItems()}
    </div>
  )
}

export default ReconciliationIssueList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    margin: '10px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    margin: '15px 0 20px 0',
  },
  cell: {
    flex: 1,
    padding: '0px 10px'
  },
  handleButtonCell: {
    textAlign: 'center',
    flex: '0 0 200px'
  },
})
