import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Colors } from '../../constants/colors'
import { CustomerListItemModel } from '../../redux/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faFileInvoice, faLock, faUsers } from '@fortawesome/free-solid-svg-icons'
import Card from '../Card'
import Pagination from '../Pagination'
import { CompanyModel } from '../../redux/companyMonitorTypes'

export interface Props {
  customersOnPage: CustomerListItemModel[]
  pageIndex: number
  pageSize: number
  totalCount: number
  onPageChange: (pageIndex: number) => void
  companiesWithUnhandledChanges: CompanyModel[]
}

const CustomerList = ({ customersOnPage, pageIndex, pageSize, totalCount, onPageChange, companiesWithUnhandledChanges }: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        <div className={styles.cell}>
          <h6>Name</h6>
        </div>
        <div className={styles.cell}>
          <h6>Company registration number</h6>
        </div>
        <div className={`${styles.cell} ${styles.iconCell}`}>
          <h6>Employer</h6>
        </div>
        <div className={`${styles.cell} ${styles.iconCell}`}>
          <h6>Invoice payout provider</h6>
        </div>
        <div className={`${styles.cell} ${styles.iconCell}`}>
          <h6>Changed</h6>
        </div>
        <div className={`${styles.cell} ${styles.iconCell}`}>
          <h6>Locked</h6>
        </div>
      </Card>
    )    
  }

  const renderListItems = () => {
    return customersOnPage?.map((item, index) => {
      return (
        <Link to={`/customers/${item.id}`} key={`customer_${index}`}>
          <Card className={`${styles.container} card`}>
            <div className={styles.cell}>
              {item.legalName}
            </div>
            <div className={styles.cell}>
              {item.companyRegistrationNumber}
            </div>
            <div className={`${styles.cell} ${styles.iconCell}`}>
              {renderEmployerIndicator(item)}
            </div>
            <div className={`${styles.cell} ${styles.iconCell}`}>
              {renderInvoicePayoutProviderIndicator(item)}
            </div>
            <div className={`${styles.cell} ${styles.iconCell}`}>
              {renderChangedIndicator(item)}
            </div>
            <div className={`${styles.cell} ${styles.iconCell}`}>
              {renderLockedIndicator(item)}
            </div>
          </Card>
        </Link>
      )
    })
  }

  const renderEmployerIndicator = (customerListItem: CustomerListItemModel) => {
    if (customerListItem.employer) {
      return (<Link to={`/employers/${customerListItem.employer.id}`} key={`${customerListItem.employer.id}`}>
        <FontAwesomeIcon
          icon={faUsers}
          color={customerListItem.employer.state == 'active' ? Colors.euro_600 : Colors.darkGray1}
        /></Link>) 
    } else {
      return (<p></p>)
    }
  }

  const renderInvoicePayoutProviderIndicator = (customerListItem: CustomerListItemModel) => {
    if (customerListItem.invoicePayoutProvider) {
      return (<Link to={`/invoicepayoutproviders/${customerListItem.invoicePayoutProvider.id}`} key={`${customerListItem.invoicePayoutProvider.id}`}>
        <FontAwesomeIcon
          icon={faFileInvoice}
          color={customerListItem.invoicePayoutProvider.state == 'active' ? Colors.euro_600 : Colors.darkGray1}
        /></Link>) 
    } else {
      return (<p></p>)
    }
  }

  const renderLockedIndicator = (customerListItem: CustomerListItemModel) => {
    if (customerListItem.state == 'locked') {
      return (<FontAwesomeIcon
          icon={faLock}
          color={Colors.euro_600}
        />) 
    } else {
      return (<p></p>)
    }
  }

  const renderChangedIndicator = (customerListItem: CustomerListItemModel) => {
    const hasUnhandledChanges = companiesWithUnhandledChanges.find(x => x.marketId == customerListItem?.marketId && x.companyRegistrationNumber == customerListItem.companyRegistrationNumber) != undefined

    if (hasUnhandledChanges) {
      return (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          color={Colors.yellow}
          title='Unhandled changes'
        />
      )
    } else {
      return (<p></p>)
    }
  }

  return (
    <div>
      {renderHeader()}
      {renderListItems()}
      <div className={styles.pagination}>
        <Pagination
          pageIndex={pageIndex}
          itemCount={totalCount}
          pageSize={pageSize}
          siblingCount={1}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  )
}

export default CustomerList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    margin: '10px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    margin: '30px 0 20px 0',
  },
  cell: {
    flex: 1
  },
  iconCell: {
    textAlign: 'center',
    flex: '0 0 80px'
  },
  pagination: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center'
  }
})
