import {restConfig} from "../authConfig";
import {useAppDispatch} from "../redux/hooks";
import {
  inAppMessageCreated,
  inAppMessageCreateFailure,
  inAppMessageFetched,
  inAppMessageFetchFailure,
  inAppMessagesFetched,
  inAppMessagesFetchFailure,
  inAppMessageUpdated,
  inAppMessageUpdateFailure,
  loading
} from "../redux/slices/inAppMessageSlice";
import {
  CreateOrUpdateInAppMessageModel,
  InAppMessage, InAppMessageTranslation
} from '../redux/types';

import useFetchClient from "./useFetchClient";

const useInAppMessageActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getInAppMessages = () => {
    dispatch(loading())

    return fetchClient.get(restConfig.routes.inAppMessages)
      .then(response => {
        dispatch(inAppMessagesFetched(response.data as InAppMessage[]))
      })
      .catch(error => {
        console.log('getInAppMessages:', error)
        dispatch(inAppMessagesFetchFailure(error))
      })
  }

  const getInAppMessage = async (id: string) => {
    dispatch(loading())

    const url = restConfig.routes.inAppMessages + `/${id}`

    return fetchClient.get(url)
      .then(response => {
        dispatch(inAppMessageFetched(response.data as InAppMessage))
      })
      .catch(error => {
        console.log('getInAppMessage error:', error)
        dispatch(inAppMessageFetchFailure(error))
      })
  }

  const createInAppMessage = async (model: CreateOrUpdateInAppMessageModel) => {
    dispatch(loading())

    const url = restConfig.routes.inAppMessages

    return fetchClient.post(url, model)
      .then(response => {
        dispatch(inAppMessageCreated(response.data as InAppMessage))
      })
      .catch(error => {
        console.log('createInAppMessage error:', error)
        dispatch(inAppMessageCreateFailure(error))
      })
  }

  const updateInAppMessage = async (id: string, model: CreateOrUpdateInAppMessageModel) => {
    dispatch(loading())

    const url = restConfig.routes.inAppMessages + `/${id}`

    return fetchClient.post(url, model)
      .then(response => {
        dispatch(inAppMessageUpdated(response.data as InAppMessage))
      })
      .catch(error => {
        console.log('updateInAppMessage error:', error)
        dispatch(inAppMessageUpdateFailure(error))
      })
  }

  const publishInAppMessage = async (id: string) => {
    dispatch(loading())

    const url = restConfig.routes.inAppMessages + `/${id}/publish`

    return fetchClient.post(url)
      .then(response => {
        dispatch(inAppMessageUpdated(response.data as InAppMessage))
      })
      .catch(error => {
        console.log('publishInAppMessage error:', error)
        dispatch(inAppMessageUpdateFailure(error))
      })
  }

  const unpublishInAppMessage = async (id: string) => {
    dispatch(loading())

    const url = restConfig.routes.inAppMessages + `/${id}/unpublish`

    return fetchClient.post(url)
      .then(response => {
        dispatch(inAppMessageUpdated(response.data as InAppMessage))
      })
      .catch(error => {
        console.log('unpublishInAppMessage error:', error)
        dispatch(inAppMessageUpdateFailure(error))
      })
  }

  const setTranslation = async (inAppMessageId: string, translation: InAppMessageTranslation) => {
    dispatch(loading())

    const url = restConfig.routes.inAppMessages + `/${inAppMessageId}/translation`

    return fetchClient.post(url, translation)
      .then(response => {
        dispatch(inAppMessageUpdated(response.data as InAppMessage))
      })
      .catch(error => {
        console.log('setTranslation error:', error)
        dispatch(inAppMessageUpdateFailure(error))
      })
  }

  return {
    getInAppMessages,
    getInAppMessage,
    createInAppMessage,
    updateInAppMessage,
    publishInAppMessage,
    unpublishInAppMessage,
    setTranslation
  }
}

export default useInAppMessageActions