import React, {useEffect, useState} from 'react'
import Modal from '../Modal'
import {LearningModuleQuizQuestionModel, LearningModuleQuizQuestionType} from '../../redux/types'
import InputField from '../InputField'
import MultilingualInputField from "./MultilingualInputField";
import Select from "../Select";
import {createUseStyles} from "react-jss";
import {Toolbar, ToolbarItem} from "./Toolbar";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Alert} from "react-bootstrap";
import Form from 'react-bootstrap/Form'

interface Props {
  initialValue?: LearningModuleQuizQuestionModel | undefined
  onOkClick?: (model: LearningModuleQuizQuestionModel) => void
  onCancelClick?: () => void
  onRemoveClick?: () => void
  visible: boolean
}

const newQuestion = {
  id: null,
  published: true,
  question: {},
  explanation: {},
  orderNumber: 9,
  type: LearningModuleQuizQuestionType.YesNo,
  alternatives: [],
} as LearningModuleQuizQuestionModel

const EditQuizQuestionModal = ({initialValue, onOkClick, onCancelClick, onRemoveClick, visible}: Props) => {
  const styles = useStyles()
  const [editModel, setEditModel] = useState<LearningModuleQuizQuestionModel>(newQuestion)
  const isNewQuestion = editModel.id === null

  useEffect(() => {
    reset()
  }, [initialValue])


  const reset = () => {
    if (initialValue) {
      setEditModel({
        id: initialValue.id,
        published: initialValue.published,
        question: initialValue.question,
        explanation: initialValue.explanation,
        orderNumber: initialValue.orderNumber,
        type: initialValue.type,
        alternatives: initialValue.alternatives
      })
    } else {
      setEditModel(newQuestion)
    }
  }

  const handleOnOk = () => {
    onOkClick && onOkClick(editModel)
    reset()
  }

  const handleOnCancel = () => {
    onCancelClick && onCancelClick()
    reset()
  }

  const handleOnChange = (propName: string, value: any) => {
    setEditModel({...editModel, [propName]: value})
  }

  const handleOnChangeAlternative = (index: number, propName: string, value: any) => {
    const newAlternatives = [...editModel.alternatives]
    const alternative = newAlternatives[index]
    if (!alternative) {
      return
    }
    newAlternatives[index] = {...alternative, [propName]: value}
    handleOnChange('alternatives', newAlternatives)
  }

  const addAlternative = () => {
    setEditModel({
      ...editModel,
      alternatives: [...editModel.alternatives, {
        id: null,
        published: true,
        answer: {
          sv: 'Nytt alternativ',
          en: 'New alternative'
        },
        description: {},
        isCorrect: false,
        orderNumber: 10
      }]
    })
  }

  const removeAlternative = (index: number) => {
    setEditModel({
      ...editModel,
      alternatives: editModel.alternatives.filter((_, i) => i !== index)
    })
  }

  const getQuestionTypeOptions = () => {
    return Object.keys(LearningModuleQuizQuestionType)
      .map((key) => {
        return {
          name: key,
          value: LearningModuleQuizQuestionType[key as keyof typeof LearningModuleQuizQuestionType]
        }
      })
  }

  const renderAlternatives = () => {
    return (
      editModel.alternatives.map((alternative, index) => {
        const alternativeStyle = alternative.published && alternative.id ? styles.alternative : styles.unpublishedAlternative

        return (
          <div className={alternativeStyle} key={index}>
            <div className={styles.titleBar}>
              <h4>Alternative {index + 1}</h4>
              <Toolbar>
                <Form.Switch
                  id={'isCorrect_' + index}
                  key={`alternative_${index}_correct`}
                  label='Correct'
                  checked={alternative.isCorrect}
                  onChange={() => handleOnChangeAlternative(index, 'isCorrect', !alternative.isCorrect)}
                />
                <Form.Switch
                  id={'isPublished_' + index}
                  key={`alternative_${index}_published`}
                  label='Published'
                  checked={alternative.published}
                  onChange={() => handleOnChangeAlternative(index, 'published', !alternative.published)}
                />
                <ToolbarItem
                  icon={faMinus}
                  disabled={!!alternative.id}
                  title={"Remove"}
                  display={"iconAndText"}
                  onClick={() => removeAlternative(index)}
                />
              </Toolbar>
            </div>
            <InputField
              id='alternativeOrderNumber'
              value={alternative.orderNumber.toString()}
              onChange={(event) => handleOnChangeAlternative(index, 'orderNumber', event.target.value)}
              label='Order Number'
              numeric
              placeholder='10'
            />
            <MultilingualInputField
              id={'answer_' + index}
              key={`alternative_${index}_answer`}
              label={'Answer'}
              translations={alternative.answer}
              onChange={(value) => handleOnChangeAlternative(index, 'answer', value)}
            />
            <MultilingualInputField
              id={'description_' + index}
              key={`alternative_${index}_description`}
              label={'Description'}
              translations={alternative.description}
              onChange={(value) => handleOnChangeAlternative(index, 'description', value)}
            />
          </div>
        )
      })
    )
  }


  return (
    <Modal
      title={isNewQuestion ? 'Create question' : 'Edit question'}
      show={visible}
      okButtonTitle='OK'
      cancelButtonTitle='Cancel'
      scrollable={true}
      onHide={handleOnCancel}
      onOkClick={handleOnOk}
      onCancelClick={handleOnCancel}
      size={'xl'}
    >
      <div className={styles.container}>
        {
          !isNewQuestion &&
            <Alert variant={"warning"}>
                <p>
                    When editing questions, avoid editing it in a way that changes the way users would answer it,
                    e.g. negating the question or changing the correctness of alternatives. This is because users
                    may already have answered the question, and changing it would invalidate their answers. Fix typos
                    etc, but for larger changes, it's better to create a new question.
                </p>
            </Alert>
        }
        <div className={styles.editor}>
          <div className={styles.titleBar}>
            <h2>Question</h2>
            <Toolbar>
              <Form.Switch
                id={'isPublished'}
                label='Published'
                checked={editModel.published}
                onChange={() => handleOnChange('published', !editModel.published)}
              />
              <ToolbarItem
                icon={faMinus}
                disabled={!isNewQuestion}
                title={"Remove question"}
                display={"iconAndText"}
                onClick={onRemoveClick}
              />
            </Toolbar>
          </div>
          <Select
            id='questionType'
            label='Type'
            options={getQuestionTypeOptions()}
            selectedValue={editModel.type}
            onSelect={(value) => handleOnChange('type', value)}
          />
          <MultilingualInputField
            id={'question'}
            label={'Question'}
            translations={editModel.question}
            onChange={(value) => handleOnChange('question', value)}
          />
          <MultilingualInputField
            id={'explanation'}
            label={'Explanation'}
            translations={editModel.explanation}
            onChange={(value) => handleOnChange('explanation', value)}
          />
          <InputField
            id='questionOrderNumber'
            value={editModel.orderNumber.toString() || ''}
            onChange={(event) => handleOnChange('orderNumber', event.target.value)}
            label='Order Number'
            numeric
            placeholder='3'
          />
        </div>

        <div className={styles.editor}>
          <div className={styles.titleBar}>
            <h2>Alternatives</h2>
            <Toolbar>
              <ToolbarItem
                icon={faPlus}
                title={"New alternative"}
                display={"iconAndText"}
                onClick={addAlternative}
              />
            </Toolbar>
          </div>
          {renderAlternatives()}
        </div>
      </div>
    </Modal>
  )
}

export default EditQuizQuestionModal

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    gap: 20
  },
  editor: {
    minWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: '10px'
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  alternative: {
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 8,
  },
  unpublishedAlternative: {
    borderRadius: 8,
    borderStyle: 'dashed',
    borderWidth: 1,
    padding: 8,
  }
})
