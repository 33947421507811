import { useState } from "react"
import InputField from "../InputField"
import Modal from "../Modal"

interface Props {
    defaultValue?: number
    onSaveClick?: (tear: number) => void
    onCancelClick?: () => void
    visible: boolean
}

const GeneratePaydaysForYearModal = ({defaultValue, onSaveClick, onCancelClick, visible}: Props) => {
    const [year, setYear] = useState<number>(defaultValue ?? 2022)

    const handleOnSave = () => {
        if (year >= 2022) {
            onSaveClick && onSaveClick(year)       
        }
    }

    const handleOnClose = () => {
        onCancelClick && onCancelClick()
    }

    return (
        <Modal
            title='Generate paydays for year'
            show={visible}
            okButtonTitle='Generate'
            cancelButtonTitle='Cancel'
            onHide={() => handleOnClose()}
            onOkClick={() => handleOnSave()}
            onCancelClick={() => handleOnClose()}
        >
            <InputField
                id='year'
                numeric={true}
                value={year.toString()}
                onChange={(event) => setYear(parseFloat(event.target.value))}
                label='Year'
                placeholder=''
              />
        </Modal>
    )
}

export default GeneratePaydaysForYearModal