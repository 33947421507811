import {restConfig} from "../authConfig";
import {useAppDispatch} from "../redux/hooks";
import {
  employeeDetailsFetched,
  employeeDetailsFetchFailure,
  employeesFetched,
  employeesFetchFailure,
  loading
} from "../redux/slices/employeeSlice";
import {EmployeeListItemModel, EmployeeModel} from '../redux/types';
import {getPaginationDataFromResponse} from "../utils/responseHelper";
import {createUrlWithQueryString} from "../utils/url";
import useFetchClient from "./useFetchClient";

const useEmployeeActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getEmployees = (employerId: string | null, searchTerm: string, pageIndex: number, pageSize: number) => {
    dispatch(loading())

    const params = {
      employerId: employerId,
      query: searchTerm,
      pageNumber: pageIndex,
      pageSize: pageSize
    }
    const url = createUrlWithQueryString(restConfig.routes.employees, params)

    return fetchClient.get(url)
      .then(response => {
        const paginationData = getPaginationDataFromResponse(response.raw)
        dispatch(employeesFetched({
          items: response.data as EmployeeListItemModel[],
          totalCount: paginationData?.totalItemCount ?? 0
        }))
      })
      .catch(error => {
        console.log('getEmployees error:', error)
        dispatch(employeesFetchFailure(error))
      })
  }

  const getEmployeeDetails = (employeeId: string) => {
    dispatch(loading())

    const url = restConfig.routes.employees + `/${employeeId}`

    return fetchClient.get(url)
      .then(response => {
        dispatch(employeeDetailsFetched(response.data as EmployeeModel))
      })
      .catch(error => {
        console.log('getEmployeeDetails error:', error)
        dispatch(employeeDetailsFetchFailure(error))
      })
  }

  const fetchEmployees = async (employerId: string | null, searchTerm: string): Promise<EmployeeListItemModel[]> => {
    const MAX_EMPLOYEES_TO_FETCH = 50
    let allEmployees: EmployeeListItemModel[] = []
    let pageIndex = 1
    let totalItemCount = 0

    do {
      const params = {
        employerId: employerId,
        query: searchTerm,
        pageNumber: pageIndex,
        pageSize: MAX_EMPLOYEES_TO_FETCH
      }
      const url = createUrlWithQueryString(restConfig.routes.employees, params)

      const response = await fetchClient.get(url)
      const paginationData = getPaginationDataFromResponse(response.raw)

      if (paginationData?.totalItemCount) {
        totalItemCount = paginationData.totalItemCount
      }

      allEmployees = allEmployees.concat(response.data as EmployeeListItemModel[])
      pageIndex++
    } while (allEmployees.length < totalItemCount)

    return allEmployees
  }

  return {
    getEmployees,
    getEmployeeDetails,
    fetchEmployees
  }
}

export default useEmployeeActions
