import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {EmployerPayRunDashboardModel, PagedListItems, PayRunModel} from '../types'

export interface EmployerPayRunPage {
    loading: boolean,
    employerPayRuns: EmployerPayRunDashboardModel[]
    error: Error | string | null
}

export interface PayRunPage {
    loading: boolean,
    payRuns: PayRunModel[]
    pageIndex: number
    totalCount: number
    pageSize: number
    searchTerm: string
    error: Error | string | null
}

interface PayRunDashboardState {
    autoUpdateInterval: number | null
    incompleteEmployerPayRunPage: EmployerPayRunPage
    incompletePayRuns: PayRunPage
    frozenPayRuns: PayRunPage
}

const initialState: PayRunDashboardState = {
    autoUpdateInterval: null,
    incompleteEmployerPayRunPage: {
        loading: false,
        employerPayRuns: [],
        error: null
    },
    incompletePayRuns: {
        loading: false,
        payRuns: [],
        pageIndex: 1,
        totalCount: 0,
        pageSize: 20,
        searchTerm: '',
        error: null
    },
    frozenPayRuns: {
        loading: false,
        payRuns: [],
        pageIndex: 1,
        totalCount: 0,
        pageSize: 20,
        searchTerm: '',
        error: null
    }
}

export const payRunDashboardSlice = createSlice({
    name: 'payRunDashboardData',
    initialState,
    reducers: {
        autoUpdateIntervalChanged: (state, action: PayloadAction<number | null>) => {
            state.autoUpdateInterval = action.payload
        },
        incompleteEmployerPayRunsLoading: state => {
            state.incompleteEmployerPayRunPage.loading = true
            state.incompleteEmployerPayRunPage.error = null
        },
        incompleteEmployerPayRunsFetched: (state, action: PayloadAction<EmployerPayRunDashboardModel[]>) => {
            state.incompleteEmployerPayRunPage.loading = false
            state.incompleteEmployerPayRunPage.employerPayRuns = action.payload
            state.incompleteEmployerPayRunPage.error = null
        },
        incompleteEmployerPayRunsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.incompleteEmployerPayRunPage.loading = false
            state.incompleteEmployerPayRunPage.error = action.payload
        },
        incompletePayRunsLoading: state => {
            state.incompletePayRuns.loading = true
            state.incompletePayRuns.error = null
        },
        incompletePayRunsFetched: (state, action: PayloadAction<PagedListItems<PayRunModel>>) => {
            state.incompletePayRuns.loading = false
            state.incompletePayRuns.payRuns = action.payload.items
            state.incompletePayRuns.totalCount = action.payload.totalCount
            state.incompletePayRuns.error = null
        },
        incompletePayRunsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.incompletePayRuns.loading = false
            state.incompletePayRuns.error = action.payload
        },
        incompletePayRunsPageIndexChanged: (state, action: PayloadAction<number>) => {
            state.incompletePayRuns.pageIndex = action.payload
        },
        incompletePayRunsFilterChanged: (state, action: PayloadAction<string>) => {
            state.incompletePayRuns.searchTerm = action.payload
        },
        frozenPayRunsLoading: state => {
            state.frozenPayRuns.loading = true
            state.frozenPayRuns.error = null
        },
        frozenPayRunsFetched: (state, action: PayloadAction<PagedListItems<PayRunModel>>) => {
            state.frozenPayRuns.loading = false
            state.frozenPayRuns.payRuns = action.payload.items
            state.frozenPayRuns.totalCount = action.payload.totalCount
            state.frozenPayRuns.error = null
        },
        frozenPayRunsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.frozenPayRuns.loading = false
            state.frozenPayRuns.error = action.payload
        },
        frozenPayRunsPageIndexChanged: (state, action: PayloadAction<number>) => {
            state.frozenPayRuns.pageIndex = action.payload
        },
        frozenPayRunsFilterChanged: (state, action: PayloadAction<string>) => {
            state.frozenPayRuns.searchTerm = action.payload
        }
    }
})

export const {
    autoUpdateIntervalChanged,
    incompleteEmployerPayRunsLoading,
    incompleteEmployerPayRunsFetched,
    incompleteEmployerPayRunsFetchFailure,
    incompletePayRunsLoading,
    incompletePayRunsFetched,
    incompletePayRunsFetchFailure,
    incompletePayRunsPageIndexChanged,
    incompletePayRunsFilterChanged,
    frozenPayRunsLoading,
    frozenPayRunsFetched,
    frozenPayRunsFetchFailure,
    frozenPayRunsPageIndexChanged,
    frozenPayRunsFilterChanged
} = payRunDashboardSlice.actions

export default payRunDashboardSlice.reducer
