import { loginRequest } from '../authConfig';
import { PublicClientApplication } from "@azure/msal-browser"
import { msalInstance } from "./msalService"

class AuthService {
  private instance: PublicClientApplication

  constructor(instance: PublicClientApplication) {
    this.instance = instance
  }

  async getTokenAsync(): Promise<string | null> {

    const account = this.instance.getActiveAccount();
    if (!account) {
      return null
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account
    });

    return response.idToken
  }
}

const authService = new AuthService(msalInstance)

export default authService