import Card from '../Card'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../constants/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { CreditorConnectionModel } from '../../redux/types'

interface Props {
  creditors: CreditorConnectionModel[]
  onDeleteClick?: (creditor: CreditorConnectionModel) => void
}

const CreditorConnectionList = ({ onDeleteClick, creditors }: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        <div className={styles.cell}>
            <p><span>Name</span></p>
        </div>
        <div className={styles.cell}>
          <p><span>Priority</span></p>
        </div>
        <div className={`${styles.cell} ${styles.deleteButtonCell}`}>
            
        </div>
    </Card>
    )    
  }

  const renderListItems = () => {
    return creditors?.map((item, index) => {
      return (
        <Card className={`${styles.container} card`} key={`creditor_${index}`}>
          <div className={styles.cell}>
              <p>{item.name}</p>
          </div>
          <div className={styles.cell}>
              <p>{item.priority}</p>
          </div>
          <div className={`${styles.cell} ${styles.deleteButtonCell}`}>
              <FontAwesomeIcon
                  className={styles.button}
                  icon={faTrashAlt}
                  color={Colors.euro_600}
                  title='Delete'
                  onClick={() => onDeleteClick && onDeleteClick(item)}
              />
          </div>
      </Card>
      )
    })
  }

  return (
    <div>
      {renderHeader()}
      {renderListItems()}
    </div>
  )
}

export default CreditorConnectionList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 3px',
    margin: '5px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    margin: '30px 0 20px 0',
  },
  '& p': {
      fontSize: 12,
      margin: 0,
      '& span': {
        fontFamily: 'Aestetico-Bold',
        color: Colors.euro_600,
      }
  },
  flagged: {
      backgroundColor: Colors.red,
  },
  cell: {
    flex: 1,
    padding: '0px 5px'
  },
  deleteButtonCell: {
    textAlign: 'center',
    flex: '0 0 40px'
  },
  button: {
      transition: '0.4s',
      '&:hover': {
        color: Colors.euro5,
        cursor: 'pointer',
      }
    },
})
