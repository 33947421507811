import { useState } from 'react'
import CardSection from '../components/CardSection'
import DetailsCard from '../components/DetailsCard'
import Button from '../components/Button'
import InputField from '../components/InputField'
import { createUseStyles } from 'react-jss'
import { titleMarginBottom } from '../constants/layout'
import useTaxTableActions from '../hooks/useTaxTableActions'
import { useAppSelector } from '../redux/hooks'
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator'

const TaxTables = () => {
  const styles = useStyles()

  const [year, setYear] = useState<number>(2022)
  const { importTaxTables } = useTaxTableActions()

  const loading = useAppSelector(state => state.taxTable.loading)
  const importSuccess = useAppSelector(state => state.taxTable.importSuccess)
  const error = useAppSelector(state => state.taxTable.error)

  return (
    <div className={styles.container}>
      <h1>Tax tables</h1>
      <CardSection>
        <DetailsCard>
          <div className={styles.card}>
            <div className='yearInput'>
              <InputField
                id='year'
                numeric={true}
                value={year.toString()}
                onChange={(event) => {setYear(parseInt(event.target.value))}}
                placeholder=''
              />
            </div>
            <Button title='Import' onClick={() => importTaxTables(year)} />
            {importSuccess && <p>Import successful</p>}
            {error && <p>Import failed</p>}
          </div>
            
        </DetailsCard>     
      </CardSection>
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default TaxTables

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    }
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 3px',
    margin: '5px 0',
    '& .yearInput': {
      width: '150px',
      marginRight: '10px'
    },
    '& button': {
      marginRight: '10px'
    }
  }
})
