import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    CreditorConnectionModel,
    InvoicePayoutProviderModel,
} from '../types'

interface InvoicePayoutProviderState {
    loading: boolean
    providerDetails: InvoicePayoutProviderModel | null
    createdProviderId: string | null
    error: Error | string | null
}

const initialState: InvoicePayoutProviderState = {
    loading: false,
    providerDetails: null,
    createdProviderId: null,
    error: null
}

export const invoicePayoutProviderSlice = createSlice({
    name: 'invoicePayoutProvider',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        invoicePayoutProviderDetailsFetched: (state, action: PayloadAction<InvoicePayoutProviderModel>) => {
            state.loading = false
            state.providerDetails = action.payload
            state.error = null
        },
        invoicePayoutProviderDetailsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        invoicePayoutProviderCreated: (state, action: PayloadAction<string>) => {
            state.loading = false
            state.createdProviderId = action.payload
            state.error = null
        },
        invoicePayoutProviderCreationHandled: state => {
            state.createdProviderId = null
        },
        invoicePayoutProviderCreateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        invoicePayoutProviderUpdated: (state, action: PayloadAction<InvoicePayoutProviderModel>) => {
            state.loading = false
            state.providerDetails = action.payload
            state.error = null
        },
        invoicePayoutProviderUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        invoicePayoutProviderCreditSpaceWarningSilenced: (state, action: PayloadAction<InvoicePayoutProviderModel>) => {
            state.loading = false
            state.providerDetails = action.payload
            state.error = null
        },
        invoicePayoutProviderCreditSpaceWarningSilenceFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        invoicePayoutProviderLockStateSet: (state, action: PayloadAction<boolean>) => {
            state.loading = false
            state.providerDetails = {...state.providerDetails as InvoicePayoutProviderModel, state: action.payload ? 'locked' : 'active' }
            state.error = null
        },
        invoicePayoutProviderLockStateSetFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        invoicePayoutProviderCreditorAdded: (state, action: PayloadAction<CreditorConnectionModel>) => {
            state.loading = false
            state.providerDetails = {
                ...state.providerDetails as InvoicePayoutProviderModel,
                creditors: [action.payload, ...state.providerDetails?.creditors ?? []]
              }
            state.error = null
        },
        invoicePayoutProviderCreditorAddFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        invoicePayoutProviderCreditorDeleted: (state, action: PayloadAction<string>) => {
            var index = state.providerDetails?.creditors.findIndex((item) => {
                return item.id == action.payload
            })
            const creditors = [...state.providerDetails?.creditors ?? []]
            creditors.splice(index ?? 0, 1)

            state.loading = false
            state.providerDetails = {
                ...state.providerDetails as InvoicePayoutProviderModel,
                creditors: creditors
              }
            state.error = null
        },
        invoicePayoutProviderCreditorDeleteFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        }
    }
})

export const {
    loading,
    invoicePayoutProviderDetailsFetched,
    invoicePayoutProviderDetailsFetchFailure,
    invoicePayoutProviderCreated,
    invoicePayoutProviderCreationHandled,
    invoicePayoutProviderCreateFailure,
    invoicePayoutProviderUpdated,
    invoicePayoutProviderUpdateFailure,
    invoicePayoutProviderCreditSpaceWarningSilenced,
    invoicePayoutProviderCreditSpaceWarningSilenceFailure,
    invoicePayoutProviderLockStateSet,
    invoicePayoutProviderLockStateSetFailure,
    invoicePayoutProviderCreditorAdded,
    invoicePayoutProviderCreditorAddFailure,
    invoicePayoutProviderCreditorDeleted,
    invoicePayoutProviderCreditorDeleteFailure
} = invoicePayoutProviderSlice.actions
export default invoicePayoutProviderSlice.reducer
