import { useState } from "react"
import { TransactionFilterParams, TransactionStatus, TransactionType } from "../../redux/types"
import { isDateValid } from "../../utils/dateHelper"
import CardSection from "../CardSection"
import DatePicker from "../DatePicker"
import DetailsCard from "../DetailsCard"
import EmployerSelector from "../EmployerSelector"
import { SearchResultItem } from "../SearchAndSelectField"
import Select from "../Select"
import UserSelector from "../UserSelector"
import InvoicePayoutProviderSelector from "../InvoicePayoutProviderSelector"

interface Props {
    initialFilterValue: TransactionFilterParams | undefined
    onFilterChange: (filter: TransactionFilterParams) => void
}

const TransactionFilter = ({ initialFilterValue, onFilterChange }: Props) => {
    const [filterValue, setFilterValue] = useState<TransactionFilterParams>(initialFilterValue ?? {
        userId: null, 
        userSearchTerm: null,  
        employerId: null, 
        employerSearchTerm: null,
        invoicePayoutProviderId: null,
        invoicePayoutProviderSearchTerm: null,
        state: null,
        transactionType: null,
        fromDate: null, 
        toDate: null,
        paydayDate: null})

    const clearFilters = () => {
        const newFilter = {
            userId: null, 
            userSearchTerm: null,  
            employerId: null, 
            employerSearchTerm: null,
            invoicePayoutProviderId: null,
            invoicePayoutProviderSearchTerm: null,
            state: null,
            transactionType: null,
            fromDate: null, 
            toDate: null,
            paydayDate: null}

        setFilterValue(newFilter)
        onFilterChange(newFilter)    
    }

    const handleEmployerSelected = (employer: SearchResultItem | null) => {
        const newFilter = {...filterValue, employerId: employer?.value ?? null, employerSearchTerm: employer?.name ?? null}
        setFilterValue(newFilter)
        onFilterChange(newFilter)
    }
    
    const handleInvoicePayoutProviderSelected = (provider: SearchResultItem | null) => {
        const newFilter = {...filterValue, invoicePayoutProviderId: provider?.value ?? null, invoicePayoutProviderSearchTerm: provider?.name ?? null}
        setFilterValue(newFilter)
        onFilterChange(newFilter)
    }

    const handleUserSelected = (user: SearchResultItem | null) => {
        const newFilter = {...filterValue, userId: user?.value || null, userSearchTerm: user?.name || null}
        setFilterValue(newFilter)
        onFilterChange(newFilter)
    }

    const handleTransactionTypeSelected = (value: string) => {
        const selectedValue = value == 'null' ? null : value as TransactionType
        const newFilter = {...filterValue, transactionType: selectedValue}
        setFilterValue(newFilter)
        onFilterChange(newFilter)
    }

    const handleStateSelected = (value: string) => {
        const selectedValue = value == 'null' ? null : value as TransactionStatus
        const newFilter = {...filterValue, state: selectedValue}
        setFilterValue(newFilter)
        onFilterChange(newFilter)
    }

    const handleDateChanged = (propName: string, date: string | null | undefined) => {
        if (!date || date.length < 1) {
            const newFilter = {...filterValue, [propName]: null}
            setFilterValue(newFilter)
            onFilterChange(newFilter)   
        }

        if (date && isDateValid(date)) {
            const newFilter = {...filterValue, [propName]: date}
            setFilterValue(newFilter)
            onFilterChange(newFilter)
        }
    }

    const getTransactionTypeOptions = () => {
        let enumValues = Object.keys(TransactionType).filter(v => isNaN(Number(v))).map((value) => { return { name: value, value: value }})
        enumValues.splice(0, 0, { name: 'All transaction types', value: 'null'})
        return enumValues;
    }

    const getStateOptions = () => {
        let enumValues = Object.keys(TransactionStatus).filter(v => isNaN(Number(v))).map((value) => { return { name: value, value: value }})
        enumValues.splice(0, 0, { name: 'All transaction states', value: 'null'})
        return enumValues;
    }

    return (
        <div>
            <CardSection>
                <DetailsCard>
                    <DatePicker 
                        id='fromDate'
                        label=''
                        includeTime={false}
                        placeholder='From date'
                        value={filterValue?.fromDate ?? undefined}
                        onChange={value => {
                            handleDateChanged('fromDate', value)
                        }}
                    />
                </DetailsCard>
                <DetailsCard>
                    <DatePicker 
                        id='toDate'
                        label=''
                        placeholder='To date'
                        includeTime={false}
                        value={filterValue?.toDate ?? undefined}
                        onChange={value => {
                            handleDateChanged('toDate', value)
                        }}
                    />
                </DetailsCard>
                <DetailsCard>
                    <DatePicker 
                        id='paydayDate'
                        label=''
                        placeholder='Payday'
                        includeTime={false}
                        value={filterValue?.paydayDate ?? undefined}
                        onChange={value => {
                            handleDateChanged('paydayDate', value)
                        }}
                    />
                </DetailsCard>
                <DetailsCard>
                    <EmployerSelector 
                        onEmployerSelected={handleEmployerSelected} 
                        value={filterValue?.employerSearchTerm || ''}
                    />
                </DetailsCard>
                <DetailsCard>
                    <InvoicePayoutProviderSelector 
                        onInvoicePayoutProviderSelected={handleInvoicePayoutProviderSelected} 
                        value={filterValue?.invoicePayoutProviderSearchTerm || ''}
                    />
                </DetailsCard>
                <DetailsCard>
                    <UserSelector 
                        onUserSelected={handleUserSelected} 
                        value={filterValue?.userSearchTerm || ''}
                    />
                </DetailsCard>
                <DetailsCard>
                    <Select 
                        id='type'
                        key='type'
                        options={getTransactionTypeOptions()} 
                        selectedValue={filterValue?.transactionType?.toString()}
                        onSelect={handleTransactionTypeSelected}
                    />
                </DetailsCard>
                <DetailsCard>
                    <Select 
                        id='state'
                        key='state'
                        options={getStateOptions()} 
                        selectedValue={filterValue?.state?.toString()}
                        onSelect={handleStateSelected}
                    />
                </DetailsCard>
            </CardSection>
            <div style={{textAlign: 'right'}}>
                <span style={{cursor: 'pointer'}} onClick={() => clearFilters()}>Clear filters</span>
            </div>
        </div>
    )
}

export default TransactionFilter