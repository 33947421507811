import React, { FC, ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'

interface Props {
    children: ReactNode
    className?: string
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}

const Card = ({ children, className, onClick }: Props) => {
    const classes = useStyles()
    return (
        <div onClick={onClick} className={`${classes.container} ${className}`}>
            {children}
        </div>
    )
}

export default Card

const useStyles = createUseStyles({
    container: {
        backgroundColor: Colors.darkGrayCard,
        borderColor: Colors.darkGrayBorder,
        borderWidth: 2,
        borderRadius: 5,
    },
})
