import {useEffect, useState} from 'react'
import Modal from '../Modal'
import {LearningCategoryCreateOrUpdateModel, LearningCategoryManagementModel} from '../../redux/types'
import InputField from '../InputField'
import MultilingualInputField from "./MultilingualInputField";
import MultilingualTextAreaField from "./MultilingualTextAreaField";

interface Props {
  initialValue?: LearningCategoryManagementModel | undefined
  onAddClick?: (model: LearningCategoryCreateOrUpdateModel) => void
  onCancelClick?: () => void
  visible: boolean
}

const EditCategoryModal = ({initialValue, onAddClick, onCancelClick, visible}: Props) => {
  const newCategory = {
    icon: 'book',
    color: '#3B3BFF',
    backgroundColor: '#7A3B3B',
    title: {},
    description: {},
    orderNumber: 10,
    tags: [],
  } as LearningCategoryCreateOrUpdateModel

  const [editModel, setEditModel] = useState<LearningCategoryCreateOrUpdateModel>(newCategory)

  useEffect(() => {
    if (initialValue) {
      setEditModel({
        icon: initialValue.icon,
        color: initialValue.color,
        backgroundColor: initialValue.backgroundColor,
        title: initialValue.title,
        description: initialValue.description,
        orderNumber: initialValue.orderNumber,
        tags: initialValue.tags
      })
    } else {
      setEditModel(newCategory)
    }
  }, [initialValue])


  const handleOnAdd = () => {
    onAddClick && onAddClick(editModel)

    if (!initialValue) {
      setEditModel(newCategory)
    }
  }

  const handleOnCancel = () => {
    onCancelClick && onCancelClick()

    if (!initialValue) {
      setEditModel(newCategory)
    }
  }

  const handleOnChange = (propName: string, value: any) => {
    setEditModel({...editModel, [propName]: value})
  }


  return (
    <Modal
      title={initialValue ? 'Edit category' : 'Create category'}
      show={visible}
      okButtonTitle='Save'
      cancelButtonTitle='Cancel'
      scrollable={false}
      onHide={handleOnCancel}
      onOkClick={handleOnAdd}
      onCancelClick={handleOnCancel}
    >
      <InputField
        id='orderNumber'
        numeric
        value={editModel.orderNumber.toString()}
        onChange={(event) => handleOnChange('orderNumber', event.target.value)}
        label='Order number'
        placeholder='3'
      />
      <MultilingualInputField
        label={'Title'}
        translations={editModel.title}
        onChange={(value) => handleOnChange('title', value)}
      />
      <MultilingualTextAreaField
        label={'Description'}
        translations={editModel.description}
        onChange={(value) => handleOnChange('description', value)}
      />
      <InputField
        id='icon'
        label='Icon'
        value={editModel.icon}
        onChange={(event) => handleOnChange('icon', event.target.value)}
        placeholder='book'
      />
      <InputField
        id='color'
        label='Color'
        value={editModel.color}
        onChange={(event) => handleOnChange('color', event.target.value)}
        placeholder='#777777'
      />
      <InputField
        id='backgroundColor'
        label='Background color'
        value={editModel.backgroundColor}
        onChange={(event) => handleOnChange('backgroundColor', event.target.value)}
        placeholder='#ffffff'
      />
    </Modal>
  )
}

export default EditCategoryModal

