import {Route, Switch} from 'react-router-dom'
import Start from '../pages/Start'
import About from '../pages/About'
import EmployerDetails from '../pages/EmployerDetails'
import PrivateRoute from './PrivateRoute'
import EmployeeDetails from '../pages/EmployeeDetails'
import Users from '../pages/Users'
import UserDetails from '../pages/UserDetails'
import Transactions from '../pages/Transactions'
import TransactionDetails from '../pages/TransactionDetails'
import Reconciliations from '../pages/Reconciliations'
import ReconciliationDetails from '../pages/ReconciliationDetails'
import AmlFlaggedCompanyRepresentatives from '../pages/AmlFlaggedCompanyRepresentatives'
import CompanyRepresentativeAmlDetails from '../pages/CompanyRepresentativeAmlDetails'
import Nordea from '../pages/Nordea'
import TaxTables from '../pages/TaxTables'
import Paydays from '../pages/Paydays'
import PaydayDetails from '../pages/PaydayDetails'
import PayRunDetails from '../pages/PayRunDetails'
import PayScheduleDetails from '../pages/PayScheduleDetails'
import Fortnox from '../pages/Fortnox'
import FortnoxActivation from '../pages/FortnoxActivation'
import AmlFlaggedCompanies from '../pages/AmlFlaggedCompanies'
import CompanyAmlDetails from '../pages/CompanyAmlDetails'
import CompanyMonitoring from "../pages/CompanyMonitoring";
import ProtectIssues from '../pages/ProtectIssues'
import ProtectIssueDetails from '../pages/ProtectIssueDetails'
import ProtectRuleDetails from '../pages/ProtectRuleDetails'
import ProtectCustomer from '../pages/ProtectCustomer'
import EmployeePaydayDataDetails from '../pages/EmployeePaydayDataDetails'
import Campaigns from "../pages/Campaigns";
import CampaignDetails from "../pages/CampaignDetails";
import Whistleblower from "../pages/Whistleblower";
import InAppMessages from '../pages/InAppMessages'
import InAppMessageDetails from '../pages/InAppMessageDetails'
import Learning from '../pages/Learning'
import CourseDetails from "../pages/CourseDetails";
import ModuleDetails from "../pages/ModuleDetails";
import LearningAssets from "../pages/LearningAssets";
import Customers from '../pages/Customers'
import CustomerDetails from '../pages/CustomerDetails'
import InvoicePayoutProviderDetails from '../pages/InvoicePayoutProviderDetails'
import InvoicePayoutDetails from '../pages/InvoicePayoutDetails'
import CompanyCheck from "../pages/CompanyCheck";
import PayRunDashboard from "../pages/PayRunDashboard";
import Employees from "../pages/Employees";

const Router = () => {
  return (
    <Switch>
      <Route path="/" exact component={Start}/>
      <Route path="/companymonitor/:marketId/:orgNumber">
        <PrivateRoute>
          <CompanyMonitoring/>
        </PrivateRoute>
      </Route>
      <Route path="/companycheck" exact>
        <PrivateRoute>
          <CompanyCheck/>
        </PrivateRoute>
      </Route>
      <Route path="/customers" exact>
        <PrivateRoute>
          <Customers/>
        </PrivateRoute>
      </Route>
      <Route path="/customers/:id">
        <PrivateRoute>
          <CustomerDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/employers/:employerId/payschedules/:id">
        <PrivateRoute>
          <PayScheduleDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/employers/:id">
        <PrivateRoute>
          <EmployerDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/invoicepayoutproviders/:providerId/payouts/:payoutId">
        <PrivateRoute>
          <InvoicePayoutDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/invoicepayoutproviders/:providerId/repayments/:repaymentId">
        <PrivateRoute>
          <InvoicePayoutDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/invoicepayoutproviders/:id">
        <PrivateRoute>
          <InvoicePayoutProviderDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/users" exact>
        <PrivateRoute>
          <Users/>
        </PrivateRoute>
      </Route>
      <Route path="/users/:id">
        <PrivateRoute>
          <UserDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/paydaydata/:employerId/:paydayId">
        <PrivateRoute>
          <EmployeePaydayDataDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/employees" exact>
        <PrivateRoute>
          <Employees/>
        </PrivateRoute>
      </Route>
      <Route path="/employees/:id">
        <PrivateRoute>
          <EmployeeDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/transactions" exact>
        <PrivateRoute>
          <Transactions/>
        </PrivateRoute>
      </Route>
      <Route path="/transactions/:id" exact>
        <PrivateRoute>
          <TransactionDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/paydays" exact>
        <PrivateRoute>
          <Paydays/>
        </PrivateRoute>
      </Route>
      <Route path="/paydays/:id" exact>
        <PrivateRoute>
          <PaydayDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/paydays/:paydayId/payruns/:id">
        <PrivateRoute>
          <PayRunDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/learning" exact>
        <PrivateRoute>
          <Learning/>
        </PrivateRoute>
      </Route>
      <Route path="/learning/assets" exact>
        <PrivateRoute>
          <LearningAssets/>
        </PrivateRoute>
      </Route>
      <Route path="/learning/createCourse" exact>
        <PrivateRoute>
          <CourseDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/learning/course/:id" exact>
        <PrivateRoute>
          <CourseDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/learning/course/:courseId/createModule" exact>
        <PrivateRoute>
          <ModuleDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/learning/course/:courseId/module/:id" exact>
        <PrivateRoute>
          <ModuleDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/reconciliations" exact>
        <PrivateRoute>
          <Reconciliations/>
        </PrivateRoute>
      </Route>
      <Route path="/reconciliations/:id">
        <PrivateRoute>
          <ReconciliationDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/protect/issues" exact>
        <PrivateRoute>
          <ProtectIssues/>
        </PrivateRoute>
      </Route>
      <Route path="/protect/issues/:id">
        <PrivateRoute>
          <ProtectIssueDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/protect/rules/:id">
        <PrivateRoute>
          <ProtectRuleDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/protect/customers/:id">
        <PrivateRoute>
          <ProtectCustomer/>
        </PrivateRoute>
      </Route>
      <Route path="/aml/companyRepresentatives" exact>
        <PrivateRoute>
          <AmlFlaggedCompanyRepresentatives/>
        </PrivateRoute>
      </Route>
      <Route path="/aml/companies" exact>
        <PrivateRoute>
          <AmlFlaggedCompanies/>
        </PrivateRoute>
      </Route>
      <Route path="/aml/companies/:id">
        <PrivateRoute>
          <CompanyAmlDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/aml/companyRepresentatives/:id">
        <PrivateRoute>
          <CompanyRepresentativeAmlDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/nordea" exact>
        <PrivateRoute>
          <Nordea/>
        </PrivateRoute>
      </Route>
      <Route path="/taxtables" exact>
        <PrivateRoute>
          <TaxTables/>
        </PrivateRoute>
      </Route>
      <Route path="/fortnox" exact>
        <PrivateRoute>
          <Fortnox/>
        </PrivateRoute>
      </Route>
      <Route path="/fortnoxactivation">
        <PrivateRoute>
          <FortnoxActivation/>
        </PrivateRoute>
      </Route>
      <Route path="/campaigns/:id">
        <PrivateRoute>
          <CampaignDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/campaigns" exact>
        <PrivateRoute>
          <Campaigns/>
        </PrivateRoute>
      </Route>
      <Route path="/inappmessages/:id">
        <PrivateRoute>
          <InAppMessageDetails/>
        </PrivateRoute>
      </Route>
      <Route path="/inappmessages" exact>
        <PrivateRoute>
          <InAppMessages/>
        </PrivateRoute>
      </Route>
      <Route path="/whistleblower" exact>
        <PrivateRoute>
          <Whistleblower/>
        </PrivateRoute>
      </Route>
      <Route path="/payrun" exact>
        <PrivateRoute>
          <PayRunDashboard/>
        </PrivateRoute>
      </Route>
      <Route path="/about" component={About}/>
    </Switch>
  )
}

export default Router
