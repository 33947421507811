import Modal from '../Modal'
import {PayRunModel} from "../../redux/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {Colors} from "../../constants/colors";
import React from "react";
import {createUseStyles} from "react-jss";
import Card from "../Card";
import {formatMoney} from "../../utils/moneyHelper";
import Divider from "../Divider";


interface Props {
  payRun: PayRunModel
  onConfirmClick: () => void
  onCloseClick: () => void
  visible: boolean
}

const CompletePayRunModal = ({payRun, onConfirmClick, onCloseClick, visible}: Props) => {
  const styles = useStyles()

  const userName = `${payRun.userGivenName} ${payRun.userFamilyName}`

  return (
    <Modal
      show={visible}
      title={"Confirm manual handling of pay run pay transfer"}
      okButtonTitle='Confirm'
      cancelButtonTitle={'Cancel'}
      onHide={onCloseClick}
      onOkClick={onConfirmClick}
      onCancelClick={onCloseClick}
      size={"lg"}
      blurBackground={true}
    >

      <div className={styles.container}>
        <FontAwesomeIcon icon={faExclamationTriangle} color={Colors.yellow} size={"10x"}/>
        <div>
          <h4>Confirm manual handling of pay run pay transfer</h4>

          <Divider height={30}/>
          <Card className={`${styles.card} card`}>
            <h5>Pay Run for {userName}</h5>
            <p>Identification number: {payRun.userIdentificationNumber}</p>
            <p>Employer: {payRun.employerName}</p>
            <p>Pay after deductions: {formatMoney(payRun.salaryAfterDeductions)}</p>
          </Card>
          <Divider height={30}/>

          <p>
            This will force the pay transfer for the pay run to complete, allowing the pay run to proceed/complete.
            The system will NOT take any other actions for you - you must manually ensure the wage/salary reaches
            the recipient. This is normally done by returning the correct amount to the employer, who then pays the
            recipient.
          </p>
        </div>


      </div>
    </Modal>
  )
}

export default CompletePayRunModal

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10
  },
  card: {
    padding: 10,
    backgroundColor: Colors.grayCard,
    '& h5': {
      marginBottom: 8,
    },
    '& p': {
      marginBottom: 0
    }
  }
})
