import Card from '../Card'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../constants/colors'
import { formatDateString } from '../../utils/dateHelper'
import { ShiftModel } from '../../redux/types'
import Pagination from '../Pagination'

interface Props {
  shiftsOnPage: ShiftModel[]
  pageIndex: number
  pageSize: number
  totalCount: number
  onPageChange: (pageIndex: number) => void
}

const ShiftList = ({ shiftsOnPage, pageIndex, pageSize, totalCount, onPageChange }: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`} >
        <div className={styles.cell}>
          <h6>Gross amount</h6>
        </div>
        <div className={styles.cell}>
          <h6>Hours</h6>
        </div>
        <div className={styles.cell}>
          <h6>Date</h6>
        </div>
        <div className={styles.cell}>
          <h6>Type</h6>
        </div>
        <div className={styles.cell}>
          <h6>Status</h6>
        </div>
        <div className={styles.cell}>
          <h6>Payment date</h6>
        </div>
      </Card>
    )
  }

  const renderListItems = () => {
    return shiftsOnPage?.map((item, index) => {
      return (
        <Card className={`${styles.container} card`} key={`shift_${index}`}>
          <div className={styles.cell}>
            {item.grossWages.amount.toLocaleString('sv-SE')} {item.grossWages.currencyCode.toUpperCase()}
          </div>
          <div className={styles.cell}>
            {item.hours}
          </div>
          <div className={styles.cell}>
            {formatDateString(item.date)}
          </div>
          <div className={styles.cell}>
            {item.type}
          </div>
          <div className={styles.cell}>
            {item.status}
          </div>
          <div className={styles.cell}>
            {formatDateString(item.paymentDate)}
          </div>
        </Card>
      )
    })
  }

  return (
    <div>
      {renderHeader()}
      {renderListItems()}
      <div className={styles.pagination}>
        <Pagination
          pageIndex={pageIndex}
          itemCount={totalCount}
          pageSize={pageSize}
          siblingCount={1}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  )
}

export default ShiftList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    margin: '10px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    margin: '30px 0 20px 0',
  },
  cell: {
    flex: 1,
    padding: '0px 10px'
  },
  pagination: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center'
  }
})
