import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../constants/colors'

interface S1Props {
  children: string
  className?: string
  bold?: boolean
}

const S1: FC<S1Props> = ({ children, className, bold }) => {
  const useStyles = createUseStyles({
    text: {
      fontFamily: bold ? 'Aestetico-Bold' : 'Aestetico-Regular',
      color: Colors.lightDefault,
      fontSize: 20,
    },
  })

  const styles = useStyles()

  return (
    <h5 className={`${styles.text} ${className}`}>{children}</h5>
  )
}

export default S1
