import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { Colors } from "../../constants/colors"
import { PayScheduleListItem } from "../../redux/types"
import Card from "../Card"

interface Props {
    employerId: string
    paySchedules: PayScheduleListItem[]
}

const PayScheduleList = ({paySchedules, employerId}: Props) => {
    const styles = useStyles()

    const renderHeader = () => {
        return (
          <Card className={`${styles.container} ${styles.header} card`}>
            <div className={styles.cell}>
              <p><span>Identifier</span></p>
            </div>
            <div className={styles.cell}>
              <p><span>Periodicity</span></p>
            </div>
            <div className={styles.cell}>
              <p><span>Start day of period</span></p>
            </div>
            <div className={styles.cell}>
              <p><span>Default payment day</span></p>
            </div>
            <div className={styles.cell}>
              <p><span>Salary accrual</span></p>
            </div>
            <div className={`${styles.cell} ${styles.icon}`}>
              <p><span>Default</span></p>
            </div>
          </Card>
        )    
    }

    const renderListItems = () => {
        return paySchedules?.map((item, index) => {
          return (
            <Link to={{pathname: `/employers/${employerId}/payschedules/${item.id}`}} key={`payschedule_${index}`}>
              <Card className={`${styles.container} card`}>
                <div className={styles.cell}>
                    <p>{item.identifier}</p>
                </div>
                <div className={styles.cell}>
                  <p>{item.periodicity}</p>
                </div>
                <div className={styles.cell}>
                  <p>{item.periodStartDayOfMonth}</p>
                </div>
                <div className={styles.cell}>
                  <p>{item.defaultPaymentDayOfMonth}</p>
                </div>
                <div className={styles.cell}>
                  <p>{item.salaryAccrualType}</p>
                </div>
                <div className={`${styles.cell} ${styles.icon}`}>
                  {item.isDefault && 
                    <FontAwesomeIcon
                        icon={faCheck}
                        color={Colors.euro_600}
                        title='Default'
                    />}
                </div>
              </Card>
            </Link>
          )
        })
    }

    return (
        <div>
            {renderHeader()}
            {renderListItems()}
        </div>
    )
}

export default PayScheduleList

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'left',
        padding: '3px 3px',
        margin: '5px 0',
        transition: '0.4s',
        '&:hover': {
          backgroundColor: Colors.darkGrayHover,
        }
      },
      '& p': {
          fontSize: 12,
          margin: 0,
          '& span': {
            fontFamily: 'Aestetico-Bold',
            color: Colors.euro_600,
          }
      },
      header: {
        margin: '30px 0 20px 0',
      },
      cell: {
        flex: 1,
        padding: '0px 5px'
      },
    icon: {
      textAlign: 'center',  
    }
  })