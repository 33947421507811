import { createUseStyles } from 'react-jss'
import Card from '../../Card'
import { Link } from 'react-router-dom'
import { Colors } from '../../../constants/colors'
import { formatDateString } from '../../../utils/dateHelper'
import { AmlFlaggedCompanyRepresentativeListItemModel } from '../../../redux/companyMonitorTypes'

interface Props { 
    representatives: AmlFlaggedCompanyRepresentativeListItemModel[]
}

const AmlFlaggedCompanyRepresentativeList = ({ representatives }: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
      <div className={styles.cell}>
        <h6>Name</h6>
      </div>
      <div className={styles.cell}>
        <h6>Date of birth</h6>
      </div>
      <div className={styles.cell}>
        <h6>Company</h6>
      </div>
    </Card>
    )    
  }

  const renderListItems = () => {
    return representatives?.map((item, index) => {
      return (
        <Link key={`representativeListItem${index}`} to={{pathname: `/aml/companyrepresentatives/${item.id}`}}>
          <Card className={`${styles.container} card`}>
            <div className={styles.cell}>
              {`${item.givenName} ${item.familyName}`}
            </div>
            <div className={styles.cell}>
              {formatDateString(item.dateOfBirth)}
            </div>
            <div className={styles.cell}>
              {item.companyName}
            </div>
          </Card>
        </Link>
      )
    })
  }

  return (
    <div>
       {renderHeader()}
       {renderListItems()}
    </div>
  )
}

export default AmlFlaggedCompanyRepresentativeList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    margin: '10px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    margin: '30px 0 20px 0',
  },
  cell: {
    flex: 1
  },
  rejectedIndicator: {
    textAlign: 'right',
    flex: '0 0 60px'
  },
  pagination: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center'
  }
})
