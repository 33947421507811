import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Breakpoints } from '../constants/breakpoints'
import { Colors } from '../constants/colors'
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
  title: string
  onClick?: () => void
  disabled?: boolean
  className?: string
  type?: 'button' | 'reset' | 'submit'
  to?: string
  variant?: 'positive' | 'negative'
  icon?: IconDefinition
  iconColor?: string
}

const Button = ({
  title,
  onClick,
  disabled,
  className,
  type = 'button',
  to,
  variant = 'positive',
  icon,
  iconColor
}: Props) => {
  const styles = useStyles()

  const renderButton = () => {
    return (
      <button
        className={`${styles.container} ${className}`} 
        style={{backgroundColor: disabled ? Colors.darkGrayWatermark : (variant === 'negative' ? 'transparent' : Colors.euro_600), borderWidth: variant === 'negative' ? 2 : 0}}
        onClick={onClick}
        disabled={disabled}
        type={type}
        title={title}
      >
        {renderIcon(icon, iconColor)}
        {title}
      </button>
    )
  }

  const renderIcon = (icon?: IconDefinition, iconColor?: string) => {
    if (icon) {
      return <FontAwesomeIcon className={styles.icon}
        icon={icon}
        color={iconColor || Colors.white}
      />
    }
  }

  const linkWrapper = () => {
    return to ? <Link to={to}>{renderButton()}</Link> : renderButton()
  }

  return linkWrapper()
}

export default Button

const useStyles = createUseStyles({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 32,
    borderRadius: 16,
    borderStyle: 'solid',
    borderWidth: 0,
    fontFamily: "Aestetico-Bold",
    fontSize: 14,
    backgroundColor: Colors.euro_600,
    color: Colors.white,
    [`@media (min-width: ${Breakpoints.desktop}px)`]: {
      width: 'auto',
      paddingLeft: 20,
      paddingRight: 20,
      transition: '0.4s',
      '&:hover': {
        backgroundColor: Colors.euro4,
      }
    },
  },
  icon: {
    marginRight: "5px",
  }
})


