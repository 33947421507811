import {ChangeEvent, useEffect, useRef, useState} from "react";
import {createUseStyles} from "react-jss";
import {Colors} from "../../constants/colors";
import Modal from "../Modal";
import Select from "../Select";
import Button from "../Button";

interface Props {
  visible: boolean
  onUpload: (theme: string, file: File) => void
  onCancelClick: () => void
}

const UploadLogoModal = ({visible, onUpload, onCancelClick}: Props) => {
  const styles = useStyles()
  const [file, setFile] = useState<File>();
  const [fileTheme, setFileTheme] = useState<string>("LightTheme");
  const [error, setError] = useState<string>();
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const themeOptions = [
    {name: "Light theme", value: "LightTheme"},
    {name: "Dark theme", value: "DarkTheme"},
  ]

  useEffect(() => {
    const maxFileSize = 256 * 1024 // 256 kB

    if (!!file) {
      if (file.size > maxFileSize) {
        setError('File size too large')
        return
      }

      if (file.type !== 'image/png') {
        setError('Invalid file type')
        return;
      }

      setError(undefined)
    }
  }, [file])

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleOnOk = () => {
    if (file) {
      onUpload(fileTheme, file)
      setFile(undefined)
    }
  }

  const handleOnCancel = () => {
    setFile(undefined)
    onCancelClick()
  }

  const renderPreview = () => {
    if (!file || !!error) return
    const imageContainerStyle = fileTheme === 'DarkTheme' ? styles.darkImageContainer : styles.lightImageContainer
    const imageContainerStyleSmall = fileTheme === 'DarkTheme' ? styles.darkImageContainerSmall : styles.lightImageContainerSmall

    return (
      <div className={styles.previewContainer}>
        <div className={styles.imagePreviewContainer}>
          <div className={imageContainerStyle}>
            <img className={styles.image} src={URL.createObjectURL(file)} alt="Logo" />
          </div>
          <div className={imageContainerStyleSmall}>
            <img className={styles.image} src={URL.createObjectURL(file)} alt="Logo" />
          </div>
        </div>


        <div>
          <span>
            <strong>{file.name}</strong> ({file.size / 1000} kB)
          </span>
        </div>
      </div>
    )
  }

  return (
    <Modal
      title='Upload logo'
      show={visible}
      okButtonTitle={'Upload'}
      okButtonDisabled={!file || !!error}
      cancelButtonTitle={'Cancel'}
      onHide={handleOnCancel}
      onOkClick={handleOnOk}
      onCancelClick={handleOnCancel}
    >
      <p>{'Select logo (max 256 kB PNG file)'}</p>
      <div className={styles.container}>
        <Select
          options={themeOptions}
          selectedValue={fileTheme}
          onSelect={(value) => setFileTheme(value)}
        />

        <Button title={'Select logo'} onClick={() => hiddenFileInput.current?.click()}/>
        <input className={styles.hidden} ref={hiddenFileInput} type="file" accept={"image/png"}
               onChange={handleFileChange}/>
      </div>

      <div>{!!error && error}</div>
      {renderPreview()}
    </Modal>
  )
}

export default UploadLogoModal

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
    flex: 1,
  },
  hidden: {
    display: 'none',
  },
  previewContainer: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imagePreviewContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 10,
  },
  darkImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.gray0,
    borderRadius: '16px',
    width: '128px',
    height: '128px',
  },
  lightImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.gray4,
    borderRadius: '16px',
    width: '128px',
    height: '128px',
  },
  darkImageContainerSmall: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.gray0,
    borderRadius: '16px',
    width: '32px',
    height: '32px',
  },
  lightImageContainerSmall: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.gray4,
    borderRadius: '16px',
    width: '32px',
    height: '32px',
  },
  image: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  }
})
