import { useState } from "react"
import { restConfig } from "../authConfig";
import { EmployerListItemModel } from '../redux/types';
import { createUrlWithQueryString } from "../utils/url";
import useFetchClient from "./useFetchClient";

const useEmployerSelectorActions = () => {
  const fetchClient = useFetchClient()
  const [employers, setEmployers] = useState<EmployerListItemModel[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error>()

  const search = async (searchTerm: string, maxCount: number) => {
    const params = {
      query: searchTerm,
      pageNumber: 1,
      pageSize: maxCount
    }
    const url = createUrlWithQueryString(restConfig.routes.employers, params)
    
    setLoading(true)

    return fetchClient.get(url)
      .then(response => {
        setEmployers(response.data as EmployerListItemModel[])
        setLoading(false)
      })
      .catch(error => {
        console.log('search error:', error)
        setError(new Error(error))
        setLoading(false)
      })
  }

  return {
    search,
    employers,
    error,
    loading
  }
}

export default useEmployerSelectorActions
