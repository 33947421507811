import { useEffect, useState } from "react"
import InputField from "./InputField"
import Modal from "./Modal"

interface Props {
    initialValue?: string
    onSaveClick: (phoneNumber: string) => void
    onCancelClick: () => void
    visible: boolean
}

const SetUserPhoneModal = ({initialValue, onSaveClick, onCancelClick, visible}: Props) => {
    const [phoneNumber, setPhoneNumber] = useState(initialValue)

    useEffect(() => {
        if (visible) {
            setPhoneNumber(initialValue)
        }
    }, [visible])

    return (
        <Modal
            title='Set phone number'
            show={visible}
            okButtonTitle='Set'
            cancelButtonTitle='Cancel'
            okButtonDisabled={!((phoneNumber?.length ?? 0) > 0)}
            onHide={() => onCancelClick()}
            onOkClick={() => onSaveClick(phoneNumber ?? '')}
            onCancelClick={() => onCancelClick()}
        >
            <InputField
                id='phoneNumber'
                value={phoneNumber ?? ''}
                onChange={(event) => setPhoneNumber(event.target.value)}
                label=''
                placeholder=''
            />
        </Modal>
    )
}

export default SetUserPhoneModal
