import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
  ImageModel, Language,
  LearningCategoryManagementModel,
  LearningCourseManagementModel,
  LearningModuleManagementModel
} from '../types'

interface LearningState {
  loading: boolean
  images: ImageModel[]
  learningCategories: LearningCategoryManagementModel[]
  learningCourses: LearningCourseManagementModel[]
  error: Error | string | null
  previewLanguage: Language
}

const initialState: LearningState = {
  loading: false,
  images: [],
  learningCategories: [],
  learningCourses: [],
  error: null,
  previewLanguage: Language.en
}

export const learningSlice = createSlice({
  name: 'payday',
  initialState,
  reducers: {
    loading: state => {
      state.loading = true
      state.error = null
    },
    failure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
    imagesFetched: (state, action: PayloadAction<ImageModel[]>) => {
      state.loading = false
      state.images = action.payload
      state.error = null
    },
    imageUploaded: (state, action: PayloadAction<ImageModel>) => {
      state.loading = false
      state.images.push(action.payload)
      state.error = null
    },
    imageDeleted: (state, action: PayloadAction<string>) => {
      state.loading = false
      state.images = state.images.filter((image) => image.name !== action.payload)
      state.error = null
    },
    learningCategoriesFetched: (state, action: PayloadAction<LearningCategoryManagementModel[]>) => {
      state.loading = false
      state.learningCategories = action.payload
      state.error = null
    },
    learningCategoryCreated: (state, action: PayloadAction<LearningCategoryManagementModel>) => {
      state.loading = false
      state.learningCategories.push(action.payload)
      state.error = null
    },
    learningCategoryUpdated: (state, action: PayloadAction<LearningCategoryManagementModel>) => {
      state.loading = false
      const index = state.learningCategories.findIndex((category) => category.id === action.payload.id)
      state.learningCategories[index] = action.payload
      state.error = null
    },
    learningCoursesFetched: (state, action: PayloadAction<LearningCourseManagementModel[]>) => {
      state.loading = false
      state.learningCourses = action.payload
      state.error = null
    },
    learningCourseCreated: (state, action: PayloadAction<LearningCourseManagementModel>) => {
      state.loading = false
      state.learningCourses.push(action.payload)
      state.error = null
    },
    learningCourseUpdated: (state, action: PayloadAction<LearningCourseManagementModel>) => {
      state.loading = false
      const index = state.learningCourses.findIndex((course) => course.id === action.payload.id)
      state.learningCourses[index] = action.payload
      state.error = null
    },
    learningModuleCreated: (state, action: PayloadAction<LearningModuleManagementModel>) => {
      state.loading = false
      const index = state.learningCourses.findIndex((course) => course.id === action.payload.learningCourseId)
      state.learningCourses[index].modules.push(action.payload)
      state.error = null
    },
    learningModuleUpdated: (state, action: PayloadAction<LearningModuleManagementModel>) => {
      state.loading = false
      const index = state.learningCourses.findIndex((course) => course.id === action.payload.learningCourseId)
      const moduleIndex = state.learningCourses[index].modules.findIndex((module) => module.id === action.payload.id)
      state.learningCourses[index].modules[moduleIndex] = action.payload
      state.error = null
    },
    previewLanguageChanged: (state, action: PayloadAction<Language>) => {
      state.previewLanguage = action.payload
    }
  }
})

export const {
  loading,
  failure,
  imagesFetched,
  imageUploaded,
  imageDeleted,
  learningCategoriesFetched,
  learningCategoryCreated,
  learningCategoryUpdated,
  learningCoursesFetched,
  learningCourseCreated,
  learningCourseUpdated,
  learningModuleCreated,
  learningModuleUpdated,
  previewLanguageChanged
} = learningSlice.actions

export default learningSlice.reducer
