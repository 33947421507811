import { createUseStyles } from 'react-jss'
import { Colors } from '../../constants/colors'
import {EmployerLogoModel} from "../../redux/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";

interface Props {
  employerLogos: EmployerLogoModel[]
  onDeleteClick?: (logo: EmployerLogoModel) => void
}

const EmployerLogoList = ({employerLogos, onDeleteClick}: Props) => {
  const styles = useStyles()

  const renderListItems = () => {
    return employerLogos?.map((item, index) => {
      const imageContainerStyle = item.theme === 'DarkTheme' ? styles.darkImageContainer : styles.lightImageContainer

      return (
        <div className={styles.container} key={`employerLogo_${index}`}>
          <div className={imageContainerStyle}>
            <img className={styles.image} src={`data:${item.contentType};base64,${item.image}`} alt="Logo" />
          </div>
          <p>{item.theme}</p>
          <p>{item.contentType}</p>
          <FontAwesomeIcon
              className={styles.button}
              icon={faTrashAlt}
              color={Colors.euro_600}
              title='Delete'
              onClick={() => onDeleteClick && onDeleteClick(item)}
          />
        </div>
      )
    })
  }

  return (
    <div>
      {renderListItems()}
    </div>
  )
}

export default EmployerLogoList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    height: '50px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 10px',
    margin: '5px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  darkImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.gray0,
    borderRadius: '16px',
    width: '32px',
    height: '32px',
  },
  lightImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.gray4,
    borderRadius: '16px',
    width: '32px',
    height: '32px',
  },
  image: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  button: {
    transition: '0.4s',
    '&:hover': {
      color: Colors.euro5,
      cursor: 'pointer',
    }
  },
})
