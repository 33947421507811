import { useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { createUseStyles } from 'react-jss'
import { titleMarginBottom } from '../constants/layout'
import { ReconciliationIssueModel } from '../redux/types'
import ReconciliationIssueHandlingModal from '../components/ReconciliationIssue/ReconciliationIssueHandlingModal'
import useReconciliationActions from '../hooks/useReconciliationActions';
import { useAppSelector } from '../redux/hooks';
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator';
import ReconciliationIssueList from '../components/ReconciliationIssue/ReconciliationIssueList';
import { formatDateString } from '../utils/dateHelper';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavLink from '../components/NavLink';

const ReconciliationDetails = () => {
  const styles = useStyles()
  const params = useParams<{id: string}>()
  const history = useHistory();

  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [selectedIssue, setSelectedIssue] = useState<ReconciliationIssueModel | null>(null)

  const { getReconciliationDetails, handleReconciliationIssue } = useReconciliationActions()

  const loading = useAppSelector(state => state.reconciliation.loading)
  const reconciliationDetails = useAppSelector(state => state.reconciliation.reconciliationDetails)

  useEffect(() => {
    if (reconciliationDetails == null || reconciliationDetails.id != params.id) {
      getReconciliationDetails(params.id)
    }
  }, [])

  const onHandleReconciliationIssue = (issue: ReconciliationIssueModel) => {
    setSelectedIssue(issue)
    setModalVisible(true)
  }

  const onReconciliationIssueHandled = (comment: string) => {
    if (selectedIssue?.id && selectedIssue?.reconciliationId) {
      handleReconciliationIssue(selectedIssue.id, selectedIssue.reconciliationId, comment)
      setModalVisible(false)
    }
  }

  return (
    <div className={styles.container}>
      <h1>{reconciliationDetails && formatDateString(reconciliationDetails.date)}</h1>
      <div className={styles.actionBar}>
        <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()} />
      </div>
      <ReconciliationIssueList
        reconciliationIssues={reconciliationDetails?.issues ?? []}
        onHandleClick={(reconciliationIssue) => onHandleReconciliationIssue(reconciliationIssue)}
      />
      <ReconciliationIssueHandlingModal 
        visible={modalVisible} 
        reconciliationId={selectedIssue?.reconciliationId}
        reconciliationIssueId={selectedIssue?.id}
        onHideClick={() => setModalVisible(false)}
        onSaveClick={onReconciliationIssueHandled}
        onCancelClick={() => setModalVisible(false)}
      />
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default ReconciliationDetails

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    }
  },
  actionBar: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between', 
    alignItems: 'center'
  },
})