import {createUseStyles} from 'react-jss'
import {titleMarginBottom} from '../constants/layout'
import useLearningActions from "../hooks/useLearningActions";
import {useAppSelector} from "../redux/hooks";
import React, {useEffect, useState} from "react";
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator";
import {Language, LearningCourseManagementModel} from "../redux/types";
import {useHistory, useParams} from "react-router-dom";
import CourseCard from "../components/Learning/CourseCard";
import NavLink from "../components/NavLink";
import CourseEditor from "../components/Learning/CourseEditor";
import ModuleList from "../components/Learning/ModuleList";
import {
  faArrowLeft,
  faEye,
  faEyeSlash,
  faFileCode,
  faFlag,
  faPen,
  faSave,
  faUserCheck
} from '@fortawesome/free-solid-svg-icons'
import useRouterQuery from "../hooks/useRouterQuery";
import {Toolbar, ToolbarItem} from "../components/Learning/Toolbar";
import useLearningEditor from "../hooks/useLearningEditor";
import Button from "../components/Button";
import {multilingual as m} from "../utils/multilingualStringHelper";


const CourseDetails = () => {
  const styles = useStyles()
  const params = useParams<{ id: string | undefined }>()
  const {query} = useRouterQuery()
  const history = useHistory();

  const {
    getLearningCourses,
    createLearningCourse,
    updateLearningCourse,
    publishLearningCourse,
    unpublishLearningCourse,
  } = useLearningActions()

  const {
    createNewCourse,
    toEditableCourse,
    toLearningCourseCreateOrUpdateModel,
    previewLanguage,
    setPreviewLanguage
  } = useLearningEditor()

  const loading = useAppSelector(state => state.learning.loading)
  const courses = useAppSelector(state => state.learning.learningCourses)

  const [editModel, setEditModel] = useState<LearningCourseManagementModel>(createNewCourse(''))
  const isNewCourse = !params.id


  useEffect(() => {
    if (courses.length === 0) {
      getLearningCourses()
    }
  }, []);

  useEffect(() => {
    const categoryId = query.get('category') || ''
    const course = courses.find(course => course.id === params.id)

    if (course) {
      setEditModel(toEditableCourse(course))
    } else {
      setEditModel(createNewCourse(categoryId))
    }
  }, [query, params.id, courses]);

  const publishCourse = () => {
    if (!isNewCourse) {
      publishLearningCourse(params.id!)
    }
  }

  const unpublishCourse = () => {
    if (!isNewCourse) {
      unpublishLearningCourse(params.id!)
    }
  }

  const saveCourse = () => {
    const createOrUpdateModel = toLearningCourseCreateOrUpdateModel(editModel)
    if (isNewCourse) {
      createLearningCourse(
        createOrUpdateModel,
        (newModel) => {
          history.replace(`/learning/course/${newModel.id}`)
        })
    } else {
      updateLearningCourse(params.id!, createOrUpdateModel)
    }
  }

  const renderDeepLinkButton = () => {
    const deepLinkText = m(previewLanguage, editModel.deepLinkText)
    const deepLinkUrl = m(previewLanguage, editModel.deepLinkUrl)

    if (deepLinkText && deepLinkUrl) {
      return (
        <Button
          variant={'negative'}
          title={deepLinkText}
          onClick={() => window.open(deepLinkUrl, '_blank')}
        />
      )
    }
  }

  return (
    <div className={styles.page}>
      <h1>{isNewCourse ? 'Create' : 'Edit'} course</h1>

      <div className={styles.titleBar}>
        <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()}/>
      </div>

      <div className={styles.container}>
        <div className={styles.editor}>
          <div className={styles.titleBar}>
            <h2>Preview</h2>
            <Toolbar>
              <ToolbarItem icon={faFlag} title={"English"} display={"iconAndText"}
                           disabled={previewLanguage === Language.en}
                           onClick={() => setPreviewLanguage(Language.en)}/>
              <ToolbarItem icon={faFlag} title={"Swedish"} display={"iconAndText"}
                           disabled={previewLanguage === Language.sv}
                           onClick={() => setPreviewLanguage(Language.sv)}/>
            </Toolbar>
          </div>
          <CourseCard course={editModel} language={previewLanguage}/>
          {renderDeepLinkButton()}

          <div className={styles.titleBar}>
            <h2>Modules</h2>
            <Toolbar label={"Create"}>
              <ToolbarItem
                icon={faFileCode}
                title={"Markdown"}
                display={"iconAndText"}
                disabled={isNewCourse}
                onClick={() => {
                  history.push(`/learning/course/${params.id}/createModule?moduleType=markdown`)
                }}
              />
              <ToolbarItem
                icon={faUserCheck}
                title={"Action"}
                display={"iconAndText"}
                disabled={isNewCourse}
                onClick={() => {
                  history.push(`/learning/course/${params.id}/createModule?moduleType=action`)
                }}
              />
              <ToolbarItem
                icon={faPen}
                title={"Quiz"}
                display={"iconAndText"}
                disabled={isNewCourse}
                onClick={() => {
                  history.push(`/learning/course/${params.id}/createModule?moduleType=quiz`)
                }}
              />
            </Toolbar>
          </div>
          <ModuleList modules={editModel.modules || []} language={previewLanguage}/>
        </div>

        <div className={styles.editor}>
          <div className={styles.titleBar}>
            <h2>Edit</h2>
            <Toolbar>
              <ToolbarItem icon={faSave} title={"Save"} display={"iconAndText"} onClick={saveCourse}/>
              {!editModel.isPublished &&
                  <ToolbarItem icon={faEye} title={"Publish"} display={"iconAndText"} disabled={isNewCourse}
                               onClick={publishCourse}/>}
              {editModel.isPublished &&
                  <ToolbarItem icon={faEyeSlash} title={"Unpublish"} display={"iconAndText"} disabled={isNewCourse}
                               onClick={unpublishCourse}/>}
            </Toolbar>
          </div>
          <CourseEditor course={editModel} onChange={setEditModel}/>
        </div>
      </div>
      <FullScreenLoadingIndicator visible={loading}/>
    </div>
  )
}

export default CourseDetails

const useStyles = createUseStyles({
  page: {
    '& h1': {
      marginBottom: titleMarginBottom
    },
    marginBottom: 40
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20
  },
  editor: {
    minWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 10
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})
