import { restConfig } from "../authConfig"
import { useAppDispatch } from "../redux/hooks"
import { issueFetched, issueFetchFailure, issuesFetched, issuesFetchFailure, issueUpdated, issueUpdateFailure, loading } from "../redux/slices/protectIssueSlice"
import {ProtectIssue, UpdateProtectIssueModel} from '../redux/types'
import { getPaginationDataFromResponse } from "../utils/responseHelper"
import { createUrlWithQueryString } from "../utils/url"
import useFetchClient from "./useFetchClient"

const useProtectIssueActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getIssues = (includeHandled: boolean, pageIndex: number, pageSize: number) => {
    dispatch(loading())   

    const params = {
      includeHandled: includeHandled,
      pageNumber: pageIndex,
      pageSize: pageSize
    }
    const url = createUrlWithQueryString(restConfig.routes.protectIssues, params)

    return fetchClient.get(url)
      .then(response => {
          const paginationData = getPaginationDataFromResponse(response.raw)
          dispatch(issuesFetched({ items: response.data as ProtectIssue[], totalCount: paginationData?.totalItemCount ?? 0}))
      })
      .catch(error => {
          console.log('getIssues error:', error)
          dispatch(issuesFetchFailure(error))
      })
  }

  const getIssue = (issueId: string) => {
    dispatch(loading())

    const url = restConfig.routes.protectIssues + '/' + issueId

    return fetchClient.get(url)
      .then(response => {
          dispatch(issueFetched(response.data as ProtectIssue))
      })
      .catch(error => {
          console.log('getIssue error:', error)
          dispatch(issueFetchFailure(error))
      })
  }

  const updateIssue = (issueId: string, investigation: UpdateProtectIssueModel) => {
    dispatch(loading())

    const url = restConfig.routes.protectIssues + '/' + issueId

    return fetchClient.post(url, investigation)
      .then(response => {
          dispatch(issueUpdated(response.data as ProtectIssue))
      })
      .catch(error => {
          console.log('updateIssue error:', error)
          dispatch(issueUpdateFailure(error))
      })
  }

  return {
    getIssues,
    getIssue,
    updateIssue
  }
}

export default useProtectIssueActions