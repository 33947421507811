import { useEffect, useState } from "react"
import InputField from "../InputField"
import Modal from "../Modal"

interface Props {
    onSaveClick: (days: number) => void
    onCancelClick: () => void
    visible: boolean
}

const SilenceCreditSpaceWarningModal = ({onSaveClick, onCancelClick, visible}: Props) => {
    
    const [days, setDays] = useState<number>(1)

    useEffect(() => {
        if (visible) {
            setDays(1)
        }
    }, [visible])
    
    return (
        <Modal
            title='Silence credit space warning'
            show={visible}
            okButtonTitle='Save'
            cancelButtonTitle='Cancel'
            onHide={() => onCancelClick()}
            onOkClick={() => onSaveClick(days)}
            onCancelClick={() => onCancelClick()}
        >
            <InputField
                id='days'
                key='days'
                numeric={true}
                value={days.toString()}
                onChange={(event) => setDays(parseFloat(event.target.value))}
                label={'Days'}
                placeholder=''
              />
        </Modal>
    )
}

export default SilenceCreditSpaceWarningModal