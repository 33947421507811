import {useAppDispatch, useAppSelector} from "../redux/hooks"
import {previewLanguageChanged} from "../redux/slices/learningSlice"
import {
  Language,
  LearningCourseCreateOrUpdateModel,
  LearningCourseManagementModel,
  LearningModuleActionManagementModel,
  LearningModuleCreateOrUpdateModel,
  LearningModuleManagementModel,
  LearningModuleMarkdownCreateOrUpdateModel,
  LearningModuleMarkdownManagementModel,
  LearningModuleQuizManagementModel,
  LearningModuleType
} from '../redux/types'

const useLearningEditor = () => {
  const dispatch = useAppDispatch()

  const previewLanguage = useAppSelector(state => state.learning.previewLanguage)
  const setPreviewLanguage = (language: Language) => {
    dispatch(previewLanguageChanged(language))
  }

  const createNewCourse = (categoryId: string) => {
    return {
      id: '',
      learningCategoryId: categoryId,
      employerId: null,
      title: {},
      description: {},
      imageUrl: '',
      orderNumber: 10,
      tags: [],
      deepLinkText: {},
      deepLinkUrl: {},
      modules: [],
      isPublished: false,
      isNew: true,
      isTopRated: false,
      isPopular: false,
      rating: 0,
      ratingCount: 0
    } as LearningCourseManagementModel
  }

  const toEditableCourse = (course: LearningCourseManagementModel) => {
    return JSON.parse(JSON.stringify(course)) as LearningCourseManagementModel
  }

  const toLearningCourseCreateOrUpdateModel = (course: LearningCourseManagementModel) => {
    return {
      learningCategoryId: course.learningCategoryId,
      employerId: course.employerId,
      title: course.title,
      description: course.description,
      imageUrl: course.imageUrl,
      orderNumber: course.orderNumber,
      tags: course.tags,
      deepLinkText: course.deepLinkText,
      deepLinkUrl: course.deepLinkUrl
    } as LearningCourseCreateOrUpdateModel
  }
  const createNewModule = (courseId: string, moduleType: LearningModuleType) => {
    const newModule = {
      id: '',
      learningCourseId: courseId,
      moduleType: moduleType,
      estimatedCompletionTime: '00:00:00',
      orderNumber: 10,
      title: {},
      isPublished: false
    } as LearningModuleManagementModel

    switch (moduleType) {
      case LearningModuleType.Markdown:
        return {
          ...newModule,
          content: {}
        } as LearningModuleMarkdownManagementModel
      case LearningModuleType.Quiz:
        return {
          ...newModule,
          questions: []
        } as LearningModuleQuizManagementModel
      case LearningModuleType.Action:
        return {
          ...newModule,
          description: {},
          prompt: {},
          context: {},
          deepLinkText: {},
          deepLinkUrl: {}
        } as LearningModuleActionManagementModel
    }
  }

  const toEditableModule = (module: LearningModuleManagementModel) => {
    return JSON.parse(JSON.stringify(module)) as LearningModuleManagementModel
  }

  const toLearningModuleCreateOrUpdateModel = (module: LearningModuleManagementModel) => {
    const createOrEditModel = {
      moduleType: module.moduleType,
      title: module.title,
      orderNumber: module.orderNumber,
      estimatedCompletionTime: module.estimatedCompletionTime
    } as LearningModuleCreateOrUpdateModel

    switch (module.moduleType) {
      case LearningModuleType.Markdown:
        return {
          ...createOrEditModel,
          content: (module as LearningModuleMarkdownManagementModel).content
        } as LearningModuleMarkdownCreateOrUpdateModel
      case LearningModuleType.Quiz:
        return {
          ...createOrEditModel,
          questions: (module as LearningModuleQuizManagementModel).questions
        } as LearningModuleQuizManagementModel
      case LearningModuleType.Action:
        return {
          ...createOrEditModel,
          description: (module as LearningModuleActionManagementModel).description,
          prompt: (module as LearningModuleActionManagementModel).prompt,
          context: (module as LearningModuleActionManagementModel).context,
          deepLinkText: (module as LearningModuleActionManagementModel).deepLinkText,
          deepLinkUrl: (module as LearningModuleActionManagementModel).deepLinkUrl
        } as LearningModuleActionManagementModel
    }
  }


  return {
    createNewCourse,
    toEditableCourse,
    toLearningCourseCreateOrUpdateModel,
    createNewModule,
    toEditableModule,
    toLearningModuleCreateOrUpdateModel,
    previewLanguage,
    setPreviewLanguage
  }
}

export default useLearningEditor
