import { createStore, combineReducers, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import marketReducer from './slices/marketSlice'
import bankAccountReducer from './slices/bankAccountSlice'
import amlFlaggedCompanyRepresentativeReducer from './slices/amlFlaggedCompanyRepresentativeSlice'
import amlFlaggedCompanyReducer from './slices/amlFlaggedCompanySlice'
import taxTableReducer from './slices/taxTableSlice'
import nordeaTokenReducer from './slices/nordeaTokenSlice'
import reconciliationReducer from './slices/reconciliationSlice'
import transactionReducer from './slices/transactionSlice'
import userReducer from './slices/userSlice'
import employerReducer from './slices/employerSlice'
import employeeReducer from './slices/employeeSlice'
import shiftReducer from './slices/shiftSlice'
import payDeviationReducer from './slices/payDeviationSlice'
import paydayReducer from './slices/paydaySlice'
import payRunReducer from './slices/payRunSlice'
import payScheduleReducer from './slices/payScheduleSlice'
import creditorReducer from './slices/creditorSlice'
import fortnoxReducer from './slices/fortnoxSlice'
import appStateReducer from './slices/appStateSlice'
import protectIssueReducer from './slices/protectIssueSlice'
import protectRuleReducer from './slices/protectRuleSlice'
import protectCustomerReducer from './slices/protectCustomerSlice'
import campaignReducer from './slices/campaignSlice'
import dashboardMetricsReducer from './slices/dashboardMetricsSlice'
import inAppMessageReducer from './slices/inAppMessageSlice'
import learningReducer from './slices/learningSlice'
import customerReducer from './slices/customerSlice'
import invoicePayoutProviderReducer from './slices/invoicePayoutProviderSlice'
import invoicePayoutReducer from './slices/invoicePayoutSlice'
import companyMonitorReducer from './slices/companyMonitorSlice'
import companyCheckReducer from './slices/companyCheckSlice'
import payRunDashboardReducer from './slices/payRunDashboardSlice'
import communicationOptOutReducer from './slices/communicationOptOutSlice'

let middleWares: any = [thunk]

const rootReducer = combineReducers({
  appState: appStateReducer,
  payRun: payRunReducer,
  payday: paydayReducer,
  shift: shiftReducer,
  payDeviation: payDeviationReducer,
  customer: customerReducer,
  invoicePayoutProvider: invoicePayoutProviderReducer,
  invoicePayout: invoicePayoutReducer,
  employee: employeeReducer,
  employer: employerReducer,
  paySchedule: payScheduleReducer,
  user: userReducer,
  transaction: transactionReducer,
  reconciliation: reconciliationReducer,
  amlFlaggedCompanyRepresentative: amlFlaggedCompanyRepresentativeReducer,
  amlFlaggedCompany: amlFlaggedCompanyReducer,
  taxTable: taxTableReducer,
  nordeaToken: nordeaTokenReducer,
  bankAccount: bankAccountReducer,
  creditors: creditorReducer,
  fortnox: fortnoxReducer,
  market: marketReducer,
  protectIssues: protectIssueReducer,
  protectRules: protectRuleReducer,
  protectCustomer: protectCustomerReducer,
  campaigns: campaignReducer,
  dashboardMetrics: dashboardMetricsReducer,
  inAppMessages: inAppMessageReducer,
  learning: learningReducer,
  companyMonitor: companyMonitorReducer,
  companyCheck: companyCheckReducer,
  payRunDashboard: payRunDashboardReducer,
  optOut: communicationOptOutReducer
})

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [],
}

const persistedReducer = persistReducer<any, any>(persistConfig, rootReducer)

const store = createStore(persistedReducer, applyMiddleware(...middleWares))
const persistor = persistStore(store)

export default { store, persistor }

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
