import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MarketModel } from '../types'

interface MarketState {
    loading: boolean
    markets: MarketModel[]
    error: Error | string | null
  }

const initialState: MarketState = {
    loading: false,
    markets: [],
    error: null
}

export const marketSlice = createSlice({
    name: 'market',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        marketsFetched: (state, action: PayloadAction<MarketModel[]>) => {
            state.loading = false
            state.markets = action.payload
        },
        marketsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
    }
})

export const { 
    loading, 
    marketsFetched, 
    marketsFetchFailure 
} = marketSlice.actions

export default marketSlice.reducer