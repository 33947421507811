import { TransactionListItemModel, TransactionModel, TransactionFilterParams } from '../redux/types'
import { loading, transactionDetailsFetched, transactionDetailsFetchFailure, transactionsFetched, transactionsFetchFailure } from "../redux/slices/transactionSlice"
import useFetchClient from "./useFetchClient"
import { useAppDispatch } from "../redux/hooks"
import { restConfig } from "../authConfig"
import { createUrlWithQueryString } from "../utils/url"
import { getPaginationDataFromResponse } from "../utils/responseHelper"

const useTransactionActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getTransactions = (searchParams: TransactionFilterParams | null, pageIndex: number, pageSize: number) => {
    dispatch(loading()) 

    const params = {
      userId: searchParams?.userId ?? null,
      employerId: searchParams?.employerId ?? null,
      invoicePayoutProviderId: searchParams?.invoicePayoutProviderId ?? null,
      state: searchParams?.state ?? null,
      type: searchParams?.transactionType ?? null,
      fromDate: searchParams?.fromDate ?? null,
      toDate: searchParams?.toDate ?? null,
      paydayDate: searchParams?.paydayDate ?? null,
      pageNumber: pageIndex,
      pageSize: pageSize
    }

  const url = createUrlWithQueryString(restConfig.routes.transactions, params)

  return fetchClient.get(url)
      .then(response => {
          const paginationData = getPaginationDataFromResponse(response.raw)
          dispatch(transactionsFetched({ items: response.data as TransactionListItemModel[], totalCount: paginationData?.totalItemCount ?? 0}))
      })
      .catch(error => {
          console.log('getTransactions error:', error)
          dispatch(transactionsFetchFailure(error))
      })
  }

  const getTransactionDetails = (id: string) => {
    dispatch(loading())   

    const url = restConfig.routes.transactions + `/${id}`

    return fetchClient.get(url)
        .then(response => {
            dispatch(transactionDetailsFetched(response.data as TransactionModel))
        })
        .catch(error => {
            console.log('getTransactionDetails error:', error)
            dispatch(transactionDetailsFetchFailure(error))
        })
}

  return {
    getTransactions,
    getTransactionDetails
  }
}

export default useTransactionActions
