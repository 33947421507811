import { ReactNode } from 'react'
import { Modal as RBSModal } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'
import Button from './Button'

interface Props {
  title?: string
  okButtonTitle?: string,
  cancelButtonTitle?: string,
  children: ReactNode
  show: boolean
  okButtonDisabled?: boolean
  hideCancelButton?: boolean
  onHide: () => void
  onOkClick: () => void
  onCancelClick: () => void
  size?: "sm" | "lg" | "xl" | undefined
  scrollable?: boolean,
  blurBackground?: boolean
}

const Modal = ({
  children,
  title = 'Modal title',
  okButtonTitle = 'Save changes',
  cancelButtonTitle = 'Close',
  show,
  okButtonDisabled = false,
  hideCancelButton = false,
  onHide,
  onOkClick: onSaveClick,
  onCancelClick,
  size,
  scrollable = true,
  blurBackground = false
}: Props) => {
  const styles = useStyles()

  return (
    <RBSModal size={size} show={show} onHide={onHide} scrollable={scrollable} style={blurBackground && {backgroundColor: '#000000AA'}}>
      <RBSModal.Header closeButton>
        <RBSModal.Title className={styles.modalTitle}>{title}</RBSModal.Title>
      </RBSModal.Header>
      <RBSModal.Body>{children}</RBSModal.Body>
      <RBSModal.Footer>
        {!hideCancelButton && <Button title={cancelButtonTitle} variant="negative" onClick={onCancelClick} />}
        <Button title={okButtonTitle} onClick={onSaveClick} disabled={okButtonDisabled} />
      </RBSModal.Footer>
    </RBSModal>
  )
}

export default Modal

const useStyles = createUseStyles({
  modalTitle: {
    fontFamily: 'Aestetico-Bold',
    textTransform: 'uppercase',
    color: Colors.darkGray3,
  },
})
