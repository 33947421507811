import { useState } from "react"
import InputField from "../InputField"
import Modal from "../Modal"


interface Props {
    onAuthorizerIdSpecified: (authorizerId: string) => void
    onCancel: () => void
    visible: boolean
}

const SpecifyAuthorizerIdModal = ({ onAuthorizerIdSpecified, onCancel, visible}: Props) => {

    const [authorizerId, setAuthorizerId] = useState<string>('')
 
    return (
        <Modal
            title='Specify authorizer id'
            show={visible}
            okButtonTitle={'Ok'}
            cancelButtonTitle={'Cancel'}
            onHide={() => onCancel()}
            onOkClick={() => onAuthorizerIdSpecified(authorizerId)}
            onCancelClick={() => onCancel()}
            okButtonDisabled={authorizerId?.length <= 1}
        >
            <InputField
                id='authorizerId'
                value=''
                onChange={(event) => setAuthorizerId(event.target.value)}
                label='Authorizer id'
                placeholder=''
              />
        </Modal>
    )
}

export default SpecifyAuthorizerIdModal