import {createUseStyles} from "react-jss"
import {formatDateTimeString} from '../../utils/dateHelper'
import {Link} from "react-router-dom"
import {Colors} from "../../constants/colors"
import {InAppMessage} from "../../redux/types"
import Card from "../Card"
import Badge from 'react-bootstrap/Badge';
import InAppMessageStatusBadge from "./InAppMessageStatusBadge"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

interface Props {
  inAppMessages: InAppMessage[]
}

const InAppMessageList = ({inAppMessages}: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        <div className={styles.cell}>
          <h6>Internal name</h6>
        </div>
        <div className={styles.cell}>
          <h6>Valid from</h6>
        </div>
        <div className={styles.cell}>
          <h6>Valid to</h6>
        </div>
        <div className={styles.smallCell}>
          <h6>Sticky</h6>
        </div>
        <div className={styles.cell}>
          <h6>Min app version</h6>
        </div>
        <div className={styles.smallCell}>
          <h6>Priority</h6>
        </div>
        <div className={styles.cell}>
          <h6>Status</h6>
        </div>
        <div className={styles.smallCell}>
          <h6>Read count</h6>
        </div>
      </Card>
    )
  }

  const renderListItems = () => {
    return inAppMessages?.map((item, index) => {
      return (
        <Link to={`/inAppMessages/${item.id}`} key={`inAppMessage_${index}`}>
          <Card className={`${styles.container} card`}>
            <div className={styles.cell}>
              {item.internalName}
            </div>
            <div className={styles.cell}>
              {formatDateTimeString(item.validFrom)}
            </div>
            <div className={styles.cell}>
              {item.validTo == null ? '∞' : formatDateTimeString(item.validTo)}
            </div>
            <div className={styles.smallCell}>
              {item.sticky && 
                <FontAwesomeIcon
                  icon={faCheck}
                  color={Colors.euro_600}
                  title=''
                />}
            </div>
            <div className={styles.cell}>
              {item.minAppVersion}
            </div>
            <div className={styles.smallCell}>
              {item.priority}
            </div>
            <div className={styles.cell}>
              <InAppMessageStatusBadge inAppMessage={item} />
            </div>
            <div className={styles.smallCell}>
              {item.readCount}
            </div>
          </Card>
        </Link>
      )
    })
  }

  return (
    <div className={styles.inAppMessageList}>
      {renderHeader()}
      {renderListItems()}
    </div>
  )
}

export default InAppMessageList

const useStyles = createUseStyles({
  inAppMessageList: {
    marginTop: '30px',
    marginBottom: '30px'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    margin: '10px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    marginBottom: '20px'
  },
  cell: {
    flex: 1
  },
  smallCell: {
    width: '90px'
  }
})