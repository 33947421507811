import {useEffect, useState} from 'react'
import Modal from '../Modal'
import {Campaign, CampaignRecipientType, CampaignType, CreateOrUpdateCampaignModel} from '../../redux/types'
import InputField from '../InputField'
import Select from '../Select'
import DatePicker from "../DatePicker";

interface Props {
  initialValue?: Campaign
  onAddClick?: (campaign: CreateOrUpdateCampaignModel) => void
  onCancelClick?: () => void
  visible: boolean
}

const CreateOrUpdateCampaignModal = ({initialValue, onAddClick, onCancelClick, visible}: Props) => {
  const emptyCampaign = () => {
    return {
      name: '',
      type: CampaignType.FreePayoutCampaign,
      executionTime: '',
      recipientType: CampaignRecipientType.ActiveEmployees,
      recipients: [],
      numberOfFreePayouts: 1
    }
  }

  const [campaign, setCampaign] = useState<CreateOrUpdateCampaignModel>(emptyCampaign)

  useEffect(() => {
    if (initialValue) {
      setCampaign({
        name: initialValue.name,
        type: initialValue.type,
        executionTime: initialValue.executionTime,
        recipientType: initialValue.recipientType,
        recipients: initialValue.recipients?.map(x => x.id) ?? [],
        numberOfFreePayouts: initialValue.numberOfFreePayouts,
      })
    } else {
      setCampaign(emptyCampaign)
    }
  }, [initialValue])

  const handleOnAdd = () => {
    onAddClick && onAddClick(campaign)
    if (!initialValue) {
      setCampaign(emptyCampaign)
    }
  }

  const handleOnCancel = () => {
    onCancelClick && onCancelClick()
    if (!initialValue) {
      setCampaign(emptyCampaign)
    }
  }

  const handleOnChange = (propName: string, value: any) => {
    setCampaign({...campaign, [propName]: value})
  }

  const getCampaignTypeOptions = () => {
    return Object.keys(CampaignType)
      .map((key) => {
        return {
          name: key,
          value: CampaignType[key as keyof typeof CampaignType]
        }
      })
  }

  const getRecipientTypeOptions = () => {
    return Object.keys(CampaignRecipientType)
      .map((key) => {
        return {
          name: key,
          value: CampaignRecipientType[key as keyof typeof CampaignRecipientType]
        }
      })
  }

  return (
    <Modal
      title={initialValue ? 'Edit campaign' : 'Create campaign'}
      show={visible}
      okButtonTitle='Save'
      cancelButtonTitle='Cancel'
      scrollable={false}
      onHide={() => handleOnCancel()}
      onOkClick={() => handleOnAdd()}
      onCancelClick={() => handleOnCancel()}
    >
      {!initialValue &&
          <Select
              id='type'
              key='type'
              label='Campaign Type'
              options={getCampaignTypeOptions()}
              selectedValue={campaign.type?.toString()}
              onSelect={(value) => handleOnChange('type', CampaignType[value as keyof typeof CampaignType])}
          />}
      <InputField
        id='name'
        label='Name'
        value={campaign.name}
        onChange={(event) => handleOnChange('name', event.target.value)}
        placeholder='My campaign'
      />
      <DatePicker
        id='executionTime'
        label='Execution time'
        includeTime={true}
        value={campaign.executionTime}
        onChange={value => handleOnChange('executionTime', value)}
      />
      <Select
        id='recipientType'
        key='recipientType'
        label='Recipient Type'
        options={getRecipientTypeOptions()}
        selectedValue={campaign.recipientType?.toString()}
        onSelect={(value) => handleOnChange('recipientType', value)}
      />
      {(campaign?.type === CampaignType.FreePayoutCampaign) &&
          <InputField
              id='numberOfFreePayouts'
              value={campaign.numberOfFreePayouts.toString() ?? ''}
              onChange={(event) => handleOnChange('numberOfFreePayouts', parseInt(event.target.value))}
              label='Number of free payouts'
              placeholder='1'
          />
      }
    </Modal>
  )
}

export default CreateOrUpdateCampaignModal
