import { useState, ChangeEvent } from 'react'
import Modal from '../../Modal'
import InputField from '../../InputField'

interface Props {
  onSaveClick?: (comment: string) => void
  onCancelClick?: () => void
  visible: boolean
}

const AcceptAmlFlaggedEntityModal = (props: Props) => {
  const [comment, setComment] = useState('')

  const handleOnSave = () => {
    props.onSaveClick && props.onSaveClick(comment)
  }

  const handleOnCancel = () => {
    props.onCancelClick && props.onCancelClick()
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value)  
  }

	return (
		<Modal
			title='Accept'
			show={props.visible}
			onHide={() => handleOnCancel()}
			onOkClick={() => handleOnSave()}
			onCancelClick={() => handleOnCancel()}
		>
			<InputField
                id={`acceptComment`}
                value={comment}
                onChange={handleOnChange}
                placeholder='Enter your comment here'
                label='Have you made a thorough investigation and are sure you want to accept? If so, write your comment below'
              />
		</Modal>
	)
}

export default AcceptAmlFlaggedEntityModal
