import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TransactionListItemModel, TransactionModel, TransactionFilterParams, PagedListItems } from '../types'

interface TransactionState {
    loading: boolean
    transactionDetails: TransactionModel | null
    transactionsOnPage: TransactionListItemModel[]
    filter: TransactionFilterParams | null
    pageIndex: number
    totalCount: number
    pageSize: number 
    error: Error | string | null
  }

const initialState: TransactionState = {
    loading: false,
    transactionDetails: null,
    transactionsOnPage: [],
    filter: null,
    totalCount: 0,
    pageIndex: 1,
    pageSize: 20,
    error: null
}

export const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        transactionsFetched: (state, action: PayloadAction<PagedListItems<TransactionListItemModel>>) => {
            state.loading = false
            state.transactionsOnPage = action.payload.items
            state.totalCount = action.payload.totalCount
            state.error = null
        },
        transactionsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        transactionDetailsFetched: (state, action: PayloadAction<TransactionModel>) => {
            state.loading = false
            state.transactionDetails = action.payload
            state.error = null
        },
        transactionDetailsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        filterChanged: (state, action: PayloadAction<TransactionFilterParams>) => {
            state.filter = action.payload
            state.pageIndex = 1
        },
    }
})

export const { 
    loading, 
    transactionsFetched, 
    transactionsFetchFailure, 
    transactionDetailsFetched, 
    transactionDetailsFetchFailure, 
    pageIndexChanged, 
    filterChanged 
} = transactionSlice.actions

export default transactionSlice.reducer