import {useEffect, useState} from 'react'
import Modal from '../Modal'
import {CreateOrUpdateInAppMessageModel, InAppMessage} from '../../redux/types'
import InputField from '../InputField'
import Select from '../Select'
import DatePicker from "../DatePicker";
import { isDateValid } from '../../utils/dateHelper'
import { Colors } from '../../constants/colors'
import { createUseStyles } from 'react-jss'
import useEmployerActions from "../../hooks/useEmployerActions";
import {useAppSelector} from "../../redux/hooks";
import Switch from '../Switch'

interface Props {
  initialValue?: InAppMessage | undefined
  onAddClick?: (model: CreateOrUpdateInAppMessageModel) => void
  onCancelClick?: () => void
  visible: boolean
}

const CreateOrUpdateInAppMessageModal = ({initialValue, onAddClick, onCancelClick, visible}: Props) => {
  const styles = useStyles()

  const {getEmployerNames} = useEmployerActions()
  const employerNames = useAppSelector(state => state.employer.employerNames)

  useEffect(() => {
    if (employerNames.length === 0) {
      getEmployerNames()
    }
  }, [])

  const defaultValidState = {
    internalNameValid: true,
    iconValid: true,
    minAppVersionValid: true,
    fromDateValid: true,
    toDateValid: true
  }

  const emptyMessage = () => {
    return {
      employerId: undefined,
      internalName: '',
      icon: '',
      url: undefined,
      validFrom: new Date().toISOString(),
      validTo: undefined,
      sticky: false,
      minAppVersion: '1.0.0',
      priority: 1,
    }
  }

  const [message, setMessage] = useState<CreateOrUpdateInAppMessageModel>(emptyMessage)
  const [valid, setValid] = useState<{
    internalNameValid: boolean
    iconValid: boolean
    minAppVersionValid: boolean
    fromDateValid: boolean
    toDateValid: boolean
}>(defaultValidState)

  useEffect(() => {
    if (initialValue) {
      setMessage({
        employerId: initialValue.employerId,
        internalName: initialValue.internalName,
        icon: initialValue.icon,
        url: initialValue.url,
        validFrom: initialValue.validFrom,
        validTo: initialValue.validTo,
        sticky: initialValue.sticky,
        minAppVersion: initialValue.minAppVersion,
        priority: initialValue.priority
      })
    } else {
      setMessage(emptyMessage)
    }
  }, [initialValue])

  const validateInput = () => {
    const appVersionRegex = /^(\d+\.)(\d+\.)(\d+)$/

    let validClone = {...valid}

    validClone.internalNameValid = message.internalName.length > 0
    validClone.iconValid = message.icon.length > 0
    validClone.minAppVersionValid = appVersionRegex.test(message.minAppVersion)
    validClone.fromDateValid = isDateValid(message.validFrom)
    validClone.toDateValid = message.validTo == null || message.validTo === '' || isDateValid(message.validTo)

    setValid(validClone)

    return (
      validClone.internalNameValid &&
      validClone.iconValid &&
      validClone.minAppVersionValid &&
      validClone.fromDateValid &&
      validClone.toDateValid)
  }

  const handleOnAdd = () => {
    if (!validateInput()) {
      return
    }

    onAddClick && onAddClick(message)
    setValid(defaultValidState)

    if (!initialValue) {
      setMessage(emptyMessage)
    }
  }

  const handleOnCancel = () => {
    onCancelClick && onCancelClick()

    if (!initialValue) {
      setMessage(emptyMessage)
    }
  }

  const handleOnChange = (propName: string, value: any) => {
    setMessage({...message, [propName]: value})
  }

  const handleSetEmployer = (value: string) => {
    setMessage({...message, employerId: value === '' ? undefined : value})
  }

  const getEmployerOptions = () => {
    return [{name: 'All', value: ''}, ...employerNames.map(employer => {
      return {
        name: employer.name,
        value: employer.id
      }
    })]
  }

  return (
    <Modal
      title={initialValue ? 'Edit in-app message' : 'Create in-app message'}
      show={visible}
      okButtonTitle='Save'
      cancelButtonTitle='Cancel'
      scrollable={false}
      onHide={() => handleOnCancel()}
      onOkClick={() => handleOnAdd()}
      onCancelClick={() => handleOnCancel()}
    >
      <Select
        id='employerSelector'
        label='Recipient employer'
        options={getEmployerOptions()}
        selectedValue={message.employerId ?? ''}
        onSelect={(value) => handleSetEmployer(value)}
      />
      <InputField
        id='internalName'
        label='Internal name'
        value={message.internalName}
        onChange={(event) => handleOnChange('internalName', event.target.value)}
        placeholder='My in-app message'
        valid={valid.internalNameValid}
      />
      <DatePicker
        id='validFrom'
        label='Valid from'
        includeTime={true}
        value={message.validFrom}
        onChange={value => handleOnChange('validFrom', value)}
        valid={valid.fromDateValid}
      />
      <DatePicker
        id='validTo'
        label='Valid to'
        includeTime={true}
        value={message.validTo}
        onChange={value => handleOnChange('validTo', value)}
        valid={valid.toDateValid}
      />
      <Switch
        id='sticky'
        key='sticky'
        checked={message?.sticky ?? false}
        label='Sticky'
        onChange={() => handleOnChange('sticky', !(message?.sticky ?? false))}
      />
      <InputField
        id='minAppVersion'
        label='Min app version'
        value={message.minAppVersion}
        onChange={(event) => handleOnChange('minAppVersion', event.target.value)}
        placeholder='X.Y.Z'
        valid={valid.minAppVersionValid}
      />
      <InputField
        id='icon'
        label='Icon'
        value={message.icon}
        onChange={(event) => handleOnChange('icon', event.target.value)}
        placeholder='calendar'
        valid={valid.iconValid}
      />
      <a className={styles.externalLink} target='blank' href={`https://feathericons.com`}>https://feathericons.com</a>
      <InputField
        id='url'
        label='Url'
        value={message.url ?? ''}
        onChange={(event) => handleOnChange('url', event.target.value)}
        placeholder='Ex. cappy://learn'
      />
      <InputField
        numeric
        id='priority'
        label='Priority'
        value={message.priority.toString()}
        onChange={(event) => handleOnChange('priority', event.target.value)}
      />
    </Modal>
  )
}

export default CreateOrUpdateInAppMessageModal

const useStyles = createUseStyles({
  externalLink: {
      marginLeft: 5,
      cursor: 'pointer',
          '&:hover': {
          color: Colors.euro_600,
      },
  }
})
