import {useState} from "react";
import {EmployeeListItemModel} from "../../redux/types";
import useEmployeeActions from "../../hooks/useEmployeeActions";
import {ColumnDefinition} from "./ColumnDefinition";

const useEmployeeCsvExport = (columns: ColumnDefinition[]) => {
  const {fetchEmployees} = useEmployeeActions()
  const [exporting, setExporting] = useState(false)

  const exportEmployees = async (employerId: string | null, searchTerm: string | null) => {

    const convertToCSV = (employees: EmployeeListItemModel[]): string => {
      const headers = columns.map(col => col.title).join(',');
      const rows = employees.map(employee => columns.map(col => col.extractor(employee)).join(','))
      return `${headers}\n${rows.join('\n')}`;
    };

    try {
      setExporting(true);
      const employees = await fetchEmployees(employerId, searchTerm ?? '');

      if (employees.length === 0) {
        console.warn('No employees found to export');
        return;
      }

      const csvContent = convertToCSV(employees);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = "employees.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting employees:', error);
    }
    finally {
      setExporting(false);
    }
  }

  return {exporting, exportEmployees}
}

export default useEmployeeCsvExport
