import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from '../Modal'
import InputField from '../InputField'
import { 
    CurrencyCodeString,
    InvoicePayoutProviderModel,
    UpdateInvoicePayoutProviderModel
} from '../../redux/types'
import DatePicker from '../DatePicker'
import S1 from '../typography/S1'
import Select from '../Select'

interface Props {
    initialValue?: InvoicePayoutProviderModel
    onSaveClick?: (updateModel: UpdateInvoicePayoutProviderModel) => void
    onCancelClick?: () => void
    visible: boolean
}

const EditInvoicePayoutProviderModal = ({
    initialValue,
    onSaveClick,
    onCancelClick,
    visible
}: Props) => {
    const styles = useStyles()
    const [updateModel, setUpdateModel] = useState<UpdateInvoicePayoutProviderModel>()

    useEffect(() => {
        if (initialValue) {
            setUpdateModel({
                creditLimit: initialValue.creditLimit?.amount,
                remainingCreditSpaceWarningPercent: initialValue.remainingCreditSpaceWarningPercent,
                launchDate: initialValue.launchDate,
                currency: initialValue.currency,
                callbackUrl: initialValue.callbackUrl,
                requestValidationUrl: initialValue.requestValidationUrl,
                cappyFeePercentage: initialValue.cappyFeePercentage,
                customerFeePercentage: initialValue.customerFeePercentage,
                maximumPayoutAmount: initialValue.maximumPayoutAmount,
                minimumPayoutAmount: initialValue.minimumPayoutAmount,
                minimumDaysUntilDueDate: initialValue.minimumDaysUntilDueDate,
                signingCertificateThumbprint: initialValue.signingCertificateThumbprint
            })
        }
    }, [initialValue])

    const handleOnSave = () => {
        if (updateModel) {
            onSaveClick && onSaveClick(updateModel)
        }
    }

    const handleOnClose = () => {
        onCancelClick && onCancelClick()
    }

    const handleOnChange = (propName: string, value: any) => {
        if (updateModel) {
            setUpdateModel({...updateModel, [propName]: value === '' ? null : value})
        }
    }

    const getCurrencyOptions = () => {
        return Object.keys(CurrencyCodeString).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    return (
        <Modal
            title='Edit invoice payout provider'
            show={visible}
            okButtonTitle='Save'
            cancelButtonTitle='Cancel'
            onHide={() => handleOnClose()}
            onOkClick={() => handleOnSave()}
            onCancelClick={() => handleOnClose()}
        >
            <S1 className={styles.sectionTitle}>General</S1>
            <Select
                id='currency'
                key='currency'
                label='Currency'
                options={getCurrencyOptions()}
                selectedValue={updateModel?.currency.toString()}
                onSelect={(value) => handleOnChange('currency', value)}
            />
            <DatePicker
                id='launchDate'
                label='Launch date'
                includeTime={true}
                value={updateModel?.launchDate}
                onChange={value => {
                    handleOnChange('launchDate', value)
                }}
            />
            <InputField
                id='cappyFeePercentage'
                value={updateModel?.cappyFeePercentage?.toString() ?? ''}
                label='Cappy fee percentage (0-100)'
                onChange={(event) => handleOnChange('cappyFeePercentage', event.target.value)}
                placeholder=''
            />
            <InputField
                id='customerFeePercentage'
                value={updateModel?.customerFeePercentage?.toString() ?? ''}
                label='Customer fee percentage (0-100)'
                onChange={(event) => handleOnChange('customerFeePercentage', event.target.value)}
                placeholder=''
            />
            <InputField
                id='minimumPayoutAmount'
                value={updateModel?.minimumPayoutAmount?.toString() ?? ''}
                label='Min. payout amount'
                onChange={(event) => handleOnChange('minimumPayoutAmount', event.target.value)}
                placeholder=''
            />
            <InputField
                id='maximumPayoutAmount'
                value={updateModel?.maximumPayoutAmount?.toString() ?? ''}
                label='Max. payout amount'
                onChange={(event) => handleOnChange('maximumPayoutAmount', event.target.value)}
                placeholder=''
            />
            <InputField
                id='minimumDaysUntilDueDate'
                value={updateModel?.minimumDaysUntilDueDate?.toString() ?? ''}
                label='Min. days until due date'
                onChange={(event) => handleOnChange('minimumDaysUntilDueDate', event.target.value)}
                placeholder=''
            />
            <InputField
                id='callbackUrl'
                value={updateModel?.callbackUrl ?? ''}
                onChange={(event) => handleOnChange('callbackUrl', event.target.value)}
                label='Callback url'
                placeholder=''
            />
            <InputField
                id='requestValidationUrl'
                value={updateModel?.requestValidationUrl ?? ''}
                onChange={(event) => handleOnChange('requestValidationUrl', event.target.value)}
                label='Request validation url'
                placeholder=''
            />
            <InputField
                id='signingCertificateThumbprint'
                value={updateModel?.signingCertificateThumbprint ?? ''}
                onChange={(event) => handleOnChange('signingCertificateThumbprint', event.target.value)}
                label='Signing certificate thumbprint'
                placeholder=''
            />     
            <div style={{marginTop: 30}}>
                <S1 className={styles.sectionTitle}>Credit settings</S1>
                <InputField
                    id='creditLimit'
                    value={updateModel?.creditLimit?.toString() ?? ''}
                    label={`Credit limit (${initialValue?.creditUsage?.currencyCode?.toUpperCase()})`}
                    onChange={(event) => handleOnChange('creditLimit', event.target.value)}
                    placeholder=''
                />
                <InputField
                    id='remainingCreditSpaceWarningPercent'
                    numeric={true}
                    value={updateModel?.remainingCreditSpaceWarningPercent?.toString() ?? ''}
                    onChange={(event) => handleOnChange('remainingCreditSpaceWarningPercent', event.target.value)}
                    label='Remaining credit space warning percent'
                    placeholder=''
                />
            </div>
        </Modal>
    )
}

export default EditInvoicePayoutProviderModal

const useStyles = createUseStyles({
    container: {
        alignItems: 'center',
        textAlign: 'center',
        padding: '12px 20px',
        margin: '10px 0',
    },
    sectionTitle: {
        marginBottom: 15
    }
})
