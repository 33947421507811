import { useEffect } from "react"
import { createUseStyles } from "react-jss"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import IssueList from "../components/Protect/IssueList"
import { titleMarginBottom } from "../constants/layout"
import useProtectIssueActions from "../hooks/useProtectIssueActions"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { pageIndexChanged } from "../redux/slices/protectIssueSlice"
import useDidUpdateEffect from "../hooks/useDidUpdateEffect"

const ProtectIssues = () => {
    const styles = useStyles()
    const { getIssues } = useProtectIssueActions()
    const dispatch = useAppDispatch()

    const loading = useAppSelector(state => state.protectIssues.loading)
    const issuesOnPage = useAppSelector(state => state.protectIssues.issuesOnPage)
    const totalCount = useAppSelector(state => state.protectIssues.totalCount)
    const pageIndex = useAppSelector(state => state.protectIssues.pageIndex)
    const pageSize = useAppSelector(state => state.protectIssues.pageSize)
    const filter = useAppSelector(state => state.protectIssues.filter)

    useEffect(() => {
        if (issuesOnPage.length === 0 || filter?.includeHandled) {
          getIssues(filter?.includeHandled ?? false, pageIndex, pageSize)
        }
      }, [])

    useDidUpdateEffect(() => {
      getIssues(filter?.includeHandled ?? false, pageIndex, pageSize)
    }, [pageIndex])

    const onPageChange = (pageIndex: number) => {
      dispatch(pageIndexChanged(pageIndex))
    }

    return (
        <div className={styles.container}>
          <h1>Protect issues</h1>
          <IssueList 
            issuesOnPage={issuesOnPage}
            pageIndex={pageIndex}
            pageSize={pageSize} 
            totalCount={totalCount}
            onPageChange={onPageChange}
            displayEmployerColumn={true}
          />
          <FullScreenLoadingIndicator visible={loading} />
        </div>
    )
}

export default ProtectIssues

const useStyles = createUseStyles({
    container: {
      '& h1': {
        marginBottom: titleMarginBottom
      }
    },
    headerBar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between', 
    }
  })