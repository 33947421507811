import { ShiftModel } from '../redux/types';
import { getPaginationDataFromResponse } from '../utils/responseHelper'
import useFetchClient from "./useFetchClient";
import { useAppDispatch } from "../redux/hooks";
import { loading, shiftsFetched, shiftsFetchFailure } from "../redux/slices/shiftSlice";
import { createUrlWithQueryString } from "../utils/url";
import { restConfig } from "../authConfig";

const useShiftActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getShifts = (employeeId: string, paydayId: string | null, pageIndex: number, pageSize: number) => {
    dispatch(loading())   

    const params = {
      employeeId: employeeId,
      paydayId: paydayId,
      pageNumber: pageIndex,
      pageSize: pageSize
    }
    const url = createUrlWithQueryString(restConfig.routes.shifts, params)

    return fetchClient.get(url)
      .then(response => {
          const paginationData = getPaginationDataFromResponse(response.raw)
          dispatch(shiftsFetched({ items: response.data as ShiftModel[], totalCount: paginationData?.totalItemCount ?? 0}))
      })
      .catch(error => {
          console.log('getShifts error:', error)
          dispatch(shiftsFetchFailure(error))
      })
  }

  return {
    getShifts
  }
}

export default useShiftActions