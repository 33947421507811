import { useState, useEffect } from 'react'
import SearchAndSelectField, { SearchResultItem } from '../SearchAndSelectField'
import useInvoicePayoutProviderSelectorActions from '../../hooks/useInvoicePayoutProviderSelectorActions'

interface Props {
    onInvoicePayoutProviderSelected: (employer: SearchResultItem | null) => void
    value: string
 }

const InvoicePayoutProviderSelector = ({ onInvoicePayoutProviderSelected = () => '', value}: Props) => {
    const [resultItems, setResultItems] = useState<SearchResultItem[]>()
    const { search, providers } = useInvoicePayoutProviderSelectorActions()

    useEffect(() => {
        if (providers) {
            const items = providers.map((item) => {
                return {name: item.name ?? '', value: item.id}
            })
            setResultItems(items)
        } else{
            setResultItems([])   
        }              
    }, [providers])

    const handleSearchTermChange = (searchTerm: string) => {
        search(searchTerm, 10)
    }
    
    const handleProviderSelected = (selectedItem: SearchResultItem | null) => {
        onInvoicePayoutProviderSelected && onInvoicePayoutProviderSelected(selectedItem ?? null)
    }

    return (
        <>
            <SearchAndSelectField
                placeholder='Search invoice payout provider'
                id='invoicePayoutProviderSearch'
                onSearchTermChange={handleSearchTermChange}
                onSelect={handleProviderSelected}
                value={value}
                searchResult={resultItems ?? []}
            /> 
        </>
    )
}

export default InvoicePayoutProviderSelector