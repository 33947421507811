import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PagedListItems, ReconciliationListItemModel, ReconciliationModel } from '../types'

interface ReconciliationState {
    loading: boolean
    reconciliationDetails: ReconciliationModel | null
    reconciliationsOnPage: ReconciliationListItemModel[]
    reconciliationsWithIssuesCount: number
    pageIndex: number
    totalCount: number
    pageSize: number 
    error: Error | string | null
  }

const initialState: ReconciliationState = {
    loading: false,
    reconciliationDetails: null,
    reconciliationsOnPage: [],
    reconciliationsWithIssuesCount: 0,
    totalCount: 0,
    pageIndex: 1,
    pageSize: 20,
    error: null
}

export const reconciliationSlice = createSlice({
    name: 'reconciliation',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        reconciliationsFetched: (state, action: PayloadAction<PagedListItems<ReconciliationListItemModel>>) => {
            state.loading = false
            state.reconciliationsOnPage = action.payload.items
            state.totalCount = action.payload.totalCount
            state.error = null
        },
        reconciliationsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        reconciliationsWithIssuesCountFetched: (state, action: PayloadAction<number>) => {
            state.loading = false
            state.reconciliationsWithIssuesCount = action.payload
            state.error = null
        },
        reconciliationsWithIssuesCountFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        reconciliationDetailsFetched: (state, action: PayloadAction<ReconciliationModel>) => {
            state.loading = false
            state.reconciliationDetails = action.payload
            state.error = null
        },
        reconciliationDetailsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        reconciliationIssueHandled: (state, action: PayloadAction<ReconciliationModel>) => {
            state.loading = false
            state.reconciliationDetails = action.payload
            state.reconciliationsOnPage = []
            state.pageIndex = 1
            state.error = null
        },
        reconciliationIssueHandleFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        }
    }
})

export const { 
    loading, 
    reconciliationsFetched, 
    reconciliationsFetchFailure, 
    reconciliationDetailsFetched, 
    reconciliationDetailsFetchFailure, 
    reconciliationIssueHandled, 
    reconciliationIssueHandleFailure, 
    reconciliationsWithIssuesCountFetched,
    reconciliationsWithIssuesCountFetchFailure,
    pageIndexChanged 
} = reconciliationSlice.actions

export default reconciliationSlice.reducer