import {createUseStyles} from "react-jss"
import {Language, LearningModuleActionManagementModel} from "../../redux/types"
import React from "react";
import Card from "../Card";
import {multilingual as m} from "../../utils/multilingualStringHelper";
import Button from "../Button";
import {Colors} from "../../constants/colors";
import Badge from "react-bootstrap/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-solid-svg-icons";


interface Props {
  actionModule: LearningModuleActionManagementModel
  language: Language
  onClick?: () => void
}

const ActionModulePreview = ({actionModule, language, onClick}: Props) => {
  const styles = useStyles()

  const renderBadges = () => {
    return (
      <h5>
        <div className={styles.badges}>
          {actionModule.isPublished ?
            <Badge variant={"success"}>Published</Badge> :
            <Badge variant={"secondary"}>Unpublished</Badge>
          }
          <Badge variant={"info"}>Order: {actionModule.orderNumber}</Badge>
          <Badge variant={"info"}>
            <FontAwesomeIcon icon={faClock}/>
            <span style={{marginLeft: 4}}>{actionModule.estimatedCompletionTime}</span>
          </Badge>
        </div>
      </h5>
    )
  }

  const renderDeepLinkButton = () => {
    const deepLinkText = m(language, actionModule.deepLinkText)
    const deepLinkUrl = m(language, actionModule.deepLinkUrl)

    if (deepLinkText && deepLinkUrl) {
      return (
        <Button
          variant={'negative'}
          title={deepLinkText}
          onClick={() => window.open(deepLinkUrl, '_blank')}
        />
      )
    }
  }

  return (
    <div className={styles.container}>
      {renderBadges()}
      <Card className={`${styles.actionCard} card`} onClick={onClick}>
        <h6>{m(language, actionModule.title)}</h6>
        <h4>{m(language, actionModule.context)}</h4>
        <div className={styles.actionSubCard}>
          <h5>{m(language, actionModule.prompt)}</h5>
          <h6>{m(language, actionModule.description)}</h6>
        </div>
      </Card>
      {renderDeepLinkButton()}
    </div>
  )
}

export default ActionModulePreview

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
  },
  actionCard: {
    '& h4': {
      color: Colors.gray4,
      textAlign: 'center',
      marginBottom: 10
    },
    '& h5': {
      color: Colors.gray4,
      textAlign: 'center'
    },
    '& h6': {
      color: Colors.gray4,
      textAlign: 'center'
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: Colors.dollar_100,
    padding: 14,
    width: '100%',
    minHeight: 200,
    minWidth: 400,
  },
  actionSubCard: {
    padding: 14,
    backgroundColor: Colors.dollar_50,
    borderRadius: 8,
    width: '100%',
    flex: 1,
  },
  badges: {
    display: 'flex',
    gap: 8,
    flexDirection: 'row-reverse'
  }
})
