import Modal from '../../Modal'

interface Props {
    title: string
    reportContent: string
    onCloseClick?: () => void
    visible: boolean
}

const ReportModal = ({
    title,
    reportContent,
    onCloseClick,
    visible
}: Props) => {

    const handleOnClose = () => {
        onCloseClick && onCloseClick()
    }

    return (
        <Modal
            title={title}
            show={visible}
            okButtonTitle='Close'
            hideCancelButton = {true}
            onHide={() => handleOnClose()}
            onOkClick={() => handleOnClose()}
            onCancelClick={() => handleOnClose()}
            size={'xl'}>
          <pre id="json">{ reportContent }</pre>
        </Modal>
    )
}

export default ReportModal
