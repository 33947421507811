import {createUseStyles} from 'react-jss'
import {titleMarginBottom} from '../constants/layout'
import {useAppSelector} from "../redux/hooks";
import React, {useEffect, useState} from "react";
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator";
import {faArrowLeft, faUpload} from '@fortawesome/free-solid-svg-icons'
import UploadImageModal from "../components/Learning/UploadImageModal";
import ImageAssetCard from "../components/Learning/ImageAssetCard";
import {Image, Toast} from "react-bootstrap";
import {Colors} from "../constants/colors";
import Card from "../components/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useLearningAssets from "../hooks/useLearningAssets";
import ConfirmModal from "../components/ConfirmModal";
import {ImageModel} from "../redux/types";
import Modal from "../components/Modal";
import NavLink from "../components/NavLink";
import {useHistory} from "react-router-dom";

const LearningAssets = () => {
  const styles = useStyles()
  const history = useHistory();

  const [showCopyUrlToast, setShowCopyUrlToast] = useState(false)
  const [uploadImageModalVisible, setUploadImageModalVisible] = useState(false)
  const [deleteCandidate, setDeleteCandidate] = useState<ImageModel>()
  const [fullScreenImage, setFullScreenImage] = useState<ImageModel>()

  const {
    getImages,
    uploadImage,
    deleteImage,
  } = useLearningAssets()

  const images = useAppSelector(state => state.learning.images)
  const loading = useAppSelector(state => state.learning.loading)

  useEffect(() => {
    if (images.length === 0) {
      getImages()
    }
  }, []);

  const handleUploadImage = (file: File, overwrite: boolean) => {
    uploadImage(file, overwrite)
    setUploadImageModalVisible(false)
  }

  const handleDeleteImageConfirmed = () => {
    if (deleteCandidate) {
      deleteImage(deleteCandidate.name)
      setDeleteCandidate(undefined)
    }
  }

  return (
    <div>
      <div className={styles.titleBar}>
        <h1>Learning assets</h1>
      </div>

      <div className={styles.actionBar}>
        <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()}/>
      </div>

      <FullScreenLoadingIndicator visible={loading}/>

      <div className={styles.imageAssetList}>
        <Card className={styles.addImageCard} onClick={() => setUploadImageModalVisible(true)}>
          <FontAwesomeIcon size={'6x'} icon={faUpload}/>
        </Card>

        {
          images.map((image) => {
            return (
              <ImageAssetCard
                key={image.uri}
                image={image}
                onClick={setFullScreenImage}
                onCopyUri={() => {
                  setShowCopyUrlToast(true)
                }}
                onDelete={setDeleteCandidate}
              />
            )
          })
        }
      </div>

      <div className={styles.toastContainer}>
        <Toast style={{backgroundColor: Colors.grayCard}} onClose={() => setShowCopyUrlToast(false)}
               show={showCopyUrlToast} delay={3000} autohide animation>
          <Toast.Header style={{color: Colors.gray1, backgroundColor: Colors.grayCard}}>
            Copied URI to clipboard
          </Toast.Header>
        </Toast>
      </div>

      <UploadImageModal
        visible={uploadImageModalVisible}
        onUpload={handleUploadImage}
        onCancelClick={() => setUploadImageModalVisible(false)}
      />

      <ConfirmModal
        visible={!!deleteCandidate}
        confirmText={`Are you sure you want to remove image ${deleteCandidate?.name}?`}
        onConfirmClick={handleDeleteImageConfirmed}
        onCancelClick={() => setDeleteCandidate(undefined)}
      />

      <Modal
        title={fullScreenImage?.name}
        size={"xl"}
        show={!!fullScreenImage}
        onHide={() => setFullScreenImage(undefined)}
        onOkClick={() => setFullScreenImage(undefined)}
        onCancelClick={() => setFullScreenImage(undefined)}
        okButtonTitle={"Close"}
        hideCancelButton
      >
        <Image src={fullScreenImage?.uri} rounded fluid/>
        <footer>{fullScreenImage?.uri}</footer>
      </Modal>

    </div>
  )
}

export default LearningAssets

const useStyles = createUseStyles({
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: titleMarginBottom,
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  imageAssetList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 15,
  },
  toastContainer: {
    position: 'fixed',
    bottom: 30,
    right: 100,
  },
  addImageCard: {
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    flexBasis: '30%',
    minWidth: 250,
    maxWidth: 400,
    padding: 14,
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  fullScreenImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  }
})
