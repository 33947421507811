import homePageTranslation from './translations/homePageTranslation.json'
import paginationTranslation from './translations/paginationTranslation.json'

export default {
  en: {
    homePageTranslation: { ...homePageTranslation.en },
    paginationTranslation: { ...paginationTranslation.en }
  },
  sv: {
    homePageTranslation: { ...homePageTranslation.sv },
    paginationTranslation: { ...paginationTranslation.sv }
  }
}
