import { faArrowLeft, faEdit, faFile, faLink, faLock, faLockOpen, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { useHistory, useParams } from "react-router-dom"
import CardSection from "../components/CardSection"
import ConfirmModal from "../components/ConfirmModal"
import DetailsCard from "../components/DetailsCard"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import NavLink from "../components/NavLink"
import EditPaydayModal from "../components/PaySchedule/EditPaydayModal"
import EditPayScheduleModal from "../components/PaySchedule/EditPayScheduleModal"
import GeneratePaydaysForYearModal from "../components/PaySchedule/GeneratePaydaysForYearModal"
import { Colors } from "../constants/colors"
import { titleMarginBottom } from "../constants/layout"
import usePayScheduleActions from "../hooks/usePayScheduleActions"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { resetPayScheduleCreatedFlag } from "../redux/slices/payScheduleSlice"
import { PayScheduleEditModel, PaySchedulePaydayModel } from "../redux/types"

const PayScheduleDetails = () => {
    const styles = useStyles()
    const params = useParams<{employerId: string, id: string}>()
    const history = useHistory();
    const dispatch = useAppDispatch()

    const { 
        getPayScheduleDetails, 
        createPaySchedule, 
        updatePaySchedule, 
        generatePaydaysForYear, 
        updatePayday, 
        deletePayday,
        setPayoutAvailability,
        setEmptyPayroll
    } = usePayScheduleActions()

    const loading = useAppSelector(state => state.paySchedule.loading)
    const payScheduleCreatedFlag = useAppSelector(state => state.paySchedule.payScheduleCreatedFlag)
    const payScheduleDetails = useAppSelector(state => state.paySchedule.payScheduleDetails)

    const [addPayScheduleModalVisible, setAddPayScheduleModalVisible] = useState(false)
    const [editPayScheduleModalVisible, setEditPayScheduleModalVisible] = useState(false)
    const [generatePaydaysModalVisible, setGeneratePaydaysModalVisible] = useState(false)
    const [deletePaydayConfirmModalVisible, setDeletePaydayConfirmModalVisible] = useState(false)
    const [editPaydayModalVisible, setEditPaydayModalVisible] = useState(false)

    const [editedPayday, setEditedPayday] = useState<PaySchedulePaydayModel>()
    const [deletePaydayCandidate, setDeletePaydayCandidate] = useState<PaySchedulePaydayModel>()

    useEffect(() => {
        if (params.id == 'create') {
            setAddPayScheduleModalVisible(true)
        } else if (payScheduleDetails == null || payScheduleDetails.id !== params.id) {
          getPayScheduleDetails(params.employerId, params.id)
        } 
    }, [])

    useEffect(() => {
        if (payScheduleCreatedFlag) {
            history.push(`/employers/${params.employerId}/payschedules/${payScheduleDetails?.id}`)
            dispatch(resetPayScheduleCreatedFlag())
        } 
    }, [payScheduleCreatedFlag])

    const onGeneratePaydaysForYear = (year: number) => {
        generatePaydaysForYear(params.employerId, params.id, year)
        setGeneratePaydaysModalVisible(false)
    }

    const onAddPaySchedule = (model: PayScheduleEditModel) => {
        createPaySchedule(params.employerId, model)
        setAddPayScheduleModalVisible(false)
    }

    const onEditPaySchedule = (model: PayScheduleEditModel) => {
        updatePaySchedule(params.employerId, params.id, model)
        setEditPayScheduleModalVisible(false)
    }

    const onEditPaydayClick = (payday: PaySchedulePaydayModel) => {
        setEditedPayday(payday)
        setEditPaydayModalVisible(true)
    }

    const onEditPayday = (payday: string) => {
        if (editedPayday) {
            updatePayday(params.employerId, params.id, editedPayday?.id, payday)
            setEditedPayday(undefined)
        }
        
        setEditPaydayModalVisible(false)
    }

    const onDeletePaydayClick = (payday: PaySchedulePaydayModel) => {
        setDeletePaydayCandidate(payday)
        setDeletePaydayConfirmModalVisible(true)    
    }

    const onDeletePayday = () => {
        if (deletePaydayCandidate) {
            deletePayday(params.employerId, params.id, deletePaydayCandidate.id)
            setDeletePaydayCandidate(undefined)
        }

        setDeletePaydayConfirmModalVisible(false)     
    }


    const togglePayoutAvailability = (payday: PaySchedulePaydayModel) => {
        setPayoutAvailability(params.employerId, params.id, payday.id, payday.payoutsDisabled)    
    }

    const setEmptyPayrollForPayday = (payday: PaySchedulePaydayModel) => {
        setEmptyPayroll(params.employerId, params.id, payday.id)      
    }

    const renderPaydays = () => {
        if (payScheduleDetails?.paydays) {
            var paydaysGroupedByYear = payScheduleDetails.paydays.reduce(function (r, payday) {
                r[payday.paymentDate.substring(0, 4)] = r[payday.paymentDate.substring(0, 4)] || [];
                r[payday.paymentDate.substring(0, 4)].push(payday);
                return r;
            }, Object.create(null));

            return Object.entries(paydaysGroupedByYear).map(([key, value], index) => {
                return (
                    <DetailsCard key={`year_${key}`}>
                        <h6>Year {key}</h6>
                        {(value as PaySchedulePaydayModel[])
                            .sort((a, b) => {
                                return new Date(a.paymentDate).getTime() - new Date(b.paymentDate).getTime()
                            })
                            .map((payday, index) => {
                                return (
                                    <div className={styles.paydayContainer} key={`payday_${key}_${index}`}>
                                        {payday.paymentDate} 
                                        {payday.isShared &&
                                        <FontAwesomeIcon
                                            icon={faLink}
                                            color={Colors.darkDefault}
                                            title='Used by multiple pay schedules'
                                        />}
                                        {!payday.isReadOnly &&
                                        <>
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                color={Colors.euro_600}
                                                title='Edit'
                                                className={styles.button}
                                                onClick={() => onEditPaydayClick(payday)}
                                            />
                                            <FontAwesomeIcon
                                                icon={payday.payoutsDisabled ? faLock : faLockOpen}
                                                color={Colors.euro_600}
                                                title={payday.payoutsDisabled ? 'Payouts disabled' : 'Payouts enabled'}
                                                className={styles.button}
                                                onClick={() => togglePayoutAvailability(payday)}
                                            />
                                            {!payday.payrollExists &&
                                            <FontAwesomeIcon
                                                icon={faFile}
                                                color={Colors.euro_600}
                                                title={'Set empty payroll file'}
                                                className={styles.button}
                                                onClick={() => setEmptyPayrollForPayday(payday)}
                                            />
                                            }
                                            <FontAwesomeIcon
                                                icon={faTrashAlt}
                                                color={Colors.euro_600}
                                                title='Delete'
                                                className={styles.button}
                                                onClick={() => onDeletePaydayClick(payday)}
                                            /> 
                                        </>
                                        }
                                    </div>
                                )  
                            }
                        )}
                        
                    </DetailsCard>
                )
            })
        }

        return (<></>)
    }

    return (
        <div className={styles.container}>
            <h1>Pay schedule: {payScheduleDetails?.identifier}</h1>
            <div className={styles.actionBar}>
                <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()} />
            </div>
            <CardSection>
                <DetailsCard iconType="Edit" onClick={() => setEditPayScheduleModalVisible(true)}>
                    <h6>General information</h6>
                    <p>Identifier: <span>{payScheduleDetails?.identifier}</span></p>
                    <p>Periodicity: <span>{payScheduleDetails?.periodicity}</span></p>
                    <p>Start day of period: <span>{payScheduleDetails?.periodStartDayOfMonth}</span></p>
                    <p>Default payment day: <span>{payScheduleDetails?.defaultPaymentDayOfMonth}</span></p>
                    <p>Deviation period end offset: <span>{payScheduleDetails?.deviationPeriodEndOffset}</span></p>
                    <p>Salary accrual: <span>{payScheduleDetails?.salaryAccrualType}</span></p>
                    <p>Salary earning on non business days: <span>{payScheduleDetails?.salaryEarningOnWeekends ? 'yes' : 'no'}</span></p>
                    <p>Payday on weekend action: <span>{payScheduleDetails?.paydayOnWeekendHandling}</span></p>
                    <p>Is default pay schedule: <span>{payScheduleDetails?.isDefault ? 'yes' : 'no'}</span></p>
                </DetailsCard>
            </CardSection>
            <CardSection>
                <DetailsCard iconType='Add' onClick={() => setGeneratePaydaysModalVisible(true)}>
                    <h6>Paydays</h6>
                    <CardSection>
                        {renderPaydays()}
                    </CardSection>
                </DetailsCard>
            </CardSection>
            <GeneratePaydaysForYearModal 
                visible={generatePaydaysModalVisible}
                defaultValue={2022}
                onCancelClick={() => setGeneratePaydaysModalVisible(false)}
                onSaveClick={onGeneratePaydaysForYear}
            />
            <EditPaydayModal 
                visible={editPaydayModalVisible}
                intitalValue={editedPayday?.paymentDate ?? ''}
                onCancelClick={() => setEditPaydayModalVisible(false)}
                onSaveClick={onEditPayday}    
            />
            <EditPayScheduleModal 
                key='add'
                visible={addPayScheduleModalVisible}
                intitalValue={null}
                onCancelClick={() => history.goBack()}
                onSaveClick={onAddPaySchedule}
            />
            <EditPayScheduleModal 
                key='edit'
                visible={editPayScheduleModalVisible}
                intitalValue={payScheduleDetails}
                onCancelClick={() => setEditPayScheduleModalVisible(false)}
                onSaveClick={onEditPaySchedule}
            />
            <ConfirmModal      
                visible={deletePaydayConfirmModalVisible}
                confirmText={`Note! A payday must exist for all valid pay periods. Are you sure you want to delete this payday?`}
                onConfirmClick={onDeletePayday}
                onCancelClick={() => setDeletePaydayConfirmModalVisible(false)}
            />
            <FullScreenLoadingIndicator visible={loading} />
        </div>
    )
}

export default PayScheduleDetails

const useStyles = createUseStyles({
    container: {
        '& h1': {
            marginBottom: titleMarginBottom
          },
      '& h4': {
        marginTop: '15px'
      }
    },
    actionBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    paydayContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        columnGap: 15
    },
    button: {
        transition: '0.4s',
        '&:hover': {
          color: Colors.euro5,
          cursor: 'pointer',
        }
    },
})