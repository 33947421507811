import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { Colors } from "../../constants/colors"
import { ProtectIssue } from "../../redux/types"
import { formatDateTimeString } from "../../utils/dateHelper"
import Card from "../Card"
import Pagination from "../Pagination"

interface Props {
    issuesOnPage: ProtectIssue[],
    pageIndex: number
    pageSize: number
    totalCount: number
    onPageChange: (pageIndex: number) => void
    displayEmployerColumn: boolean
}

const IssueList = ({ issuesOnPage, pageIndex, pageSize, totalCount, onPageChange, displayEmployerColumn }: Props) => {
    const styles = useStyles()

    const renderHeader = () => {
        return (
          <Card className={`${styles.container} ${styles.header} card`}>
            {displayEmployerColumn && 
            <div className={styles.cell}>
              <h6>Customer</h6>
            </div>
            }
            <div className={styles.cell}>
              <h6>Created</h6>
            </div>
            <div className={styles.cell}>
              <h6>Description</h6>
            </div>
            <div className={`${styles.cell} ${styles.iconCell}`}>
              <h6>Closed</h6>
            </div>
          </Card>
        )
      }
    
      const renderListItems = () => {
        return issuesOnPage?.map((item, index) => {
          return (
            <Link to={`/protect/issues/${item.id}`} key={`issue_${index}`}>
              <Card className={`${styles.container} card`}>
                {displayEmployerColumn && 
                <div className={styles.cell}>
                  {item.customerName || "Unknown"}
                </div>
                }
                <div className={styles.cell}>
                  {formatDateTimeString(item.createdDate)}
                </div>
                <div className={styles.cell}>
                  {item.description}
                </div>
                <div className={`${styles.cell} ${styles.iconCell}`}>
                  {renderHandledIndicator(item)}
                </div>
              </Card>
            </Link>
          )
        })
      }

      const renderHandledIndicator = (issue: ProtectIssue) => {
        return (
          <FontAwesomeIcon
            icon={faCircle}
            color={issue.closed ? Colors.euro_600 : Colors.red}
            title={issue.closed ? 'Closed' : 'Open'}
          />
        ) 
      }

    return (
        <div>
          {renderHeader()}
          {renderListItems()}
          <div className={styles.pagination}>
            <Pagination
              pageIndex={pageIndex}
              itemCount={totalCount}
              pageSize={pageSize}
              siblingCount={1}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      )
}

export default IssueList

const useStyles = createUseStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: 'left',
      padding: '3px 20px',
      margin: '10px 0',
      transition: '0.4s',
      '&:hover': {
        backgroundColor: Colors.darkGrayHover,
      }
    },
    header: {
      margin: '30px 0 20px 0',
    },
    cell: {
      flex: 1
    },
    iconCell: {
      textAlign: 'center',
      flex: '0 0 80px'
    },
    pagination: {
      marginTop: 10,
      display: 'flex',
      justifyContent: 'center'
    }
  })