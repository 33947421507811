import { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { PayRunFilterParams } from '../../redux/types'
import PayRunListFilter from './PayRunListFilter'
import PayRunList from './PayRunList'
import usePayRunActions from '../../hooks/usePayRunActions'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect'
import { filterChanged, pageIndexChanged } from '../../redux/slices/payRunSlice'
import FullScreenLoadingIndicator from '../FullScreenLoadingIndicator'

interface Props {
  paydayId: string
}

const PayRunListComponent = ({ paydayId }: Props) => {
  const styles = useStyles()
  const dispatch = useAppDispatch()

  const { getPayRuns } = usePayRunActions()

  const loading = useAppSelector(state => state.payRun.loading)
  const payRunsOnPage = useAppSelector(state => state.payRun.payRunsOnPage)
  const totalCount = useAppSelector(state => state.payRun.totalCount)
  const pageIndex = useAppSelector(state => state.payRun.pageIndex)
  const pageSize = useAppSelector(state => state.payRun.pageSize)
  const filter = useAppSelector(state => state.payRun.filter)

  useEffect(() => {
    if (paydayId && paydayId !== filter?.paydayId)
      dispatch(filterChanged({ paydayId: paydayId, includeCompletedPayRuns: false, userId: null, userName: null}))
  }, [paydayId])

  useDidUpdateEffect(() => {
    if (filter?.paydayId)
      getPayRuns(filter.paydayId, filter.userId, filter.includeCompletedPayRuns ?? false, pageIndex, pageSize)
  }, [pageIndex, filter])

  const onPageChange = (pageIndex: number) => {
    dispatch(pageIndexChanged(pageIndex))
  }

  const onFilterChange = (value: PayRunFilterParams) => {
    dispatch(filterChanged(value))
  }

  return (
    <div className={styles.container}>
      <PayRunListFilter 
        filterValue={filter || {paydayId: paydayId, includeCompletedPayRuns: false, userId: null, userName: null}}
        onFilterChange={onFilterChange}
      /> 
      <PayRunList
        paydayId={paydayId}
        payRunsOnPage={payRunsOnPage}
        pageIndex={pageIndex}
        pageSize={pageSize} 
        totalCount={totalCount}
        onPageChange={onPageChange} 
      />
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default PayRunListComponent

const useStyles = createUseStyles({
  container: {},
})