import { restConfig } from "../authConfig";
import { useAppDispatch } from "../redux/hooks";
import { loading, payRunDetailsFetched, payRunDetailsFetchFailure, payRunsFetched, payRunsFetchFailure } from "../redux/slices/payRunSlice";
import { PayRunListItemModel, PayRunModel } from '../redux/types';
import { getPaginationDataFromResponse } from "../utils/responseHelper";
import { createUrlWithQueryString } from "../utils/url";
import useFetchClient from "./useFetchClient";

const usePayRunActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getPayRuns = (paydayId: string, userId: string | null, includeCompleted: boolean, pageIndex: number, pageSize: number) => {
    dispatch(loading())   

    const params = {
      paydayId: paydayId,
      userId: userId,
      includeCompleted: includeCompleted,
      pageNumber: pageIndex,
      pageSize: pageSize
    }
    const url = createUrlWithQueryString(restConfig.routes.paydays + `/${paydayId}/payruns`, params)

    return fetchClient.get(url)
      .then(response => {
          const paginationData = getPaginationDataFromResponse(response.raw)
          dispatch(payRunsFetched({ items: response.data as PayRunListItemModel[], totalCount: paginationData?.totalItemCount ?? 0}))
      })
      .catch(error => {
          console.log('getPayRuns error:', error)
          dispatch(payRunsFetchFailure(error))
      })
  }

  const getPayRunDetails = async (paydayId: string, payRunId: string) => {
    dispatch(loading())   

    const url = restConfig.routes.paydays + `/${paydayId}/payruns/${payRunId}`

    return fetchClient.get(url)
      .then(response => {
          dispatch(payRunDetailsFetched(response.data as PayRunModel))
      })
      .catch(error => {
          console.log('getPayRunDetails error:', error)
          dispatch(payRunDetailsFetchFailure(error))
      })
  }

  return {
    getPayRuns,
    getPayRunDetails
  }
}

export default usePayRunActions