import { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { titleMarginBottom } from '../constants/layout'
import { useAppSelector } from '../redux/hooks'
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator'
import useCompanyAmlActions from '../hooks/useCompanyAmlActions'
import AmlFlaggedCustomerList from '../components/Aml/AmlFlaggedCustomerList'

const AmlFlaggedCompanies = () => {
  const styles = useStyles()

  const { getAmlFlaggedCompanies } = useCompanyAmlActions()

  const loading = useAppSelector(state => state.amlFlaggedCompany.loading)
  const customers = useAppSelector(state => state.amlFlaggedCompany.companies)

  useEffect(() => {
    if (customers.length == 0) {
      getAmlFlaggedCompanies()
    }
  }, [])

  return (
    <div className={styles.container}>
      <h1>Sanction flagged companies</h1>
      <AmlFlaggedCustomerList 
        customers={customers}
      />
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default AmlFlaggedCompanies

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    }
  },
})