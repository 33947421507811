import { FC, ReactNode } from 'react'
import { Container } from 'react-bootstrap'
import NavMenu from '../navigation//NavMenu'

interface LayoutProps {
	children: ReactNode
}

const Layout: FC<LayoutProps> = ({ children }) => {
	return (
		<div>
			<NavMenu />
			<Container>
				{children}
			</Container>
		</div>
	)
}

export default Layout
