import {EmployerPayRunDashboardModel} from "../../redux/types";
import Card from "../Card";
import {formatDateString} from "../../utils/dateHelper";
import {createUseStyles} from "react-jss";
import {Colors} from "../../constants/colors";
import EmployerPayRunModal from "./EmployerPayRunModal";
import {useState} from "react";
import {EmployerPayRunPage} from "../../redux/slices/payRunDashboardSlice";
import {Alert} from "react-bootstrap";

interface Props {
  employerPayRunPage: EmployerPayRunPage
}

const EmployerPayRunList = ({employerPayRunPage}: Props) => {
  const [selectedEmployerPayRun, setSelectedEmployerPayRun] = useState<EmployerPayRunDashboardModel>()
  const styles = useStyles()

  const renderListItems = () => {
    return employerPayRunPage.employerPayRuns.map((item, index) => {
      return (
        <Card
          className={`${styles.card} card`}
          key={`payDeviation_${index}`}
          onClick={() => setSelectedEmployerPayRun(item)}>
          <div>
            {item.customerName}
          </div>
          <div>
            {formatDateString(item.paymentDate)}
          </div>
        </Card>
      )
    })
  }

  return (
    <>
      <div className={styles.container}>
        {
          employerPayRunPage.error &&
            <Alert variant="danger">
              {employerPayRunPage.error instanceof Error ? employerPayRunPage.error.message : employerPayRunPage.error}
            </Alert>
        }
        {renderListItems()}
      </div>
      <EmployerPayRunModal
        employerPayRun={selectedEmployerPayRun}
        visible={selectedEmployerPayRun !== undefined}
        onCloseClick={() => setSelectedEmployerPayRun(undefined)}/>
    </>
  )
}

export default EmployerPayRunList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  }
})
