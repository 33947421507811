import { createUseStyles } from 'react-jss'
import { UserListItemModel } from '../../redux/types'
import Pagination from '../Pagination'
import { Colors } from '../../constants/colors'
import Card from '../Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

interface Props {
  usersOnPage: UserListItemModel[]
  pageIndex: number
  pageSize: number
  totalCount: number
  onPageChange: (pageIndex: number) => void
}

const UserList = ({ usersOnPage, pageIndex, pageSize, totalCount, onPageChange }: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        <div className={styles.cell}>
          <h6>Name</h6>
        </div>
        <div className={styles.cell}>
          <h6>Identification number</h6>
        </div>
        <div className={`${styles.cell} ${styles.statusContainer}`}>
          <h6>State</h6>
        </div>
      </Card>
    )    
  }

  const renderListItems = () => {
    return usersOnPage?.map((item, index) => {
      return (
        <Link to={{ pathname: `/users/${item.id}` }} key={`item${index}`}>
          <Card className={`${styles.container} card`}>
            <div className={styles.cell}>
              {`${item.givenName} ${item.familyName}`}
            </div>
            <div className={styles.cell}>
              {item.identificationNumber}
            </div>
            <div className={`${styles.cell} ${styles.statusContainer} ${styles.status}`}>
              <span>{item?.state}</span>
            </div>
          </Card>
        </Link>
      )
    })
  }

  return (
    <div>
      {renderHeader()}
      {renderListItems()}
      <div className={styles.pagination}>
        <Pagination
          pageIndex={pageIndex}
          itemCount={totalCount}
          pageSize={pageSize}
          siblingCount={1}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  )
}

export default UserList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    margin: '10px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    margin: '30px 0 20px 0',
  },
  cell: {
    flex: 1
  },
  statusContainer: {
    flex: '0 0 140px',
  },
  status: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: 5,
    padding: '2px 5px',
    backgroundColor: Colors.darkGray3,
    '& span': {
      fontFamily: 'Aestetico-Regular',
      color: Colors.darkGray4,
      fontSize: 14,
    }
  },
  pagination: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center'
  }
})
