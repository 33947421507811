import {ReactNode} from 'react'
import {createUseStyles} from 'react-jss'
import {Colors} from '../../constants/colors'
import Card from '../Card'
import Divider from "../Divider";

interface Props {
  title: string
  subTitle?: string
  children: ReactNode
}

const DashboardMetricStack = ({title, subTitle, children}: Props) => {
  const styles = useStyles()

  return (
    <Card className={styles.container}>
      <div className={styles.title}>{title}</div>
      {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
      <Divider height={20}/>
      <div className={styles.body}>
        {children}
      </div>
    </Card>
  )
}

export default DashboardMetricStack

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 25,
    backgroundColor: Colors.darkGrayCard,
    borderColor: Colors.darkGrayBorder,
    borderWidth: 2,
    borderRadius: 10,
    width: '100%'
  },
  title: {
    color: Colors.euro_600,
    fontSize: 15,
    textTransform: 'uppercase'
  },
  subTitle: {
    color: Colors.gray3
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: 25,
    columnGap: 25,
    width: '100%'
  }
})
