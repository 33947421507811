import { AddRepresentativeModel } from '../redux/companyMonitorTypes';

export const validateCompanyRepresentative = (representative: AddRepresentativeModel): Error[] | null => {
  const errors: Error[] = []
  if (!representative.dateOfBirth) errors.push(new Error('No date of birth'))
  if (!representative.givenName) errors.push(new Error('No given name'))
  if (!representative.familyName) errors.push(new Error('No family name'))
  if (representative.positions?.length < 1) errors.push(new Error('No positions'))
  return errors.length > 0 ? errors : null
}

export const validateTimeSpan = (value: string) => {
  var regex = new RegExp('^([0-9]+.)?([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$')
  return regex.test(value)
}
