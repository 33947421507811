import {createUseStyles} from 'react-jss'
import {
  BookkeepingAccountTypeConfigurationModel,
  BookkeepingAccountTypeRuleModel,
  BookkeepingConfigurationModel
} from "../../redux/types";
import {Colors} from "../../constants/colors";
import Badge from "react-bootstrap/Badge";

interface Props {
  bookkeepingConfiguration: BookkeepingConfigurationModel
}

const EmployerBookkeepingConfiguration = ({bookkeepingConfiguration}: Props) => {
  const styles = useStyles()

  const renderAccountTypeRule = (model: BookkeepingAccountTypeRuleModel, index: number) => {
    const backgroundColor = index % 2 === 0 ? Colors.grayBackground : Colors.grayCard

    return (
      <div className={styles.rule} key={index} style={{backgroundColor}}>
        <div style={{paddingLeft: 4, minWidth: 30}}>
          <p><span>{model.orderNumber}</span></p>
        </div>
        <div className={styles.rule}>
          <div className={styles.ruleSection}>
            <h6>Filter</h6>
            <div>
              <p>Employee type: <span className={styles.enumText}>{model.filterEmployeeType ?? '[Any]'}</span></p>
              <p>Department: <span>{model.filterDepartment ?? '[Any]'}</span></p>
              <p>Category: <span>{model.filterCategory ?? '[Any]'}</span></p>
            </div>
          </div>
          <div className={styles.ruleSection}>
            <h6>Values</h6>
            <div>
              <p>Account: <span>{model.account}</span></p>
              <p>Project: <span>{model.project}</span></p>
              <p>Cost center: <span>{model.costCenter}</span></p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderAccountTypeConfiguration = (model: BookkeepingAccountTypeConfigurationModel, index: number) => {
    const sortedRules = [...model.rules]
      .sort((a, b) => a.orderNumber - b.orderNumber);

    return (
      <div className={styles.accountTypeConfiguration} key={index}>
        <div className={styles.accountTypeConfigurationHeader}>
          <p>Account type: <span className={styles.enumText}>{model.accountType}</span></p>
          <div className={styles.badgeContainer}>
            <p>Included dimensions:</p>
            {model.includeCostCenterDimension && <Badge variant={"secondary"}>Cost center</Badge>}
            {model.includeEmploymentDimension && <Badge variant={"secondary"}>Employment</Badge>}
            {model.includeProjectDimension && <Badge variant={"secondary"}>Project</Badge>}
          </div>
        </div>
        <div className={styles.ruleList}>
          {sortedRules.map(renderAccountTypeRule)}
        </div>
      </div>
    )
  }

  return (
    <div>
      <p>
        Verification description: <span>{bookkeepingConfiguration.verificationDescription}</span>
      </p>
      <p>
        Verifications per individual: <span>{bookkeepingConfiguration.verificationsPerIndividual ? 'Yes' : 'No'}</span>
      </p>
      <div className={styles.list}>
        {bookkeepingConfiguration.accountTypeConfigurations.map(renderAccountTypeConfiguration)}
      </div>
    </div>
  )
}

export default EmployerBookkeepingConfiguration

const useStyles = createUseStyles({
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10
  },
  ruleList: {
    display: 'flex',
    flexDirection: 'column'
  },
  accountTypeConfiguration: {
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 8,
    borderColor: Colors.grayDisabled,
    overflow: "hidden"
  },
  accountTypeConfigurationHeader: {
    backgroundColor: Colors.grayCard,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10
  },
  rule: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: "wrap",
    alignItems: 'center',
    flex: 1,
    padding: 4,
    gap: 4
  },
  ruleSection: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    minWidth: 250,
    gap: 10,
    paddingLeft: 4,
    paddingRight: 4,
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 8,
    borderColor: Colors.grayDisabled,
  },
  badgeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  enumText: {
    textTransform: 'capitalize'
  }
})
