import { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../constants/colors'
import Modal from '../Modal'
import Card from '../Card'
import { CreditorModel, CreditorConnectionModel } from '../../redux/types'
import { useAppSelector } from '../../redux/hooks'
import useCreditorActions from '../../hooks/useCreditorActions'
import InputField from '../InputField'

interface Props {
    marketId: string
    existingCreditorConnectionModels: CreditorConnectionModel[]
    onAddClick?: (creditor: CreditorConnectionModel) => void
    onCancelClick?: () => void
    visible: boolean
}

const AddCreditorConnectionModal = ({ marketId, existingCreditorConnectionModels: existingEmployerCreditorModels, onAddClick, onCancelClick, visible }: Props) => {
    const styles = useStyles()
    const [selectedCreditor, setSelectedCreditor] = useState<CreditorModel | null>(null)
    const [priority, setPriority] = useState<number>(1)
    
    const { getCreditors } = useCreditorActions()

    const loading = useAppSelector(state => state.creditors.loading)
    const creditors = useAppSelector(state => state.creditors.creditors)

    useEffect(() => {
        if (visible && creditors.length === 0) {
            getCreditors(marketId)
            setPriority(1)
        }
    }, [visible])

    const handleOnAdd = () => {
        if (selectedCreditor) {
            onAddClick && onAddClick({
                id: selectedCreditor.id,
                name: selectedCreditor.name,
                priority: priority
            })
        }
    }

    const handleOnCancel = () => {
        onCancelClick && onCancelClick()
    }

    const handleOnHide = () => {
        onCancelClick && onCancelClick()
    }

    const renderCreditors = () => {
        const availableCreditors = creditors.filter(creditor => existingEmployerCreditorModels.find(x => x.id == creditor.id) == undefined)

        return availableCreditors?.map((creditor) => {
            return (
                <div className={styles.container} key={`creditor_${creditor.id}`}>
                    <Card className={`${styles.card} card ${creditor.id == selectedCreditor?.id ? styles.selected : ''}`} 
                        onClick={() => setSelectedCreditor(creditor)}>
                        <div className={styles.cell}>
                            {creditor.name}
                        </div>
                    </Card>
                </div>
            )
        })
    }

    return (
        <Modal
            title='Add creditor'
            show={visible}
            okButtonTitle='Add'
            cancelButtonTitle='Cancel'
            onHide={() => handleOnHide()}
            onOkClick={() => handleOnAdd()}
            onCancelClick={() => handleOnCancel()}
        >
            { renderCreditors() }
            <InputField
                numeric={true}
                id='priority'
                value={priority.toString()}
                label='Priority'
                onChange={(event) => setPriority(parseFloat(event.target.value))}
                placeholder=''
            />
        </Modal>
    )
}

export default AddCreditorConnectionModal

const useStyles = createUseStyles({
    container: {
        marginBottom: 30
    },
    card: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'left',
        padding: '3px 10px',
        transition: '0.4s',
        '&:hover': {
        backgroundColor: Colors.darkGray3,
        }
    },
    cell: {
        flex: 1,
        padding: '0px 10px'
    },
    selected: {
        backgroundColor: Colors.darkGray5
    },
    connectedEmployerContainer: {
        marginLeft: 60
    }
})
  