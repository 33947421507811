import { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { filterChanged, pageIndexChanged } from '../../redux/slices/invoicePayoutSlice'
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect'
import InvoicePayoutList from './InvoicePayoutList'
import InvoicePayoutFilter from './InvoicePayoutFilter'
import useInvoicePayoutActions from '../../hooks/useInvoicePayoutActions'

export interface Props {
    invoicePayoutProviderId?: string
  }

const InvoicePayoutListComponent = ({ invoicePayoutProviderId }: Props) => {
  const styles = useStyles()
  const dispatch = useAppDispatch()
  const { getInvoicePayouts } = useInvoicePayoutActions()

  const payoutsOnPage = useAppSelector(state => state.invoicePayout.payoutsOnPage)
  const totalCount = useAppSelector(state => state.invoicePayout.totalCount)
  const searchTerm = useAppSelector(state => state.invoicePayout.searchTerm)
  const searchInvoicePayoutProviderId = useAppSelector(state => state.invoicePayout.invoicePayoutProviderId)
  const pageIndex = useAppSelector(state => state.invoicePayout.pageIndex)
  const pageSize = useAppSelector(state => state.invoicePayout.pageSize)

  useEffect(() => {
    if (invoicePayoutProviderId && invoicePayoutProviderId !== searchInvoicePayoutProviderId)
      dispatch(filterChanged({ searchTerm: '', invoicePayoutProviderId: invoicePayoutProviderId as string}))
  }, [invoicePayoutProviderId])

  useDidUpdateEffect(() => {
    if (searchInvoicePayoutProviderId) {
      getInvoicePayouts(searchInvoicePayoutProviderId, searchTerm ?? '', pageIndex, pageSize)
    }
  }, [searchInvoicePayoutProviderId, pageIndex, searchTerm])

  const onPageChange = (pageIndex: number) => {
    dispatch(pageIndexChanged(pageIndex))
  }

  const onFilterChange = (value: string) => {
    dispatch(filterChanged({ searchTerm: value.toLowerCase(), invoicePayoutProviderId: invoicePayoutProviderId as string}))
  }

  return (
      <div className={styles.container}>
        <InvoicePayoutFilter 
          filterValue={searchTerm || ''}
          onFilterChange={onFilterChange}
        /> 
        <InvoicePayoutList 
          invoicePayoutProviderId={invoicePayoutProviderId ?? ''}
          payoutsOnPage={payoutsOnPage}
          pageIndex={pageIndex}
          pageSize={pageSize} 
          totalCount={totalCount}
          onPageChange={onPageChange}
        />
      </div>
    )
}

export default InvoicePayoutListComponent

const useStyles = createUseStyles({
  container: {
      marginTop: 40,
  }
})