import { useState } from "react"
import { TokenModel } from "../../redux/types"
import Modal from "../Modal"
import Select from "../Select"

interface Props {
    tokens: TokenModel[]
    onSigningTokenSelected: (signingTokenKey: string) => void
    onCancel: () => void
    visible: boolean
}

const SelectSigningKeyModal = ({ tokens, onSigningTokenSelected, onCancel, visible}: Props) => {

    const [selectedSigningTokenKey, setSelectedSigningTokenKey] = useState<string>('NordeaSigningToken')
 
    const getOptions = () => {
        return tokens
            .filter(x => x.key.startsWith('NordeaSigning') || x.key.startsWith('NordeaAdditionalSigning'))
            .map((token) => { return { name: token.key, value: token.key }
        })
    }

    return (
        <Modal
            title='Select signing token'
            show={visible}
            okButtonTitle={'Ok'}
            cancelButtonTitle={'Cancel'}
            onHide={() => onCancel()}
            onOkClick={() => onSigningTokenSelected(selectedSigningTokenKey)}
            onCancelClick={() => onCancel()}
        >
            <Select 
                id='signingToken'
                key='signingToken'
                label='Select signing key'
                options={getOptions()} 
                selectedValue='NordeaSigningToken'
                onSelect={(value) => setSelectedSigningTokenKey(value)}
            />
        </Modal>
    )
}

export default SelectSigningKeyModal