import {createUseStyles} from "react-jss"
import {Colors} from "../../constants/colors"
import {Language, LearningModuleQuizManagementModel, LearningModuleQuizQuestionModel} from "../../redux/types"
import Card from "../Card"
import {multilingual as m} from "../../utils/multilingualStringHelper";
import React from "react";
import Badge from "react-bootstrap/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-solid-svg-icons";

interface Props {
  quizModule: LearningModuleQuizManagementModel
  language: Language
  onClickQuestion?: (question: LearningModuleQuizQuestionModel) => void
}

const QuizModulePreview = ({quizModule, language, onClickQuestion}: Props) => {
  const styles = useStyles()
  const questionCount = quizModule.questions.length

  const renderBadges = () => {
    return (
      <h5>
        <div className={styles.badges}>
          {quizModule.isPublished ?
            <Badge variant={"success"}>Published</Badge> :
            <Badge variant={"secondary"}>Unpublished</Badge>
          }
          <Badge variant={"info"}>Order: {quizModule.orderNumber}</Badge>
          <Badge variant={"info"}>
            <FontAwesomeIcon icon={faClock}/>
            <span style={{marginLeft: 4}}>{quizModule.estimatedCompletionTime}</span>
          </Badge>
        </div>
      </h5>
    )
  }

  const renderQuestion = (question: LearningModuleQuizQuestionModel, index: number) => {
    const questionNumber = quizModule.questions.findIndex(q => q.id === question.id) + 1 || 0
    return (
      <Card key={`question_preview_${index}`} className={`${styles.questionCard} card`}
            onClick={() => onClickQuestion && onClickQuestion(question)}>
        <h6>Question {questionNumber} of {questionCount}</h6>
        <h6>{m(language, quizModule.title)}</h6>
        <h4>{m(language, question.question)}</h4>
        <p>{m(language, question.explanation)}</p>
        {
          [...question.alternatives]
            .filter(alternative => alternative.published)
            .sort((a, b) => b.orderNumber - a.orderNumber)
            .map((alternative, answerIx) => {
              let style = alternative.isCorrect ? styles.alternativeCardCorrect : styles.alternativeCardIncorrect

              return (
                <div className={style} key={`answer_${answerIx}`}>
                  <h5>{m(language, alternative.answer)}</h5>
                  <h6>{m(language, alternative.description)}</h6>
                  <div style={{display: "flex", gap: 10, flex: 1, justifyContent: "flex-end"}}>
                    <Badge variant={"secondary"}>Order: {alternative.orderNumber}</Badge>
                  </div>
                </div>
              )
            })
        }
        <div style={{display: "flex", gap: 10, flex: 1, justifyContent: "flex-end"}}>
          <Badge variant={"primary"}>Type: {question.type}</Badge>
          <Badge variant={"secondary"}>Order: {question.orderNumber}</Badge>
        </div>
      </Card>
    )
  }

  const renderUnpublishedQuestion = (question: LearningModuleQuizQuestionModel, index: number) => {
    return (
      <Card key={`unpublished_question_preview_${index}`} className={`card`}
            onClick={() => onClickQuestion && onClickQuestion(question)}>
        <h6>Unpublished question</h6>
        <h4>{m(language, question.question)}</h4>
      </Card>
    )
  }

  const publishedQuestions = [...quizModule.questions]
    .filter(q => q.published)
    .sort((a, b) => {
      return b.orderNumber - a.orderNumber
    })

  const unpublishedQuestions = [...quizModule.questions]
    .filter(q => !q.published)
    .sort((a, b) => {
      return b.orderNumber - a.orderNumber
    })

  return (
    <div className={styles.container}>
      {renderBadges()}
      <div className={styles.questionList}>
        {publishedQuestions.map((question, index) => renderQuestion(question, index))}
      </div>
      <div className={styles.questionList}>
        {unpublishedQuestions.map((question, index) => renderUnpublishedQuestion(question, index))}
      </div>
    </div>
  )
}

export default QuizModulePreview

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
  },
  questionList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  questionCard: {
    '& h4': {
      color: Colors.gray4,
      textAlign: 'center',
      marginBottom: 10
    },
    '& h5': {
      color: Colors.gray4,
      textAlign: 'center'
    },
    '& h6': {
      color: Colors.gray4,
      textAlign: 'center'
    },
    '& p': {
      color: Colors.gray4,
      textAlign: 'center'
    },
    borderRadius: 8,
    display: 'flex',
    padding: 14,
    gap: 8,
    backgroundColor: Colors.dollar_100,
    width: '100%',
    minWidth: 400,
    minHeight: 200,
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.dollar_200,
    }
  },
  alternativeCardCorrect: {
    padding: 14,
    borderRadius: 8,
    backgroundColor: Colors.dollar_50,
  },
  alternativeCardIncorrect: {
    padding: 14,
    borderRadius: 8,
    backgroundColor: Colors.yuan_100,
  },
  badges: {
    display: 'flex',
    gap: 8,
    flexDirection: 'row-reverse'
  }
})
