import {createUseStyles} from "react-jss"
import {Colors} from "../../constants/colors"
import React, {ReactNode} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

interface Props {
  label?: string
  children: ReactNode
}

interface ToolbarItemProps {
  title: string
  icon?: IconDefinition
  display?: 'icon' | 'text' | 'iconAndText'
  disabled?: boolean
  onClick?: () => void
}

export const ToolbarItem = ({icon, title, display = 'icon', disabled, onClick}: ToolbarItemProps) => {
  const styles = useStyles()

  let showIcon = !!icon && (display === 'icon' || display === 'iconAndText')
  let showText = !showIcon || display === 'text' || display === 'iconAndText'

  const itemStyle = disabled ? styles.disabledItem : styles.enabledItem


  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    if (!disabled && onClick) {
      onClick()
    }
  }

  if (showText) {
    return (
      <div className={styles.item + ' ' + itemStyle} onClick={handleOnClick}>
        {showIcon && <FontAwesomeIcon size={'lg'} icon={icon!} title={title}/>}
        {showText && <span>{title}</span>}
      </div>
    )
  }
  else {
    return (
      <OverlayTrigger
        key={`trigger-${title}`}
        placement={'auto'}
        overlay={<Tooltip id={`tooltip-${title}`}>{title}</Tooltip>}
      >
        <div className={styles.item + ' ' + itemStyle} onClick={handleOnClick}>
          {showIcon && <FontAwesomeIcon size={'lg'} icon={icon!} title={title}/>}
        </div>
      </OverlayTrigger>
    )
  }
}

export const Toolbar = ({label, children}: Props) => {
  const styles = useStyles()

  return (
    <div className={styles.toolbar}>
      {label && <span>{label}</span>}
      {children}
    </div>
  )
}

const useStyles = createUseStyles({
  toolbar: {
    backgroundColor: 'rgba(44, 44, 46, 0.6)',
    display: 'flex',
    flexDirection: 'row',
    columnGap: 15,
    alignItems: 'center',
    padding: '5px 10px',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.grayBorder,
    '& span': {
      cursor: 'default'
    }
  },
  item: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    columnGap: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: '5px 10px',
    borderRadius: 40,
    minHeight: 35,
    minWidth: 40
  },
  disabledItem: {
    color: Colors.gray3,
    backgroundColor: Colors.grayCard,
    borderColor: Colors.grayBorder,
  },
  enabledItem: {
    color: Colors.gray1,
    backgroundColor: Colors.darkGrayCard,
    borderColor: Colors.gray3,
    transition: '0.4s',
    '&:hover': {
      boxShadow: '0 0 10px 0 #6ABF4B',
      backgroundColor: Colors.darkGrayHover,
    }
  }
})
