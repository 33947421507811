import {useEffect, useState} from 'react'
import Modal from '../Modal'
import {InAppMessageTranslation} from '../../redux/types'
import InputField from '../InputField'
import TextAreaField from '../TextAreaField'

interface Props {
  initialValue: InAppMessageTranslation | undefined
  onSaveClick?: (translation: InAppMessageTranslation) => void
  onCancelClick?: () => void
  visible: boolean
}

const UpdateInAppMessageTranslationModal = ({initialValue, onSaveClick, onCancelClick, visible}: Props) => {
  const [translation, setTranslation] = useState<InAppMessageTranslation>()

  useEffect(() => {
    if (initialValue) {
      setTranslation({
        language: initialValue.language,
        type: initialValue.type,
        title: initialValue.title,
        description: initialValue.description,
        buttonText: initialValue.buttonText
      })
    }
  }, [initialValue])

  const handleOnSave = () => {
    translation && onSaveClick && onSaveClick(translation)
  }

  const handleOnCancel = () => {
    onCancelClick && onCancelClick()
  }

  const handleOnChange = (propName: string, value: any) => {
    if (translation) {
      setTranslation({...translation, [propName]: value})
    }
  }

  return (
    <Modal
      title='Edit translation'
      show={visible}
      okButtonTitle='Save'
      cancelButtonTitle='Cancel'
      onHide={() => handleOnCancel()}
      onOkClick={() => handleOnSave()}
      onCancelClick={() => handleOnCancel()}
    >
      <h5>Language: {translation?.language ?? '-'}</h5>
      <InputField
        id='type'
        value={translation?.type ?? ''}
        onChange={(event) => handleOnChange('type', event.target.value)}
        label='Type'
        placeholder=''
      />
      <InputField
        id='title'
        value={translation?.title ?? ''}
        onChange={(event) => handleOnChange('title', event.target.value)}
        label='Title'
        placeholder=''
      />
      <TextAreaField
        id='description'
        value={translation?.description ?? ''}
        onChange={(event) => handleOnChange('description', event.target.value)}
        label='Description'
        placeholder=''
      />
      <InputField
        id='buttonText'
        value={translation?.buttonText ?? ''}
        onChange={(event) => handleOnChange('buttonText', event.target.value)}
        label='Button text'
        placeholder=''
      />
    </Modal>
  )
}

export default UpdateInAppMessageTranslationModal
