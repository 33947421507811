import {createUseStyles} from "react-jss"
import {Language, LearningCourseManagementModel} from "../../redux/types"
import {useEffect} from "react";
import useLearningActions from "../../hooks/useLearningActions";
import InputField from "../InputField";
import MultilingualInputField from "./MultilingualInputField";
import MultilingualTextAreaField from "./MultilingualTextAreaField";
import Select from "../Select";
import {useAppSelector} from "../../redux/hooks";
import useEmployerActions from "../../hooks/useEmployerActions";
import useLearningEditor from "../../hooks/useLearningEditor";
import useLearningAssets from "../../hooks/useLearningAssets";

interface Props {
  course: LearningCourseManagementModel
  onChange: (course: LearningCourseManagementModel) => void
}

const CourseEditor = ({course, onChange}: Props) => {
  const styles = useStyles()
  const {getLearningCategories} = useLearningActions()
  const {previewLanguage, setPreviewLanguage} = useLearningEditor()
  const {getImages} = useLearningAssets()
  const {getEmployerNames} = useEmployerActions()

  const categories = useAppSelector(state => state.learning.learningCategories)
  const images = useAppSelector(state => state.learning.images)
  const employerNames = useAppSelector(state => state.employer.employerNames)

  useEffect(() => {
    if (categories.length === 0) {
      getLearningCategories()
    }
  }, []);

  useEffect(() => {
    if (images.length === 0) {
      getImages()
    }
  }, []);

  useEffect(() => {
    if (employerNames.length === 0) {
      getEmployerNames()
    }
  }, [])

  const handleOnChange = (propName: string, value: any) => {
    onChange({...course, [propName]: value})
  }

  const handleSetEmployer = (value: string) => {
    onChange({...course, employerId: value === '' ? null : value})
  }

  const getCategoryOptions = () => {
    return categories.map(category => {
      return {
        name: category.title[previewLanguage],
        value: category.id
      }
    })
  }

  const getImagesOptions = () => {
    return images.map(image => {
      return {
        name: image.name,
        value: image.uri
      }
    })
  }

  const getEmployerOptions = () => {
    return [{name: 'All', value: ''}, ...employerNames.map(employer => {
      return {
        name: employer.name,
        value: employer.id
      }
    })]
  }

  return (
    <div className={styles.container}>
      <Select
        id={'learningCategoryId'}
        label='Category'
        options={getCategoryOptions()}
        selectedValue={course.learningCategoryId}
        onSelect={(value) => handleOnChange('learningCategoryId', value)}
      />
      <Select
        id={'employerId'}
        label='Limit to employer'
        options={getEmployerOptions()}
        selectedValue={course.employerId ?? ''}
        onSelect={(value) => handleSetEmployer(value)}
      />
      <MultilingualInputField
        id={'title'}
        label={'Title'}
        translations={course.title}
        placeholders={{[Language.en]: 'Title', [Language.sv]: 'Titel'}}
        onChange={(value) => handleOnChange('title', value)}
        onLanguageChange={setPreviewLanguage}
      />
      <MultilingualTextAreaField
        id={'description'}
        label={'Description'}
        translations={course.description}
        onChange={(value) => handleOnChange('description', value)}
        onLanguageChange={setPreviewLanguage}
      />
      <Select
        id={'imageUrl'}
        label='Image'
        options={getImagesOptions()}
        selectedValue={course.imageUrl}
        onSelect={(value) => handleOnChange('imageUrl', value)}
      />
      <InputField
        id='orderNumber'
        numeric
        value={course.orderNumber.toString()}
        onChange={(event) => handleOnChange('orderNumber', event.target.value)}
        label='Order number'
        placeholder='3'
      />
      <MultilingualInputField
        id={'deepLinkText'}
        label={'Deep link text'}
        translations={course.deepLinkText}
        onChange={(value) => handleOnChange('deepLinkText', value)}
        onLanguageChange={setPreviewLanguage}
      />
      <MultilingualInputField
        id={'deepLinkUrl'}
        label={'Deep link URL'}
        translations={course.deepLinkUrl}
        onChange={(value) => handleOnChange('deepLinkUrl', value)}
        onLanguageChange={setPreviewLanguage}
      />
    </div>
  )
}

export default CourseEditor

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 4
  }
})
