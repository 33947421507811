import Card from '../Card'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../constants/colors'
import { TransactionListItemModel } from '../../redux/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { formatDateString, formatDateTimeString } from '../../utils/dateHelper'
import { roundToMaxTwoDecimals } from '../../utils/numberHelper'
import { truncate } from '../../utils/stringHelper'
import Pagination from '../Pagination'
import Link from '../Link'
import { Link as RowLink } from 'react-router-dom'

interface Props {
  transactionsOnPage: TransactionListItemModel[]
  pageIndex: number
  pageSize: number
  totalCount: number
  onPageChange: (pageIndex: number) => void
}

const TransactionList = ({ transactionsOnPage, pageIndex, pageSize, totalCount, onPageChange }: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        <div className={styles.cell}>
          <h6>Date</h6>
        </div>
        <div className={`${styles.cell} ${styles.amount}`}>
          <h6>Amount</h6>
        </div>
        <div className={styles.cell}>
          <h6>Type</h6>
        </div>
        <div className={styles.cell}>
          <h6>User</h6>
        </div>
        <div className={styles.cell}>
          <h6>Payday</h6>
        </div>
        <div className={styles.cell}>
          <h6>Customer</h6>
        </div>
        <div className={`${styles.cell} ${styles.stateIndicator}`}>
          <h6>State</h6>
        </div>
      </Card>
    )
  }
  
  const renderListItems = () => {
    return transactionsOnPage?.map((item, index) => {
        return (
          <RowLink to={{pathname:`/transactions/${item.id}`}} key={`transactionListItem${index}`}>
            <Card className={`${styles.container} card`}>
              <div className={styles.cell}>
                {formatDateTimeString(item.date)}
              </div>
              <div className={`${styles.cell} ${styles.amount}`}>
                {`${roundToMaxTwoDecimals(item.amount.amount).toLocaleString('sv-SE')} ${item.amount.currencyCode.toUpperCase()}`}
              </div>
              <div className={styles.cell} title={item.type.toString()}>
                {truncate(item.type.toString(), 16)}
              </div>
              <div className={styles.cell}>
                {item.userId && 
                  <Link to={`/users/${item.userId}`}>
                    {item.userGivenName ?? ''} {item.userFamilyName ?? ''}
                  </Link>
                }
              </div>
              <div className={styles.cell}>
                {item.paydayDate != null ? formatDateString(item.paydayDate) : ''}
              </div>
              <div className={styles.cell}>
                {item.employerId && 
                  <Link to={`/employers/${item.employerId}`}>
                    {item.customerName ?? ''}
                  </Link>
                }
                {item.invoicePayoutProviderId && 
                  <Link to={`/invoicepayoutproviders/${item.invoicePayoutProviderId}`}>
                    {item.customerName ?? ''}
                  </Link>
                }
              </div>
              <div className={`${styles.cell} ${styles.stateIndicator}`}>
                {renderStateIndicator(item)}
              </div>
            </Card>
          </RowLink>
        )
      })
    }

    const renderStateIndicator = (transactionListItem: TransactionListItemModel) => {
      let color = Colors.white
      
      switch(transactionListItem.state) {
        case 'pending': 
          color = Colors.lightDark
          break
        case 'cancelled': 
          color = Colors.red
          break
        case 'completed':
          color = Colors.euro_600
          break
      }
  
      return (
        <FontAwesomeIcon
          icon={faCircle}
          color={color}
          title={transactionListItem.state}
        />
      ) 
    }

  return (
    <div>
      {renderHeader()}
      {renderListItems()}
      <div className={styles.pagination}>
        <Pagination
            pageIndex={pageIndex}
            itemCount={totalCount}
            pageSize={pageSize}
            siblingCount={1}
            onPageChange={onPageChange}
        />
      </div>
  </div> 
  )
}

export default TransactionList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    margin: '10px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    margin: '30px 0 20px 0',
  },
  cell: {
    flex: 1,
    padding: '0px 10px'
  },
  stateIndicator: {
    textAlign: 'center',
    flex: '0 0 60px'
  },
  amount: {
    flex: '0 0 130px'
  },
  pagination: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center'
  }
})
