import { restConfig } from "../authConfig";
import { useAppDispatch } from "../redux/hooks";
import { emptyPayrollSetForPayday, emptyPayrollSetForPaydayFailure, loading, paydayDeleted, paydayDeleteFailure, paydayPayoutAvailabilitySet, paydayPayoutAvailabilitySetFailure, paydaysForYearGenerated, paydaysForYearGenerationFailure, paydayUpdated, paydayUpdateFailure, payScheduleCreated, payScheduleCreateFailure, payScheduleDetailsFetched, payScheduleDetailsFetchFailure, payScheduleUpdated, payScheduleUpdateFailure } from "../redux/slices/payScheduleSlice";
import { PayScheduleEditModel, PayScheduleModel, PaySchedulePaydayModel } from "../redux/types";
import useFetchClient from "./useFetchClient";

const usePayScheduleActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getPayScheduleDetails = (employerId: string, payScheduleId: string) => {
    dispatch(loading())   

    const url = restConfig.routes.employers + `/${employerId}/payschedules/${payScheduleId}`

    return fetchClient.get(url)
      .then(response => {
          dispatch(payScheduleDetailsFetched(response.data as PayScheduleModel))
      })
      .catch(error => {
          console.log('getPayScheduleDetails error:', error)
          dispatch(payScheduleDetailsFetchFailure(error))
      })
  }

  const createPaySchedule = (employerId: string, model: PayScheduleEditModel) => {
    dispatch(loading())   

    const url = restConfig.routes.employers + `/${employerId}/payschedules`

    return fetchClient.post(url, model)
      .then(response => {
          dispatch(payScheduleCreated(response.data as PayScheduleModel))
      })
      .catch(error => {
          console.log('createPaySchedule error:', error)
          dispatch(payScheduleCreateFailure(error))
      })
  }

  const updatePaySchedule = (employerId: string, payScheduleId: string, model: PayScheduleEditModel) => {
    dispatch(loading())   

    const url = restConfig.routes.employers + `/${employerId}/payschedules/${payScheduleId}`

    return fetchClient.post(url, model)
      .then(response => {
          dispatch(payScheduleUpdated(response.data as PayScheduleModel))
      })
      .catch(error => {
          console.log('updatePaySchedule error:', error)
          dispatch(payScheduleUpdateFailure(error))
      })
  }

  const generatePaydaysForYear = (employerId: string, payScheduleId: string, year: number) => {
    dispatch(loading())   

    const url = restConfig.routes.employers + `/${employerId}/payschedules/${payScheduleId}/paydays/${year}`

    return fetchClient.post(url)
      .then(response => {
          dispatch(paydaysForYearGenerated(response.data as PayScheduleModel))
      })
      .catch(error => {
          console.log('generatePaydaysForYear error:', error)
          dispatch(paydaysForYearGenerationFailure(error))
      })
  }

  const updatePayday = (employerId: string, payScheduleId: string, paydayId: string, date: string) => {
    dispatch(loading())   

    const url = restConfig.routes.employers + `/${employerId}/payschedules/${payScheduleId}/paydays/${paydayId}`

    return fetchClient.post(url, { paymentDate: date })
      .then(response => {
          dispatch(paydayUpdated(response.data as PaySchedulePaydayModel))
      })
      .catch(error => {
          console.log('updatePayday error:', error)
          dispatch(paydayUpdateFailure(error))
      })
  }

  const deletePayday = (employerId: string, payScheduleId: string, paydayId: string) => {
    dispatch(loading())   

    const url = restConfig.routes.employers + `/${employerId}/payschedules/${payScheduleId}/paydays/${paydayId}`

    return fetchClient.delete(url)
      .then(response => {
          dispatch(paydayDeleted(paydayId))
      })
      .catch(error => {
          console.log('deletePayday error:', error)
          dispatch(paydayDeleteFailure(error))
      })
  }

  const setPayoutAvailability = (employerId: string, payScheduleId: string, paydayId: string, payoutsAvailable: boolean) => {
    dispatch(loading())   

    const url = restConfig.routes.employers + `/${employerId}/payschedules/${payScheduleId}/paydays/${paydayId}/payouts/${payoutsAvailable ? 'enable' : 'disable'}`

    return fetchClient.post(url)
      .then(response => {
          dispatch(paydayPayoutAvailabilitySet({paydayId, payoutsAvailable}))
      })
      .catch(error => {
          console.log('setPayoutAvailability error:', error)
          dispatch(paydayPayoutAvailabilitySetFailure(error))
      })
  }

  const setEmptyPayroll = (employerId: string, payScheduleId: string, paydayId: string) => {
    dispatch(loading())   

    const url = restConfig.routes.employers + `/${employerId}/payschedules/${payScheduleId}/paydays/${paydayId}/emptyPayroll`

    return fetchClient.post(url)
      .then(response => {
          dispatch(emptyPayrollSetForPayday(paydayId))
      })
      .catch(error => {
          console.log('setEmptyPayroll error:', error)
          dispatch(emptyPayrollSetForPaydayFailure(error))
      })
  }

  return {
    getPayScheduleDetails,
    createPaySchedule,
    updatePaySchedule,
    generatePaydaysForYear,
    updatePayday,
    deletePayday,
    setPayoutAvailability,
    setEmptyPayroll
  }
}

export default usePayScheduleActions