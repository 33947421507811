import { ReactNode, useEffect } from 'react'
import useInitialDataFetch from '../hooks/useInitialDataFetch'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { initialDataFetchTriggered } from '../redux/slices/appStateSlice'

interface Props {
	children: ReactNode
}

const AuthencticatedWrapper = ({children}: Props) => {
    const dispatch = useAppDispatch()
    const { fetchData } = useInitialDataFetch()

    const initialDataFetched = useAppSelector(state => state.appState.initialDataFetchTriggered)

    useEffect(() => {
        if (!initialDataFetched) {
            fetchData()
            dispatch(initialDataFetchTriggered())
        }
    }, [])

    return (
        <>
            {children}
        </>
    )
}

export default AuthencticatedWrapper