import CardSection from "../CardSection"
import DetailsCard from "../DetailsCard"
import InputField from "../InputField"

interface Props {
  filterValue: string
  onFilterChange: (value: string) => void
}

const EmployeeFilter = ({filterValue, onFilterChange}: Props) => {
  return (
    <CardSection>
      <DetailsCard>
        <InputField
          id="employeeSearchField"
          placeholder="Search employees..."
          value={filterValue || ''}
          onEnterKeyOrBlur={value => onFilterChange(value)}
        />
      </DetailsCard>
    </CardSection>
  )
}

export default EmployeeFilter
