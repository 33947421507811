import { restConfig } from "../authConfig";
import { AddRepresentativeModel, CompanyModel, CompanyMonitorDetails } from "../redux/companyMonitorTypes";
import { useAppDispatch } from "../redux/hooks";
import { 
  addRepresentativeFailure, 
  companiesWithUnhandledChangesFetchFailure, 
  companiesWithUnhandledChangesFetched, 
  companyMonitorDetailsFetchFailure, 
  companyMonitorDetailsFetched, 
  companyReportChangeCommentFailure, 
  companyReportChangeHandleFailure, 
  deleteRepresentativeFailure, 
  loading } from "../redux/slices/companyMonitorSlice";
import { validateCompanyRepresentative } from "../utils/validation";
import useFetchClient from "./useFetchClient";

const useCompanyMonitorActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getCompaniesWithUnhandledChanges = () => {
      dispatch(loading())
      const url = restConfig.routes.companyMonitor + `/withUnhandledChanges`

      return fetchClient.get(url)
        .then(response => {
            dispatch(companiesWithUnhandledChangesFetched(response.data as CompanyModel[]))
        })
        .catch(error => {
            console.log('getCompaniesWithUnhandledChanges error:', error)
            dispatch(companiesWithUnhandledChangesFetchFailure(error))
        })
    }

    const getDetailsForCompany = (marketId: string, companyRegistrationNumber: string, reportId?: string ) => {
      dispatch(loading())
      let url = restConfig.routes.companyMonitor + `/${marketId}/${companyRegistrationNumber}`

      if (reportId)
      {
          url += `?reportId=${reportId}`
      }
  
      return fetchClient.get(url)
        .then(response => {
            dispatch(companyMonitorDetailsFetched(response.data as CompanyMonitorDetails))
        })
        .catch(error => {
            console.log('getDetailsForCompany error:', error)
            dispatch(companyMonitorDetailsFetchFailure(error))
        })
    }

    const addRepresentative = (companyId: string, representative: AddRepresentativeModel) => {
      dispatch(loading())
      const url = restConfig.routes.companyMonitor + `/${companyId}/representatives`
  
      const validationErrors = validateCompanyRepresentative(representative)
      if (validationErrors) {
        dispatch(addRepresentativeFailure(validationErrors[0]))
        return
      }
  
      return fetchClient.post(url, representative)
        .then(response => {
          dispatch(companyMonitorDetailsFetched(response.data as CompanyMonitorDetails))
        })
        .catch(error => {
            console.log('addRepresentative error:', error)
            dispatch(addRepresentativeFailure(error))
        })
      }
    
      const deleteRepresentative = (companyId: string, representativeId: string) => {
        dispatch(loading())
        const url = restConfig.routes.companyMonitor + `/${companyId}/representatives/${representativeId}`
    
        return fetchClient.delete(url)
          .then(response => {
            dispatch(companyMonitorDetailsFetched(response.data as CompanyMonitorDetails))
          })
          .catch(error => {
              console.log('deleteRepresentative error:', error)
              dispatch(deleteRepresentativeFailure(error))
          })
      }

      const handleCompanyReportChange = (companyId: string, companyReportChangeId: string, comment: string) => {
        dispatch(loading())
        const url = restConfig.routes.companyMonitor + `/${companyId}/reportChanges/${companyReportChangeId}`
        return fetchClient.post(url, { comment: comment })
          .then(response => {
            dispatch(companyMonitorDetailsFetched(response.data as CompanyMonitorDetails))
            getCompaniesWithUnhandledChanges()
          })
          .catch(error => {
            console.log('handleCompanyReportChange error:', error)
            dispatch(companyReportChangeHandleFailure(error))
          })
      }

      const commentCompanyReportChange = (companyId: string, companyReportChangeId: string, comment: string) => {
        dispatch(loading())
        const url = restConfig.routes.companyMonitor + `/${companyId}/reportChanges/${companyReportChangeId}/comment`
        return fetchClient.post(url, { comment: comment })
          .then(response => {
            dispatch(companyMonitorDetailsFetched(response.data as CompanyMonitorDetails))
          })
          .catch(error => {
            console.log('commentCompanyReportChange error:', error)
            dispatch(companyReportChangeCommentFailure(error))
          })
      }

      return {
        getCompaniesWithUnhandledChanges,
        getDetailsForCompany,
        addRepresentative,
        deleteRepresentative,
        handleCompanyReportChange,
        commentCompanyReportChange      
      }
 }

export default useCompanyMonitorActions