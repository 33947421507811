import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'
import DateTimePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import S2 from './typography/S2'
import { cardPadding } from '../constants/layout'

interface Props {
    id?: string
    includeTime?: boolean
    label?: string
    value?: string
    valid?: boolean
    disabled?: boolean
    placeholder?: string
    onChange?: (value: string | undefined | null) => void 
}

const DatePicker = ({ 
    id = "select",
    includeTime,
    label,
    value,
    valid = true,
    disabled,
    placeholder,
    onChange,
} : Props) => {
    const styles = useStyles()
    const [dateValue, setDateValue] = useState<Date | null>(value ? new Date(value) : null)

    useEffect(() => {
        const valueAsDate = value ? new Date(value) : null;

        if (valueAsDate != dateValue)
            setDateValue(valueAsDate)             
    }, [value])

    const handleOnChange = (date: Date | null) => {
        setDateValue(date)
        if (date && onChange) {
            if (includeTime) {
                onChange(new Date(date.getTime()).toISOString())
            } else {
                const offset = date.getTimezoneOffset()
                const utcDateString = new Date(date.getTime() - (offset * 60 * 1000)).toISOString()
                onChange(utcDateString.split('T')[0])
            }
            
        } else if (onChange) {
            onChange(null)
        }
    }

    const renderLabel = () => {
        return label && (
            <div>
                <S2 className={styles.label}>{label}</S2>
            </div>
        )
    }  

    return (
        <div className={styles.container}>
            {renderLabel()}
            <div className={`${styles.inputContainer}`}>
            <DateTimePicker
                className={styles.input}
                disabled={disabled}
                selected={dateValue}
                onChange={(date) => handleOnChange(date as Date | null)}
                dateFormat={includeTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'}
                calendarStartDay={1}
                showTimeInput={includeTime}
                placeholderText={placeholder}
            />
            </div>
        </div>
    )
}

export default DatePicker

const useStyles = createUseStyles({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    inputContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        width: '100%',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 3,
        borderRadius: 8,
        backgroundColor: Colors.darkGray2,
        transition: '0.4s',
        '&:hover': {
            backgroundColor: Colors.darkGrayHover,
        }
    },
    input: {
        width: '100%',
        border: 'none',
        borderRadius: 5,
        backgroundColor: 'transparent',
        fontFamily: 'Aestetico-Regular',
        fontSize: 16,
        padding: {
          top: cardPadding * 0.5,
          bottom: cardPadding * 0.5,
          right: cardPadding * 2.5,
          // left: cardPadding * 2.5,
        },
        '&:focus': {
          border: 'none',
          backgroundColor: Colors.darkGray3,
          outline: 'none',
        },
        '&:active': {
          backgroundColor: Colors.darkGray3,
        },
      },
    label: {
        marginBottom: 8,
        marginTop: 8
      }
  })
  