import { FC, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { titleMarginBottom } from '../constants/layout'
import AmlFlaggedCompanyRepresentativeList from '../components/Aml/AmlFlaggedCompanyRepresentativeList'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import useCompanyRepresentativeAmlActions from '../hooks/useCompanyRepresentativeAmlActions'
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator'

interface AmlFlaggedCompanyRepresentativesProps { }

const AmlFlaggedCompanyRepresentatives: FC<AmlFlaggedCompanyRepresentativesProps> = ({  }) => {
  const styles = useStyles()

  const { getAmlFlaggedCompanyRepresentatives } = useCompanyRepresentativeAmlActions()

  const loading = useAppSelector(state => state.amlFlaggedCompanyRepresentative.loading)
  const flaggedRepresentatives = useAppSelector(state => state.amlFlaggedCompanyRepresentative.representatives)

  useEffect(() => {
    if (flaggedRepresentatives.length == 0) {
      getAmlFlaggedCompanyRepresentatives()
    }
  }, [])

  return (
    <div className={styles.container}>
      <h1>PEP &amp; sanction flagged company representatives</h1>
      <AmlFlaggedCompanyRepresentativeList 
        representatives={flaggedRepresentatives}
      />
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default AmlFlaggedCompanyRepresentatives

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    }
  },
})