import { Spinner } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import FullScreenModal from './FullScreenModal'

interface Props {
    visible: boolean
}

const FullScreenLoadingIndicator = ({visible} : Props) => {
    const styles = useStyles()


    return (
        <FullScreenModal visible={visible}>
            <Spinner
                as='span'
                animation='border'
            />
        </FullScreenModal>
    )
}

export default FullScreenLoadingIndicator

const useStyles = createUseStyles({
    container: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }
})