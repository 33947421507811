import { restConfig } from "../authConfig";
import { useAppDispatch } from "../redux/hooks";
import { taxTablesImported, taxTableImportFailure, loading } from "../redux/slices/taxTableSlice";
import useFetchClient from "./useFetchClient";

const useTaxTableActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const importTaxTables = async (year: number) => {
    dispatch(loading())  

    const url = restConfig.routes.taxTables + `/import?year=${year}`

    return fetchClient.post(url)
      .then(response => {
          dispatch(taxTablesImported())
      })
      .catch(error => {
          console.log('acceptAmlFlimportTaxTablesaggedCompanyRepresentative error:', error)
          dispatch(taxTableImportFailure(error))
      })
  }

  return {
    importTaxTables
  }
}

export default useTaxTableActions