import Card from '../Card'
import {createUseStyles} from 'react-jss'
import {Colors} from '../../constants/colors'
import {faFileContract} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import ReportModal from "./ReportModal";
import {CompanyCreditReportModel} from "../../redux/companyMonitorTypes";

interface Props {
  creditReport: CompanyCreditReportModel
}

const CompanyReportCreditReportCard = ({creditReport}: Props) => {
  const styles = useStyles()
  const [reportModalVisible, setReportModalVisible] = useState(false)

  return (
    <>
      <Card className={`${styles.card} card`}
            onClick={() => setReportModalVisible(true)}>
        <FontAwesomeIcon className={styles.largeIcon}
                         icon={faFileContract}
                         color={Colors.white}
        />
        <h4>Credit report</h4>
      </Card>

      <ReportModal
        title='Company Credit report'
        visible={reportModalVisible}
        reportContent={creditReport.report}
        onCloseClick={() => setReportModalVisible(false)}
      />
    </>
  )
}
export default CompanyReportCreditReportCard

const useStyles = createUseStyles({
  card: {
    height: '100%',
    backgroundColor: '#4B4B4B',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    borderRadius: 8,
    padding: 14,
    gap: 8,
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  warning: {
    boxShadow: '0 0 5px 0 #FF3333',
    '&:hover': {
      boxShadow: '0 0 30px 0 #FF3333',
    }
  },
  largeIcon: {
    fontSize: "xxx-large"
  },
})
