import {createUseStyles} from "react-jss"
import {Language, LearningModuleActionManagementModel} from "../../redux/types"
import MultilingualInputField from "./MultilingualInputField";
import InputField from "../InputField";
import MultilingualTextAreaField from "./MultilingualTextAreaField";
import {Toolbar, ToolbarItem} from "./Toolbar";
import {faEye, faEyeSlash, faFlag, faSave} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import ActionModulePreview from "./ActionModulePreview";
import useLearningEditor from "../../hooks/useLearningEditor";


interface Props {
  editModel: LearningModuleActionManagementModel
  onChange: (module: LearningModuleActionManagementModel) => void
  onSave: () => void
  onPublish?: () => void
  onUnpublish?: () => void
}

const ActionModuleEditor = ({editModel, onChange, onSave, onPublish, onUnpublish}: Props) => {
  const styles = useStyles()
  const {previewLanguage, setPreviewLanguage} = useLearningEditor()

  const handleOnChange = (propName: string, value: any) => {
    onChange({...editModel, [propName]: value})
  }

  return (
    <div className={styles.container}>
      <div className={styles.editor}>
        <div className={styles.titleBar}>
          <h2>Preview</h2>
          <Toolbar>
            <ToolbarItem icon={faFlag} title={"English"} display={"iconAndText"}
                         disabled={previewLanguage === Language.en}
                         onClick={() => setPreviewLanguage(Language.en)}/>
            <ToolbarItem icon={faFlag} title={"Swedish"} display={"iconAndText"}
                         disabled={previewLanguage === Language.sv}
                         onClick={() => setPreviewLanguage(Language.sv)}/>
          </Toolbar>
        </div>
        <ActionModulePreview actionModule={editModel} language={previewLanguage}/>
      </div>

      <div className={styles.editor}>
        <div className={styles.titleBar}>
          <h2>Edit</h2>
          <Toolbar>
            <ToolbarItem icon={faSave} title={"Save"} display={"iconAndText"} onClick={onSave}/>
            {onPublish && <ToolbarItem icon={faEye} title={"Publish"} display={"iconAndText"} onClick={onPublish}/>}
            {onUnpublish && <ToolbarItem icon={faEyeSlash} title={"Unpublish"} display={"iconAndText"} onClick={onUnpublish}/>}
          </Toolbar>
        </div>

        <MultilingualInputField
          id={'title'}
          label={'Title'}
          translations={editModel.title}
          onChange={(value) => handleOnChange('title', value)}
          onLanguageChange={setPreviewLanguage}
        />
        <InputField
          id='orderNumber'
          value={editModel.orderNumber.toString() || ''}
          onChange={(event) => handleOnChange('orderNumber', event.target.value)}
          label='Order Number'
          numeric
          placeholder='3'
        />
        <InputField
          id='estimatedCompletionTime'
          value={editModel.estimatedCompletionTime || ''}
          onChange={(event) => handleOnChange('estimatedCompletionTime', event.target.value)}
          label='Estimated completion time'
          placeholder='00:01:00'
        />
        <MultilingualInputField
          id={'context'}
          label={'Context'}
          translations={editModel.context}
          onChange={(value) => handleOnChange('context', value)}
          onLanguageChange={setPreviewLanguage}
        />
        <MultilingualInputField
          id={'prompt'}
          label={'Prompt'}
          translations={editModel.prompt}
          onChange={(value) => handleOnChange('prompt', value)}
          onLanguageChange={setPreviewLanguage}
        />
        <MultilingualTextAreaField
          id={'description'}
          label={'Description'}
          translations={editModel.description}
          onChange={(value) => handleOnChange('description', value)}
          onLanguageChange={setPreviewLanguage}
        />
        <MultilingualInputField
          id={'deepLinkText'}
          label={'Deep link text'}
          translations={editModel.deepLinkText}
          onChange={(value) => handleOnChange('deepLinkText', value)}
          onLanguageChange={setPreviewLanguage}
        />
        <MultilingualInputField
          id={'deepLinkUrl'}
          label={'Deep link URL'}
          translations={editModel.deepLinkUrl}
          onChange={(value) => handleOnChange('deepLinkUrl', value)}
          onLanguageChange={setPreviewLanguage}
        />
      </div>
    </div>
  )
}

export default ActionModuleEditor

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20
  },
  editor: {
    minWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 10
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
})
