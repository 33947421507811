import { Badge } from "react-bootstrap"
import { InAppMessage } from "../../redux/types"

interface Props {
    inAppMessage: InAppMessage
  }

const InAppMessageStatusBadge = ({inAppMessage}: Props) => {
    const render = () => {
        const expired = (inAppMessage.validTo != null && new Date(inAppMessage.validTo).getTime() < new Date().getTime())
        
        let variant = 'success'
        let text = 'active'
        
        if (!inAppMessage.published) {
          variant = 'primary'
          text = 'unpublished'
        } else if (inAppMessage.published && expired) {
          variant = 'secondary'
          text = 'expired'
        }
    
        return <Badge variant={variant}>{text}</Badge>
    }
    
    return render()
}

export default InAppMessageStatusBadge