import { useHistory } from "react-router-dom";

// MSAL imports
import { MsalProvider } from "@azure/msal-react"
import { CustomNavigationClient } from "./utils/NavigationClient"

import Layout from './pages/Layout'
import Router from "./navigation/Router";

function App({ pca }: any) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <Layout>
        <Router />
      </Layout>
    </MsalProvider>
  );
}

export default App
