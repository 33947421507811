import { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { AccrualType, PaydayOnWeekendHandling, PayScheduleEditModel, PayScheduleModel, Periodicity } from "../../redux/types"
import InputField from "../InputField"
import Modal from "../Modal"
import Select from "../Select"
import Switch from "../Switch"

interface Props {
    intitalValue: PayScheduleModel | null
    onSaveClick: (paySchedule: PayScheduleEditModel) => void
    onCancelClick: () => void
    visible: boolean
}

const EditPayScheduleModal = ({ intitalValue, onSaveClick, onCancelClick, visible }: Props) => {
    const styles = useStyles()
    const [paySchedule, setPaySchedule] = useState<PayScheduleEditModel>()

    useEffect(() => {
        if (visible) {
            setPaySchedule({
                id: intitalValue?.id ?? null,
                identifier: intitalValue?.identifier ?? '',
                defaultPaymentDayOfMonth: intitalValue?.defaultPaymentDayOfMonth ?? 25,
                deviationPeriodEndOffset: intitalValue?.deviationPeriodEndOffset ?? 0,
                paydayOnWeekendHandling: intitalValue?.paydayOnWeekendHandling ?? PaydayOnWeekendHandling.advance,
                periodStartDayOfMonth: intitalValue?.periodStartDayOfMonth ?? 1,
                periodicity: intitalValue?.periodicity ?? Periodicity.monthly,
                salaryAccrualType: intitalValue?.salaryAccrualType ?? AccrualType.advance,
                salaryEarningOnWeekends: intitalValue?.salaryEarningOnWeekends ?? false
            })
        }
    }, [visible])

    const handleOnSave = () => {
        if (paySchedule) {
            onSaveClick(paySchedule)       
        }
    }

    const handleOnClose = () => {
        onCancelClick && onCancelClick()
    }

    const handleOnChange = (propName: string, value: any) => {
        if (paySchedule) {
            setPaySchedule({...paySchedule, [propName]: value === '' ? null : value})
        }
    }

    const getPeriodicityOptions = () => {
        return Object.keys(Periodicity).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    const getAccrualOptions = () => {
        return Object.keys(AccrualType).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    const getPaydayOnWeekendHandlingOptions = () => {
        return Object.keys(PaydayOnWeekendHandling).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    return (
        <Modal
            title='Edit pay schedule'
            show={visible}
            okButtonTitle='Save'
            cancelButtonTitle='Cancel'
            onHide={() => handleOnClose()}
            onOkClick={() => handleOnSave()}
            onCancelClick={() => handleOnClose()}
        >
            <InputField
                id='identifier'
                value={paySchedule?.identifier ?? ''}
                label='Identifier'
                placeholder=''
                onChange={(event) => handleOnChange('identifier', event.target.value)}
            /> 
            <Select 
                id='periodicity'
                key='periodicity'
                label='Periodicity'
                options={getPeriodicityOptions()} 
                selectedValue={paySchedule?.periodicity.toString()}
                onSelect={(value) => handleOnChange('periodicity', Periodicity[value as keyof typeof Periodicity])}
            />
            <InputField
                id='periodStartDayOfMonth'
                numeric={true}
                valid={(paySchedule?.periodStartDayOfMonth ?? 1) > 0 && (paySchedule?.periodStartDayOfMonth ?? 1) < 29}
                value={paySchedule?.periodStartDayOfMonth?.toString() ?? '1'}
                onChange={(event) => handleOnChange('periodStartDayOfMonth', parseFloat(event.target.value))}
                label='Start day of period'
                placeholder=''
            />
            <InputField
                id='defaultPaymentDayOfMonth'
                numeric={true}
                valid={(paySchedule?.defaultPaymentDayOfMonth ?? 1) > 0 && (paySchedule?.defaultPaymentDayOfMonth ?? 1) < 29}
                value={paySchedule?.defaultPaymentDayOfMonth?.toString() ?? '25'}
                onChange={(event) => handleOnChange('defaultPaymentDayOfMonth', parseFloat(event.target.value))}
                label='Default payment day'
                placeholder=''
            />
            <InputField
                id='deviationPeriodEndOffset'
                numeric={true}
                valid={(paySchedule?.deviationPeriodEndOffset ?? 0) >= 0}
                value={paySchedule?.deviationPeriodEndOffset?.toString() ?? '0'}
                onChange={(event) => handleOnChange('deviationPeriodEndOffset', parseFloat(event.target.value))}
                label='Deviation period end offset'
                placeholder=''
            />
            <Select 
                id='salaryAccrualType'
                key='salaryAccrualType'
                label='Salary accrual'
                options={getAccrualOptions()} 
                selectedValue={paySchedule?.salaryAccrualType.toString()}
                onSelect={(value) => handleOnChange('salaryAccrualType', AccrualType[value as keyof typeof AccrualType])}
            />
            <Switch 
                id='salaryEarningOnWeekends'
                key='salaryEarningOnWeekends'
                checked={paySchedule?.salaryEarningOnWeekends ?? false} 
                label='Salary earning on non business days' 
                onChange={() => handleOnChange('salaryEarningOnWeekends', !(paySchedule?.salaryEarningOnWeekends ?? false))} 
            />
            <Select 
                id='paydayOnWeekendHandling'
                key='paydayOnWeekendHandling'
                label='Payday on weekend action'
                options={getPaydayOnWeekendHandlingOptions()} 
                selectedValue={paySchedule?.paydayOnWeekendHandling.toString()}
                onSelect={(value) => handleOnChange('paydayOnWeekendHandling', PaydayOnWeekendHandling[value as keyof typeof PaydayOnWeekendHandling])}
            />
        </Modal>
    )
}

export default EditPayScheduleModal

const useStyles = createUseStyles({
    datePickerContainer: {
        marginTop: 20,
        minHeight: 350
    },
})