import { useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { createUseStyles } from 'react-jss'
import { titleMarginBottom } from '../constants/layout'
import { formatDateTimeString, formatDateString } from '../utils/dateHelper'
import CardSection from '../components/CardSection'
import DetailsCard from '../components/DetailsCard'
import { TransactionType } from '../redux/types';
import useTransactionActions from '../hooks/useTransactionActions';
import { useAppSelector } from '../redux/hooks';
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator';
import NavLink from '../components/NavLink';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Link from '../components/Link';

const TransactionDetails = () => {
  const styles = useStyles()
  const params = useParams<{id: string}>()
  const history = useHistory();

  const { getTransactionDetails } = useTransactionActions()

  const loading = useAppSelector(state => state.transaction.loading)
  const transactionDetails = useAppSelector(state => state.transaction.transactionDetails)

  useEffect(() => {
    if (transactionDetails == null || transactionDetails.id != params.id) {
      getTransactionDetails(params.id)
    }
  }, [])

  const renderEmployeePayoutDetails = () => {
    if (transactionDetails?.type == TransactionType.EmployeePayoutTransaction) {        
      return (
        <DetailsCard>
          <h6>Type specific details</h6>
          <p>Fee: <span>{transactionDetails.employeePayoutTransactionDetails?.fee.amount.toLocaleString('sv-SE')} {transactionDetails.employeePayoutTransactionDetails?.fee.currencyCode.toUpperCase()}</span></p>
          <p>User: <Link to={`/users/${transactionDetails.employeePayoutTransactionDetails?.userId}`}>{transactionDetails.employeePayoutTransactionDetails?.userGivenName} {transactionDetails.employeePayoutTransactionDetails?.userFamilyName}</Link></p>
          <p>Employer: <Link to={`/employers/${transactionDetails.employeePayoutTransactionDetails?.employerId}`}>{transactionDetails.employeePayoutTransactionDetails?.employerName}</Link></p>
          <p>Payout method: <span>{transactionDetails.employeePayoutTransactionDetails?.payoutMethod.payoutMethodType} {transactionDetails.employeePayoutTransactionDetails?.payoutMethod.target}</span></p>
          <p>Repayment date: <span>{transactionDetails.employeePayoutTransactionDetails && formatDateString(transactionDetails.employeePayoutTransactionDetails.repaymentDate)}</span></p>
            {transactionDetails.state == 'cancelled' && 
              <p>Failure reason: <span>{transactionDetails.employeePayoutTransactionDetails?.failureReason}</span></p>
            }
        </DetailsCard>
      )
    }
  }

  const renderEmployerPayPaymentDetails = () => {
    if (transactionDetails?.type == TransactionType.EmployerPayPaymentTransaction) {        
      return (
        <DetailsCard>
          <h6>Type specific details</h6>
          <p>Deducted payouts: <span>{transactionDetails.employerPayPaymentTransactionDetails?.deductedPayoutAmount?.amount.toLocaleString('sv-SE')} {transactionDetails.employerPayPaymentTransactionDetails?.deductedPayoutAmount?.currencyCode.toUpperCase()}</span></p>
          <p>Deducted fees: <span>{transactionDetails.employerPayPaymentTransactionDetails?.deductedFeeAmount?.amount.toLocaleString('sv-SE')} {transactionDetails.employerPayPaymentTransactionDetails?.deductedFeeAmount?.currencyCode.toUpperCase()}</span></p>
          <p>Remaining salary: <span>{transactionDetails.employerPayPaymentTransactionDetails?.remainingPayAmount?.amount.toLocaleString('sv-SE')} {transactionDetails.employerPayPaymentTransactionDetails?.remainingPayAmount?.currencyCode.toUpperCase()}</span></p>
          <p>User: <Link to={`/users/${transactionDetails.employerPayPaymentTransactionDetails?.userId}`}>{transactionDetails.employerPayPaymentTransactionDetails?.userGivenName} {transactionDetails.employerPayPaymentTransactionDetails?.userFamilyName}</Link></p>
          <p>Employer: <Link to={`/employers/${transactionDetails.employerPayPaymentTransactionDetails?.employerId}`}>{transactionDetails.employerPayPaymentTransactionDetails?.employerName}</Link></p>
        </DetailsCard>
      )
    }
  }

  const renderPayPayoutDetails = () => {
    if (transactionDetails?.type == TransactionType.PayPayoutTransaction) {        
      return (
        <DetailsCard>
          <h6>Type specific details</h6>
          <p>Payout method: <span>{transactionDetails.payPayoutTransactionDetails?.payoutMethod.payoutMethodType} {transactionDetails.payPayoutTransactionDetails?.payoutMethod.target}</span></p>
          <p>User: <Link to={`/users/${transactionDetails.payPayoutTransactionDetails?.userId}`}>{transactionDetails.payPayoutTransactionDetails?.userGivenName} {transactionDetails.payPayoutTransactionDetails?.userFamilyName}</Link></p>
          <p>Employer: <Link to={`/employers/${transactionDetails.payPayoutTransactionDetails?.employerId}`}>{transactionDetails.payPayoutTransactionDetails?.employerName}</Link></p>
        </DetailsCard>
      )
    }
  }

  const renderTransferDebtsToLandingAccountDetails = () => {
    if (transactionDetails?.type == TransactionType.TransferDebtsToLandingAccountTransaction) {        
      return (
        <DetailsCard>
          <h6>Type specific details</h6>
          <p>Employer: <Link to={`/employers/${transactionDetails.transferDebtsToLandingAccountTransactionDetails?.employerId}`}>{transactionDetails.transferDebtsToLandingAccountTransactionDetails?.employerName}</Link></p>
        </DetailsCard>
      )
    }
  }

  const renderTransfePaysToOperatingAccountDetails = () => {
    if (transactionDetails?.type == TransactionType.TransferPaysToOperatingAccountTransaction) {        
      return (
        <DetailsCard>
          <h6>Type specific details</h6>
          <p>Employer: <Link to={`/employers/${transactionDetails.transferPaysToOperatingAccountTransactionDetails?.employerId}`}>{transactionDetails.transferPaysToOperatingAccountTransactionDetails?.employerName}</Link></p>
        </DetailsCard>
      )
    }
  }

  const renderEmployerDeductionPaymentDetails = () => {
    if (transactionDetails?.type == TransactionType.EmployerPayDeductionPaymentTransaction) {        
      return (
        <DetailsCard>
          <h6>Type specific details</h6>
          <p>Employer: <Link to={`/employers/${transactionDetails.employerPayDeductionPaymentTransactionDetails?.employerId}`}>{transactionDetails.employerPayDeductionPaymentTransactionDetails?.employerName}</Link></p>
        </DetailsCard>
      )
    }
  }

  const renderCreditorPostfundingDetails = () => {
    if (transactionDetails?.type == TransactionType.CreditorPostfundingTransaction) {        
      return (
        <DetailsCard>
          <h6>Type specific details</h6>
          <p>Creditor: <span>{transactionDetails.creditorPostfundingTransactionDetails?.creditorName}</span></p>
          <p>Funding cost: <span>{transactionDetails.creditorPostfundingTransactionDetails?.fundingCost.amount.toLocaleString('sv-SE')} {transactionDetails.creditorPostfundingTransactionDetails?.fundingCost.currencyCode.toUpperCase()}</span></p>
        </DetailsCard>
      )
    }
  }

  const renderInvoicePayoutDetails = () => {
    if (transactionDetails?.type == TransactionType.InvoicePayoutTransaction) {        
      return (
        <DetailsCard>
          <h6>Type specific details</h6>
          <p>Invoice payout: <Link to={`/invoicepayoutproviders/${transactionDetails.invoicePayoutTransactionDetails.invoicePayoutProviderId}/payouts/${transactionDetails.invoicePayoutTransactionDetails.invoicePayoutId}`}>Details</Link></p>
        </DetailsCard>
      )
    }
  }

  const renderInvoicePayoutRepaymentDetails = () => {
    if (transactionDetails?.type == TransactionType.InvoicePayoutRepaymentTransaction) {        
      return (
        <DetailsCard>
          <h6>Type specific details</h6>
          <p>Invoice payout: <Link to={`/invoicepayoutproviders/${transactionDetails.invoicePayoutRepaymentTransactionDetails.invoicePayoutProviderId}/repayments/${transactionDetails.invoicePayoutRepaymentTransactionDetails.invoicePayoutRepaymentId}`}>Details</Link></p>
        </DetailsCard>
      )
    }
  }

  return (
    <div className={styles.container}>
      <h1>{transactionDetails && transactionDetails.type.toString().replace('Transaction', '')}</h1>
      <div className={styles.actionBar}>
        <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()} />
      </div>
      <CardSection>
        <DetailsCard>
          <h6>General</h6>
          <p>Date: <span>{transactionDetails && formatDateTimeString(transactionDetails.date)}</span></p>
          <p>State: <span>{transactionDetails?.state}</span></p>
          <p>Amount: <span>{transactionDetails?.amount.amount.toLocaleString('sv-SE')} {transactionDetails?.amount.currencyCode.toUpperCase()}</span></p>
        </DetailsCard>
        {renderEmployeePayoutDetails()}
        {renderEmployerPayPaymentDetails()}
        {renderPayPayoutDetails()}
        {renderTransferDebtsToLandingAccountDetails()}
        {renderTransfePaysToOperatingAccountDetails()}
        {renderEmployerDeductionPaymentDetails()}
        {renderCreditorPostfundingDetails()}
        {renderInvoicePayoutDetails()}
        {renderInvoicePayoutRepaymentDetails()}
      </CardSection>
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default TransactionDetails

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    }
  },
  actionBar: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between', 
    alignItems: 'center'
  },
})