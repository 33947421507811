import TransactionsComponent from '../components/TransactionList/Transactions'
import { createUseStyles } from 'react-jss'
import { titleMarginBottom } from '../constants/layout'

const Transactions = () => {
  const styles = useStyles()

  return (
    <div className={styles.container}>
      <h1>Transactions</h1>
      <TransactionsComponent />
    </div>
  )
}

export default Transactions

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    }
  }
})
