import Card from '../../Card'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../constants/colors'
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { formatDateString } from '../../../utils/dateHelper'
import Badge from "../../Badge";
import { ManualRepresentativeModel } from '../../../redux/companyMonitorTypes';

interface Props {
  representatives: ManualRepresentativeModel[]
  onDeleteClick?: (representative: ManualRepresentativeModel) => void
}

const ManualCompanyRepresentativeList = ({ onDeleteClick, representatives }: Props) => {
  const styles = useStyles()
  const history = useHistory();

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        <div className={styles.cell}>
            <p><span>Name</span></p>
        </div>
        <div className={styles.cell}>
          <p><span>Date of birth</span></p>
        </div>
        <div className={styles.cell}>
          <p><span>Country</span></p>
        </div>
        <div className={styles.cell}>
          <p><span>Email</span></p>
        </div>
        <div className={styles.badgeCell}>
          <p><span>Positions</span></p>
        </div>
        <div className={styles.smallCell}/>
    </Card>
    )    
  }

  const renderListItems = () => {
    return representatives?.map((item, index) => {
      return (
        <Card className={`${styles.container} card`} key={`rep_${index}`}>
          <div className={styles.cell}>
              <p>{item.givenName} {item.familyName}</p>
          </div>
          <div className={styles.cell}>
              <p>{item.dateOfBirth ? formatDateString(item.dateOfBirth) : '-'}</p>
          </div>
          <div className={styles.cell}>
            <p>{item.country}</p>
          </div>
          <div className={styles.cell}>
              <p>{item.email}</p>
          </div>
          <div className={styles.badgeCell}>
            {renderPositionBadges(item)}
          </div>
          <div className={styles.smallCell}>
              <FontAwesomeIcon
                  className={styles.button}
                  icon={faTrashAlt}
                  color={Colors.euro_600}
                  title='Delete'
                  onClick={() => onDeleteClick && onDeleteClick(item)}
              />
          </div>
      </Card>
      )
    })
  }

  const renderPositionBadges = (item: ManualRepresentativeModel) => {
    return item.positions?.map((position, index) => {
      return (
        <Badge
          key={`positionBadge${index}`}
          text={position}
          backgroundColor={Colors.darkGray3}
          textColor={Colors.darkGray4}
        />
      )
    })
  }

  return (
    <div>
      {renderHeader()}
      {renderListItems()}
    </div>
  )
}

export default ManualCompanyRepresentativeList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 3px',
    margin: '5px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    margin: '30px 0 20px 0',
  },
  '& p': {
      fontSize: 12,
      margin: 0,
      '& span': {
        fontFamily: 'Aestetico-Bold',
        color: Colors.euro_600,
      }
  },
  cell: {
    flex: 1,
    padding: '0px 5px'
  },
  smallCell: {
    textAlign: 'center',
    padding: '0px 10px',
    flex: '0 0 40px'
  },
  mediumCell: {
    textAlign: 'center',
    padding: '0px 10px',
    flex: '0 0 110px'
  },
  badgeCell: {
    display: 'flex',
    flex: 1.5,
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  button: {
      transition: '0.4s',
      '&:hover': {
        color: Colors.euro5,
        cursor: 'pointer',
      }
    },
})
