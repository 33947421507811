export const formatDateTimeString = (dateStr: string | null | undefined): string => {
    if (!dateStr)
        return ''

    const date = new Date(dateStr)

    const year = date.getFullYear().toString()
    const month = (date.getMonth()+1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hour = date.getHours().toString().padStart(2, '0')
    const minute = date.getMinutes().toString().padStart(2, '0')
    const second = date.getSeconds().toString().padStart(2, '0')

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}

export const formatDateString = (dateStr: string): string => {
    const date = new Date(dateStr)

    const year = date.getFullYear().toString()
    const month = (date.getMonth()+1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    return `${year}-${month}-${day}`
}

export const formatMonthString = (dateStr: string): string => {
    const date = new Date(dateStr)

    const year = date.getFullYear().toString()
    const month = (date.getMonth()+1).toString().padStart(2, '0')

    return `${year}-${month}`
}

export const isDateValid = (dateStr: string) => {
    const date = Date.parse(dateStr)
    return !isNaN(date)
}

export const isValidBirthDate = (dateStr: string) => {
    const date = Date.parse(dateStr)
    if (isNaN(date)) return false

    if (date > Date.now()) return false

    const tooOld = 150 * 365 * 24 * 60 * 60 * 1000 //Older than 150 years...
    if (date < (Date.now() - tooOld)) return false

    return true
}
