import { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../constants/colors'
import Modal from '../Modal'
import Card from '../Card'
import { BankAccountModel } from '../../redux/types'
import useBankAccountActions from '../../hooks/useBankAccountActions'
import { useAppSelector } from '../../redux/hooks'

interface Props {
    marketId: string
    onSelectClick?: (bankAccount: BankAccountModel) => void
    onCancelClick?: () => void
    visible: boolean
}

const BankAccountSelectorModal = ({ marketId, onSelectClick, onCancelClick, visible }: Props) => {
    const styles = useStyles()
    const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccountModel | null>(null)
    
    const { getBankAccounts } = useBankAccountActions()

    const accounts = useAppSelector(state => state.bankAccount.bankAccounts)

    useEffect(() => {
        if (visible)
            getBankAccounts(marketId)
    }, [visible])

    const handleOnSelect = () => {
        if (selectedBankAccount) {
            onSelectClick && onSelectClick(selectedBankAccount)
        }
    }

    const handleOnCancel = () => {
        onCancelClick && onCancelClick()
    }

    const handleOnHide = () => {
        onCancelClick && onCancelClick()
    }

    const renderConnectedEmployers = (bankAccount: BankAccountModel) => {
        return bankAccount.connectedEmployers.map((employer, index) => {
            return (
                <div key={`employer_${employer.employerId}`}>
                    {employer.employerName}
                </div>
            )
        })
    }

    const renderBankAccounts = () => {
        return accounts?.map((bankAccount) => {
            return (
                <div className={styles.container} key={`bankAccount_${bankAccount.bankAccountId}`}>
                    <Card className={`${styles.card} card ${bankAccount.bankAccountId == selectedBankAccount?.bankAccountId ? styles.selected : ''}`} 
                        onClick={() => setSelectedBankAccount(bankAccount)}>
                        <div className={styles.cell}>
                            {bankAccount.name}
                        </div>
                        <div className={styles.cell}>
                            {bankAccount.bban}
                        </div>
                    </Card>
                    <div className={styles.connectedEmployerContainer}>
                        <span style={{fontSize: 10}}>Used by employers</span>
                        {renderConnectedEmployers(bankAccount)}    
                    </div>
                </div>
            )
        })
    }

    return (
        <Modal
            title='Connect bank account'
            show={visible}
            okButtonTitle='Select'
            cancelButtonTitle='Cancel'
            onHide={() => handleOnHide()}
            onOkClick={() => handleOnSelect()}
            onCancelClick={() => handleOnCancel()}
        >
        { renderBankAccounts() }
        </Modal>
    )
}

export default BankAccountSelectorModal

const useStyles = createUseStyles({
    container: {
        marginBottom: 30
    },
    card: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'left',
        padding: '3px 10px',
        transition: '0.4s',
        '&:hover': {
        backgroundColor: Colors.darkGray3,
        }
    },
    cell: {
        flex: 1,
        padding: '0px 10px'
    },
    selected: {
        backgroundColor: Colors.darkGray5
    },
    connectedEmployerContainer: {
        marginLeft: 60
    }
})
  