import { useState } from "react"
import { restConfig } from "../authConfig";
import { UserListItemModel } from '../redux/types';
import { createUrlWithQueryString } from "../utils/url";
import useFetchClient from "./useFetchClient";

const useUserSelectorActions = () => {
  const fetchClient = useFetchClient()
  const [users, setUsers] = useState<UserListItemModel[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error>()

  const search = async (searchTerm: string, maxCount: number) => {
    const params = {
      query: searchTerm,
      pageNumber: 1,
      pageSize: maxCount
    }
    const url = createUrlWithQueryString(restConfig.routes.users, params)
    
    setLoading(true)

    return fetchClient.get(url)
      .then(response => {
        setUsers(response.data as UserListItemModel[])
        setLoading(false)
      })
      .catch(error => {
        console.log('search error:', error)
        setError(new Error(error))
        setLoading(false)
      })
  }

  return {
    search,
    users,
    error,
    loading
  }
}

export default useUserSelectorActions
