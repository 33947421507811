import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FrozenBankTransfer, TokenModel } from '../types'

interface NordeaTokenState {
    loading: boolean
    tokens : TokenModel[]
    frozenTransfers: FrozenBankTransfer[]
    error: Error | string | null
  }

const initialState: NordeaTokenState = {
    loading: false,
    tokens: [],
    frozenTransfers: [],
    error: null
}

export const nordeaTokenSlice = createSlice({
    name: 'nordeaToken',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        nordeaTokensFetched: (state, action: PayloadAction<TokenModel[]>) => {
            state.loading = false
            state.tokens = action.payload
            state.error = null
        },
        nordeaTokensFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        nordeaFrozenTransfersFetched: (state, action: PayloadAction<FrozenBankTransfer[]>) => {
            state.loading = false
            state.frozenTransfers = action.payload
            state.error = null
        },
        nordeaFrozenTransfersFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        nordeaAccessTokenCreated: (state, action: PayloadAction<TokenModel[]>) => {
            state.loading = false
            state.tokens = action.payload
            state.error = null
        },
        nordeaAccessTokenCreateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        nordeaSigningTokenCreated: (state, action: PayloadAction<TokenModel[]>) => {
            state.loading = false
            state.tokens = action.payload
            state.error = null
        },
        nordeaSigningTokenCreateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        nordeaPaymentVerificationInitiated: (state) => {
            state.loading = false
        },
        nordeaPaymentVerificationInitiationFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
    }
})

export const { 
    loading, 
    nordeaTokensFetched, 
    nordeaTokensFetchFailure, 
    nordeaAccessTokenCreated, 
    nordeaAccessTokenCreateFailure, 
    nordeaSigningTokenCreated, 
    nordeaSigningTokenCreateFailure,
    nordeaFrozenTransfersFetched,
    nordeaFrozenTransfersFetchFailure,
    nordeaPaymentVerificationInitiated,
    nordeaPaymentVerificationInitiationFailure 
} = nordeaTokenSlice.actions

export default nordeaTokenSlice.reducer