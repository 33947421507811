import { restConfig } from "../authConfig";
import { useAppDispatch } from "../redux/hooks";
import { fortnoxAuthenticationUrlFetched, fortnoxAuthenticationUrlFetchFailure, fortnoxIntegrationActivated, fortnoxIntegrationActivationFailure, fortnoxTokensFetched, fortnoxTokensFetchFailure, loading } from "../redux/slices/fortnoxSlice";
import { TokenModel } from '../redux/types';
import useFetchClient from "./useFetchClient";

const useFortnoxActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const fetchTokens = async () => {
    dispatch(loading())

    const url = restConfig.routes.fortnox

    return fetchClient.get(url)
      .then(response => {
          dispatch(fortnoxTokensFetched(response.data as TokenModel[]))
      })
      .catch(error => {
          console.log('fetchTokens error:', error)
          dispatch(fortnoxTokensFetchFailure(error))
      })
  }

  const fetchAuthenticationUrl = async () => {
    dispatch(loading())

    const url = restConfig.routes.fortnox + '/authenticationUrl'

    return fetchClient.get(url)
      .then(response => {
          dispatch(fortnoxAuthenticationUrlFetched(response.data.url as string))
      })
      .catch(error => {
          console.log('getAuthenticationUrl error:', error)
          dispatch(fortnoxAuthenticationUrlFetchFailure(error))
      })
  }

  const activateIntegration = async (code: string, state: string) => {
    dispatch(loading())

    const url = restConfig.routes.fortnox + '/activate'

    return fetchClient.post(url, { code, state })
      .then(response => {
          dispatch(fortnoxIntegrationActivated())
      })
      .catch(error => {
          console.log('activateIntegration error:', error)
          dispatch(fortnoxIntegrationActivationFailure(error))
      })
  }

  return {
    fetchTokens,
    fetchAuthenticationUrl,
    activateIntegration
  }
}

export default useFortnoxActions
