import {PayRunModel} from "../../redux/types";
import Card from "../Card";
import {createUseStyles} from "react-jss";
import {Colors} from "../../constants/colors";
import {useState} from "react";
import PayRunModal from "./PayRunModal";
import InputField from "../InputField";
import Pagination from "../Pagination";
import {PayRunPage} from "../../redux/slices/payRunDashboardSlice";
import {Alert} from "react-bootstrap";

interface ColumnDefinition {
  title: string
  accessor: (payRun: PayRunModel) => string | undefined
}

interface Props {
  payRunPage: PayRunPage
  onFilterChange: (value: string) => void
  onPageChange: (pageIndex: number) => void
  columns: ColumnDefinition[]
}

const PayRunList = ({payRunPage, onFilterChange, onPageChange, columns}: Props) => {
  const [selectedPayRun, setSelectedPayRun] = useState<PayRunModel>()
  const styles = useStyles()

  const renderTitle = () => {
    return (
      <Card className={styles.card}>
        <div className={styles.column}>
          <strong>Name</strong>
        </div>
        {
          columns.map((column, index) => (
            <div key={index} className={styles.column}>
              <strong>{column.title}</strong>
            </div>
          ))
        }
      </Card>
    )
  }

  const renderListItems = () => {
    return payRunPage.payRuns.map((item, index) => {
      return (
        <Card
          className={`${styles.card} card`}
          key={`payDeviation_${index}`}
          onClick={() => setSelectedPayRun(item)}>
          <div className={styles.column}>
            {`${item.userGivenName ?? '-'} ${item.userFamilyName ?? '-'}`}
          </div>
          {
            columns.map((column, index) => (
              <div key={index} className={styles.column}>
                {column.accessor(item) ?? '-'}
              </div>
            ))
          }
        </Card>
      )
    })
  }

  return (
    <>
      <div className={styles.container}>
        <InputField
          id="userSearchField"
          placeholder="Filter pay runs..."
          value={payRunPage.searchTerm}
          onEnterKeyOrBlur={value => onFilterChange(value)}
        />
        {
          payRunPage.error &&
            <Alert variant="danger">
              {payRunPage.error instanceof Error ? payRunPage.error.message : payRunPage.error}
            </Alert>
        }
        {renderTitle()}
        {renderListItems()}
        <div className={styles.pagination}>
          <Pagination
            pageIndex={payRunPage.pageIndex}
            itemCount={payRunPage.totalCount}
            pageSize={payRunPage.pageSize}
            siblingCount={1}
            onPageChange={onPageChange}
          />
        </div>
      </div>
      <PayRunModal
        payRun={selectedPayRun}
        visible={selectedPayRun !== undefined}
        onCloseClick={() => setSelectedPayRun(undefined)}/>
    </>
  )
}

export default PayRunList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    },
    gap: 10
  },
  column: {
    flex: 1
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center'
  }
})
