import { Line } from "react-chartjs-2"
import { Colors } from "../../constants/colors"
import { ProtectZScoreResult } from "../../redux/types"
import { formatDateString } from "../../utils/dateHelper"
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

interface Props {
    triggerValue: number,
    ruleData: ProtectZScoreResult
}

const ZScoreChart = ({ triggerValue, ruleData } : Props) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      );

    const renderGraph = () => {
        if (!ruleData?.historicalResults) {
            return
        }

        var dates = ruleData.historicalResults.map(x => { return formatDateString(x.date) })
        dates.push(formatDateString(ruleData.currentResult.date))

        var values = ruleData.historicalResults.map(x => { return x.value })
        values.push(ruleData.currentResult.value)

        var zScores = ruleData.historicalResults.map(x => { return x.zScore })
        zScores.push(ruleData.currentResult.zScore)

        var triggerValues = dates.map(x => { return triggerValue })

        const data = {
        labels: dates,
        datasets: [
            {
            label: 'Z score trigger value',
            data: triggerValues,
            borderColor: Colors.rubel5,
            backgroundColor: Colors.rubel5,
            yAxisID: 'y',
            pointRadius: 0
            },
            {
            label: 'Z score',
            data: zScores,
            borderColor: Colors.euro_600,
            backgroundColor: Colors.euro_600,
            yAxisID: 'y',
            }, {
            label: 'Value',
            data: values,
            borderColor: Colors.darkGray5,
            backgroundColor: Colors.darkGray5,
            yAxisID: 'y1',
            }
        ]};

        const options = {
            responsive: true,
            interaction: {
                mode: 'index' as const,
                intersect: false,
            },
            stacked: false,
            plugins: {
                title: {
                    display: true,
                    text: 'Z score and value over time',
                    color: Colors.darkGray1
                },
                legend: {
                    display: true,
                    labels: {
                        color: Colors.darkGray1
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: Colors.darkGray1
                    }
                },
                y: {
                    type: 'linear' as const,
                    display: true,
                    position: 'left' as const,
                    ticks: {
                        color: Colors.darkGray1
                    }
                },
                y1: {
                    type: 'linear' as const,
                    display: true,
                    position: 'right' as const,
                    grid: {
                        drawOnChartArea: false,
                    },
                    ticks: {
                        color: Colors.darkGray1
                    }
                }
            }
        }

        return (
            <Line data={data} options={options} />  
        )
    }

    return (
        <>
        {renderGraph()}
        </>
    )
}

export default ZScoreChart