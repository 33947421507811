import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {CompanyCheckResponseModel} from '../companyMonitorTypes'

interface CompanyCheckState {
  loading: boolean
  latestCompanyChecks: CompanyCheckResponseModel[]
  error: Error | string | null
}

const initialState: CompanyCheckState = {
  loading: false,
  latestCompanyChecks: [],
  error: null
}

export const companyCheckSlice = createSlice({
  name: 'companyCheck',
  initialState,
  reducers: {
    loading: state => {
      state.loading = true
      state.error = null
    },
    latestCompanyChecksFetched: (state, action: PayloadAction<CompanyCheckResponseModel[]>) => {
      state.loading = false
      state.latestCompanyChecks = action.payload
      state.error = null
    },
    latestCompanyChecksFetchFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
    newCompanyCheckFetched : (state, action: PayloadAction<CompanyCheckResponseModel>) => {
      state.loading = false
      state.latestCompanyChecks = [action.payload, ...state.latestCompanyChecks]
      state.error = null
    },
    newCompanyCheckFetchFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    }
  }
})

export const {
  loading,
  latestCompanyChecksFetched,
  latestCompanyChecksFetchFailure,
  newCompanyCheckFetched,
  newCompanyCheckFetchFailure
} = companyCheckSlice.actions

export default companyCheckSlice.reducer
