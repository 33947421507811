import { useEffect, useState } from 'react'
import Card from '../components/Card'
import CardSection from '../components/CardSection'
import DetailsCard from '../components/DetailsCard'
import { createUseStyles } from 'react-jss'
import { titleMarginBottom } from '../constants/layout'
import { formatDateTimeString } from '../utils/dateHelper'
import Button from '../components/Button'
import ConfirmModal from '../components/ConfirmModal'
import useNordeaIntegrationActions from '../hooks/useNordeaIntegrationActions'
import { useAppSelector } from '../redux/hooks'
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator'
import { roundToMaxTwoDecimals } from '../utils/numberHelper'
import { BankTransferState, FrozenBankTransfer } from '../redux/types'
import SpecifyAuthorizerIdModal from '../components/Nordea/SpecifyAuthorizerIdModal'
import SelectSigningKeyModal from '../components/Nordea/SelectSigningKeyModal'
import useBankAccountActions from '../hooks/useBankAccountActions'
import useMarketActions from '../hooks/useMarketActions'

const Nordea = () => {
  const styles = useStyles()

  const [confirmNewAccessTokenVisible, setConfirmNewAccessTokenVisible] = useState(false)
  const [confirmNewSigningTokenVisible, setConfirmNewSigningTokenVisible] = useState(false)
  const [specifyAuthorizerIdModalVisible, setSpecifyAuthorizerIdModalVisible] = useState(false)
  const [selectSigningKeyModalVisible, setSelectSigningKeyModalVisible] = useState(false)

  const [authorizationOperation, setAuthorizationOperation] = useState<'access' | 'defaultSigning' | 'additionalSigning'>()
  const [authorizerId, setAuthorizerId] = useState<string>()
  const [verifyTransferCandidate, setVerifyTransferCandidate] = useState<FrozenBankTransfer>()

  const { fetchTokens, fetchFrozenTransfers, initiateAccessToken, initiateSigningKey, initiatePaymentVerification } = useNordeaIntegrationActions()
  const { requestBankAccountTransactionSync } = useBankAccountActions()
  const { getMarkets } = useMarketActions()

  const loading = useAppSelector(state => state.nordeaToken.loading)
  const bankAccountsLoading = useAppSelector(state => state.bankAccount.loading)
  const tokens = useAppSelector(state => state.nordeaToken.tokens)
  const frozenTransfers = useAppSelector(state => state.nordeaToken.frozenTransfers)
  const error = useAppSelector(state => state.nordeaToken.error)
  const markets = useAppSelector(state => state.market.markets)

  useEffect(() => {
    if (tokens.length === 0) {
      fetchTokens()
    }

    if (frozenTransfers.length === 0) {
      fetchFrozenTransfers()
    }

    if (markets.length === 0)
      getMarkets()

  }, [])

  const handleOnConfirmNewAccessToken = () => {
    if (authorizerId) {
      initiateAccessToken(authorizerId)
    }
    setAuthorizerId(undefined)
    setConfirmNewAccessTokenVisible(false)
  }

  const handleOnConfirmNewSigningToken = () => {
    if (authorizerId && authorizationOperation) {
      if (authorizationOperation === 'defaultSigning') {
        initiateSigningKey(authorizerId, true)
      } else if (authorizationOperation === 'additionalSigning') {
        initiateSigningKey(authorizerId, false)
      } 
    }
    setAuthorizerId(undefined)
    setAuthorizationOperation(undefined)
    setConfirmNewSigningTokenVisible(false)
  }

  const handleAuthorizerIdSpecified = (authorizerId: string) => {
    setAuthorizerId(authorizerId)
    setSpecifyAuthorizerIdModalVisible(false)

    if (authorizationOperation === 'access') {
      setConfirmNewAccessTokenVisible(true)
    } else if (authorizationOperation === 'defaultSigning' || authorizationOperation === 'additionalSigning') {
      setConfirmNewSigningTokenVisible(true)  
    }
  }

  const handleSyncTransactionsButtonClick = () => {
    requestBankAccountTransactionSync(markets[0].id) // todo: support selecting which market to sync when more than one market
  }

  const handleInitiateAccessButtonClick = () => {
    setAuthorizationOperation('access')
    setSpecifyAuthorizerIdModalVisible(true)
  }

  const handleInitiateDefaultSigningButtonClick = () => {
    setAuthorizationOperation('defaultSigning')
    setSpecifyAuthorizerIdModalVisible(true)
  }

  const handleInitiateAdditionalSigningButtonClick = () => {
    setAuthorizationOperation('additionalSigning')
    setSpecifyAuthorizerIdModalVisible(true)
  }

  const handleVerifyTransferButtonClick = (transfer: FrozenBankTransfer) => {
    setVerifyTransferCandidate(transfer)
    setSelectSigningKeyModalVisible(true)
  }

  const handleSigningKeySelected = (signingKey: string) => {
    if (verifyTransferCandidate) {
      initiatePaymentVerification(verifyTransferCandidate?.identifier, signingKey)
    }

    setVerifyTransferCandidate(undefined)
    setSelectSigningKeyModalVisible(false)
  }

  const renderTokenHeader = () => {
    return (
      <Card className={styles.listContainer}>
        <div className={styles.listCell}>
          <p>Key</p>
        </div>
        <div className={styles.listCell}>
          <p>Description</p>
        </div>
        <div className={styles.listCell}>
          <p>Expires</p>
        </div>
      </Card>
    )
  }

  const renderTokens = () => {
    return tokens?.map((token, index) => {
      return (
        <Card className={styles.listContainer} key={`token${index}`}>
          <div className={styles.listCell}>
            <p><span>{token.key}</span></p>
          </div>
          <div className={styles.listCell}>
            <p><span>{token.description}</span></p>
          </div>
          <div className={styles.listCell}>
            <p><span>{token.expires ? formatDateTimeString(token.expires) : 'Never'}</span></p>
          </div>
        </Card>
      )
    })
  }

  const renderFrozenTransfersHeader = () => {
    return (
      <Card className={styles.listContainer}>
        <div className={styles.idCell}>
          <p>Identifier</p>
        </div>
        <div className={styles.listCell}>
          <p>Amount</p>
        </div>
        <div className={styles.listCell}>
          <p>From</p>
        </div>
        <div className={styles.listCell}>
          <p>To</p>
        </div>
        <div className={styles.listCell}>
          <p>State</p>
        </div>
        <div className={styles.buttonCell}>
        </div>
      </Card>
    )
  }

  const renderFrozenTransfers = () => {
    return frozenTransfers?.map((transfer, index) => {
      return (
        <Card className={styles.listContainer} key={`token${index}`}>
          <div className={styles.idCell}>
            <p><span>{transfer.identifier}</span></p>
          </div>
          <div className={styles.listCell}>
            <p><span>{roundToMaxTwoDecimals(transfer.amount?.amount).toLocaleString('sv-SE')} {transfer.amount.currencyCode.toUpperCase()}</span></p>
          </div>
          <div className={styles.listCell}>
            <p><span>{transfer.fromAccountBban}</span></p>
          </div>
          <div className={styles.listCell}>
            <p><span>{transfer.toAccountBban}</span></p>
          </div>
          <div className={styles.listCell}>
            <p><span>{transfer.state}</span></p>
          </div>
          <div className={styles.buttonCell}>
           { renderVerifyTransferButton(transfer) } 
          </div>
        </Card>
      )
    })
  }

  const renderVerifyTransferButton = (transfer: FrozenBankTransfer) => {
    if (transfer.state === BankTransferState.PaymentVerificationRequired) {
      return (
        <Button
            onClick={() => handleVerifyTransferButtonClick(transfer) }
            title='Verify'
        />) 
    }  
  }

  return (
    <div className={styles.container}>
       <h1>Nordea integration</h1>
      <div className={styles.actionBar}>
        <Button title='Sync transactions' onClick={handleSyncTransactionsButtonClick} />
        <Button title='Initiate access' onClick={handleInitiateAccessButtonClick} /> 
        <Button title='Initiate default signing key' onClick={handleInitiateDefaultSigningButtonClick} />
        <Button title='Initiate additional signing key' onClick={handleInitiateAdditionalSigningButtonClick} />
      </div>
      <CardSection>
        <DetailsCard>
          <h6>Tokens</h6>
          {renderTokenHeader()}
          {renderTokens()}
        </DetailsCard>
      </CardSection>
      <CardSection>
        <DetailsCard>
          <h6>Frozen transfers</h6>
          {renderFrozenTransfersHeader()}
          {renderFrozenTransfers()}
        </DetailsCard>
      </CardSection>
      <ConfirmModal  
        key='confirmAccessToken'    
        visible={confirmNewAccessTokenVisible}
        confirmText={`Are you sure you want to initiate a new access token? Don't do this unless you are sure about what you are doing!`}
        onConfirmClick={handleOnConfirmNewAccessToken}
        onCancelClick={() => setConfirmNewAccessTokenVisible(false)}
      />
      <ConfirmModal      
        key='confirmSigningKey'    
        visible={confirmNewSigningTokenVisible}
        confirmText={`Are you sure you want to initiate a new signing token? Don't do this unless you are sure about what you are doing!`}
        onConfirmClick={handleOnConfirmNewSigningToken}
        onCancelClick={() => setConfirmNewSigningTokenVisible(false)}
      />
      <SpecifyAuthorizerIdModal 
        visible={specifyAuthorizerIdModalVisible}
        onCancel={() => setSpecifyAuthorizerIdModalVisible(false)}
        onAuthorizerIdSpecified={handleAuthorizerIdSpecified}
      />
      <SelectSigningKeyModal 
        visible={selectSigningKeyModalVisible}
        onSigningTokenSelected={handleSigningKeySelected}
        onCancel={() => setSelectSigningKeyModalVisible(false)}
        tokens={tokens}
      />
      <FullScreenLoadingIndicator visible={loading || bankAccountsLoading} />
    </div>
  )
}

export default Nordea

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    }
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    transition: '0.4s'
  },
  idCell: {
    display: 'flex',
    width: 270
  },
  listCell: {
    display: 'flex',
    width: 150
  },
  buttonCell: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  actionBar: {
    display: 'flex',
    marginTop: -40,
    flexDirection: 'row',
    columnGap: 10,
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
})