import {useState, ChangeEvent} from 'react'
import Modal from '../../Modal'
import InputField from '../../InputField'

interface Props {
  title: string
  companyChangeId?: string
  onSaveClick?: (comment: string) => void
  onCancelClick?: () => void
  onHideClick?: () => void
  visible: boolean
}

const CompanyReportChangeHandlingModal = (props: Props) => {
  const [comment, setComment] = useState('')
  const [isCommentValid, setIsCommentValid] = useState<boolean>(true)

  const validateBeforeSave = () => {
    if (comment && comment.trim()) {
      setIsCommentValid(true);
      handleOnSave()
    } else {
      setIsCommentValid(false);
      return
    }
  }

  const handleOnSave = () => {
    props.onSaveClick && props.onSaveClick(comment)
    setComment('')
  }

  const handleOnCancel = () => {
    props.onCancelClick && props.onCancelClick()
  }

  const handleOnHide = () => {
    props.onHideClick && props.onHideClick()
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value)
  }

  return (
    <Modal
      title={props.title}
      show={props.visible}
      onHide={() => handleOnHide()}
      onOkClick={() => validateBeforeSave()}
      onCancelClick={() => handleOnCancel()}
    >
      <InputField
        id={`companyReportChangeComment${props.companyChangeId}`}
        value={comment}
        valid={isCommentValid}
        onChange={handleOnChange}
        placeholder='Enter your comment here'
        label='Comment'
      />
    </Modal>
  )
}

export default CompanyReportChangeHandlingModal
