import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Breakpoints } from '../../constants/breakpoints'
import { Colors } from '../../constants/colors'

interface S2Props {
  children: string
  className?: string
  bold?: boolean
}

const S2: FC<S2Props> = ({ children, className, bold }) => {
  const useStyles = createUseStyles({
    text: {
      fontFamily: bold ? 'Aestetico-Bold' : 'Aestetico-Regular',
      color: Colors.darkGray2,
      fontSize: 16,
      [`@media (min-width: ${Breakpoints.tablet}px)`]: {
        fontSize: 18,
      }
    },
  })

  const classes = useStyles()

  return (
    <h5 className={`${classes.text} ${className}`}>{children}</h5>
  )
}

export default S2
