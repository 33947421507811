import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Campaign} from '../types'

interface CampaignState {
  loading: boolean
  campaign: Campaign | null
  campaignsOnPage: Campaign[]
  error: Error | string | null
}

const initialState: CampaignState = {
  loading: false,
  campaign: null,
  campaignsOnPage: [],
  error: null
}

export const campaignSlice = createSlice({
  name: 'campaignSlice',
  initialState,
  reducers: {
    loading: state => {
      state.loading = true
      state.error = null
    },
    campaignFetched: (state, action: PayloadAction<Campaign>) => {
      state.loading = false
      state.campaign = action.payload
      state.error = null
    },
    campaignFetchFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
    campaignsFetched: (state, action: PayloadAction<Campaign[]>) => {
      state.loading = false
      state.campaignsOnPage = action.payload
      state.error = null
    },
    campaignsFetchFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
    campaignCreated: (state, action: PayloadAction<Campaign>) => {
      state.loading = false
      state.campaign = action.payload
      state.error = null

      const campaignsOnPage = [...state.campaignsOnPage ?? []]
      campaignsOnPage.push(action.payload)
      state.campaignsOnPage = campaignsOnPage
    },
    campaignCreateFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
    campaignUpdated: (state, action: PayloadAction<Campaign>) => {
      state.loading = false
      state.campaign = action.payload
      state.error = null

      let index = state.campaignsOnPage?.findIndex((item) => {
        return item.id === action.payload.id
      })

      if (index === -1)
        return

      const campaignsOnPage = [...state.campaignsOnPage ?? []]
      campaignsOnPage[index as number] = action.payload
      state.campaignsOnPage = campaignsOnPage
    },
    campaignUpdateFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    }
  }
})

export const {
  loading,
  campaignsFetched,
  campaignsFetchFailure,
  campaignFetched,
  campaignFetchFailure,
  campaignCreated,
  campaignCreateFailure,
  campaignUpdated,
  campaignUpdateFailure
} = campaignSlice.actions

export default campaignSlice.reducer