import {Language, MultilingualString} from "../../redux/types";
import {createUseStyles} from "react-jss";
import {Colors} from "../../constants/colors";
import TextAreaField from "../TextAreaField";
import {useState} from "react";

interface Props {
  id?: string
  label: string
  translations: MultilingualString | undefined | null
  className?: string
  onChange: (translations: MultilingualString) => void
  onLanguageChange?: (language: Language) => void
}

const MultilingualTextAreaField = ({
                                     id = "multilingual",
                                     label,
                                     translations,
                                     className,
                                     onChange,
                                     onLanguageChange
                                   }: Props) => {
  const styles = useStyles()
  const [currentEditLanguage, setCurrentEditLanguage] = useState<Language>()

  const handleOnChange = (language: Language, value: any) => {
    if (currentEditLanguage !== language) {
      setCurrentEditLanguage(language)
      onLanguageChange && onLanguageChange(language)
    }
    onChange({...translations, [language]: value})
  }


  return (
    <div>
      <h5>{label}</h5>
      <div className={styles.container}>
        {
          Object.keys(Language).map(key => {
            const translation = translations ? translations[key as Language] : ''
            return (
              <TextAreaField
                id={`${id}_${key}`}
                key={`${label}_${key}`}
                value={translation || ''}
                onChange={(event) => {
                  handleOnChange(key as Language, event.target.value)
                }}
                label={key}
                className={className}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default MultilingualTextAreaField

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 4,
    borderRadius: 8,
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: Colors.gray3,
  }
})
