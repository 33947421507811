import { createUseStyles } from 'react-jss'
import { titleMarginBottom } from '../constants/layout'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import usePaydayActions from '../hooks/usePaydayActions'
import { useEffect } from 'react'
import PaydayListFilter from '../components/PaydayList/PaydayListFilter'
import { filterChanged, pageIndexChanged } from '../redux/slices/paydaySlice'
import { PaydayFilterParams } from '../redux/types'
import useDidUpdateEffect from '../hooks/useDidUpdateEffect'
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator'
import PaydayList from '../components/PaydayList/PaydayList'

const Paydays = () => {
  const styles = useStyles()
  const dispatch = useAppDispatch()

  const { getPaydays } = usePaydayActions()

  const loading = useAppSelector(state => state.payday.loading)
  const paydaysOnPage = useAppSelector(state => state.payday.paydaysOnPage)
  const totalCount = useAppSelector(state => state.payday.totalCount)
  const pageIndex = useAppSelector(state => state.payday.pageIndex)
  const pageSize = useAppSelector(state => state.payday.pageSize)
  const filter = useAppSelector(state => state.payday.filter)

  useEffect(() => {
    if (paydaysOnPage.length === 0) {
      getPaydays(null, false, pageIndex, pageSize)
    }
  }, [])

  useDidUpdateEffect(() => {
    getPaydays(filter?.employerId ?? null, filter?.includeCompletedPaydays ?? false, pageIndex, pageSize)
  }, [pageIndex, filter])

  const onPageChange = (pageIndex: number) => {
    dispatch(pageIndexChanged(pageIndex))
  }

  const onFilterChange = (value: PaydayFilterParams) => {
    dispatch(filterChanged(value))
  }

  return (
    <div className={styles.container}>
      <h1>Paydays</h1>
      <PaydayListFilter 
        filterValue={filter || {employerId: null, employerName: null, includeCompletedPaydays: false}}
        onFilterChange={onFilterChange}
      /> 
      <PaydayList
        paydaysOnPage={paydaysOnPage}
        pageIndex={pageIndex}
        pageSize={pageSize} 
        totalCount={totalCount}
        onPageChange={onPageChange} 
      />
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default Paydays

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    }
  }
})
