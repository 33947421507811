import { useState } from "react"
import { PaydayFilterParams } from "../../redux/types"
import CardSection from "../CardSection"
import DetailsCard from "../DetailsCard"
import EmployerSelector from "../EmployerSelector"
import { SearchResultItem } from "../SearchAndSelectField"
import Switch from "../Switch"

interface Props {
    filterValue: PaydayFilterParams
    onFilterChange: (value: PaydayFilterParams) => void
}

const PaydayListFilter = ({filterValue, onFilterChange}: Props) => {
    const [includeCompleted, setIncludeCompleted] = useState(filterValue.includeCompletedPaydays)

    const handleIncludeCompletedChange = () => {
        var newValue = !filterValue.includeCompletedPaydays
        setIncludeCompleted(newValue)
        onFilterChange({...filterValue, includeCompletedPaydays: newValue})
    }
    
    const handleEmployerSelected = (selectedEmployer: SearchResultItem | null) => {
        onFilterChange({...filterValue, employerId: selectedEmployer?.value || null, employerName: selectedEmployer?.name ?? null})
    }

    return (
        <CardSection>
            <DetailsCard>
            <EmployerSelector
                onEmployerSelected={handleEmployerSelected} 
                value={filterValue.employerName || ''}
            />
            <Switch 
                id='includeCompleted'
                key='includeCompleted'
                checked={includeCompleted} 
                label='Include completed paydays' 
                onChange={() => handleIncludeCompletedChange()} 
            /> 
            </DetailsCard>
        </CardSection>
    )
}

export default PaydayListFilter