import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AmlFlaggedCompanyRepresentativeListItemModel, CompanyRepresentativeAmlDetailsModel } from '../companyMonitorTypes'

interface AmlFlaggedCompanyRepresentativeState {
    loading: boolean
    representativeDetails: CompanyRepresentativeAmlDetailsModel | null
    representatives: AmlFlaggedCompanyRepresentativeListItemModel[]
    error: Error | string | null
  }

const initialState: AmlFlaggedCompanyRepresentativeState = {
    loading: false,
    representativeDetails: null,
    representatives: [],
    error: null
}

export const amlFlaggedCompanyRepresentativeSlice = createSlice({
    name: 'amlFlaggedCompanyRepresentative',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        amlFlaggedRepresentativesFetched: (state, action: PayloadAction<AmlFlaggedCompanyRepresentativeListItemModel[]>) => {
            state.loading = false
            state.representatives = action.payload
            state.error = null
        },
        amlFlaggedRepresentativesFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        amlFlaggedRepresentativeDetailsFetched: (state, action: PayloadAction<CompanyRepresentativeAmlDetailsModel>) => {
            state.loading = false
            state.representativeDetails = action.payload
            state.error = null
        },
        amlFlaggedRepresentativeDetailsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        amlFlaggedRepresentativeAccepted: (state, action: PayloadAction<CompanyRepresentativeAmlDetailsModel>) => {
            state.loading = false
            state.representativeDetails = action.payload
            state.representatives = []
            state.error = null
        },
        amlFlaggedRepresentativeAcceptFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        }
    }
})

export const { 
    loading,
    amlFlaggedRepresentativesFetched, 
    amlFlaggedRepresentativesFetchFailure, 
    amlFlaggedRepresentativeDetailsFetched, 
    amlFlaggedRepresentativeDetailsFetchFailure, 
    amlFlaggedRepresentativeAccepted, 
    amlFlaggedRepresentativeAcceptFailure
} = amlFlaggedCompanyRepresentativeSlice.actions

export default amlFlaggedCompanyRepresentativeSlice.reducer