export const createUrlWithQueryString = (url: string, queryStringParams: any) => {
    const uriEncoder = encodeURIComponent
    
    const queryString = Object.keys(queryStringParams)
      .map(key => {
        const value = queryStringParams[key]
        if (value) 
            return uriEncoder(key) + '=' + uriEncoder(queryStringParams[key])
        return ''
      })
      .filter(val => val)
      .join('&')

    return url + '?' + queryString
  }