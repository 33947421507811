import {faArrowLeft} from "@fortawesome/free-solid-svg-icons"
import {useEffect, useState} from "react"
import {createUseStyles} from "react-jss"
import {Link, useHistory, useParams} from "react-router-dom"
import Button from "../components/Button"
import CardSection from "../components/CardSection"
import ConfirmModal from "../components/ConfirmModal"
import DetailsCard from "../components/DetailsCard"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import TextAreaField from "../components/TextAreaField";
import NavLink from "../components/NavLink"
import {titleMarginBottom} from "../constants/layout"
import useProtectIssueActions from "../hooks/useProtectIssueActions"
import useProtectCustomerActions from "../hooks/useProtectCustomerActions"
import {useAppSelector} from "../redux/hooks"
import {formatDateTimeString} from "../utils/dateHelper"
import {UpdateProtectIssueModel} from "../redux/types";
import Select from "../components/Select";

const ProtectIssueDetails = () => {
  const styles = useStyles()
  const params = useParams<{ id: string }>()
  const history = useHistory();
  const {getIssue, updateIssue} = useProtectIssueActions()
  const {getProtectCustomers} = useProtectCustomerActions()

  const loading = useAppSelector(state => state.protectIssues.loading)
  const issueDetails = useAppSelector(state => state.protectIssues.issueDetails)
  const customers = useAppSelector(state => state.protectCustomer.allCustomers);

  const [investigation, setInvestigation] = useState<UpdateProtectIssueModel>()
  const [confirmCloseModalVisible, setConfirmCloseModalVisible] = useState(false)

  useEffect(() => {
    if (issueDetails == null || issueDetails.id !== params.id) {
      getIssue(params.id)
    }

    if (!customers || customers.length === 0) {
      getProtectCustomers()
    }

  })

  useEffect(() => {
    if (issueDetails) {
      setInvestigation({
        customerId: issueDetails.customerId,
        investigationResult: issueDetails.investigationResult,
        closed: issueDetails.closed
      })
    }
  }, [issueDetails])

  const saveAndCloseIssue = () => {
    setConfirmCloseModalVisible(true)
  }

  const onCloseIssueConfirmed = () => {
    if (issueDetails && investigation) {
      let closedInvestigation = {...investigation, closed: true}
      updateIssue(issueDetails?.id, closedInvestigation)
    }
    setConfirmCloseModalVisible(false)
  }

  const saveIssue = () => {
    if (issueDetails && investigation)
      updateIssue(issueDetails?.id, investigation)
  }

  const handleOnChange = (propName: string, value: any) => {
    if (investigation) {
      setInvestigation({...investigation, [propName]: value === '' ? null : value})
    }
  }

  const getCustomerOptions = () => {
    return [{name: "Unknown", value: ''}].concat(
      customers.map(x => {
        return {name: x.name, value: x.id}
      })
    )
  }

  const renderInvestigationResult = () => {
    if (issueDetails?.closed) {
      return (
        <div>
          <p>Result: <span>{issueDetails.investigationResult}</span></p>
          <p>Closed at: <span>{formatDateTimeString(issueDetails.closedDate ?? undefined)}</span></p>
        </div>)
    }
  }

  const renderInvestigationContainer = () => {
    if (issueDetails && !issueDetails?.closed) {
      return (
        <div className={styles.investigationContainer}>
          <TextAreaField
            id='investigationResult'
            value={investigation?.investigationResult ?? ''}
            onChange={(event) => handleOnChange('investigationResult', event.target.value)}
            placeholder='Enter your investigation result here'
            label=''
          />
          {issueDetails.isGlobalRule &&
          <Select
            id='customer'
            key='customer'
            label='Customer'
            options={getCustomerOptions()}
            selectedValue={issueDetails.customerId || ''}
            onSelect={(value) => handleOnChange('customerId', value)}
          />}
          <div className={styles.buttonContainer}>
            <Button title={'Save and close'} onClick={saveAndCloseIssue}
                    disabled={!investigation?.customerId || !investigation?.investigationResult}/>
            <Button title={'Save'} onClick={saveIssue} disabled={!investigation?.investigationResult}/>
          </div>
        </div>
      )
    }
  }

  return (
    <div className={styles.container}>
      <h1>{issueDetails && `${issueDetails.customerName || issueDetails.ruleName} - ${formatDateTimeString(issueDetails.createdDate)}`}</h1>
      <div className={styles.actionBar}>
        <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()}/>
      </div>
      <CardSection>
        <DetailsCard>
          <h6>Details</h6>
          <p>Customer: <span>{issueDetails?.customerName ?? 'Unknown'}</span></p>
          <p>Description: <span>{issueDetails?.description}</span></p>
          <Link to={{pathname: `/protect/rules/${issueDetails?.ruleId}`}}><p>Rule: <span>{issueDetails?.ruleName}</span>
          </p></Link>
          <p>State: <span>{issueDetails?.closed ? 'Closed' : 'Open'}</span></p>
        </DetailsCard>
      </CardSection>
      <CardSection>
        <DetailsCard>
          <h6>Investigation</h6>
          {renderInvestigationResult()}
          {renderInvestigationContainer()}
        </DetailsCard>
      </CardSection>
      <FullScreenLoadingIndicator visible={loading}/>
      <ConfirmModal
        visible={confirmCloseModalVisible}
        confirmText={'Are you sure you want to close this issue?'}
        onConfirmClick={onCloseIssueConfirmed}
        onCancelClick={() => setConfirmCloseModalVisible(false)}
      />
    </div>
  )
}

export default ProtectIssueDetails

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    }
  },
  actionBar: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  investigationContainer: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
    alignItems: 'right'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    columnGap: 10
  }
})