import Card from '../../Card'
import {createUseStyles} from 'react-jss'
import {Colors} from '../../../constants/colors'
import { formatDateString } from '../../../utils/dateHelper'
import { CompanyEventModel } from '../../../redux/companyMonitorTypes';

interface Props {
  companyEvents: CompanyEventModel[]
}

const CompanyEventList = ({companyEvents}: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        <div className={styles.cell}>
          <p><span>Date</span></p>
        </div>
        <div className={styles.cell}>
          <p><span>Change</span></p>
        </div>
      </Card>
    )
  }

  const renderListItems = () => {
    return companyEvents?.map((item, index) => {
      return (
        <Card className={`${styles.container} card`} key={`change_${index}`}>
          <div className={styles.cell}>
            <p>{formatDateString(item.eventDate)}</p>
          </div>
          <div className={styles.cell}>
            <p>{item.ruleName}: {item.oldValue && <span><b>{item.oldValue}</b></span>} {(item.oldValue || item.newValue) && <span>{'->'}</span>} {item.newValue && <span><b>{item.newValue}</b></span>}</p>
          </div>
        </Card>
      )
    })
  }

  return (
    <div>
      {renderHeader()}
      {renderListItems()}
    </div>
  )
}

export default CompanyEventList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 3px',
    margin: '5px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    margin: '30px 0 20px 0',
  },
  '& p': {
    fontSize: 12,
    margin: 0,
    '& span': {
      fontFamily: 'Aestetico-Bold',
      color: Colors.euro_600,
    }
  },
  flagged: {
    backgroundColor: Colors.red,
  },
  cell: {
    flex: 1,
    textAlign: 'left',
    padding: '0px 10px'
  },
  smallCell: {
    textAlign: 'center',
    padding: '0px 10px'
  },
  rightCell: {
    justifySelf: 'right',
    textAlign: 'left',
    padding: '0px 10px'
  },
})
