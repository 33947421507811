import {
  InvoicePayoutListItemModel, InvoicePayoutModel,
} from '../redux/types';
import useFetchClient from "./useFetchClient";
import { useAppDispatch } from "../redux/hooks";
import { restConfig } from "../authConfig";
import { createUrlWithQueryString } from '../utils/url';
import { getPaginationDataFromResponse } from '../utils/responseHelper';
import { loading, invoicePayoutsFetchFailure, invoicePayoutsFetched, invoicePayoutDetailsFetched } from '../redux/slices/invoicePayoutSlice';
import { invoicePayoutProviderDetailsFetchFailure } from '../redux/slices/invoicePayoutProviderSlice';

const useInvoicePayoutActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getInvoicePayouts = (invoicePayoutProviderId: string, searchTerm: string, pageIndex: number, pageSize: number) => {
    dispatch(loading())

    const params = {
      query: searchTerm,
      pageNumber: pageIndex,
      pageSize: pageSize
    }
    const url = createUrlWithQueryString(restConfig.routes.invoicePayoutProviders + `/${invoicePayoutProviderId}/payouts`, params)

    return fetchClient.get(url)
      .then(response => {
          const paginationData = getPaginationDataFromResponse(response.raw)
          dispatch(invoicePayoutsFetched({ items: response.data as InvoicePayoutListItemModel[], totalCount: paginationData?.totalItemCount ?? 0}))
      })
      .catch(error => {
          console.log('getInvoicePayouts error:', error)
          dispatch(invoicePayoutsFetchFailure(error))
      })
  }

  const getInvoicePayoutDetailsById = (invoicePayoutProviderId: string, id: string) => {
    dispatch(loading())   

    const url = restConfig.routes.invoicePayoutProviders + `/${invoicePayoutProviderId}/payouts/${id}`

    return fetchClient.get(url)
      .then(response => {
          dispatch(invoicePayoutDetailsFetched(response.data as InvoicePayoutModel))
      })
      .catch(error => {
          console.log('getInvoicePayoutDetailsById error:', error)
          dispatch(invoicePayoutProviderDetailsFetchFailure(error))
      })
  }

  const getInvoicePayoutDetailsByRepaymentId = (invoicePayoutProviderId: string, repaymentId: string) => {
    dispatch(loading())   

    const url = restConfig.routes.invoicePayoutProviders + `/${invoicePayoutProviderId}/repayments/${repaymentId}`

    return fetchClient.get(url)
      .then(response => {
          dispatch(invoicePayoutDetailsFetched(response.data as InvoicePayoutModel))
      })
      .catch(error => {
          console.log('getInvoicePayoutDetailsByRepaymentId error:', error)
          dispatch(invoicePayoutProviderDetailsFetchFailure(error))
      })
  }

  return {
    getInvoicePayouts,
    getInvoicePayoutDetailsById,
    getInvoicePayoutDetailsByRepaymentId
  }
}

export default useInvoicePayoutActions
