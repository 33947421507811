import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EmployeeAndPaydayFilterParams, PagedListItems, ShiftModel } from '../types'

interface ShiftState {
    loading: boolean
    shiftsOnPage: ShiftModel[]
    pageIndex: number
    totalCount: number
    pageSize: number 
    employeeId: string | null
    paydayId: string | null
    error: Error | string | null
  }

const initialState: ShiftState = {
    loading: false,
    shiftsOnPage: [],
    pageIndex: 1,
    totalCount: 0,
    pageSize: 20,
    employeeId: null,
    paydayId: null,
    error: null
}

export const shiftSlice = createSlice({
    name: 'shift',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        shiftsFetched: (state, action: PayloadAction<PagedListItems<ShiftModel>>) => {
            state.loading = false
            state.shiftsOnPage = action.payload.items
            state.totalCount = action.payload.totalCount
            state.error = null
        },
        shiftsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        filterChanged: (state, action: PayloadAction<EmployeeAndPaydayFilterParams>) => {
            state.employeeId = action.payload.employeeId
            state.paydayId = action.payload.paydayId
            state.pageIndex = 1
        },
    }
})

export const { 
    loading, 
    shiftsFetched, 
    shiftsFetchFailure, 
    pageIndexChanged, 
    filterChanged 
} = shiftSlice.actions

export default shiftSlice.reducer