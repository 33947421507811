import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {useEffect} from "react";
import useFetchClient from "./useFetchClient";
import {loading, metricsFetched, metricsFetchFailure} from "../redux/slices/dashboardMetricsSlice";
import {restConfig} from "../authConfig";
import {DashboardMetrics} from "../redux/types";

const useDashboardMetrics = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getMetrics = () => {
    dispatch(loading())
    return fetchClient.get(restConfig.routes.dashboardMetrics)
      .then(response => {
        dispatch(metricsFetched(response.data as DashboardMetrics))
      })
      .catch(error => {
        console.log('getMetrics error:', error)
        dispatch(metricsFetchFailure(error))
      })
  }

  useEffect(() => {
    getMetrics()
  }, [])

  const dashboardMetrics = useAppSelector(state => state.dashboardMetrics)

  return {
    activityKpiMetrics: dashboardMetrics.metrics?.activityKpiMetrics,
    cappyKpiMetrics: dashboardMetrics.metrics?.cappyKpiMetrics,
    error: dashboardMetrics.error,
    loading: dashboardMetrics.loading
  }
}

export default useDashboardMetrics
