import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PayScheduleModel, PaySchedulePaydayModel } from '../types'

interface PayScheduleState {
    loading: boolean
    payScheduleDetails: PayScheduleModel | null
    payScheduleCreatedFlag: boolean
    error: Error | string | null
}

const initialState: PayScheduleState = {
    loading: false,
    payScheduleDetails: null,
    payScheduleCreatedFlag: false,
    error: null
}

export const payScheduleSlice = createSlice({
    name: 'paySchedule',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        payScheduleDetailsFetched: (state, action: PayloadAction<PayScheduleModel>) => {
            state.loading = false
            state.payScheduleDetails = action.payload
            state.error = null
        },
        payScheduleDetailsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        payScheduleCreated: (state, action: PayloadAction<PayScheduleModel>) => {
            state.loading = false
            state.payScheduleDetails = action.payload
            state.payScheduleCreatedFlag = true
            state.error = null
        },
        payScheduleCreateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        resetPayScheduleCreatedFlag: (state) => {
            state.loading = false
            state.payScheduleCreatedFlag = false
            state.error = null
        },
        payScheduleUpdated: (state, action: PayloadAction<PayScheduleModel>) => {
            state.loading = false
            state.payScheduleDetails = action.payload
            state.error = null
        },
        payScheduleUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        paydaysForYearGenerated: (state, action: PayloadAction<PayScheduleModel>) => {
            state.loading = false
            state.payScheduleDetails = action.payload
            state.error = null
        },
        paydaysForYearGenerationFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        paydayUpdated: (state, action: PayloadAction<PaySchedulePaydayModel>) => {
            var index = state.payScheduleDetails?.paydays.findIndex((item) => {
                return item.id == action.payload.id
            })
            const paydays = [...state.payScheduleDetails?.paydays ?? []]
            paydays[index as number] = action.payload

            state.loading = false
            state.payScheduleDetails = { 
                ...state.payScheduleDetails as PayScheduleModel, 
                paydays: paydays 
              }
            state.error = null
        },
        paydayUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        paydayDeleted: (state, action: PayloadAction<string>) => {
            var index = state.payScheduleDetails?.paydays.findIndex((item) => {
                return item.id == action.payload
            })
            const paydays = [...state.payScheduleDetails?.paydays ?? []]
            paydays.splice(index ?? 0, 1) 

            state.loading = false
            state.payScheduleDetails = { 
                ...state.payScheduleDetails as PayScheduleModel, 
                paydays: paydays 
              }
            state.error = null
        },
        paydayDeleteFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        paydayPayoutAvailabilitySet: (state, action: PayloadAction<{paydayId: string, payoutsAvailable: boolean}>) => {
            var index = state.payScheduleDetails?.paydays.findIndex((item) => {
                return item.id == action.payload.paydayId
            })
            const paydays = [...state.payScheduleDetails?.paydays ?? []]
            let payday = paydays[index as number]
            const updatedPayday = {...payday, payoutsDisabled: !action.payload.payoutsAvailable}
            paydays[index as number] = updatedPayday

            state.loading = false
            state.payScheduleDetails = { 
                ...state.payScheduleDetails as PayScheduleModel, 
                paydays: paydays 
              }
            state.error = null
        },
        paydayPayoutAvailabilitySetFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        emptyPayrollSetForPayday: (state, action: PayloadAction<string>) => {
            var index = state.payScheduleDetails?.paydays.findIndex((item) => {
                return item.id == action.payload
            })
            const paydays = [...state.payScheduleDetails?.paydays ?? []]
            let payday = paydays[index as number]
            const updatedPayday = {...payday, payrollExists: true}
            paydays[index as number] = updatedPayday

            state.loading = false
            state.payScheduleDetails = { 
                ...state.payScheduleDetails as PayScheduleModel, 
                paydays: paydays 
              }
            state.error = null
        },
        emptyPayrollSetForPaydayFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
    }
})

export const { 
    loading, 
    payScheduleDetailsFetched, 
    payScheduleDetailsFetchFailure, 
    payScheduleCreated, 
    payScheduleCreateFailure, 
    resetPayScheduleCreatedFlag, 
    payScheduleUpdated, 
    payScheduleUpdateFailure, 
    paydaysForYearGenerated, 
    paydaysForYearGenerationFailure, 
    paydayUpdated, 
    paydayUpdateFailure, 
    paydayDeleted, 
    paydayDeleteFailure,
    paydayPayoutAvailabilitySet,
    paydayPayoutAvailabilitySetFailure,
    emptyPayrollSetForPayday,
    emptyPayrollSetForPaydayFailure
} = payScheduleSlice.actions

export default payScheduleSlice.reducer