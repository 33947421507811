import {useEffect, useState} from 'react'
import Modal from '../Modal'
import {
  CampaignTranslation,
} from '../../redux/types'
import InputField from '../InputField'

interface Props {
  initialValue: CampaignTranslation | undefined
  onSaveClick?: (message: CampaignTranslation) => void
  onCancelClick?: () => void
  visible: boolean
}

const UpdateCampaignTranslationModal = ({initialValue, onSaveClick, onCancelClick, visible}: Props) => {
  const [translation, setTranslation] = useState<CampaignTranslation>()

  useEffect(() => {
    if (initialValue) {
      setTranslation({
        language: initialValue.language,
        title: initialValue.title,
        summary: initialValue.summary
      })
    }
  }, [initialValue])

  const handleOnSave = () => {
    translation && onSaveClick && onSaveClick(translation)
  }

  const handleOnCancel = () => {
    onCancelClick && onCancelClick()
  }

  const handleOnChange = (propName: string, value: any) => {
    if (translation) {
      setTranslation({...translation, [propName]: value})
    }
  }

  return (
    <Modal
      title='Edit translation'
      show={visible}
      okButtonTitle='Save'
      cancelButtonTitle='Cancel'
      onHide={() => handleOnCancel()}
      onOkClick={() => handleOnSave()}
      onCancelClick={() => handleOnCancel()}
    >
      <h5>Language: {translation?.language ?? '-'}</h5>
      <InputField
        id='title'
        value={translation?.title ?? ''}
        onChange={(event) => handleOnChange('title', event.target.value)}
        label='Title'
        placeholder=''
      />
      <InputField
        id='summary'
        value={translation?.summary ?? ''}
        onChange={(event) => handleOnChange('summary', event.target.value)}
        label='Summary'
        placeholder=''
      />
    </Modal>
  )
}

export default UpdateCampaignTranslationModal
