import { restConfig } from "../authConfig"
import { AmlFlaggedCompanyRepresentativeListItemModel, CompanyRepresentativeAmlDetailsModel } from "../redux/companyMonitorTypes"
import { useAppDispatch } from "../redux/hooks"
import { 
    amlFlaggedRepresentativeAccepted, 
    amlFlaggedRepresentativeAcceptFailure, 
    amlFlaggedRepresentativeDetailsFetched, 
    amlFlaggedRepresentativeDetailsFetchFailure, 
    amlFlaggedRepresentativesFetched, 
    amlFlaggedRepresentativesFetchFailure, 
    loading 
} from "../redux/slices/amlFlaggedCompanyRepresentativeSlice"
import useFetchClient from "./useFetchClient"

const useCompanyRepresentativeAmlActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getAmlFlaggedCompanyRepresentatives = () => {
        dispatch(loading())   

        const url = restConfig.routes.aml + '/flaggedrepresentatives'

        return fetchClient.get(url)
            .then(response => {
                dispatch(amlFlaggedRepresentativesFetched(response.data as AmlFlaggedCompanyRepresentativeListItemModel[]))
            })
            .catch(error => {
                console.log('getAmlFlaggedCompanyRepresentatives error:', error)
                dispatch(amlFlaggedRepresentativesFetchFailure(error))
            })
    }

    const getAmlFlaggedCompanyRepresentativeDetails = (id: string) => {
        dispatch(loading())   

        const url = restConfig.routes.aml + `/representatives/${id}`

        return fetchClient.get(url)
            .then(response => {
                dispatch(amlFlaggedRepresentativeDetailsFetched(response.data as CompanyRepresentativeAmlDetailsModel))
            })
            .catch(error => {
                console.log('getAmlFlaggedCompanyRepresentativeDetails error:', error)
                dispatch(amlFlaggedRepresentativeDetailsFetchFailure(error))
            })
    }

    const acceptAmlFlaggedCompanyRepresentative = (id: string, comment: string) => {
        dispatch(loading())   

        const url = restConfig.routes.aml + `/representatives/${id}/accept`

        return fetchClient.post(url, { comment: comment })
            .then(response => {
                dispatch(amlFlaggedRepresentativeAccepted(response.data as CompanyRepresentativeAmlDetailsModel))
            })
            .catch(error => {
                console.log('acceptAmlFlaggedCompanyRepresentative error:', error)
                dispatch(amlFlaggedRepresentativeAcceptFailure(error))
            })
    }

    return {
        getAmlFlaggedCompanyRepresentatives,
        getAmlFlaggedCompanyRepresentativeDetails,
        acceptAmlFlaggedCompanyRepresentative
    }
}

export default useCompanyRepresentativeAmlActions