import { useState, useEffect } from 'react'
import SearchAndSelectField, { SearchResultItem } from '../SearchAndSelectField'
import useUserSelectorActions from '../../hooks/useUserSelectorActions'

interface Props {
    onUserSelected: (user: SearchResultItem | null) => void
    value: string
 }

const UserSelector = ({ onUserSelected = () => '', value }: Props) => {
    const [userResultItems, setUserResultItems] = useState<SearchResultItem[]>()
    const { search, users, loading } = useUserSelectorActions()

    useEffect(() => {
        if (users) {
            const items = users.map((item) => {
                return {name: `${item.givenName} ${item.familyName} (${item.identificationNumber})`, value: item.id}
            })
            setUserResultItems(items)
        } else{
            setUserResultItems([])   
        }              
    }, [users])

    const handleSearchTermChange = (searchTerm: string) => {
        search(searchTerm, 10)
    }
    
    const handleUserSelected = (selectedItem: SearchResultItem | null) => {
        onUserSelected && onUserSelected(selectedItem || null)
    }

    return (
        <>
            <SearchAndSelectField
                placeholder='Search user'
                id='userSearch'
                onSearchTermChange={handleSearchTermChange}
                onSelect={handleUserSelected}
                value={value}
                searchResult={userResultItems ?? []}
            /> 
        </>
    )
}

export default UserSelector