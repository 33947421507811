import { restConfig } from "../authConfig"
import { useAppDispatch } from "../redux/hooks"
import { loading, marketsFetched, marketsFetchFailure } from "../redux/slices/marketSlice"
import { MarketModel } from "../redux/types"
import useFetchClient from "./useFetchClient"

const useMarketActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getMarkets = async () => {
    dispatch(loading())   

    const url = restConfig.routes.markets

    return fetchClient.get(url)
      .then(response => {
          dispatch(marketsFetched(response.data as MarketModel[]))
      })
      .catch(error => {
          console.log('getMarkets error:', error)
          dispatch(marketsFetchFailure(error))
      })
  }

  return {
    getMarkets
  }
}

export default useMarketActions
