import { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import DatePicker from "../DatePicker"
import Modal from "../Modal"

interface Props {
    intitalValue: string
    onSaveClick: (payday: string) => void
    onCancelClick: () => void
    visible: boolean
}

const EditPaydayModal = ({ intitalValue, onSaveClick, onCancelClick, visible }: Props) => {
    const styles = useStyles()
    const [payday, setPayday] = useState(intitalValue)

    useEffect(() => {
        if (visible) {
            setPayday(intitalValue)
        }
    }, [visible])

    const handleOnSave = () => {
        onSaveClick(payday)       
    }

    const handleOnClose = () => {
        onCancelClick && onCancelClick()
    }

    return (
        <Modal
            title='Edit payday'
            show={visible}
            okButtonTitle='Save'
            cancelButtonTitle='Cancel'
            onHide={() => handleOnClose()}
            onOkClick={() => handleOnSave()}
            onCancelClick={() => handleOnClose()}
        >
            <h6>Note! A payday cannot be moved outside its pay period.</h6>
            <div className={styles.datePickerContainer}>
                <DatePicker 
                    id='date'
                    label='Date'
                    includeTime={false}
                    value={payday}
                    onChange={value => setPayday(value ?? payday)}
                />
            </div>   
            
        </Modal>
    )
}

export default EditPaydayModal

const useStyles = createUseStyles({
    datePickerContainer: {
        marginTop: 20,
        minHeight: 350
    },
})