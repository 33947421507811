import { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import Button from "../components/Button"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import CreateOrUpdateInAppMessageModal from "../components/InAppMessage/CreateOrUpdateInAppMessageModal"
import InAppMessageList from "../components/InAppMessage/InAppMessageList"
import { titleMarginBottom } from "../constants/layout"
import useInAppMessageActions from "../hooks/useInAppMessageActions"
import { useAppSelector } from "../redux/hooks"
import { CreateOrUpdateInAppMessageModel } from "../redux/types"

const InAppMessages = () => {
    const styles = useStyles()

    const { getInAppMessages, createInAppMessage } = useInAppMessageActions()
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const loading = useAppSelector(state => state.inAppMessages.loading)
    const inAppMessagesOnPage = useAppSelector(state => state.inAppMessages.inAppMessagesOnPage)

    useEffect(() => {
        if (inAppMessagesOnPage.length === 0) {
            getInAppMessages()
        }
    }, [])

    const handleInAppMessageCreated = (model: CreateOrUpdateInAppMessageModel) => {
        createInAppMessage(model)
        setCreateModalVisible(false)
    }

    return (
        <div className={styles.container}>
            <h1>In-app messages</h1>
            <div className={styles.actionBar}>
                <Button title='Create new' onClick={() => setCreateModalVisible(true)} />
            </div>
            <InAppMessageList inAppMessages={inAppMessagesOnPage} />
            <CreateOrUpdateInAppMessageModal 
                visible={createModalVisible}
                onAddClick={handleInAppMessageCreated}
                onCancelClick={() => setCreateModalVisible(false)}
            />
          <FullScreenLoadingIndicator visible={loading} />
        </div>
      )
}

export default InAppMessages

const useStyles = createUseStyles({
    container: {
      '& h1': {
        marginBottom: titleMarginBottom
      }
    },
    actionBar: {
      display: 'flex',
      marginTop: -30,
      flexDirection: 'row',
      columnGap: 10,
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
  })