import {createUseStyles} from 'react-jss'
import {Colors} from '../../constants/colors'
import {formatDateTimeString} from '../../utils/dateHelper'
import {CompanyCheckResponseModel} from '../../redux/companyMonitorTypes';
import Badge from "react-bootstrap/Badge";
import React from "react";
import Card from "../Card";

interface Props {
  companyChecks: CompanyCheckResponseModel[]
  onClick?: (item: CompanyCheckResponseModel) => void
}

const CompanyCheckList = ({companyChecks, onClick}: Props) => {
  const styles = useStyles()

  const renderListItem = (item: CompanyCheckResponseModel, index: number) => {
    const itemClass = index % 2 === 0 ? styles.listItem : styles.listItemAlt

    return (
      <div
        key={`check_${index}`}
        className={itemClass}
        onClick={() => onClick && onClick(item)}
      >
        <div className={styles.cardContent}>
          {formatDateTimeString(item.checkedAt)}
          <div className={styles.allCaps}>{item.country}</div>
          <div>{item.companyRegistrationNumber}</div>
          {item.company && <div>{item.company.name}</div>}
          <div className={styles.badgeContainer}>
            <div>
              <Badge variant={item.success ? "success" : "danger"}>
                {item.success ? "Success" : "Failed"}
              </Badge>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Card className={`${styles.card} card`}>
      {companyChecks?.map(renderListItem)}
    </Card>
  )
}

export default CompanyCheckList

const useStyles = createUseStyles({
  card: {
    borderWidth: 0,
    borderRadius: 8,
    overflow: 'hidden',
    display: 'flex',
  },
  listItem: {
    padding: 4,
    transition: '0.4s',
    backgroundColor: Colors.darkGrayBackground,
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  listItemAlt: {
    padding: 4,
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  cardContent: {
    padding: 4,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
  },
  cardHeader: {
    borderRadius: 8,
    paddingLeft: 4,
    paddingRight: 4,
    backgroundColor: Colors.darkGrayHover
  },
  badgeContainer: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    gap: 10,
    fontFamily: 'Aestetico-Regular',
    fontSize: "large"
  },
  allCaps: {
    textTransform: "uppercase"
  }
})
