import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {createUseStyles} from "react-jss";
import {Colors} from "../../constants/colors";
import Modal from "../Modal";
import Button from "../Button";
import Form from "react-bootstrap/Form";

interface Props {
  visible: boolean
  onUpload: (file: File, overwrite: boolean) => void
  onCancelClick: () => void
}

const UploadImageModal = ({visible, onUpload, onCancelClick}: Props) => {
  const styles = useStyles()
  const [file, setFile] = useState<File>();
  const [allowOverwrite, setAllowOverwrite] = useState(false)
  const [error, setError] = useState<string>();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const allowedFileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/svg+xml']

  useEffect(() => {
    const maxFileSize = 1024 * 1024 // 1 MB

    if (!!file) {
      if (file.size > maxFileSize) {
        setError('File size too large')
        return
      }

      if (allowedFileTypes.findIndex(type => type === file.type) === -1) {
        setError('Invalid file type')
        return;
      }

      setError(undefined)
    }
  }, [file])

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleOnOk = () => {
    if (file) {
      onUpload(file, allowOverwrite)
      setFile(undefined)
      setAllowOverwrite(false)
    }
  }

  const handleOnCancel = () => {
    setFile(undefined)
    setAllowOverwrite(false)
    onCancelClick()
  }

  const renderPreview = () => {
    if (!file || !!error) return

    return (
      <div className={styles.previewContainer}>
        <div className={styles.imagePreviewContainer}>
          <img className={styles.image} src={URL.createObjectURL(file)} alt="Image preview"/>
        </div>

        <div>
          <span>
            <strong>{file.name}</strong> ({file.size / 1000} kB)
          </span>
        </div>
      </div>
    )
  }

  return (
    <Modal
      title='Upload image'
      show={visible}
      okButtonTitle={'Upload'}
      okButtonDisabled={!file || !!error}
      cancelButtonTitle={'Cancel'}
      onHide={handleOnCancel}
      onOkClick={handleOnOk}
      onCancelClick={handleOnCancel}
    >
      <p>{'Select image (max 1 MB image file)'}</p>
      <div className={styles.container}>
        <Button title={'Select image'} onClick={() => hiddenFileInput.current?.click()}/>
        <input
          className={styles.hidden}
          ref={hiddenFileInput}
          type="file"
          accept={allowedFileTypes.join(',')}
          onChange={handleFileChange}
        />
        <Form.Switch
          id={'allow_overwrite'}
          label='Overwrite existing image'
          checked={allowOverwrite}
          onChange={() => setAllowOverwrite(!allowOverwrite)}
        />
      </div>

      <div>{!!error && error}</div>
      {renderPreview()}
    </Modal>
  )
}

export default UploadImageModal

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
    flex: 1,
  },
  hidden: {
    display: 'none',
  },
  previewContainer: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imagePreviewContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 10,
  },
  darkImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.gray0,
    borderRadius: '16px',
    width: '128px',
    height: '128px',
  },
  lightImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.gray4,
    borderRadius: '16px',
    width: '128px',
    height: '128px',
  },
  darkImageContainerSmall: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.gray0,
    borderRadius: '16px',
    width: '32px',
    height: '32px',
  },
  lightImageContainerSmall: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.gray4,
    borderRadius: '16px',
    width: '32px',
    height: '32px',
  },
  image: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  }
})
