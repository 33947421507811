import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Breakpoints } from '../constants/breakpoints'
import { Colors } from '../constants/colors'
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
  title: string
  onClick?: () => void
  disabled?: boolean
  className?: string
  type?: 'button' | 'reset' | 'submit'
  to?: string
  variant?: 'positive' | 'negative'
  icon?: IconDefinition
  iconColor?: string
}

const NavLink = ({
  title,
  onClick,
  className,
  to,
  icon,
  iconColor
}: Props) => {
  const styles = useStyles()

  const renderContainer = () => {
    return (
      <div
        className={`${styles.container} ${className}`} 
        onClick={onClick}
        title={title}
      >
        {renderIcon(icon, iconColor)}
        {title}
      </div>
    )
  }

  const renderIcon = (icon?: IconDefinition, iconColor?: string) => {
    if (icon) {
      return <FontAwesomeIcon className={styles.icon}
        icon={icon}
        color={iconColor || Colors.white}
      />
    }
  }

  const linkWrapper = () => {
    return to ? <Link to={to}>{renderContainer()}</Link> : renderContainer()
  }

  return linkWrapper()
}

export default NavLink

const useStyles = createUseStyles({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 32,
    borderRadius: 16,
    borderStyle: 'solid',
    borderWidth: 0,
    fontFamily: "Aestetico-Bold",
    textAlign: 'center',
    fontSize: 14,
    paddingTop: 5,
    backgroundColor: Colors.darkGray4,
    color: Colors.white,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.euro_600,
    },
    paddingLeft: 20,
    paddingRight: 20,
    transition: '0.4s',
  },
  icon: {
    marginTop: 3,
    marginRight: "5px",
  }
})


