import {CountryCode, MarketModel} from "../../redux/types";
import {CompanyCheckRequest} from "../../hooks/useCompanyCheckActions";
import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import Button from "../Button";

interface Props {
  markets: MarketModel[]
  onSearch: (request: CompanyCheckRequest) => void
}

export const CompanyCheckSearchForm = ({markets, onSearch}: Props) => {
  const initialCheckRequest = {
    marketId: "",
    country: CountryCode.se,
    companyRegistrationNumber: ""
  } as CompanyCheckRequest

  const [companyCheckRequest, setCompanyCheckRequest] = useState<CompanyCheckRequest>(initialCheckRequest)

  const isRequestValid = () => {
    //E.g. validate that the reg num looks somewhat correct
    if (companyCheckRequest.companyRegistrationNumber.length < 5) {
      return false
    }

    if (companyCheckRequest.marketId === "") {
      return false
    }

    return true
  }

  const onMarketChange = (marketId: string) => {
    const market = markets.find(market => market.id === marketId)
    if (market) {
      setCompanyCheckRequest({
        ...companyCheckRequest,
        marketId: marketId,
        country: market.country
      })
    }
  }

  const onCompanyRegistrationNumberChange = (companyRegistrationNumber: string) => {
    setCompanyCheckRequest({
      ...companyCheckRequest,
      companyRegistrationNumber: companyRegistrationNumber
    })
  }

  return (
    <Form>
      <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10}}>
        <div style={{display: "flex", flex: 1, minWidth: 250}}>
          <Form.Control
            as="select"
            onChange={(event) => onMarketChange(event.target.value)}>
            <option value={""}>Select market...</option>
            {
              markets.map(market => (
                <option key={market.id} value={market.id}>{market.country}</option>
              ))
            }
          </Form.Control>
        </div>
        <div style={{display: "flex", flex: 1, minWidth: 250}}>
          <Form.Control
            type={"text"}
            placeholder={"Company registration number"}
            onChange={(event) => onCompanyRegistrationNumberChange(event.target.value)}/>
        </div>
        <Button title={"Search"} disabled={!isRequestValid()} onClick={() => onSearch(companyCheckRequest)}/>
      </div>
    </Form>
  )
}
