import React from 'react'
import {createUseStyles} from 'react-jss'
import DashboardMetricCard from "./DashboardMetricCard";
import FullScreenLoadingIndicator from "../FullScreenLoadingIndicator";
import {MonetaryMetric, NumericMetric, PercentageMetric} from "./Metrics";
import DashboardMetricStack from "./DashboardMetricStack";
import useDashboardMetrics from "../../hooks/useDashboardMetrics";
import {Alert} from "react-bootstrap";
import {formatMonthString} from "../../utils/dateHelper";

const Dashboard = () => {
  const styles = useStyles()
  const {
    loading,
    cappyKpiMetrics,
    activityKpiMetrics
  } = useDashboardMetrics()

  if (loading) {
    return <FullScreenLoadingIndicator visible/>
  }

  if (!cappyKpiMetrics || !activityKpiMetrics) {
    return <Alert variant="danger">Failed to load dashboard data</Alert>
  }

  const payPaymentMonth = formatMonthString(cappyKpiMetrics.payPaymentCountSek.values[0].date)

  const mainMetricFontSize = 40
  const secondaryMetricFontSize = 24

  return (
    <div className={styles.infoCardContainer}>
      <DashboardMetricStack title={"Eligible Employees"} subTitle={"Number of employees that are offered Cappy"}>
        <DashboardMetricCard title={"New"}>
          <NumericMetric
            size={mainMetricFontSize}
            value={cappyKpiMetrics.employeeCount.delta}
            caption={"Current Month"}>
            <NumericMetric.Trend percentage={cappyKpiMetrics.employeeCount.deltaPercentage}/>
          </NumericMetric>
          <NumericMetric
            size={secondaryMetricFontSize}
            value={cappyKpiMetrics.employeeCount.lastValue}
            caption={"In Total"}/>
        </DashboardMetricCard>
        <DashboardMetricCard title={"Non-eligible"}>
          <NumericMetric
            size={mainMetricFontSize}
            value={cappyKpiMetrics.nonEligibleEmployeeCount.delta}
            caption={"Current Month"}>
            <NumericMetric.Trend
              percentage={cappyKpiMetrics.nonEligibleEmployeeCount.deltaPercentage}
              invertTrend/>
          </NumericMetric>
          <NumericMetric
            size={secondaryMetricFontSize}
            value={cappyKpiMetrics.nonEligibleEmployeeCount.lastValue}
            caption={"In Total"}/>
        </DashboardMetricCard>
        <DashboardMetricCard title={"Terminated"}>
          <NumericMetric
            size={mainMetricFontSize}
            value={cappyKpiMetrics.terminatedEmployeeCount.delta}
            caption={"Current Month"}>
            <NumericMetric.Trend
              percentage={cappyKpiMetrics.terminatedEmployeeCount.deltaPercentage}
              invertTrend/>
          </NumericMetric>
          <NumericMetric
            size={secondaryMetricFontSize}
            value={cappyKpiMetrics.terminatedEmployeeCount.lastValue}
            caption={"In Total"}/>
        </DashboardMetricCard>
        <DashboardMetricCard title={"Eligible"} chartMetric={cappyKpiMetrics.eligibleEmployeeCount}>
          <NumericMetric
            size={mainMetricFontSize}
            value={cappyKpiMetrics.eligibleEmployeeCount.delta}
            showSign
            caption={"Current Month"}>
            <NumericMetric.Trend percentage={cappyKpiMetrics.eligibleEmployeeCount.deltaPercentage}/>
          </NumericMetric>
          <NumericMetric
            size={secondaryMetricFontSize}
            value={cappyKpiMetrics.eligibleEmployeeCount.lastValue}
            caption={"In Total"}/>
        </DashboardMetricCard>
      </DashboardMetricStack>

      <DashboardMetricStack title={"Activated Employees"} subTitle={"Number of employees that have activated Cappy"}>
        <DashboardMetricCard title={"New"}>
          <NumericMetric
            size={mainMetricFontSize}
            value={cappyKpiMetrics.activatedEmployeeCount.delta}
            caption={"Current Month"}>
            <NumericMetric.Trend percentage={cappyKpiMetrics.activatedEmployeeCount.deltaPercentage}/>
          </NumericMetric>
          <NumericMetric
            size={secondaryMetricFontSize}
            value={cappyKpiMetrics.activatedEmployeeCount.lastValue}
            caption={"In total"}/>
        </DashboardMetricCard>
        <DashboardMetricCard title={"Terminated"}>
          <NumericMetric
            size={mainMetricFontSize}
            value={cappyKpiMetrics.activatedTerminatedEmployeeCount.delta}
            caption={"Current Month"}>
            <NumericMetric.Trend
              percentage={cappyKpiMetrics.activatedTerminatedEmployeeCount.deltaPercentage}
              invertTrend/>
          </NumericMetric>
          <NumericMetric
            size={secondaryMetricFontSize}
            value={cappyKpiMetrics.activatedTerminatedEmployeeCount.lastValue}
            caption={"In total"}/>
        </DashboardMetricCard>
        <DashboardMetricCard title={"Activated"} chartMetric={cappyKpiMetrics.activatedNonTerminatedEmployeeCount}>
          <NumericMetric
            size={mainMetricFontSize}
            value={cappyKpiMetrics.activatedNonTerminatedEmployeeCount.delta}
            showSign
            caption={"Current Month"}>
            <NumericMetric.Trend percentage={cappyKpiMetrics.activatedNonTerminatedEmployeeCount.deltaPercentage}/>
          </NumericMetric>
          <NumericMetric
            size={secondaryMetricFontSize}
            value={cappyKpiMetrics.activatedNonTerminatedEmployeeCount.lastValue}
            caption={"In Total"}/>
        </DashboardMetricCard>

        <DashboardMetricCard
          title={"Activation conversion"}
          subTitle={"Share of eligible employees who activated Cappy"}>
          <PercentageMetric
            size={mainMetricFontSize}
            percentage={cappyKpiMetrics.employeeActivationConversion.lastValue}
            caption={"Current"}>
            <PercentageMetric.Trend percentage={cappyKpiMetrics.employeeActivationConversion.delta}/>
          </PercentageMetric>
          <PercentageMetric
            size={secondaryMetricFontSize}
            percentage={cappyKpiMetrics.employeeActivationConversion.firstValue}
            caption={"Month Start"}/>
        </DashboardMetricCard>
      </DashboardMetricStack>

      <DashboardMetricCard title={"Account deletions"} standalone>
        <NumericMetric
          size={mainMetricFontSize}
          value={cappyKpiMetrics.deletedUserCount.delta}
          caption={"Current month"}>
          <NumericMetric.Trend
            percentage={cappyKpiMetrics.deletedUserCount.deltaPercentage}
            invertTrend/>
        </NumericMetric>
        <NumericMetric
          size={secondaryMetricFontSize}
          value={cappyKpiMetrics.deletedUserCount.lastValue}
          caption={"In total"}/>
      </DashboardMetricCard>

      <DashboardMetricCard title={"Daily Active Users (DAU)"} subTitle={"Number of unique users who login in period"}
                           standalone>
        <NumericMetric
          size={mainMetricFontSize}
          value={activityKpiMetrics.dailyActiveUsers.lastValue}
          caption={"Current"}>
          <NumericMetric.Trend percentage={activityKpiMetrics.dailyActiveUsers.currentPercentage}/>
        </NumericMetric>
        <NumericMetric
          size={secondaryMetricFontSize}
          value={activityKpiMetrics.dailyActiveUsers.firstValue}
          caption={"Month Start"}/>
      </DashboardMetricCard>
      <DashboardMetricCard title={"Monthly Active Users (MAU)"} subTitle={"Number of unique users who login in period"}
                           standalone>
        <NumericMetric
          size={mainMetricFontSize}
          value={activityKpiMetrics.monthlyActiveUsers.lastValue}
          caption={"Current"}>
          <NumericMetric.Trend percentage={activityKpiMetrics.monthlyActiveUsers.currentPercentage}/>
        </NumericMetric>
        <NumericMetric
          size={secondaryMetricFontSize}
          value={activityKpiMetrics.monthlyActiveUsers.firstValue}
          caption={"Month Start"}/>
      </DashboardMetricCard>

      <DashboardMetricCard title={"Product stickiness"} subTitle={"Share of monthly active users who use the app daily"}
                           standalone>
        <PercentageMetric
          size={mainMetricFontSize}
          percentage={activityKpiMetrics.productStickiness.lastValue}
          caption={"Current"}>
          <PercentageMetric.Trend percentage={activityKpiMetrics.productStickiness.delta}/>
        </PercentageMetric>
        <PercentageMetric
          size={secondaryMetricFontSize}
          percentage={activityKpiMetrics.productStickiness.firstValue}
          caption={"Month Start"}/>
      </DashboardMetricCard>

      <DashboardMetricCard title={"DAU withdrawal share"} subTitle={"Share of DAU that completed withdrawal in period"}
                           standalone>
        <PercentageMetric
          size={mainMetricFontSize}
          percentage={activityKpiMetrics.dailyActiveUsersWithdrawalShare.lastValue}
          caption={"Current"}>
          <PercentageMetric.Trend percentage={activityKpiMetrics.dailyActiveUsersWithdrawalShare.delta}/>
        </PercentageMetric>
        <PercentageMetric
          size={secondaryMetricFontSize}
          percentage={activityKpiMetrics.dailyActiveUsersWithdrawalShare.firstValue}
          caption={"Month Start"}/>
      </DashboardMetricCard>

      <DashboardMetricCard title={"MAU withdrawal share"} subTitle={"Share of MAU that completed withdrawal in period"}
                           standalone>
        <PercentageMetric
          size={mainMetricFontSize}
          percentage={activityKpiMetrics.monthlyActiveUsersWithdrawalShare.lastValue}
          caption={"Current"}>
          <PercentageMetric.Trend percentage={activityKpiMetrics.monthlyActiveUsersWithdrawalShare.delta}/>
        </PercentageMetric>
        <PercentageMetric
          size={secondaryMetricFontSize}
          percentage={activityKpiMetrics.monthlyActiveUsersWithdrawalShare.firstValue}
          caption={"Month Start"}/>
      </DashboardMetricCard>

      <DashboardMetricStack title={"App logins"}>
        <DashboardMetricCard
          title={"App logins"}
          subTitle={"Number of logins in period"}
          chartMetric={activityKpiMetrics.appLoginCount}
        >
          <NumericMetric
            size={mainMetricFontSize}
            value={activityKpiMetrics.appLoginCount.delta}
            caption={"Current Month"}/>
          <NumericMetric
            size={secondaryMetricFontSize}
            value={activityKpiMetrics.appLoginCount.lastValue}
            caption={"In Total"}/>
        </DashboardMetricCard>
      </DashboardMetricStack>

      <DashboardMetricStack title={"Withdrawals"}>
        <DashboardMetricCard
          title={"Withdrawals"}
          subTitle={"Number of withdrawals in period"}
          chartMetric={cappyKpiMetrics.payoutCountSek}
        >
          <NumericMetric
            size={mainMetricFontSize}
            value={cappyKpiMetrics.payoutCountSek.delta}
            caption={"Current Month"}/>
          <NumericMetric
            size={secondaryMetricFontSize}
            value={cappyKpiMetrics.payoutCountSek.lastValue}
            caption={"In Total"}/>
        </DashboardMetricCard>
        <DashboardMetricCard
          title={"Withdrawal sum"}
          subTitle={"Sum of withdrawals in period"}
          chartMetric={cappyKpiMetrics.payoutSumSek}
        >
          <MonetaryMetric
            size={mainMetricFontSize}
            amount={cappyKpiMetrics.payoutSumSek.delta}
            caption={"Current Month"}/>
          <MonetaryMetric
            size={secondaryMetricFontSize}
            amount={cappyKpiMetrics.payoutSumSek.lastValue}
            caption={"In Total"}/>
        </DashboardMetricCard>
      </DashboardMetricStack>

      <DashboardMetricStack title={"Pay payments"}>
        <DashboardMetricCard title={"Pay payments"} subTitle={"Number of pay payments in period"}>
          <NumericMetric
            size={mainMetricFontSize}
            value={cappyKpiMetrics.payPaymentCountSek.delta}
            caption={`During ${payPaymentMonth}`}/>
          <NumericMetric
            size={secondaryMetricFontSize}
            value={cappyKpiMetrics.payPaymentCountSek.lastValue}
            caption={"In Total"}/>
        </DashboardMetricCard>
        <DashboardMetricCard title={"Pay payment sum"} subTitle={"Sum of pay payments in period"}>
          <MonetaryMetric
            size={mainMetricFontSize}
            amount={cappyKpiMetrics.payPaymentSumSek.delta}
            caption={`During ${payPaymentMonth}`}/>
          <MonetaryMetric
            size={secondaryMetricFontSize}
            amount={cappyKpiMetrics.payPaymentSumSek.lastValue}
            caption={"In Total"}/>
        </DashboardMetricCard>
      </DashboardMetricStack>

      <DashboardMetricStack title={"Account balance"}>
        <DashboardMetricCard title={"Credit usage"} subTitle={"Balance of OutstandingPayoutsAccount"}>
          <MonetaryMetric
            size={mainMetricFontSize}
            amount={cappyKpiMetrics.creditUsageSek.lastValue}
            caption={`Current balance`}/>
          <MonetaryMetric
            size={secondaryMetricFontSize}
            amount={cappyKpiMetrics.creditUsageSek.max}
            caption={"Max this month"}/>
        </DashboardMetricCard>

        <DashboardMetricCard title={"Outstanding fees"} subTitle={"Balance of OutstandingFeesAccount"}>
          <MonetaryMetric
            size={mainMetricFontSize}
            amount={cappyKpiMetrics.outstandingFeesSek.lastValue}
            caption={`Current balance`}/>
          <MonetaryMetric
            size={secondaryMetricFontSize}
            amount={cappyKpiMetrics.outstandingFeesSek.max}
            caption={"Max this month"}/>
        </DashboardMetricCard>
      </DashboardMetricStack>
    </div>
  )
}

export default Dashboard

const useStyles = createUseStyles({
  infoCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: 20,
    rowGap: 20
  }
})
