import Card from '../Card'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../constants/colors'
import { CustomerPortalUser } from '../../redux/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import Badge from '../Badge'

interface Props {
  portalUsers: CustomerPortalUser[]
  onDeleteClick?: (contact: CustomerPortalUser) => void
  onEditClick?: (contact: CustomerPortalUser) => void
}

const CustomerPortalUserList = ({ portalUsers, onDeleteClick, onEditClick }: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        <div className={styles.cell}>
          <p><span>Identification number</span></p>
        </div>
        <div className={styles.cell}>
          <p><span>Email</span></p>
        </div>
        <div className={styles.cell}>
          <p><span>Name</span></p>
        </div>
        <div className={styles.cell}>
          <p><span>Phone</span></p>
        </div>
        <div className={styles.cell}>
          <p><span>Auth scheme</span></p>
        </div>
        <div className={styles.badgeCell}>
          <p><span>Roles</span></p>
        </div>
        <div className={`${styles.cell} ${styles.iconCell} ${styles.buttonCell}`}>
        </div>
        <div className={`${styles.cell} ${styles.iconCell} ${styles.buttonCell}`}>
        </div>
      </Card>
    )
  }

  const renderListItems = () => {
    return portalUsers?.map((item, index) => {
      return (
        <Card className={`${styles.container} card`} key={`portalUser_${index}`}>
          <div className={styles.cell}>
            <p>{item.identificationNumber}</p>
          </div>
          <div className={styles.cell}>
            <p>{item.email}</p>
          </div>
          <div className={styles.cell}>
            <p>{item.givenName} {item.familyName}</p>
          </div>
          <div className={styles.cell}>
            <p>{item.phoneNumber}</p>
          </div>
          <div className={styles.cell}>
            <p>{item.authScheme}</p>
          </div>
          <div className={styles.badgeCell}>
            {renderRoleBadges(item)}
          </div>
          <div className={`${styles.cell} ${styles.iconCell} ${styles.buttonCell}`}>
            <FontAwesomeIcon
              className={styles.button}
              icon={faEdit}
              color={Colors.euro_600}
              title='Edit'
              onClick={() => onEditClick && onEditClick(item)}
            />
          </div>
          <div className={`${styles.cell} ${styles.iconCell} ${styles.buttonCell}`}>
            <FontAwesomeIcon
              className={styles.button}
              icon={faTrashAlt}
              color={Colors.euro_600}
              title='Delete'
              onClick={() => onDeleteClick && onDeleteClick(item)}
            />
          </div>
        </Card>
      )
    })
  }


  const renderRoleBadges = (user: CustomerPortalUser) => {
    const badges = user.portalRoles?.map((role, index) => {
        return (
            <Badge
                key={`roleBadge${index}`}
                text={role.name}
                backgroundColor={Colors.darkGray3}
                textColor={Colors.darkGray4}
            />
        )
    })

    if (user.isOwner) {
        badges.push(
            <Badge
                key={`roleBadgeOwner`}
                text='Owner'
                backgroundColor={Colors.darkGray3}
                textColor={Colors.darkGray4}
            />
        )
    }

    if (user.isContactTechnical) {
        badges.push(
            <Badge
                key={`roleBadgeContactTechnical`}
                text='Contact Technical'
                backgroundColor={Colors.darkGray3}
                textColor={Colors.darkGray4}
            />)
    }

    if (user.isContactFinance) {
      badges.push(
        <Badge
          key={`roleBadgeContactFinance`}
          text='Contact Finance'
          backgroundColor={Colors.darkGray3}
          textColor={Colors.darkGray4}
        />)
    }

    if (user.isContactPayroll) {
      badges.push(
        <Badge
          key={`roleBadgeContactPayroll`}
          text='Contact Payroll'
          backgroundColor={Colors.darkGray3}
          textColor={Colors.darkGray4}
        />)
    }

    return badges
}

  return (
    <div>
      {renderHeader()}
      {renderListItems()}
    </div>
  )
}

export default CustomerPortalUserList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 3px',
    margin: '5px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  '& p': {
      fontSize: 12,
      margin: 0,
      '& span': {
        fontFamily: 'Aestetico-Bold',
        color: Colors.euro_600,
      }
  },
  header: {
    margin: '30px 0 20px 0',
  },
  flagged: {
      backgroundColor: Colors.red,
  },
  cell: {
    flex: 1,
    padding: '0px 5px'
  },
  iconCell: {
    textAlign: 'center',
  },
  buttonCell: {
    flex: '0 0 40px'
  },
  badgeCell: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  button: {
      transition: '0.4s',
      '&:hover': {
        color: Colors.euro5,
        cursor: 'pointer',
      }
    },
})
