import {createUseStyles} from 'react-jss'
import {useHistory} from 'react-router-dom'
import {Colors} from '../../constants/colors'
import {EmployeeListItemModel} from '../../redux/types'
import Pagination from '../Pagination'
import {Breakpoints} from "../../constants/breakpoints";
import {ColumnDefinition} from "./ColumnDefinition";

export interface Props {
  employeesOnPage?: EmployeeListItemModel[]
  pageIndex: number
  pageSize: number
  totalCount: number
  onPageChange: (pageIndex: number) => void
  columns: ColumnDefinition[]
}

const EmployeeList = ({employeesOnPage, pageIndex, pageSize, totalCount, onPageChange, columns}: Props) => {
  const styles = useStyles()
  const history = useHistory()

  const renderHeader = () => {
    return (
      <thead>
      <tr>
        {columns.map((col, index) => (
          <th className={styles.headerCell} key={`header_${index}`}>{col.title}</th>
        ))}
      </tr>
      </thead>
    )
  }

  const renderListItems = () => {
    const getColumnStyle = (col: ColumnDefinition) => {
      if (col.style === "Badge") {
        return styles.badge
      }
    }

    return (
      <tbody>
      {employeesOnPage?.map((item, index) => (
        <tr
          key={`employee_${index}`}
          className={styles.row}
          onClick={() => history.push(`/employees/${item?.id}`)}
        >
          {columns.map((col, colIndex) => (
            <td className={styles.cell} key={`item_${index}_${colIndex}`}>
              <span className={styles.cellHeader}>{col.title}: </span>
              <span className={getColumnStyle(col)}>
                {col.extractor(item)}
              </span>
            </td>
          ))}
        </tr>
      ))}
      </tbody>
    )
  }

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        {renderHeader()}
        {renderListItems()}
      </table>
      <div className={styles.pagination}>
        <Pagination
          pageIndex={pageIndex}
          itemCount={totalCount}
          pageSize={pageSize}
          siblingCount={1}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  )
}

export default EmployeeList

const useStyles = createUseStyles({
  tableContainer: {
    padding: '10px',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
  },
  row: {
    backgroundColor: Colors.darkGrayCard,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  cell: {
    padding: 6,
    textAlign: 'left',
  },
  cellHeader: {
    display: 'none'
  },
  headerCell: {
    padding: 6,
    textAlign: 'left'
  },
  badge: {
    textAlign: 'center',
    textTransform: 'capitalize',
    borderRadius: 5,
    padding: '2px 5px',
    backgroundColor: Colors.darkGray3,
    fontFamily: 'Aestetico-Regular',
    color: Colors.darkGray4,
    fontSize: 14,
  },
  pagination: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center'
  },
  [`@media (max-width: ${Breakpoints.desktop}px)`]: {
    headerCell: {
      display: 'none'
    },
    cellHeader: {
      display: 'inline',
      fontWeight: 'bold'
    },
    cell: {
      display: 'block'
    },
    row: {
      display: 'block',
      marginBottom: '10px',
      border: `2px solid ${Colors.darkGrayBorder}`,
      borderRadius: 10,
    }
  }
})
