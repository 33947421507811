import Card from '../Card'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../constants/colors'
import { PaydayListItemModel } from '../../redux/types'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { formatDateString } from '../../utils/dateHelper'
import Pagination from '../Pagination'

interface Props {
  paydaysOnPage: PaydayListItemModel[]
  pageIndex: number
  pageSize: number
  totalCount: number
  onPageChange: (pageIndex: number) => void 
}

const PaydayList = ({ paydaysOnPage, pageIndex, pageSize, totalCount, onPageChange }: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        <div className={styles.cell}>
          <h6>Payment date</h6>
        </div>
        <div className={styles.cell}>
          <h6>Employer</h6>
        </div>
        <div className={styles.cell}>
          <h6>Off-cycle</h6>
        </div>
        <div className={`${styles.cell} ${styles.icon}`}>
          <h6>Payroll file</h6>
        </div>
        <div className={`${styles.cell} ${styles.icon}`}>
          <h6>Validation errors</h6>
        </div>
        <div className={`${styles.cell} ${styles.icon}`}>
          <h6>Completed</h6>
        </div>
      </Card>
    )    
  }

  const renderListItems = () => {
    return paydaysOnPage?.map((item, index) => {
      return (
        <Link to={{pathname: `/paydays/${item.id}`}} key={`payday_${index}`}>
          <Card className={`${styles.container} card ${item.highlighted ? styles.highlighted : ''}`}>
            <div className={styles.cell}>
              {formatDateString(item.paymentDate)}
            </div>
            <div className={styles.cell}>
              {item.employerName}
            </div>
            <div className={`${styles.cell} ${styles.icon}`}>
              {item.offCycle && 
                <FontAwesomeIcon
                    icon={faCheck}
                    color={Colors.euro_600}
                    title=''
                />}
            </div>
            <div className={`${styles.cell} ${styles.icon}`}>
              {item.payrollFileSubmitted && 
                <FontAwesomeIcon
                    icon={faCheck}
                    color={Colors.euro_600}
                    title=''
                />}
            </div>
            <div className={`${styles.cell} ${styles.icon}`}>
              {item.hasValidationErrors && 
                <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    color={Colors.euro_600}
                    title=''
                />}
            </div>
            <div className={`${styles.cell} ${styles.icon}`}>
              {item.completed && 
                <FontAwesomeIcon
                    icon={faCheck}
                    color={Colors.euro_600}
                    title=''
                />}
            </div>
          </Card>
        </Link>
      )
    })
  }

  return (
    <div>
      {renderHeader()}
      {renderListItems()}
      <div className={styles.pagination}>
        <Pagination
          pageIndex={pageIndex}
          itemCount={totalCount}
          pageSize={pageSize}
          siblingCount={1}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  )
}

export default PaydayList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    margin: '10px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    margin: '30px 0 20px 0',
  },
  cell: {
    flex: 1,
    padding: '0px 10px'
  },
  icon: {
    textAlign: 'center',  
  },
  highlighted: {
    backgroundColor: Colors.rubel5,
    color: Colors.darkGray4
  },
  pagination: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center'
  }
})
