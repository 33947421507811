import { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import useShiftActions from '../../hooks/useShiftActions'
import ShiftList from './ShiftList'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { filterChanged, pageIndexChanged } from '../../redux/slices/shiftSlice'
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect'

interface Props { 
  employeeId?: string
}

const ShiftListComponent = ({ employeeId }: Props) => {
  const styles = useStyles()
  const { getShifts } = useShiftActions()
  const dispatch = useAppDispatch()

  const shiftsOnPage = useAppSelector(state => state.shift.shiftsOnPage)
  const totalCount = useAppSelector(state => state.shift.totalCount)
  const pageIndex = useAppSelector(state => state.shift.pageIndex)
  const pageSize = useAppSelector(state => state.shift.pageSize)
  const filterEmployeeId = useAppSelector(state => state.shift.employeeId)
  const filterPaydayId = useAppSelector(state => state.shift.paydayId)

  useEffect(() => {
    if (employeeId && employeeId !== filterEmployeeId)
      dispatch(filterChanged({ employeeId: employeeId, paydayId: ''}))
  }, [employeeId])

  useDidUpdateEffect(() => {
    if (filterEmployeeId) {
      getShifts(filterEmployeeId, filterPaydayId, pageIndex, pageSize)
    }
  }, [filterEmployeeId, filterPaydayId, pageIndex])

  const onPageChange = (pageIndex: number) => {
    dispatch(pageIndexChanged(pageIndex))
  }

  const onPaydayFilterChange = (name: string, value: string) => {
    dispatch(filterChanged({ employeeId: filterEmployeeId as string, paydayId: value}))
  }

  return (
    <div className={styles.container}>
      {/* <ShiftFilter 
        filterValue={searchTerm || ''}
        onFilterChange={onFilterChange}
      />  */}
      <ShiftList 
        shiftsOnPage={shiftsOnPage}
        pageIndex={pageIndex}
        pageSize={pageSize} 
        totalCount={totalCount}
        onPageChange={onPageChange}
      />
    </div>
  )
}

export default ShiftListComponent

const useStyles = createUseStyles({
  container: {},
})