import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { useHistory, useParams } from "react-router-dom"
import CardSection from "../components/CardSection"
import DetailsCard from "../components/DetailsCard"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import NavLink from "../components/NavLink"
import EditProtectRuleModal from "../components/Protect/EditProtectRuleModal"
import IssueList from "../components/Protect/IssueList"
import RuleList from "../components/Protect/RuleList"
import { titleMarginBottom } from "../constants/layout"
import useProtectCustomerActions from "../hooks/useProtectCustomerActions"
import { useAppSelector } from "../redux/hooks"
import { CreateOrUpdateProtectRuleModel } from "../redux/types"

const ProtectCustomer = () => {
    const styles = useStyles()
    const params = useParams<{id: string}>()
    const history = useHistory()

    const { getProtectCustomer, createRule } = useProtectCustomerActions()

    const loading = useAppSelector(state => state.protectCustomer.loading)
    const protectCustomer = useAppSelector(state => state.protectCustomer.customerDetails)

    const [addRuleModalVisible, setAddRuleModalVisible] = useState(false)

    useEffect(() => {
        if (protectCustomer == null || protectCustomer.id !== params.id) {
          getProtectCustomer(params.id)
        } 
    }, [])

    const handleAddRuleClick = () => {
        setAddRuleModalVisible(true)
    }

    const handleAddRule = (model: CreateOrUpdateProtectRuleModel) => {
        setAddRuleModalVisible(false)
        createRule(model)
    }

    return (
        <div className={styles.container}>
            <h1>{protectCustomer?.name}</h1>
            <div className={styles.actionBar}>
                <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()} />
            </div>
            <CardSection>
                <DetailsCard iconType="Add" onClick={handleAddRuleClick}>
                <h6>Rules</h6>
                <RuleList 
                    rules={protectCustomer?.rules ?? []}
                />
                </DetailsCard>
            </CardSection>
            <CardSection>
                <DetailsCard>
                    <h6>Issues</h6>
                    <IssueList 
                        displayEmployerColumn={false}
                        issuesOnPage={protectCustomer?.issues ?? []}
                        pageIndex={1}
                        pageSize={protectCustomer?.issues.length ?? 0}
                        totalCount={protectCustomer?.issues.length ?? 0}
                        onPageChange={() => {}}
                    />
                </DetailsCard>
            </CardSection>
            <FullScreenLoadingIndicator visible={loading} />
            <EditProtectRuleModal 
                visible={addRuleModalVisible}
                employerId={protectCustomer?.id ?? ''}
                onCancelClick={() => setAddRuleModalVisible(false)}
                onSaveClick={handleAddRule}
            />
        </div>
      )
}

export default ProtectCustomer

const useStyles = createUseStyles({
    container: {
      '& h1': {
        marginBottom: titleMarginBottom
      }
    },
    actionBar: {
      marginTop: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between', 
      alignItems: 'center'
    }
  })