import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProtectRule } from '../types'

interface ProtectRuleState {
    loading: boolean
    ruleDetails: ProtectRule | null
    error: Error | string | null
}

const initialState: ProtectRuleState = {
    loading: false,
    ruleDetails: null,
    error: null
}

export const protectRuleSlice = createSlice({
    name: 'protectRuleSlice',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        ruleFetched: (state, action: PayloadAction<ProtectRule>) => {
            state.loading = false
            state.ruleDetails = action.payload
            state.error = null
        },
        ruleFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        ruleUpdated: (state, action: PayloadAction<ProtectRule>) => {
            state.loading = false
            state.ruleDetails = action.payload
            state.error = null
        },
        ruleUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        ruleDeleted: (state, action: PayloadAction<string>) => {
            state.loading = false
            state.error = null

            if (state.ruleDetails?.id === action.payload) {
                state.ruleDetails = {...state.ruleDetails, deleted: true}
            }
        },
        ruleDeleteFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        }
    }
})

export const { 
    loading, 
    ruleFetched, 
    ruleFetchFailure,
    ruleUpdated,
    ruleUpdateFailure,
    ruleDeleted,
    ruleDeleteFailure
} = protectRuleSlice.actions

export default protectRuleSlice.reducer