import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AmlFlaggedCompanyListItemModel, CompanyAmlDetailsModel } from '../companyMonitorTypes'

interface AmlFlaggedCompanyState {
    loading: boolean
    companyDetails: CompanyAmlDetailsModel | null
    companies: AmlFlaggedCompanyListItemModel[]
    error: Error | string | null
  }

const initialState: AmlFlaggedCompanyState = {
    loading: false,
    companyDetails: null,
    companies: [],
    error: null
}

export const amlFlaggedCompanySlice = createSlice({
    name: 'amlFlaggedCompanySlice',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        amlFlaggedCompaniesFetched: (state, action: PayloadAction<AmlFlaggedCompanyListItemModel[]>) => {
            state.loading = false
            state.companies = action.payload
            state.error = null
        },
        amlFlaggedCompanyFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        amlFlaggedCompanyDetailsFetched: (state, action: PayloadAction<CompanyAmlDetailsModel>) => {
            state.loading = false
            state.companyDetails = action.payload
            state.error = null
        },
        amlFlaggedCompanyDetailsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        amlFlaggedCompanyAccepted: (state, action: PayloadAction<CompanyAmlDetailsModel>) => {
            state.loading = false
            state.companyDetails = action.payload
            state.companies = []
            state.error = null
        },
        amlFlaggedCompanyAcceptFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        }
    }
})

export const { 
    loading, 
    amlFlaggedCompaniesFetched, 
    amlFlaggedCompanyFetchFailure, 
    amlFlaggedCompanyDetailsFetched, 
    amlFlaggedCompanyDetailsFetchFailure, 
    amlFlaggedCompanyAccepted, 
    amlFlaggedCompanyAcceptFailure, 
} = amlFlaggedCompanySlice.actions

export default amlFlaggedCompanySlice.reducer