import {createUseStyles} from "react-jss"
import {Colors} from "../../constants/colors"
import {InAppMessageTranslation, Language} from "../../redux/types"
import Card from "../Card"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";

interface Props {
  messages: InAppMessageTranslation[],
  onEditClick?: (message: InAppMessageTranslation) => void
}

const LocalizedInAppMessageList = ({messages, onEditClick}: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        <div className={styles.languageCell}>
          <h6>Language</h6>
        </div>
        <div className={styles.cell}>
          <h6>Type</h6>
        </div>
        <div className={styles.cell}>
          <h6>Title</h6>
        </div>
        <div className={styles.cell}>
          <h6>Description</h6>
        </div>
        <div className={styles.cell}>
          <h6>Button text</h6>
        </div>
      </Card>
    )
  }

  const renderEditButton = (message: InAppMessageTranslation) => {
    return <FontAwesomeIcon
      className={styles.button}
      icon={faEdit}
      color={Colors.euro_600}
      title='Edit'
      onClick={() => onEditClick && onEditClick(message)}
    />
  }

  const renderListItems = () => {
    return Object.keys(Language)
      .filter(v => isNaN(Number(v)))
      .map((value, index) => {
        let message = messages?.find(x => x.language === value)

        if (!message) {
          message = {
            language: Language[value as keyof typeof Language],
            type: '',
            title: '',
            description: '',
            buttonText: '',
          }
        }

        return (
          <Card key={index} className={`${styles.container} card`}>
            <div className={styles.languageCell}>
              {value}
            </div>
            <div className={styles.cell}>
              {message?.type || '-'}
            </div>
            <div className={styles.cell}>
              {message?.title || '-'}
            </div>
            <div className={styles.cell}>
              {message?.description || '-'}
            </div>
            <div className={styles.cell}>
              {message?.buttonText || '-'}
            </div>
            <div className={`${styles.cell} ${styles.iconCell} ${styles.buttonCell}`}>
              {renderEditButton(message)}
            </div>
          </Card>
        )
      })
  }

  return (
    <div>
      {renderHeader()}
      {renderListItems()}
    </div>
  )
}

export default LocalizedInAppMessageList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    margin: '10px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    margin: '30px 0 20px 0',
  },
  languageCell: {
    width: 100
  },
  cell: {
    flex: 1
  },
  iconCell: {
    textAlign: 'center',
  },
  buttonCell: {
    flex: '0 0 40px'
  },
  button: {
    transition: '0.4s',
    '&:hover': {
      color: Colors.euro5,
      cursor: 'pointer',
    }
  }
})