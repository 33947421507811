
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CommunicationOptOutsModel } from '../types'

interface communicationOptOutState {
    loading: boolean
    optOuts: CommunicationOptOutsModel | null
    error: Error | string | null
}

const initialState: communicationOptOutState = {
    loading: false,
    optOuts: null,
    error: null
}

export const communicationOptOutSlice = createSlice({
    name: 'communicationOptOut',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        optOutsFetched: (state, action: PayloadAction<CommunicationOptOutsModel>) => {
            state.loading = false
            state.optOuts = action.payload
            state.error = null
        },
        optOutsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employeeCommunicationOptOutStateSet: (state, action: PayloadAction<CommunicationOptOutsModel>) => {
            state.loading = false
            state.optOuts = action.payload
            state.error = null
        },
        employeeCommunicationOptOutStateSetFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        userCommunicationOptOutStateSet: (state, action: PayloadAction<CommunicationOptOutsModel>) => {
            state.loading = false
            state.optOuts = action.payload
            state.error = null
        },
        userCommunicationOptOutStateSetFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        }
    }
})

export const { 
    loading, 
    optOutsFetched, 
    optOutsFetchFailure, 
    employeeCommunicationOptOutStateSet, 
    employeeCommunicationOptOutStateSetFailure, 
    userCommunicationOptOutStateSet,
    userCommunicationOptOutStateSetFailure,
} = communicationOptOutSlice.actions

export default communicationOptOutSlice.reducer