import Modal from '../Modal'

interface Props {
    confirmText?: string
    okButtonTitle?: string
    cancelButtonTitle?: string
    onConfirmClick?: () => void
    onCancelClick?: () => void
    visible: boolean
}

const ConfirmModal = ({
    confirmText = 'Are you sure?',
    okButtonTitle = 'Yes',
    cancelButtonTitle  = 'Cancel',
    onConfirmClick,
    onCancelClick,
    visible
}: Props) => {
    const handleOnConfirm = () => {
        onConfirmClick && onConfirmClick()
    }

    const handleOnCancel = () => {
        onCancelClick && onCancelClick()
    }

    const handleOnHide = () => {
        onCancelClick && onCancelClick()
    }

    return (
        <Modal
            title='Confirm'
            show={visible}
            okButtonTitle={okButtonTitle}
            cancelButtonTitle={cancelButtonTitle}
            onHide={() => handleOnHide()}
            onOkClick={() => handleOnConfirm()}
            onCancelClick={() => handleOnCancel()}
        >
            <h6>{confirmText}</h6>
        </Modal>
    )
}

export default ConfirmModal
