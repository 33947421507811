import { useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { createUseStyles } from 'react-jss'
import { titleMarginBottom } from '../constants/layout'
import { formatDateTimeString } from '../utils/dateHelper'
import CardSection from '../components/CardSection'
import DetailsCard from '../components/DetailsCard'
import Card from '../components/Card'
import { roundToMaxTwoDecimals } from '../utils/numberHelper'
import { Colors } from '../constants/colors';
import usePayRunActions from '../hooks/usePayRunActions';
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator';
import { useAppSelector } from '../redux/hooks';
import Link from '../components/Link';
import NavLink from '../components/NavLink';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const PayRunDetails = () => {
  const styles = useStyles()
  const params = useParams<{id: string, paydayId: string}>()
  const history = useHistory();
  const { getPayRunDetails } = usePayRunActions()

  const loading = useAppSelector(state => state.payRun.loading)
  const payRunDetails = useAppSelector(state => state.payRun.payRunDetails)

  useEffect(() => {
    if (payRunDetails == null || payRunDetails.id !== params.id) {
      getPayRunDetails(params.paydayId, params.id)
    }
  }, [])

  const isFrozen = () => {
    return (payRunDetails?.frozenDueToMissingInPayrollFile || payRunDetails?.frozenDueToPendingPayouts || payRunDetails?.frozenDueToSalaryDeficit)
  }

  const renderPayoutAttemptsHeader = () => {
    return (
      <Card className={styles.listContainer}>
        <div className={styles.listCell}>
          <p>Initiated</p>
        </div>
        <div className={styles.listCell}>
          <p>Scheduled</p>
        </div>
        <div className={styles.listCell}>
          <p>Completed</p>
        </div>
        <div className={styles.listCell}>
          <p>Payout method</p>
        </div>
        <div className={styles.listCell}>
          <p>State</p>
        </div>
      </Card>)
  }

  const renderPayoutAttempts = () => {
    return payRunDetails?.payoutAttempts?.map((attempt) => {
      return (<Card className={styles.listContainer}>
        <div className={styles.listCell}>
          <p><span>{formatDateTimeString(attempt.initiatedAt)}</span></p>
        </div>
        <div className={styles.listCell}>
          <p><span>{formatDateTimeString(attempt.scheduledAt)}</span></p>
        </div>
        <div className={styles.listCell}>
          <p><span>{attempt.completedAt && formatDateTimeString(attempt.completedAt)}</span></p>
        </div>
        <div className={styles.listCell}>
          <p><span>{attempt.payoutMethod.payoutMethodType} {attempt.payoutMethod.target}</span></p>
        </div>
        <div className={styles.listCell}>
          <p><span>{attempt.state}</span></p>
        </div>
    </Card>)
    })
  }

  return (
    <div className={styles.container}>
      <h1>Pay run for {payRunDetails && `${payRunDetails.userGivenName} ${payRunDetails.userFamilyName}`}</h1>
      <div className={styles.actionBar}>
        <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()} />
      </div>
      <CardSection>
        <DetailsCard>
          <h6>General</h6>
          <p>Date: <span>{payRunDetails && formatDateTimeString(payRunDetails.created)}</span></p>
          <p>Payday: <Link to={`/paydays/${payRunDetails?.payDayId}`}>Link</Link></p>
          <p>Employer: <Link to={`/employers/${payRunDetails?.employerId}`}>{payRunDetails?.employerName}</Link></p>
          <p>Employee: <Link to={`/employees/${payRunDetails?.employeeId}`}>{payRunDetails?.userGivenName} {payRunDetails?.userFamilyName}</Link></p>
          <p>User: <Link to={`/users/${payRunDetails?.userId}`}>{payRunDetails?.userGivenName} {payRunDetails?.userFamilyName}</Link></p>
        </DetailsCard>
        <DetailsCard>
          <h6>Type and state</h6>
          <p>Type: <span>{payRunDetails?.type}</span></p>
          <p>Partial: <span>{payRunDetails?.partial ? 'true' : 'false'}</span></p>
          <p>Overall state: <span>{payRunDetails?.state} {isFrozen() ? 'but frozen' : ''}</span></p>
          {payRunDetails?.frozenDueToMissingInPayrollFile && <p>Frozen due to missing in payroll report: <span>true</span></p>}
          {payRunDetails?.frozenDueToPendingPayouts && <p>Frozen due to pending payouts: <span>true</span></p>}
          {payRunDetails?.frozenDueToSalaryDeficit && <p>Frozen due to salary deficit: <span>true</span></p>}
          <p>Payouts/fee transfer state: <span>{payRunDetails?.debtTransferState}</span></p>
          <p>Pay transfer state: <span>{payRunDetails?.payTransferState}</span></p>
          <p>Employer deduction state: <span>{payRunDetails?.employerDeductionState}</span></p>
        </DetailsCard>
        <DetailsCard>
          <h6>Amounts</h6>
          <p>Original salary: <span>{payRunDetails?.originalSalary && `${roundToMaxTwoDecimals(payRunDetails.originalSalary.amount).toLocaleString('sv-SE')} ${payRunDetails.originalSalary.currencyCode.toLocaleUpperCase()}`}</span></p>
          <p>Deducted payout amount: <span>{payRunDetails?.deductedPayoutAmount && `${roundToMaxTwoDecimals(payRunDetails.deductedPayoutAmount.amount).toLocaleString('sv-SE')} ${payRunDetails.deductedPayoutAmount.currencyCode.toLocaleUpperCase()}`}</span></p>
          <p>Deducted fee amount: <span>{payRunDetails?.deductedFeeAmount && `${roundToMaxTwoDecimals(payRunDetails.deductedFeeAmount.amount).toLocaleString('sv-SE')} ${payRunDetails.deductedFeeAmount.currencyCode.toLocaleUpperCase()}`}</span></p>
          <p>Salary after deductions: <span>{payRunDetails?.salaryAfterDeductions && `${roundToMaxTwoDecimals(payRunDetails.salaryAfterDeductions.amount).toLocaleString('sv-SE')} ${payRunDetails.salaryAfterDeductions.currencyCode.toLocaleUpperCase()}`}</span></p>
          <p>Deductions by employer: <span>{payRunDetails?.deductedPayoutAmountByEmployer && `${roundToMaxTwoDecimals(payRunDetails.deductedPayoutAmountByEmployer.amount).toLocaleString('sv-SE')} ${payRunDetails.deductedPayoutAmountByEmployer.currencyCode.toLocaleUpperCase()}`}</span></p>
        </DetailsCard>
      </CardSection>
      <CardSection>
        <DetailsCard>
          <h6>Payout attempts</h6>
          {renderPayoutAttemptsHeader()}
          {renderPayoutAttempts()}
        </DetailsCard>
      </CardSection>
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default PayRunDetails

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    },
    '& h2': {
      paddingTop: '30px',
      paddingBottom: '20px'
    }
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    margin: '10px 0',
    transition: '0.4s',
    border: '1px solid',
    borderColor: Colors.darkGray5
  },
  listCell: {
    flex: 1,
    padding: '0px 10px'
  },
})
