import {restConfig} from "../authConfig"
import {useAppDispatch} from "../redux/hooks"
import {failure, imageDeleted, imagesFetched, imageUploaded, loading} from "../redux/slices/learningSlice"
import {ImageModel} from '../redux/types'
import useFetchClient from "./useFetchClient"

const useLearningActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getImages = () => {
    dispatch(loading())

    const url = restConfig.routes.learning + '/images'

    return fetchClient.get(url)
      .then(response => {
        dispatch(imagesFetched(response.data as ImageModel[]))
      })
      .catch(error => {
        console.log('getImages error:', error)
        dispatch(failure(error))
      })
  }

  const uploadImage = (file: File, overwrite: boolean) => {
    dispatch(loading())
    const url = restConfig.routes.learning + `/images?overwrite=${overwrite}`

    return fetchClient.postFile(url, file)
      .then(response => {
        dispatch(imageUploaded(response.data as ImageModel))
      })
      .catch(error => {
        console.log('image upload error:', error)
        dispatch(failure(error))
      })
  }

  const deleteImage = (fileName: string) => {
    dispatch(loading())
    const url = restConfig.routes.learning + `/images/${fileName}`

    return fetchClient.delete(url)
      .then(response => {
        dispatch(imageDeleted(fileName))
      })
      .catch(error => {
        console.log('image delete error:', error)
        dispatch(failure(error))
      })
  }

  return {
    getImages,
    uploadImage,
    deleteImage
  }
}

export default useLearningActions
