import { restConfig } from "../authConfig";
import { useAppDispatch } from "../redux/hooks";
import { 
  employeeCommunicationOptOutStateSet, 
  employeeCommunicationOptOutStateSetFailure, 
  optOutsFetched, 
  optOutsFetchFailure, 
  userCommunicationOptOutStateSet, 
  userCommunicationOptOutStateSetFailure, 
  loading } from "../redux/slices/communicationOptOutSlice";
import { CommunicationOptOutsModel } from "../redux/types";
import useFetchClient from "./useFetchClient";

const useCommunicationOptOutActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getOptOuts = () => {
    dispatch(loading())   

    return fetchClient.get(restConfig.routes.communicationOptOut)
      .then(response => {
          dispatch(optOutsFetched(response.data as CommunicationOptOutsModel))
      })
      .catch(error => {
          console.log('getOptOuts error:', error)
          dispatch(optOutsFetchFailure(error))
      })
  }

  const setCommunicationOptOutStateForEmployee = (employeeId: string, optOut: boolean) => {
    dispatch(loading())
    const url = restConfig.routes.communicationOptOut + `/employee/${employeeId}`

    return fetchClient.post(url, { 'optOut': optOut })
      .then(response => {
          dispatch(employeeCommunicationOptOutStateSet(response.data as CommunicationOptOutsModel))
      })
      .catch(error => {
          console.log('setCommunicationOptOutStateForEmployee error:', error)
          dispatch(employeeCommunicationOptOutStateSetFailure(error))
      })
  }

  const setCommunicationOptOutStateForUser = (userId: string, optOut: boolean) => {
    dispatch(loading())
    const url = restConfig.routes.communicationOptOut + `/user/${userId}`

    return fetchClient.post(url, { 'optOut': optOut })
      .then(response => {
          dispatch(userCommunicationOptOutStateSet(response.data as CommunicationOptOutsModel))
      })
      .catch(error => {
          console.log('setCommunicationOptOutStateForUser error:', error)
          dispatch(userCommunicationOptOutStateSetFailure(error))
      })
  }

  return {
    getOptOuts,
    setCommunicationOptOutStateForEmployee,
    setCommunicationOptOutStateForUser
  }
}

export default useCommunicationOptOutActions