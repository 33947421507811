import { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
import { Link as ReactLink } from 'react-router-dom'
import { Colors } from '../constants/colors'

interface Props {
  children: ReactNode
  className?: string
  to: string
}

const Link = ({
  children,
  className,
  to,
}: Props) => {
  const styles = useStyles()

  return <ReactLink className={`${styles.linkText} ${className}`} to={to}>{children}</ReactLink>
}

export default Link

const useStyles = createUseStyles({
  linkText: {
    color: Colors.euro_600, 
    fontSize: 13
  }
})


