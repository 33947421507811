import {useEffect} from 'react'
import {createUseStyles} from 'react-jss'
import {useHistory, useParams} from "react-router-dom";
import {Colors} from '../constants/colors'
import CardSection from '../components/CardSection'
import DetailsCard from '../components/DetailsCard'
import {formatDateTimeString} from '../utils/dateHelper'
import {useAppSelector} from '../redux/hooks';
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator';
import Divider from '../components/Divider';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import NavLink from '../components/NavLink';
import {titleMarginBottom} from '../constants/layout';
import useInvoicePayoutActions from '../hooks/useInvoicePayoutActions';

const InvoicePayoutDetails = () => {
  const styles = useStyles()
  const params = useParams<{providerId: string, payoutId: string, repaymentId: string}>()
  const history = useHistory();
  
  const loading = useAppSelector(state => state.invoicePayout.loading)
  const invoicePayout = useAppSelector(state => state.invoicePayout.invoicePayoutDetails)

  const {
    getInvoicePayoutDetailsById,
    getInvoicePayoutDetailsByRepaymentId
  } = useInvoicePayoutActions()

  useEffect(() => {
    if (params.payoutId) {
      if (invoicePayout?.id == null || invoicePayout.id !== params.payoutId) {
        getInvoicePayoutDetailsById(params.providerId, params.payoutId)
      }
    }
    else if (params.repaymentId) {
      if (invoicePayout?.id == null || invoicePayout.basis.repayment.id !== params.repaymentId) {
        getInvoicePayoutDetailsByRepaymentId(params.providerId, params.repaymentId)
      }
    }
  }, [])

  const renderGeneralCardBody = () => {
    return (
      <div>
        <p>State: <span>{invoicePayout?.state}</span></p>
        <p>Created: <span>{formatDateTimeString(invoicePayout?.createDate)}</span></p>
        <p>Completed: <span>{formatDateTimeString(invoicePayout?.completedDate)}</span></p>
        <p>Amount before fee: <span>{invoicePayout?.amountBeforeFee.amount.toLocaleString('sv-SE')} {invoicePayout?.amountBeforeFee.currencyCode.toUpperCase()}</span></p>
        <p>Amount after fee: <span>{invoicePayout?.amountAfterFee.amount.toLocaleString('sv-SE')} {invoicePayout?.amountAfterFee.currencyCode.toUpperCase()}</span></p>
        <p>Cappy fee: <span>{invoicePayout?.cappyFee.amount.toLocaleString('sv-SE')} {invoicePayout?.cappyFee.currencyCode.toUpperCase()}</span></p>
        <p>Provider fee: <span>{invoicePayout?.providerFee.amount.toLocaleString('sv-SE')} {invoicePayout?.providerFee.currencyCode.toUpperCase()}</span></p>
      </div>
    )
  }

  const renderBasisCardBody = () => {
    return (
      <div>
        <p>Invoice number: <span>{invoicePayout?.basis.invoiceNumber}</span></p>
        <p>Invoice reference: <span>{invoicePayout?.basis.invoiceReference}</span></p>
        <p>Due date: <span>{invoicePayout?.basis.dueDateString}</span></p>
      </div>
    )
  }

  const renderRepaymentCardBody = () => {
    return (
      <div>
        <p>State: <span>{invoicePayout?.basis.repayment.state}</span></p>
        <p>Due date: <span>{formatDateTimeString(invoicePayout?.basis.repayment.dueDate)}</span></p>
        <p>Reference: <span>{invoicePayout?.basis.repayment.reference}</span></p>
      </div>
    )
  }

  const renderRecipientCardBody = () => {
    return (
      <div>
        <p>Identification number: <span>{invoicePayout?.basis.recipient.identificationNumber}</span></p>
        <p>Name: <span>{invoicePayout?.basis.recipient.name}</span></p>
        <p>Street: <span>{invoicePayout?.basis.recipient.street}</span></p>
        <p>Postal code: <span>{invoicePayout?.basis.recipient.postalCode}</span></p>
        <p>City: <span>{invoicePayout?.basis.recipient.city}</span></p>
        <p>Country: <span>{invoicePayout?.basis.recipient.country}</span></p>
      </div>
    )
  }

  const renderBankTransferCardBody = () => {
    return (
      <div>
        <p>Type: <span>{invoicePayout?.basis.bankTransfer.type}</span></p>
        <p>Account: <span>{invoicePayout?.basis.bankTransfer.bankAccount}</span></p>
        {invoicePayout?.basis.bankTransfer.bankCode && <p>Bank code: <span>{invoicePayout?.basis.bankTransfer.bankCode}</span></p>}
        {invoicePayout?.basis.bankTransfer.bic && <p>Bic: <span>{invoicePayout?.basis.bankTransfer.bic}</span></p>}
        {invoicePayout?.basis.bankTransfer.ocrNumber &&<p>Ocr number: <span>{invoicePayout?.basis.bankTransfer.ocrNumber}</span></p>}
        {invoicePayout?.basis.bankTransfer.regulatoryPurposeCode &&<p>Regulatory purpose code: <span>{invoicePayout?.basis.bankTransfer.regulatoryPurposeCode}</span></p>}
        {invoicePayout?.basis.bankTransfer.type == 'crossBorderIbanTransfer' && invoicePayout?.basis.bankTransfer.recipientIdentificationNumber &&<p>Identification number: <span>{invoicePayout?.basis.bankTransfer.recipientIdentificationNumber}</span></p>}
        {invoicePayout?.basis.bankTransfer.recipientName &&<p>Name: <span>{invoicePayout?.basis.bankTransfer.recipientName}</span></p>}
        {invoicePayout?.basis.bankTransfer.recipientStreet &&<p>Street: <span>{invoicePayout?.basis.bankTransfer.recipientStreet}</span></p>}
        {invoicePayout?.basis.bankTransfer.recipientPostalCode &&<p>Postal code: <span>{invoicePayout?.basis.bankTransfer.recipientPostalCode}</span></p>}
        {invoicePayout?.basis.bankTransfer.recipientCity &&<p>City: <span>{invoicePayout?.basis.bankTransfer.recipientCity}</span></p>}
        {invoicePayout?.basis.bankTransfer.recipientCountry &&<p>Country: <span>{invoicePayout?.basis.bankTransfer.recipientCountry}</span></p>}
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <h1>Invoice payout: {formatDateTimeString(invoicePayout?.createDate)}</h1>
      <div className={styles.actionBar}>
        <div className={styles.actionBarCell} style={{justifyContent: 'flex-start'}}>
          <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()} />
        </div>
        <div className={styles.actionBarCell} style={{justifyContent: 'flex-end'}}>
        </div>
      </div>
      <CardSection>
        <DetailsCard>
          <h6>General</h6>
          {renderGeneralCardBody()}
        </DetailsCard>
        <DetailsCard>
          <h6>Basis</h6>
          {renderBasisCardBody()}
        </DetailsCard>
        <DetailsCard>
          <h6>Repayment</h6>
          {renderRepaymentCardBody()}
        </DetailsCard>
        <DetailsCard>
          <h6>Recipient</h6>
          {renderRecipientCardBody()}
        </DetailsCard>
        <DetailsCard>
          <h6>Bank transfer</h6>
          {renderBankTransferCardBody()}
        </DetailsCard>
      </CardSection>
      <Divider height={50}/>
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default InvoicePayoutDetails

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    },
    '& h5': {
      fontFamily: 'Aestetico-Regular',
      color: Colors.darkDefault,
    }
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionBarCell: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 15,
    alignItems: 'center',
    width: '33%'
  },
  navLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10
  }
})
