import {createUseStyles} from 'react-jss'
import {titleMarginBottom} from '../constants/layout'
import useLearningActions from "../hooks/useLearningActions";
import {useAppSelector} from "../redux/hooks";
import React, {useEffect, useState} from "react";
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator";
import {
  LearningModuleActionManagementModel,
  LearningModuleManagementModel,
  LearningModuleMarkdownManagementModel,
  LearningModuleQuizManagementModel,
  LearningModuleType
} from "../redux/types";
import {useHistory, useParams} from "react-router-dom";
import NavLink from "../components/NavLink";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import useRouterQuery from "../hooks/useRouterQuery";
import MarkdownModuleEditor from "../components/Learning/MarkdownModuleEditor";
import QuizModuleEditor from "../components/Learning/QuizModuleEditor";
import ActionModuleEditor from "../components/Learning/ActionModuleEditor";
import useLearningEditor from "../hooks/useLearningEditor";


const ModuleDetails = () => {
  const styles = useStyles()
  const params = useParams<{ courseId: string, id: string | undefined }>()
  const history = useHistory()
  const {query} = useRouterQuery()

  const {
    getLearningCourses,
    createLearningModule,
    updateLearningModule,
    publishLearningModule,
    unpublishLearningModule
  } = useLearningActions()

  const {createNewModule, toEditableModule, toLearningModuleCreateOrUpdateModel} = useLearningEditor()

  const loading = useAppSelector(state => state.learning.loading)
  const courses = useAppSelector(state => state.learning.learningCourses)

  const [editModel, setEditModel] = useState<LearningModuleManagementModel>(createNewModule(params.courseId, LearningModuleType.Markdown))
  const isNewModule = !params.id

  useEffect(() => {
    if (courses.length === 0) {
      getLearningCourses()
    }
  }, []);

  useEffect(() => {
    const course = courses.find(course => course.id === params.courseId)
    const module = course?.modules?.find(module => module.id === params.id)

    if (module) {
      setEditModel(toEditableModule(module))
    } else {
      const newModuleType = query.get('moduleType') as LearningModuleType || LearningModuleType.Markdown
      setEditModel(createNewModule(params.courseId, newModuleType))
    }
  }, [query, params.id, params.courseId, courses]);


  const saveModule = () => {
    const createOrUpdateModel = toLearningModuleCreateOrUpdateModel(editModel)
    if (isNewModule) {
      createLearningModule(
        params.courseId,
        createOrUpdateModel,
        (newModel) => {
          history.replace(`/learning/course/${newModel.learningCourseId}/module/${newModel.id}`)
        }
      )
    } else {
      updateLearningModule(params.courseId, params.id!, createOrUpdateModel)
    }
  }

  const publishModule = () => {
    if (!isNewModule) {
      publishLearningModule(params.id!)
    }
  }

  const unpublishModule = () => {
    if (!isNewModule) {
      unpublishLearningModule(params.id!)
    }
  }

  const renderTypeSpecificEditor = () => {
    const publishFunc = (isNewModule || editModel.isPublished) ? undefined : publishModule
    const unpublishFunc = (isNewModule || !editModel.isPublished) ? undefined : unpublishModule

    switch (editModel.moduleType) {
      case LearningModuleType.Markdown:
        return <MarkdownModuleEditor
          editModel={editModel as LearningModuleMarkdownManagementModel}
          onChange={setEditModel}
          onSave={saveModule}
          onPublish={publishFunc}
          onUnpublish={unpublishFunc}
        />
      case LearningModuleType.Quiz:
        return <QuizModuleEditor
          editModel={editModel as LearningModuleQuizManagementModel}
          onChange={setEditModel}
          onSave={saveModule}
          onPublish={publishFunc}
          onUnpublish={unpublishFunc}
        />
      case LearningModuleType.Action:
        return <ActionModuleEditor
          editModel={editModel as LearningModuleActionManagementModel}
          onChange={setEditModel}
          onSave={saveModule}
          onPublish={publishFunc}
          onUnpublish={unpublishFunc}
        />
      default:
        return null
    }
  }

  return (
    <div className={styles.page}>
      <h1>{isNewModule ? 'Create' : 'Edit'} {editModel.moduleType} module</h1>
      <div className={styles.titleBar}>
        <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()}/>
      </div>
      {renderTypeSpecificEditor()}
      <FullScreenLoadingIndicator visible={loading}/>
    </div>
  )
}

export default ModuleDetails

const useStyles = createUseStyles({
  page: {
    '& h1': {
      marginBottom: titleMarginBottom
    },
    marginBottom: 40
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
})
