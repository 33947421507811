import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from '../Modal'
import InputField from '../InputField'
import Switch from '../Switch'
import Select from '../Select'
import { PayoutSettings, CurrencyCodeString } from '../../redux/types'
import { useAppSelector } from '../../redux/hooks'
import useMarketActions from '../../hooks/useMarketActions'

interface Props {
    initialValue?: PayoutSettings
    marketId: string
    currencyEditable: boolean
    onSaveClick?: (payoutSettings: PayoutSettings) => void
    onCancelClick?: () => void
    visible: boolean
}

const EditPayoutSettingsModal = ({
    initialValue,
    marketId,
    currencyEditable = false,
    onSaveClick,
    onCancelClick,
    visible
}: Props) => {
    const [payoutSettings, setPayoutSettings] = useState<PayoutSettings | undefined>(initialValue)
    const markets = useAppSelector(state => state.market.markets)
    const { getMarkets } = useMarketActions();

    useEffect(() => {
        if (visible && markets.length === 0)
            getMarkets()
    }, [visible, markets])

    const getCurrency = () => {
        return markets.find(x => x.id === marketId)?.payoutSettings.currency?.toUpperCase()
    }

    useEffect(() => {
        if (initialValue)
            setPayoutSettings(initialValue)
    }, [initialValue])

    const handleOnSave = () => {
        if (payoutSettings) {
            let result = {...payoutSettings}
            
            if (!currencyEditable) 
                result.currency = undefined

            if (!result.enableCustomShiftSettings) 
                result.maxCustomPercentPerPayday = undefined
        
            onSaveClick && onSaveClick(result)       
        }
    }

    const handleOnClose = () => {
        onCancelClick && onCancelClick()
    }

    const handleOnChange = (propName: string, value: any) => {
        setPayoutSettings({...payoutSettings, [propName]: value === '' ? null : value})
    }

    const getCurrencyOptions = () => {
        return Object.keys(CurrencyCodeString).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    return (
        <Modal
            title='Edit Payout settings'
            show={visible}
            okButtonTitle='Save'
            cancelButtonTitle='Cancel'
            onHide={() => handleOnClose()}
            onOkClick={() => handleOnSave()}
            onCancelClick={() => handleOnClose()}
        >
            <InputField
                id='minAmountPerPayout'
                key='minAmountPerPayout'
                numeric={true}
                value={payoutSettings?.minAmountPerPayout?.toString() ?? ''}
                onChange={(event) => handleOnChange('minAmountPerPayout', event.target.value)}
                label={`Min amount per payout (${getCurrency()})`}
                placeholder=''
              />
            {/* <InputField
                id='maxAmountPerPayout'
                value={payoutSettings.maxAmountPerPayout?.toString() ?? ''}
                onChange={(event) => handleOnChange('maxAmountPerPayout', event.target.value)}
                label='Max amount per payout'
                placeholder=''
              /> */}
            <InputField
                id='maxAmountPerPayPeriod'
                key='maxAmountPerPayPeriod'
                numeric={true}
                value={payoutSettings?.maxAmountPerPayday?.toString() ?? ''}
                onChange={(event) => handleOnChange('maxAmountPerPayday', event.target.value)}
                label={`Max amount per pay period (${getCurrency()})`}
                placeholder=''
            />
            <InputField
                id='maxPayoutPercentPerPayPeriod'
                key='maxPayoutPercentPerPayPeriod'
                numeric={true}
                value={payoutSettings?.maxPercentPerPayday?.toString() ?? ''}
                onChange={(event) => handleOnChange('maxPercentPerPayday', event.target.value)}
                label='Max % per pay period'
                placeholder=''
            />
            <InputField
                id='maxNumberOfPayoutsPerPayPeriod'
                key='maxNumberOfPayoutsPerPayPeriod'
                numeric={true}
                value={payoutSettings?.maxNumberOfPayoutsPerPayday?.toString() ?? ''}
                onChange={(event) => handleOnChange('maxNumberOfPayoutsPerPayday', event.target.value)}
                label='Max # of payouts per pay period'
                placeholder=''
            />
            <InputField
                id='payoutFee'
                key='payoutFee'
                value={payoutSettings?.fee?.toString() ?? ''}
                numeric={true}
                onChange={(event) => handleOnChange('fee', event.target.value)}
                label={`Fee (${getCurrency()})`}
                placeholder=''
            />
            {currencyEditable && 
            <Select 
                id='payoutCurrency'
                key='payoutCurrency'
                label='Currency'
                options={getCurrencyOptions()} 
                selectedValue={payoutSettings?.currency}
                onSelect={(value) => handleOnChange('currency', CurrencyCodeString[value as keyof typeof CurrencyCodeString])}
            />}
            <Switch 
                id='enableCustomSettingsForOvertime'
                key='enableCustomSettingsForOvertime'
                checked={payoutSettings?.enableCustomShiftSettings ?? false} 
                label='Enable custom shift settings' 
                onChange={() => handleOnChange('enableCustomShiftSettings', !(payoutSettings?.enableCustomShiftSettings ?? false))} 
            />  
            {(payoutSettings?.enableCustomShiftSettings ?? false) && 
            <InputField
                id='maxOvertimePayoutPercentPerPayPeriod'
                key='maxOvertimePayoutPercentPerPayPeriod'
                numeric={true}
                value={payoutSettings?.maxCustomPercentPerPayday?.toString() ?? ''}
                onChange={(event) => handleOnChange('maxCustomPercentPerPayday', event.target.value)}
                label='Max % per pay period for custom shifts'
                placeholder=''
            />}
        </Modal>
    )
}

export default EditPayoutSettingsModal

const useStyles = createUseStyles({
    container: {
        alignItems: 'center',
        textAlign: 'center',
        padding: '12px 20px',
        margin: '10px 0',
    }
})