import {createUseStyles} from "react-jss"
import {Link} from "react-router-dom"
import {Colors} from "../../constants/colors"
import {CampaignRecipient} from "../../redux/types"
import Card from "../Card"

interface Props {
  recipients: CampaignRecipient[] | undefined
}

const RecipientList = ({recipients}: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        <div className={styles.cell}>
          <h6>Given name</h6>
        </div>
        <div className={styles.cell}>
          <h6>Family name</h6>
        </div>
      </Card>
    )
  }

  const renderListItems = () => {
    return recipients?.map((item, index) => {
      return (
        <Link to={`/users/${item.id}`} key={`recipient_${index}`}>
          <Card className={`${styles.container} card`}>
            <div className={styles.cell}>
              {item.givenName}
            </div>
            <div className={styles.cell}>
              {item.familyName}
            </div>
          </Card>
        </Link>
      )
    })
  }

  return (
    <div>
      {renderHeader()}
      {renderListItems()}
    </div>
  )
}

export default RecipientList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    margin: '10px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    margin: '30px 0 20px 0',
  },
  cell: {
    flex: 1
  }
})