import {restConfig} from "../authConfig";
import {CompanyCheckResponseModel} from "../redux/companyMonitorTypes";
import {useAppDispatch} from "../redux/hooks";
import {
  latestCompanyChecksFetched,
  latestCompanyChecksFetchFailure,
  loading,
  newCompanyCheckFetched,
  newCompanyCheckFetchFailure
} from "../redux/slices/companyCheckSlice";
import useFetchClient from "./useFetchClient";
import {CountryCode} from "../redux/types";

export interface CompanyCheckRequest {
  marketId: string
  country: CountryCode
  companyRegistrationNumber: string
}

const useCompanyCheckActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getLatestCompanyChecks = async () => {
    dispatch(loading())
    const url = restConfig.routes.companyCheck

    try {
      let response = await fetchClient.get(url);
      dispatch(latestCompanyChecksFetched(response.data as CompanyCheckResponseModel[]))
    } catch (error) {
      console.log('getLatestCompanyChecks error:', error)
      dispatch(latestCompanyChecksFetchFailure(error))
    }
  }

  const performCompanyCheck = async (request: CompanyCheckRequest) => {
    dispatch(loading())
    const url = restConfig.routes.companyCheck

    return fetchClient.post(url, request)
      .then(response => {
        dispatch(newCompanyCheckFetched(response.data as CompanyCheckResponseModel))
        return response.data as CompanyCheckResponseModel
      })
      .catch(error => {
        console.log('performCompanyCheck error:', error)
        dispatch(newCompanyCheckFetchFailure(error))
        return undefined
      })
  }

  return {
    getLatestCompanyChecks,
    performCompanyCheck
  }
}

export default useCompanyCheckActions
