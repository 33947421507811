import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CreditorModel } from '../types'

interface CreditorState {
    loading: boolean
    creditors: CreditorModel[]
    error: Error | string | null
  }

const initialState: CreditorState = {
    loading: false,
    creditors: [],
    error: null
}

export const creditorSlice = createSlice({
    name: 'creditor',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        creditorsFetched: (state, action: PayloadAction<CreditorModel[]>) => {
            state.loading = false
            state.creditors = action.payload
        },
        creditorsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
    }
})

export const { 
    loading, 
    creditorsFetched, 
    creditorsFetchFailure 
} = creditorSlice.actions

export default creditorSlice.reducer