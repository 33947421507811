import { useMsal } from "@azure/msal-react"
import { restConfig } from "../authConfig"
import authService from '../services/authService'

const useFetchClient = () => {
    const { instance } = useMsal()

    const request = (method: string) => {
        return async (url: string, body?: Object) => {
            const options: RequestInit = {
                method,
                headers: await createHeaders(url, 'application/json'),
                body: body && JSON.stringify(body)
            };
            return fetch(url, options).then(handleResponse);
        }
    }

    const createHeaders = async (url: string, contentType: string | undefined) => {
        const isApiRequest = url.startsWith(restConfig.defaultBaseUrl) || 
            url.startsWith(restConfig.protectBaseUrl) || 
            url.startsWith(restConfig.companyMonitorBaseURl) ||
            url.startsWith(restConfig.communicationBaseUrl)

        const token = await authService.getTokenAsync()
        const requestHeaders: HeadersInit = new Headers();

        if (token && isApiRequest) {
            requestHeaders.set('Authorization', `bearer ${token}`)
        }
        else {
            requestHeaders.set('Authorization', '')
        }

        if (contentType)
        {
            requestHeaders.set('Content-Type', contentType)
        }

        return requestHeaders
    }

    const handleResponse = (response: Response) => {
        return response.text().then(text => {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                if ([401, 403].includes(response.status)) {
                    console.log('Error: unauthenticated')
                    instance.logoutRedirect()
                }

                const error = (data && data.message) || response.statusText || response.status.toString();
                return Promise.reject(error);
            }

            return {data: data, raw: response};
        });
    }

    const postFile = async (url: string, file: File) => {
        const formData = new FormData();
        formData.append("file", file);

        return fetch(url, {
            method: "POST",
            body: formData,
            headers: await createHeaders(url, undefined)
        }).then(handleResponse);
    }

    return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        delete: request('DELETE'),
        patch: request('PATCH'),
        postFile
    };
}

export default useFetchClient
