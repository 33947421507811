import {createUseStyles} from "react-jss"
import {Language, LearningModuleQuizManagementModel, LearningModuleQuizQuestionModel} from "../../redux/types"
import MultilingualInputField from "./MultilingualInputField";
import InputField from "../InputField";
import EditQuizQuestionModal from "./EditQuizQuestionModal";
import React, {useState} from "react";
import {Toolbar, ToolbarItem} from "./Toolbar";
import {faEye, faEyeSlash, faFlag, faPlus, faSave} from "@fortawesome/free-solid-svg-icons";
import QuizModulePreview from "./QuizModulePreview";
import useLearningEditor from "../../hooks/useLearningEditor";


interface Props {
  editModel: LearningModuleQuizManagementModel
  onChange: (model: LearningModuleQuizManagementModel) => void
  onSave: () => void
  onPublish?: () => void
  onUnpublish?: () => void
}


const QuizModuleEditor = ({editModel, onChange, onSave, onPublish, onUnpublish}: Props) => {
  const styles = useStyles()
  const {previewLanguage, setPreviewLanguage} = useLearningEditor()
  const [editQuizQuestionModalVisible, setEditQuizQuestionModalVisible] = useState(false)
  const [editQuizQuestionModel, setEditQuizQuestionModel] = useState<LearningModuleQuizQuestionModel>()

  const handleOnChange = (propName: string, value: any) => {
    onChange({...editModel, [propName]: value})
  }

  const handleOnChangeQuestion = (question: LearningModuleQuizQuestionModel) => {
    if (editQuizQuestionModel) {
      const newQuestions = [...editModel.questions]
      const index = newQuestions.indexOf(editQuizQuestionModel)
      newQuestions[index] = question
      onChange({...editModel, questions: newQuestions})
    } else {
      onChange({...editModel, questions: [...editModel.questions, question]})
    }
    setEditQuizQuestionModalVisible(false)
  }

  const onAddQuestion = () => {
    setEditQuizQuestionModel(undefined)
    setEditQuizQuestionModalVisible(true)
  }

  const onClickQuestion = (question: LearningModuleQuizQuestionModel) => {
    setEditQuizQuestionModel(question)
    setEditQuizQuestionModalVisible(true)
  }

  return (
    <div className={styles.container}>
      <div className={styles.editor}>
        <div className={styles.titleBar}>
          <h2>Questions</h2>
          <Toolbar>
            <ToolbarItem icon={faFlag} title={"English"} display={"iconAndText"}
                         disabled={previewLanguage === Language.en}
                         onClick={() => setPreviewLanguage(Language.en)}/>
            <ToolbarItem icon={faFlag} title={"Swedish"} display={"iconAndText"}
                         disabled={previewLanguage === Language.sv}
                         onClick={() => setPreviewLanguage(Language.sv)}/>
          </Toolbar>
        </div>
        <QuizModulePreview
          quizModule={editModel}
          language={previewLanguage}
          onClickQuestion={onClickQuestion}
        />
      </div>

      <div className={styles.editor}>
        <div className={styles.titleBar}>
          <h2>Edit</h2>
          <Toolbar>
            <ToolbarItem icon={faPlus} title={"New question"} display={"iconAndText"} onClick={onAddQuestion}/>
            <ToolbarItem icon={faSave} title={"Save"} display={"iconAndText"} onClick={onSave}/>
            {onPublish && <ToolbarItem icon={faEye} title={"Publish"} display={"iconAndText"} onClick={onPublish}/>}
            {onUnpublish && <ToolbarItem icon={faEyeSlash} title={"Unpublish"} display={"iconAndText"} onClick={onUnpublish}/>}
          </Toolbar>
        </div>
        <MultilingualInputField
          id={'title'}
          label={'Title'}
          translations={editModel.title}
          onChange={(value) => handleOnChange('title', value)}
          onLanguageChange={setPreviewLanguage}
        />
        <InputField
          id='orderNumber'
          value={editModel.orderNumber.toString() || ''}
          onChange={(event) => handleOnChange('orderNumber', event.target.value)}
          label='Order Number'
          numeric
          placeholder='3'
        />
        <InputField
          id='estimatedCompletionTime'
          value={editModel.estimatedCompletionTime || ''}
          onChange={(event) => handleOnChange('estimatedCompletionTime', event.target.value)}
          label='Estimated completion time'
          placeholder='00:01:00'
        />
      </div>

      <EditQuizQuestionModal
        visible={editQuizQuestionModalVisible}
        initialValue={editQuizQuestionModel}
        onCancelClick={() => setEditQuizQuestionModalVisible(false)}
        onOkClick={handleOnChangeQuestion}
      />
    </div>
  )
}

export default QuizModuleEditor

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20
  },
  editor: {
    minWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 10
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
})
