import { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from '../Modal'
import InputField from '../InputField'
import { CreateFreePayoutModel } from '../../redux/types'
import { Colors } from '../../constants/colors'

interface Props {
    onSaveClick?: (freePayout: CreateFreePayoutModel) => void
    onCancelClick?: () => void
    visible: boolean
    maxCreateCount?: number
}

const CreateFreePayoutModal = ({ onSaveClick, onCancelClick, visible, maxCreateCount = 10 }: Props) => {
    const styles = useStyles()
    const [freePayout, setFreePayout] = useState<CreateFreePayoutModel>({ numberOfFreePayouts: 1 })
    const [isValidFreePayoutNumber, setValidFreePayoutNumber] = useState<boolean>(true)

    useEffect(() => {
        if (visible) {
            setFreePayout({numberOfFreePayouts: 1})
        }
    }, [visible])

    const handleOnSave = () => {
        if (freePayout.numberOfFreePayouts < 1 || freePayout.numberOfFreePayouts > maxCreateCount) {
            setValidFreePayoutNumber(false)
            return
        } else {
            setValidFreePayoutNumber(true)
        }

        if (freePayout) {
            onSaveClick && onSaveClick(freePayout)
        }
    }

    const handleOnClose = () => {
        onCancelClick && onCancelClick()
    }

    const handleOnChange = (value: any) => {
        setFreePayout({numberOfFreePayouts: value})
    }

    return (
        <Modal
            title='Create free payouts'
            show={visible}
            okButtonTitle='Save'
            cancelButtonTitle='Cancel'
            onHide={() => handleOnClose()}
            onOkClick={() => handleOnSave()}
            onCancelClick={() => handleOnClose()}
        >
            <InputField
                id='numberOfFreePayouts'
                key='numberOfFreePayouts'
                numeric={true}
                value={freePayout.numberOfFreePayouts.toString()}
                onChange={(event) => handleOnChange(event.target.value)}
                label={`Number of free payouts to create (1-${maxCreateCount})`}
                placeholder=''
              />
            {!isValidFreePayoutNumber &&
            <div className={styles.errorMessage}>
                The number of free payouts should be between 1 and {maxCreateCount}
            </div>
            }
        </Modal>
    )
}

export default CreateFreePayoutModal

const useStyles = createUseStyles({
    container: {
        alignItems: 'center',
        textAlign: 'center',
        padding: '12px 20px',
        margin: '10px 0',
    },
    errorMessage: {
        marginTop: 20,
        color: Colors.rubel_700
    }
})