import { restConfig } from "../authConfig";
import { useAppDispatch } from "../redux/hooks";
import { bankAccountsFetched, bankAccountsFetchFailure, bankAccountTransactionSyncRequested, bankAccountTransactionSyncRequestFailure, loading } from "../redux/slices/bankAccountSlice";
import { BankAccountModel } from '../redux/types';
import useFetchClient from "./useFetchClient";

const useBankAccountActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getBankAccounts = async (marketId: string) => {
    dispatch(loading())   

    return fetchClient.get(restConfig.routes.markets + `/${marketId}/bankaccounts`)
      .then(response => {
          dispatch(bankAccountsFetched(response.data as BankAccountModel[]))
      })
      .catch(error => {
          console.log('getBankAccounts error:', error)
          dispatch(bankAccountsFetchFailure(error))
      })
  }

  const requestBankAccountTransactionSync = async (marketId: string) => {
    dispatch(loading())   

    return fetchClient.post(restConfig.routes.markets + `/${marketId}/bankaccounts/sync`)
      .then(response => {
          dispatch(bankAccountTransactionSyncRequested())
      })
      .catch(error => {
          console.log('requestBankAccountTransactionSync error:', error)
          dispatch(bankAccountTransactionSyncRequestFailure(error))
      })
  }

  return {
    getBankAccounts,
    requestBankAccountTransactionSync
  }
}

export default useBankAccountActions
