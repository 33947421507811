import {Line} from "react-chartjs-2"
import {Colors} from "../../constants/colors"
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js';
import {MonetaryTimeSeries, NumericTimeSeries} from "../../redux/types";
import {formatDateTimeString} from "../../utils/dateHelper";

interface Props {
  label: string
  metric: NumericTimeSeries | MonetaryTimeSeries
}

const MetricChart = ({label, metric}: Props) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    Filler
  );

  const isMonetaryTimeSeries = (timeSeries: NumericTimeSeries | MonetaryTimeSeries): timeSeries is MonetaryTimeSeries => {
    return typeof timeSeries.firstValue === 'object' && 'amount' in timeSeries.firstValue;
  }

  const renderGraph = () => {

    const values = isMonetaryTimeSeries(metric)
      ? metric.values.map(x => x.value.amount)
      : metric.values.map(x => x.value)

    const dates = isMonetaryTimeSeries(metric)
      ? metric.values.map(x => formatDateTimeString(x.date))
      : metric.values.map(x => formatDateTimeString(x.date))

    const data = {
      labels: dates,
      datasets: [
        {
          label: label,
          data: values,
          borderColor: Colors.brandGreen,
          backgroundColor: 'rgba(106, 191, 75, 0.2)',
          yAxisID: 'y',
          pointRadius: 0,
          pointHoverRadius: 20,
          tension: 0.2,
          fill: true,
        }
      ]
    };

    const options = {
      maintainAspectRatio: false,
      responsive: true,
      interaction: {
        mode: 'index' as const,
        intersect: false,
      },
      stacked: false,
      plugins: {
        legend: {
          display: false,
        }
      },
      scales: {
        x: {
          display: false,
          ticks: {
            color: Colors.darkGray1
          }
        },
        y: {
          type: 'linear' as const,
          display: false,
          position: 'left' as const,
          ticks: {
            color: Colors.darkGray1,
          }
        }
      }
    }

    return (
      <Line data={data} options={options}/>
    )
  }

  return (
    <>
      {renderGraph()}
    </>
  )
}

export default MetricChart
