import React from "react";
import {createUseStyles} from "react-jss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {Colors} from "../../constants/colors";
import {Money} from "../../redux/types";


const formatNumber = (value: number, addSign?: boolean) => {
  const formattedValue = value.toLocaleString('sv-SE', {
    signDisplay: addSign ? 'exceptZero' : 'auto',
    maximumFractionDigits: 1
  })

  if (addSign && value === 0) {
    return '±' + formattedValue
  }

  return formattedValue;
}

const formatPercentageValue = (percentage: number, addSign?: boolean) => {
  const roundedValue = Math.round(percentage * 10) / 10
  return formatNumber(roundedValue, addSign)
}

interface TrendProps {
  percentage: number
  invertTrend?: boolean
  baseline?: number
  showSign?: boolean
}

const Trend = ({percentage, invertTrend, baseline = 0, showSign = true} : TrendProps) => {
  const styles = useStyles({size: 24})

  const roundedPercentage = Math.round(percentage * 10) / 10

  let color = roundedPercentage > baseline ? Colors.euro_600 : Colors.rubel_700
  if (invertTrend) {
    color = roundedPercentage > baseline ? Colors.rubel_700 : Colors.euro_600
  }

  return (
    <div className={styles.trendContainer}>
      {percentage !== baseline &&
          <FontAwesomeIcon
              icon={percentage > baseline ? faChevronUp : faChevronDown}
              color={color}
              size='1x'
          />
      }
      {formatPercentageValue(percentage, showSign)}%
    </div>
  )
}

interface NumericMetricProps {
  value: number
  showSign?: boolean
  caption: string
  size: number
  children?: React.ReactNode
}

export const NumericMetric = (props: NumericMetricProps) => {
  const styles = useStyles(props)

  return (
    <div className={styles.metricContainer}>
      <div>
        <div className={styles.metricValue}>
          {formatNumber(props.value, props.showSign)}
        </div>
        <div>
          {props.caption}
        </div>
      </div>
      {props.children}
    </div>
  )
}

NumericMetric.Trend = Trend

interface MonetaryMetricProps {
  amount?: Money
  caption: string
  size: number
  children?: React.ReactNode
}

export const MonetaryMetric = (props: MonetaryMetricProps) => {
  const styles = useStyles(props)

  return (
    <div className={styles.metricContainer}>
      <div>
        <div className={styles.metricWithUnit}>
          <div className={styles.metricValue}>
            {props.amount?.amount.toLocaleString('sv-SE') ?? 0}
          </div>
          {props.amount?.currencyCode.toUpperCase()}
        </div>
        <div>
          {props.caption}
        </div>
      </div>
      {props.children}
    </div>
  )
}

MonetaryMetric.Trend = Trend

interface PercentageMetricProps {
  percentage: number
  caption: string
  size: number
  children?: React.ReactNode
}

export const PercentageMetric = (props: PercentageMetricProps) => {
  const styles = useStyles(props)

  return (
    <div className={styles.metricContainer}>
      <div>
        <div className={styles.metricValue}>
          {formatPercentageValue(props.percentage)}%
        </div>
        <div>
          {props.caption}
        </div>
      </div>
      {props.children}
    </div>
  )
}

PercentageMetric.Trend = Trend

const useStyles = createUseStyles({
  metricContainer: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  metricWithUnit: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    columnGap: 10,
  },
  metricValue: (props : {size: number}) => ({
    fontSize: props.size,
    lineHeight: 1
  }),
  trendContainer: {
    fontSize: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 10,
  }
})
