import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InvoicePayoutFilterParams, InvoicePayoutListItemModel, InvoicePayoutModel, PagedListItems } from '../types'

interface InvoicePayoutState {
    loading: boolean
    invoicePayoutDetails: InvoicePayoutModel | null
    payoutsOnPage: InvoicePayoutListItemModel[]
    pageIndex: number
    totalCount: number
    pageSize: number 
    invoicePayoutProviderId: string | null
    searchTerm: string | null
    error: Error | string | null
}

const initialState: InvoicePayoutState = {
    loading: false,
    invoicePayoutDetails: null,
    payoutsOnPage: [],
    totalCount: 0,
    pageIndex: 1,
    pageSize: 20,
    invoicePayoutProviderId: null,
    searchTerm: null,
    error: null
}

export const invoicePayoutSlice = createSlice({
    name: 'invoicePayout',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        invoicePayoutsFetched: (state, action: PayloadAction<PagedListItems<InvoicePayoutListItemModel>>) => {
            state.loading = false
            state.payoutsOnPage = action.payload.items
            state.totalCount = action.payload.totalCount
            state.error = null
        },
        invoicePayoutFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        invoicePayoutDetailsFetched: (state, action: PayloadAction<InvoicePayoutModel>) => {
            state.loading = false
            state.invoicePayoutDetails = action.payload
            state.error = null
        },
        invoicePayoutsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        filterChanged: (state, action: PayloadAction<InvoicePayoutFilterParams>) => {
            state.searchTerm = action.payload.searchTerm
            state.invoicePayoutProviderId = action.payload.invoicePayoutProviderId
            state.pageIndex = 1
        },
    }
})

export const { 
    loading, 
    invoicePayoutsFetched, 
    invoicePayoutFetchFailure, 
    invoicePayoutDetailsFetched, 
    invoicePayoutsFetchFailure, 
    pageIndexChanged, 
    filterChanged 
} = invoicePayoutSlice.actions

export default invoicePayoutSlice.reducer