import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EmployeeAndPaydayFilterParams, PagedListItems, PayDeviationModel } from '../types'

interface PayDeviationState {
    loading: boolean
    payDeviationsOnPage: PayDeviationModel[]
    pageIndex: number
    totalCount: number
    pageSize: number 
    employeeId: string | null
    paydayId: string | null
    error: Error | string | null
  }

const initialState: PayDeviationState = {
    loading: false,
    payDeviationsOnPage: [],
    pageIndex: 1,
    totalCount: 0,
    pageSize: 20,
    employeeId: null,
    paydayId: null,
    error: null
}

export const payDeviationSlice = createSlice({
    name: 'payDeviation',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        payDeviationsFetched: (state, action: PayloadAction<PagedListItems<PayDeviationModel>>) => {
            state.loading = false
            state.payDeviationsOnPage = action.payload.items
            state.totalCount = action.payload.totalCount
            state.error = null
        },
        payDeviationsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        filterChanged: (state, action: PayloadAction<EmployeeAndPaydayFilterParams>) => {
            state.employeeId = action.payload.employeeId
            state.paydayId = action.payload.paydayId
            state.pageIndex = 1
        },
    }
})

export const { 
    loading, 
    payDeviationsFetched, 
    payDeviationsFetchFailure, 
    pageIndexChanged,
    filterChanged 
} = payDeviationSlice.actions

export default payDeviationSlice.reducer