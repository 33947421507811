import {createUseStyles} from "react-jss"
import {Language, LearningModuleMarkdownManagementModel} from "../../redux/types"
import React from "react";
import Card from "../Card";
import {multilingual as m} from "../../utils/multilingualStringHelper";
import {Colors} from "../../constants/colors";
import MDEditor from "@uiw/react-md-editor";
import Badge from "react-bootstrap/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-solid-svg-icons";


interface Props {
  markdownModule: LearningModuleMarkdownManagementModel
  language: Language
  onClick?: () => void
}

const MarkdownModulePreview = ({markdownModule, language, onClick}: Props) => {
  const styles = useStyles()

  const renderBadges = () => {
    return (
      <h5>
        <div className={styles.badges}>
          {markdownModule.isPublished ?
            <Badge variant={"success"}>Published</Badge> :
            <Badge variant={"secondary"}>Unpublished</Badge>
          }
          <Badge variant={"info"}>Order: {markdownModule.orderNumber}</Badge>
          <Badge variant={"info"}>
            <FontAwesomeIcon icon={faClock}/>
            <span style={{marginLeft: 4}}>{markdownModule.estimatedCompletionTime}</span>
          </Badge>
        </div>
      </h5>
    )
  }

  return (
    <div className={styles.container}>
      <h4>{m(language, markdownModule.title)}</h4>
      {renderBadges()}
      <Card className={`${styles.moduleCard} card`} onClick={onClick}>
        <MDEditor.Markdown style={{backgroundColor: Colors.darkGrayCard}}
                           source={m(language, markdownModule.content)}/>
      </Card>
    </div>
  )
}

export default MarkdownModulePreview

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
  },
  moduleCard: {
    width: '100%',
    minWidth: 400,
    minHeight: 200,
    borderRadius: 8,
    padding: 14,
  },
  badges: {
    display: 'flex',
    gap: 8,
    flexDirection: 'row-reverse'
  }
})
