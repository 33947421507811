import {Language, MultilingualString} from "../../redux/types";
import {createUseStyles} from "react-jss";
import {Colors} from "../../constants/colors";
import MDEditor from "@uiw/react-md-editor";
import {useState} from "react";

interface Props {
  id?: string
  label: string
  translations: MultilingualString | undefined | null
  onChange: (translations: MultilingualString) => void
  onLanguageChange?: (language: Language) => void
}

const MultilingualMDEditor = ({id = "markdown", label, translations, onChange, onLanguageChange}: Props) => {
  const styles = useStyles()
  const [currentEditLanguage, setCurrentEditLanguage] = useState<Language>()

  const handleOnChange = (language: Language, value: any) => {
    if (currentEditLanguage !== language) {
      setCurrentEditLanguage(language)
      onLanguageChange && onLanguageChange(language)
    }
    onChange({...translations, [language]: value})
  }

  return (
    <div className={styles.container}>
      <h5>{label}</h5>
      <div className={styles.editorContainer}>
        {
          Object.keys(Language).map(key => {
            const translation = translations ? translations[key as Language] : ''
            return (
              <MDEditor
                id={`${id}_${key}`}
                key={`${label}_${key}`}
                style={{flexGrow: 1, flexShrink: 1}}
                preview="edit"
                value={translation || ''}
                onChange={(value) => {
                  handleOnChange(key as Language, value)
                }}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default MultilingualMDEditor

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 4,
    borderRadius: 8,
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: Colors.gray3,
    gap: 10
  }
})
