import {createUseStyles} from 'react-jss'
import {titleMarginBottom} from '../constants/layout'
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator'
import React, {useState} from "react";
import useFetchClient from "../hooks/useFetchClient";
import {restConfig} from "../authConfig";
import TextAreaField from "../components/TextAreaField";
import Button from "../components/Button";
import Card from "../components/Card";
import {Alert, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBullhorn} from '@fortawesome/free-solid-svg-icons'
import {Colors} from "../constants/colors";

const Whistleblower = () => {
  const styles = useStyles()
  const fetchClient = useFetchClient()
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<boolean | undefined>()

  const submitMessage = () => {
    console.log("Submit message: " + message)
    setLoading(true)
    fetchClient
      .post(restConfig.routes.whistleblower, {"message": message})
      .then(() => postComplete(true))
      .catch(() => postComplete(false))
  }

  const postComplete = (result: boolean) => {
    setMessage("")
    setLoading(false)
    setResult(result)
  }

  return (
    <Container className={styles.container}>
      <h1>AML Whistleblower</h1>

      <Row className={styles.row}>
        <Alert
          className={styles.alert}
          show={result === false}
          width={100}
          variant={"danger"}>
          Something went wrong, your message was not submitted
        </Alert>
        <Alert
          className={styles.alert}
          show={result === true}
          width={100}
          variant={"success"}>
          Your message was submitted successfully
        </Alert>
      </Row>

      <Row className={styles.row}>
        <Col xs={"auto"}>
          <FontAwesomeIcon
            icon={faBullhorn}
            color={Colors.darkGray3}
            size='5x'
          />
        </Col>
        <Col>
          <p>
            This form lets you anonymously submit a whistleblower message,
            if you suspect AML related misconduct within Cappy (e.g. money laundry or financing of terrorism).
          </p>
        </Col>
      </Row>

      <Row className={styles.row}>
        <Card className={styles.whistleblowerCard}>
          <TextAreaField
            id='message'
            className={styles.textarea}
            label='AML Whistleblower message'
            placeholder={'Message'}
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          />

          <Button
            title={"Send"}
            disabled={message.length < 10}
            onClick={submitMessage}
          />
        </Card>
      </Row>
      <FullScreenLoadingIndicator visible={loading}/>
    </Container>
  )
}

export default Whistleblower

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    },
    flex: 1
  },
  row: {
    flex: 1,
    justifyContent: "left",
    alignItems: "center",
    gap: 20,
    marginBottom: 15
  },
  alert: {
    flexGrow: 1
  },
  whistleblowerCard: {
    flexGrow: 1,
    padding: 15
  },
  textarea: {
    marginBottom: 15
  }
})
