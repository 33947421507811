import {useEffect, useState} from 'react'
import {createUseStyles} from 'react-jss'
import {titleMarginBottom} from '../constants/layout'
import {useAppSelector} from '../redux/hooks'
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator'
import useCampaignActions from "../hooks/useCampaignActions";
import CampaignList from "../components/Campaign/CampaignList";
import Button from "../components/Button";
import CreateOrUpdateCampaignModal from "../components/Campaign/CreateOrUpdateCampaignModal";
import {CampaignState, CreateOrUpdateCampaignModel} from "../redux/types";

const Campaigns = () => {
  const styles = useStyles()

  const activeCampaignStates = [CampaignState.New, CampaignState.Launched, CampaignState.Ongoing, CampaignState.Failed]
  const [createCampaignModalVisible, setCreateCampaignModalVisible] = useState(false)
  const { createCampaign, getCampaigns } = useCampaignActions()

  const loading = useAppSelector(state => state.campaigns.loading)
  const campaignsOnPage = useAppSelector(state => state.campaigns.campaignsOnPage)

  useEffect(() => {
    if (campaignsOnPage.length === 0) {
      getCampaigns()
    }
  }, [])

  const handleCreateCampaign = (model: CreateOrUpdateCampaignModel) => {
    createCampaign(model)
    setCreateCampaignModalVisible(false)
  }

  return (
    <div className={styles.container}>
      <h1>Campaigns</h1>
      <div className={styles.actionBar}>
        <Button title='Create new' onClick={() => setCreateCampaignModalVisible(true)} />
      </div>
      <h5>Active campaigns</h5>
      <CampaignList
        campaigns={campaignsOnPage.filter(x => activeCampaignStates.includes(x.state))}
      />
      <h5>Inactive campaigns</h5>
      <CampaignList
        campaigns={campaignsOnPage.filter(x => !activeCampaignStates.includes(x.state))}
      />
      <CreateOrUpdateCampaignModal
        visible={createCampaignModalVisible}
        onAddClick={handleCreateCampaign}
        onCancelClick={() => setCreateCampaignModalVisible(false)}
      />
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default Campaigns

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    }
  },
  actionBar: {
    display: 'flex',
    marginTop: -30,
    flexDirection: 'row',
    columnGap: 10,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
})
