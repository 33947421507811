import {PayRunModel} from "../../redux/types";
import Card from "../Card";
import {createUseStyles} from "react-jss";
import {Colors} from "../../constants/colors";
import React, {useState} from "react";
import {formatDateTimeString} from "../../utils/dateHelper";
import {capitalize} from "../../utils/stringHelper";
import Accordion from "react-bootstrap/Accordion";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
  payRun: PayRunModel
}

const PayoutAttemptList = ({payRun}: Props) => {
  const styles = useStyles()
  const [activeKey, setActiveKey] = useState<string>();

  const renderHeader = () => {
    return (
      <Card
        className={`${styles.header} card`}
        onClick={() => setActiveKey(undefined)}
      >
        <div className={styles.column}>
          State
        </div>
        <div className={styles.column}>
          Scheduled at
        </div>
        <div className={styles.column}>
          Initiated at
        </div>
      </Card>
    )
  }

  const renderListItems = () => {
    return payRun.payoutAttempts.map((item, index) => {
      return (
        <Card
          className={`${styles.listItem} card`}
          key={`payoutAttempt_${index}`}
          onClick={() => setActiveKey(index.toString())}
        >
          <Accordion className={styles.accordion} activeKey={activeKey}>
            <div className={styles.row}>
              <div className={styles.column}>
                {capitalize(item.state)}
              </div>
              <div className={styles.column}>
                {formatDateTimeString(item.scheduledAt)}
              </div>
              <div className={styles.column}>
                {formatDateTimeString(item.initiatedAt)}
              </div>
            </div>
            <Accordion.Collapse eventKey={index.toString()}>
              <div className={styles.collapse}>
                <p>
                  <span>{capitalize(item.payoutMethod.payoutMethodType)}</span> to <span>{item.payoutMethod.target}</span>
                </p>
                {!item.payoutMethod.isDefault &&
                    <p><FontAwesomeIcon icon={faExclamationCircle} color={Colors.white}/> <span>Not default payout method</span>
                    </p>}
                {item.errorMessage &&
                    <p>Error: <span>{item.errorMessage}</span></p>}
              </div>
            </Accordion.Collapse>
          </Accordion>
        </Card>
      )
    })
  }

  return (
    <div className={styles.list}>
      {renderHeader()}
      {renderListItems()}
    </div>
  )
}

export default PayoutAttemptList

const useStyles = createUseStyles({
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4
  },
  header: {
    display: 'flex',
    fontWeight: 'bold',
    borderWidth: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    gap: 10
  },
  listItem: {
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  accordion: {
    display: 'flex',
    flexDirection: 'column',
    padding: '3px 20px'
  },
  collapse: {
    paddingLeft: 20,
    '& p': {
      fontSize: 12,
      margin: 0,
      '& span': {
        fontFamily: 'Aestetico-Bold',
        color: Colors.darkGray2,
      }
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left'
  },
  column: {
    width: '33%'
  }
})
