import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import Button from '../components/Button'
import { createUseStyles } from 'react-jss'
import { titleMarginBottom } from '../constants/layout'
import AddCustomerModal from '../components/AddEmployerModal'
import { CreateNewCustomerModel } from '../redux/types'
import useDidUpdateEffect from '../hooks/useDidUpdateEffect';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator';
import { customerCreationHandled, filterChanged, pageIndexChanged } from '../redux/slices/customerSlice';
import useCustomerActions from '../hooks/useCustomerActions';
import CustomerFilter from '../components/CustomerList/CustomerFilter';
import CustomerList from '../components/CustomerList/CustomerList';

const Customers = () => {
  const styles = useStyles()
  const history = useHistory();
  const dispatch = useAppDispatch()

  const [createCustomerModalVisible, setCreateCustomerModalVisible] = useState(false)
  const {createCustomer, getCustomers} = useCustomerActions()

  const loading = useAppSelector(state => state.customer.loading)
  const customers = useAppSelector(state => state.customer.customersOnPage)
  const createdCustomerId = useAppSelector(state => state.customer.createdCustomerId)
  const totalCount = useAppSelector(state => state.customer.totalCount)
  const searchTerm = useAppSelector(state => state.customer.searchTerm)
  const pageIndex = useAppSelector(state => state.customer.pageIndex)
  const pageSize = useAppSelector(state => state.customer.pageSize)

  const companiesWithUnhandledChanges = useAppSelector(state => state.companyMonitor.companiesWithUnhandledChanges)

  useEffect(() => {
    if (customers.length === 0) {
      getCustomers(searchTerm ?? '', pageIndex, pageSize)
    }
  }, [])

  useDidUpdateEffect(() => {
    getCustomers(searchTerm ?? '', pageIndex, pageSize)
  }, [pageIndex, searchTerm])

  useDidUpdateEffect(() => {
    if (createdCustomerId) {
      history.push(`/customers/${createdCustomerId}`)
      dispatch(customerCreationHandled())
    } 
  }, [createdCustomerId])

  const handleCreateNewCustomer = (customer: CreateNewCustomerModel) => {
    createCustomer(customer)
  }

  const onPageChange = (pageIndex: number) => {
    dispatch(pageIndexChanged(pageIndex))
  }

  const onFilterChange = (value: string) => {
    dispatch(filterChanged(value.toLowerCase()))
  }

  return (
    <div className={styles.container}>
      <h1>Customers</h1>
      <div className={styles.actionBar}>
        <Button title='Create new' onClick={() => setCreateCustomerModalVisible(true)} />
      </div>
      <CustomerFilter 
        filterValue={searchTerm || ''}
        onFilterChange={onFilterChange}
      /> 
      <CustomerList 
        customersOnPage={customers}
        pageIndex={pageIndex}
        pageSize={pageSize} 
        totalCount={totalCount}
        onPageChange={onPageChange}
        companiesWithUnhandledChanges={companiesWithUnhandledChanges}
      />
      <AddCustomerModal 
        visible={createCustomerModalVisible}
        onAddClick={handleCreateNewCustomer}  
        onCancelClick={() => setCreateCustomerModalVisible(false)}
      />
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default Customers

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    }
  },
  actionBar: {
    display: 'flex',
    marginTop: -40,
    flexDirection: 'row',
    columnGap: 10,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
})