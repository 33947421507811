import {createUseStyles} from 'react-jss'
import {titleMarginBottom} from '../constants/layout'
import useLearningActions from "../hooks/useLearningActions";
import {useAppSelector} from "../redux/hooks";
import {useEffect, useState} from "react";
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator";
import CourseList from "../components/Learning/CourseList";
import {Language, LearningCategoryCreateOrUpdateModel, LearningCategoryManagementModel} from "../redux/types";
import {multilingual as m} from "../utils/multilingualStringHelper";
import {faEdit, faFlag, faFolder, faList, faPlus, faUpload} from '@fortawesome/free-solid-svg-icons'
import {useHistory} from "react-router-dom";
import {Toolbar, ToolbarItem} from "../components/Learning/Toolbar";
import UploadImageModal from "../components/Learning/UploadImageModal";
import EditCategoryModal from "../components/Learning/EditCategoryModal";
import useLearningEditor from "../hooks/useLearningEditor";
import Badge from "react-bootstrap/Badge";
import useLearningAssets from "../hooks/useLearningAssets";

const Learning = () => {
  const styles = useStyles()
  const history = useHistory();

  const [uploadImageModalVisible, setUploadImageModalVisible] = useState(false)
  const [editCategoryModalVisible, setEditCategoryModalVisible] = useState(false)
  const [editCategoryModel, setEditCategoryModel] = useState<LearningCategoryManagementModel>()

  const {
    getLearningCourses,
    getLearningCategories,
    createLearningCategory,
    updateLearningCategory,
  } = useLearningActions()
  const {previewLanguage, setPreviewLanguage} = useLearningEditor()
  const {uploadImage} = useLearningAssets()

  const loading = useAppSelector(state => state.learning.loading)
  const categories = useAppSelector(state => state.learning.learningCategories)
  const courses = useAppSelector(state => state.learning.learningCourses)

  useEffect(() => {
    if (categories.length === 0) {
      getLearningCategories()
    }
  }, []);

  useEffect(() => {
    if (courses.length === 0) {
      getLearningCourses()
    }
  }, []);

  const handleUploadImage = (file: File, overwrite: boolean) => {
    uploadImage(file, overwrite)
    setUploadImageModalVisible(false)
  }

  const handleCreateOrEditCategory = (model: LearningCategoryCreateOrUpdateModel) => {
    if (editCategoryModel) {
      updateLearningCategory(editCategoryModel.id, model)
    } else {
      createLearningCategory(model)
    }
    setEditCategoryModel(undefined)
    setEditCategoryModalVisible(false)
  }

  const renderCategory = (category: LearningCategoryManagementModel) => {
    const coursesInCategory = courses.filter(course => course.learningCategoryId === category.id)
    return (
      <div className={styles.categoryContainer} key={category.id}>
        <div className={styles.categoryTitle} style={{backgroundColor: category.backgroundColor}}>
          <div style={{flex: 1}}>
            <h2 style={{color: category.color}}>{m(previewLanguage, category.title)}</h2>
            <h6 style={{color: category.color}}>{m(previewLanguage, category.description)}</h6>
          </div>
          <div style={{display: "flex", flexDirection: "column", gap: 2, flexWrap: "wrap", maxHeight: 50}}>
            <Badge variant={"secondary"}>Icon: {category.icon}</Badge>
            <Badge variant={"secondary"}>Order: {category.orderNumber}</Badge>
          </div>
          <Toolbar>
            <ToolbarItem
              icon={faEdit}
              title={"Edit category"}
              onClick={() => {
                setEditCategoryModel(category)
                setEditCategoryModalVisible(true)
              }}
            />
            <ToolbarItem
              icon={faPlus}
              title={"New course"}
              display={"iconAndText"}
              onClick={() => {
                history.push(`/learning/createCourse?category=${category.id}`)
              }}
            />
          </Toolbar>
        </div>
        <CourseList courses={coursesInCategory} language={previewLanguage}/>
      </div>
    )
  }

  return (
    <div>
      <div className={styles.titleBar}>
        <h1>Learning</h1>
        <Toolbar label={"Create"}>
          <ToolbarItem icon={faList} title={"New category"} display={"iconAndText"}
                       onClick={() => {
                         setEditCategoryModel(undefined)
                         setEditCategoryModalVisible(true)
                       }}/>
          <ToolbarItem icon={faFolder} title={"Manage assets"} display={"iconAndText"}
                       onClick={() => {
                         history.push(`/learning/assets`)
                       }}/>
          <ToolbarItem icon={faUpload} title={"Upload image"}
                       onClick={() => {
                         setUploadImageModalVisible(true)
                       }}/>
        </Toolbar>
        <Toolbar label={"Preview"}>
          <ToolbarItem icon={faFlag} title={"English"} display={"iconAndText"}
                       disabled={previewLanguage === Language.en}
                       onClick={() => setPreviewLanguage(Language.en)}/>
          <ToolbarItem icon={faFlag} title={"Swedish"} display={"iconAndText"}
                       disabled={previewLanguage === Language.sv}
                       onClick={() => setPreviewLanguage(Language.sv)}/>
        </Toolbar>
      </div>
      {
        [...categories]
          .sort((a, b) => b.orderNumber - a.orderNumber)
          .map((item) => {
            return renderCategory(item)
          })
      }
      <FullScreenLoadingIndicator visible={loading}/>
      <UploadImageModal
        visible={uploadImageModalVisible}
        onUpload={handleUploadImage}
        onCancelClick={() => setUploadImageModalVisible(false)}/>
      <EditCategoryModal
        visible={editCategoryModalVisible}
        initialValue={editCategoryModel}
        onAddClick={handleCreateOrEditCategory}
        onCancelClick={() => {
          setEditCategoryModalVisible(false)
        }}
      />
    </div>
  )
}

export default Learning

const useStyles = createUseStyles({
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: titleMarginBottom,
  },
  categoryContainer: {
    marginBottom: 30
  },
  categoryTitle: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 15,
    alignItems: 'center',
    padding: '10px 10px',
    borderRadius: 8,
    marginBottom: 15
  },
  colorView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    minWidth: 15,
    minHeight: 15,
    padding: 5,
    borderRadius: 6
  }
})
