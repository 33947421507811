import { useState } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from '../Modal'
import Card from '../Card'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { Colors } from '../../constants/colors'
import useCustomerActions from '../../hooks/useCustomerActions'
import Switch from '../Switch'
import Button from '../Button'
import { IntegrationApiType } from '../../redux/types'
import { apiKeyCleanup } from '../../redux/slices/customerSlice'

interface Props {
    customerId: string | undefined
    onCloseClick?: () => void
    visible: boolean
}

const ApiKeyModal = ({ customerId, onCloseClick, visible }: Props) => {
    const styles = useStyles()
    const { generateApiKey } = useCustomerActions()
    const apiKey = useAppSelector(state => state.customer.apiKey)
    const [employerApiEnabled, setEmployerApiEnabled] = useState(false)
    const [invoicePayoutApiEnabled, setInvoicePayoutApiEnabled] = useState(false)
    const dispatch = useAppDispatch()

    const handleOnClose = () => {
        dispatch(apiKeyCleanup())
        setEmployerApiEnabled(false)
        setInvoicePayoutApiEnabled(false)
        onCloseClick && onCloseClick()
    }

    const handleApiKeyGeneration = () => {
        let apiTypes: IntegrationApiType[] = []

        if (employerApiEnabled)
        {
            apiTypes.push('EmployerApi')
        }

        if (invoicePayoutApiEnabled)
        {
            apiTypes.push('InvoicePayoutApi')
        }

        if (customerId && apiTypes.length > 0) {
            generateApiKey(customerId, apiTypes)
        }
    }

    return (
        <Modal
            title='Integration API key generation'
            show={visible}
            hideCancelButton={true}
            okButtonTitle='Close'
            onHide={() => handleOnClose()}
            onOkClick={() => handleOnClose()}
            onCancelClick={() => handleOnClose()}
        >
            {!apiKey &&
                <Card className={`${styles.configureContainer} card`}>
                    <Switch
                        id='employerApi'
                        key='employerApi'
                        checked={employerApiEnabled}
                        label='Employer API'
                        onChange={() => setEmployerApiEnabled(!employerApiEnabled)}
                    />
                    <Switch
                        id='invoicePayoutApi'
                        key='invoicePayoutApi'
                        checked={invoicePayoutApiEnabled}
                        label='Invoice Payout API'
                        onChange={() => setInvoicePayoutApiEnabled(!invoicePayoutApiEnabled)}
                    />

                    <div className={styles.note}>
                        Note that if you generate a new API key, any existing key will expire in two days.
                    </div>

                    <Button 
                        title='Generate API key' 
                        disabled={!employerApiEnabled && !invoicePayoutApiEnabled}
                        onClick={handleApiKeyGeneration}
                    />
                </Card>
            }

            {apiKey &&
            <>
            <Card className={`${styles.resultContainer} card`}>
                <h5>{apiKey}</h5> 
                <FontAwesomeIcon 
                    style={{marginLeft: 20, cursor: 'pointer'}}
                    icon={faCopy}
                    color={Colors.darkGray1}
                    title="Copy tp clipboard"
                    onClick={() => navigator.clipboard.writeText(apiKey ?? '')}
                />
            </Card>

            <p>Save this API key somewhere safe as you will not be able to access it again. If you lose it, you will have to generate a new one which makes the old one expire in two days.</p>
            </>
            }
            
        </Modal>
    )
}

export default ApiKeyModal

const useStyles = createUseStyles({
    configureContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 20px',
        margin: '10px 0',
    },
    resultContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'center',
        padding: '12px 20px',
        margin: '10px 0',
    },
    note: {
        marginTop: '10px',
        marginBottom: '10px',
        color: Colors.rubel_300
    }
})