import {
  CustomerListItemModel,
  CustomerModel,
  CreateNewCustomerModel,
  UpdateCustomerModel,
  IntegrationApiType,
  UpdateCustomerPortalUserModel,
  CustomerPortalUser
} from '../redux/types';
import useFetchClient from "./useFetchClient";
import { useAppDispatch } from "../redux/hooks";
import { restConfig } from "../authConfig";
import { createUrlWithQueryString } from "../utils/url";
import { getPaginationDataFromResponse } from "../utils/responseHelper";
import {
  apiKeyGenerated,
  apiKeyGenerationFailure,
  loading,
  customersFetchFailure,
  customersFetched,
  customerDetailsFetched,
  customerDetailsFetchFailure,
  customerCreated,
  customerCreateFailure,
  customerInfoUpdated,
  customerInfoUpdateFailure,
  customerLockStateSet,
  customerLockStateSetFailure,
  portalUserAdded,
  portalUserAddFailure,
  portalUserDeleted,
  portalUserDeleteFailure,
  portalUserUpdated,
  portalUserUpdateFailure,
} from "../redux/slices/customerSlice";

const useCustomerActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getCustomers = (searchTerm: string, pageIndex: number, pageSize: number) => {
    dispatch(loading())

    const params = {
      query: searchTerm,
      pageNumber: pageIndex,
      pageSize: pageSize
    }
    const url = createUrlWithQueryString(restConfig.routes.customers, params)

    return fetchClient.get(url)
      .then(response => {
          const paginationData = getPaginationDataFromResponse(response.raw)
          dispatch(customersFetched({ items: response.data as CustomerListItemModel[], totalCount: paginationData?.totalItemCount ?? 0}))
      })
      .catch(error => {
          console.log('getCustomers error:', error)
          dispatch(customersFetchFailure(error))
      })
  }

  const getCustomerDetails = (customerId: string) => {
    dispatch(loading())
    const url = restConfig.routes.customers + `/${customerId}`

    return fetchClient.get(url)
      .then(response => {
          dispatch(customerDetailsFetched(response.data as CustomerModel))
      })
      .catch(error => {
          console.log('getCustomerDetails error:', error)
          dispatch(customerDetailsFetchFailure(error))
      })
  }

  const companyRegistrationNumberExists = (companyRegistrationNumber: string, marketId: string, onComplete: (exists: boolean) => void) => {
    const params = {
      companyRegistrationNumber: companyRegistrationNumber,
      marketId: marketId
    }

    const url = createUrlWithQueryString(restConfig.routes.customers + '/exists', params)

    return fetchClient.get(url)
        .then(response => {
            onComplete(response.data.exists as boolean)
        })
        .catch(error => {
            console.log('companyRegistrationNumberExists error:', error)
        })
}

  const createCustomer = (customer: CreateNewCustomerModel) => {
    dispatch(loading())
    return fetchClient.post(restConfig.routes.customers, customer)
      .then(response => {
          dispatch(customerCreated(response.data as string))
      })
      .catch(error => {
          console.log('createCustomer error:', error)
          dispatch(customerCreateFailure(error))
      })
  }

  const updateCustomer = (customerId: string, updateModel: UpdateCustomerModel) => {
    dispatch(loading())
    const url = restConfig.routes.customers + '/' + customerId

    return fetchClient.post(url, updateModel)
      .then(response => {
          dispatch(customerInfoUpdated(response.data as CustomerModel))
      })
      .catch(error => {
          console.log('updateCustomerInfo error:', error)
          dispatch(customerInfoUpdateFailure(error))
      })
  }

  const setLockState = (customerId: string, locked: boolean) => {
    dispatch(loading())
    const url = restConfig.routes.customers + `/${customerId}/${locked ? 'lock' : 'unlock'}`

    return fetchClient.post(url)
      .then(response => {
          dispatch(customerLockStateSet(locked))
      })
      .catch(error => {
          console.log('setLockState error:', error)
          dispatch(customerLockStateSetFailure(error))
      })
  }

  const generateApiKey = (customerId: string, apiTypes: IntegrationApiType[]) => {
    dispatch(loading())
    const url = restConfig.routes.customers + `/${customerId}/apikey`

    return fetchClient.post(url, {apis: apiTypes})
      .then(response => {
          dispatch(apiKeyGenerated(response.data.apiKey as string))
      })
      .catch(error => {
          console.log('generateApiKey error:', error)
          dispatch(apiKeyGenerationFailure(error))
      })
  }

  const addPortalUser = (customerId: string, portalUser: UpdateCustomerPortalUserModel) => {
    dispatch(loading())
    const url = restConfig.routes.customers + `/${customerId}/portalUsers`

    return fetchClient.post(url, portalUser)
      .then(response => {
          dispatch(portalUserAdded(response.data as CustomerPortalUser))
      })
      .catch(error => {
          console.log('addPortalUser error:', error)
          dispatch(portalUserAddFailure(error))
      })
  }

  const updatePortalUser = (customerId: string, portalUserId: string, portalUser: UpdateCustomerPortalUserModel) => {
    dispatch(loading())
    const url = restConfig.routes.customers + `/${customerId}/portalUsers/${portalUserId}`

    return fetchClient.post(url, portalUser)
      .then(response => {
          dispatch(portalUserUpdated(response.data as CustomerPortalUser))
      })
      .catch(error => {
          console.log('updatePortalUser error:', error)
          dispatch(portalUserUpdateFailure(error))
      })
  }

  const deletePortalUser = (customerId: string, portalUserId: string) => {
    dispatch(loading())
    const url = restConfig.routes.customers + `/${customerId}/portalUsers/${portalUserId}`

    return fetchClient.delete(url)
      .then(response => {
          dispatch(portalUserDeleted(portalUserId))
      })
      .catch(error => {
          console.log('deletePortalUser error:', error)
          dispatch(portalUserDeleteFailure(error))
      })
  }

  return {
    getCustomers,
    getCustomerDetails,
    companyRegistrationNumberExists,
    createCustomer,
    updateCustomer,
    setLockState,
    generateApiKey,
    addPortalUser,
    updatePortalUser,
    deletePortalUser
  }
}

export default useCustomerActions
