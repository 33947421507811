import { restConfig } from "../authConfig"
import { useAppDispatch } from "../redux/hooks"
import { loading, ruleDeleted, ruleDeleteFailure, ruleFetched, ruleFetchFailure, ruleUpdated, ruleUpdateFailure } from "../redux/slices/protectRuleSlice"
import { CreateOrUpdateProtectRuleModel, ProtectRule } from '../redux/types'
import useFetchClient from "./useFetchClient"

const useProtectRuleActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getRule = (ruleId: string) => {
    dispatch(loading())

    const url = restConfig.routes.protectRules + '/' + ruleId

    return fetchClient.get(url)
      .then(response => {
          dispatch(ruleFetched(response.data as ProtectRule))
      })
      .catch(error => {
          console.log('getRule error:', error)
          dispatch(ruleFetchFailure(error))
      })
  }

  const updateRule = (rule: CreateOrUpdateProtectRuleModel) => {
    dispatch(loading())

    const url = restConfig.routes.protectRules + '/' + rule.id

    return fetchClient.post(url, rule)
      .then(response => {
          dispatch(ruleUpdated(response.data as ProtectRule))
      })
      .catch(error => {
          console.log('updateRule error:', error)
          dispatch(ruleUpdateFailure(error))
      })
  }

  const deleteRule = (ruleId: string) => {
    dispatch(loading())

    const url = restConfig.routes.protectRules + '/' + ruleId

    return fetchClient.delete(url)
      .then(response => {
          dispatch(ruleDeleted(ruleId))
      })
      .catch(error => {
          console.log('deleteRule error:', error)
          dispatch(ruleDeleteFailure(error))
      })
  }

  return {
    getRule,
    updateRule,
    deleteRule
  }
}

export default useProtectRuleActions