import {useEffect, useState} from 'react'
import {createUseStyles} from 'react-jss'
import {useHistory, useParams} from "react-router-dom";
import Switch from '../components/Switch'
import {Colors} from '../constants/colors'
import CardSection from '../components/CardSection'
import DetailsCard from '../components/DetailsCard'
import {
  CreditorConnectionModel, UpdateInvoicePayoutProviderModel,
} from '../redux/types'
import ConfirmModal from '../components/ConfirmModal'
import {formatDateTimeString} from '../utils/dateHelper'
import {useAppSelector} from '../redux/hooks';
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator';
import Divider from '../components/Divider';
import CreditorConnectionList from '../components/CreditorConnections/EmployerCreditorList';
import AddCreditorConnectionModal from '../components/CreditorConnections/AddEmployerCreditorModal';
import {faArrowLeft, faPen} from "@fortawesome/free-solid-svg-icons";
import NavLink from '../components/NavLink';
import {titleMarginBottom} from '../constants/layout';
import Link from '../components/Link';
import useInvoicePayoutProviderActions from '../hooks/useInvoicePayoutProviderActions';
import EditInvoicePayoutProviderModal from '../components/EditInvoicePayoutProviderModal';
import InvoicePayoutListComponent from '../components/InvoicePayoutList';
import SilenceCreditSpaceWarningModal from '../components/CreditSpace/SilenceCreditSpaceWarningModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InvoicePayoutProviderDetails = () => {
  const styles = useStyles()
  const params = useParams<{id: string}>()
  const history = useHistory();

  const [confirmLockModalVisible, setConfirmLockModalVisible] = useState(false)
  const [addCreditorModalVisible, setAddCreditorModalVisible] = useState(false)
  const [confirmDeleteCreditorModalVisible, setConfirmDeleteCreditorModalVisible] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [silenceCreditSpaceWarningModalVisible, setSilenceCreditSpaceWarningModalVisible] = useState(false)
  const [creditorDeleteCandidate, setCreditorDeleteCandidate] = useState<CreditorConnectionModel | null>(null)
  
  const loading = useAppSelector(state => state.invoicePayoutProvider.loading)
  const invoicePayoutProvider = useAppSelector(state => state.invoicePayoutProvider.providerDetails)

  const {
    getInvoicePayoutProviderDetails,
    updateInvoicePayoutProvider,
    silenceCreditSpaceWarning,
    setLockState,
    addCreditor,
    deleteCreditor
  } = useInvoicePayoutProviderActions()

  useEffect(() => {
    if (invoicePayoutProvider?.id == null || invoicePayoutProvider.id !== params.id) {
      getInvoicePayoutProviderDetails(params.id)
    }
  }, [])

  const handleSilenceCreditSpaceWarning = (days: number) => {
    setSilenceCreditSpaceWarningModalVisible(false)
    silenceCreditSpaceWarning(invoicePayoutProvider?.id as string, days)
  }

  const handleDeleteCreditor = (creditor: CreditorConnectionModel) => {
    setCreditorDeleteCandidate(creditor)
    setConfirmDeleteCreditorModalVisible(true)
  }

  const handleConfirmDeleteCreditor = () => {
    deleteCreditor(invoicePayoutProvider?.id as string, creditorDeleteCandidate?.id || '')
    setCreditorDeleteCandidate(null)
    setConfirmDeleteCreditorModalVisible(false)
  }

  const handleAddCreditor = (creditor: CreditorConnectionModel) => {
    addCreditor(invoicePayoutProvider?.id as string, creditor)
    setAddCreditorModalVisible(false)
  }

  const handleLockStateChange = () => {
    const isLock = invoicePayoutProvider?.state === 'active'
    if (isLock) {
      setConfirmLockModalVisible(true)
    } else {
      setLockState(invoicePayoutProvider?.id as string, false)
    }
  }

  const handleLockConfirmed = () => {
    setLockState(invoicePayoutProvider?.id as string, true)
    setConfirmLockModalVisible(false)
  }

  const handleUpdate = (updateModel: UpdateInvoicePayoutProviderModel) => {
    updateInvoicePayoutProvider(invoicePayoutProvider?.id as string, updateModel)
    setEditModalVisible(false)
  }

  const renderGeneralInformationCardBody = () => {
    return (
      <div>
        <p>Customer: <Link to={`/customers/${invoicePayoutProvider?.customerId}`}>{invoicePayoutProvider?.customerName}</Link></p>
        <p>Currency: <span>{invoicePayoutProvider?.currency}</span></p>
        <p>Launch date: <span>{invoicePayoutProvider?.launchDate != null ? formatDateTimeString(invoicePayoutProvider?.launchDate) : 'Not specified'}</span></p>
        <p>Cappy fee percentage: <span>{invoicePayoutProvider?.cappyFeePercentage}%</span></p>
        <p>Customer fee percentage: <span>{invoicePayoutProvider?.customerFeePercentage}%</span></p>
        <p>Min. payout amount: <span>{invoicePayoutProvider?.minimumPayoutAmount}</span></p>
        <p>Max. payout amount: <span>{invoicePayoutProvider?.maximumPayoutAmount}</span></p>
        <p>Min. days until due date: <span>{invoicePayoutProvider?.minimumDaysUntilDueDate}</span></p>
        <p>Callback url: <span>{invoicePayoutProvider?.callbackUrl}</span></p>
        <p>Request validation url: <span>{invoicePayoutProvider?.requestValidationUrl}</span></p>
        <p>Signing certificate thumbprint: <span>{invoicePayoutProvider?.signingCertificateThumbprint}</span></p>
      </div>
    )
  }

  const renderCreditCardBody = () => {
    return (
      <div>
        <p>Credit limit: <span>{invoicePayoutProvider?.creditLimit?.amount.toLocaleString('sv-SE')} {invoicePayoutProvider?.creditLimit?.currencyCode.toUpperCase()}</span></p>
        <p>Credit usage: <span>{invoicePayoutProvider?.creditUsage?.amount.toLocaleString('sv-SE')} {invoicePayoutProvider?.creditUsage?.currencyCode.toUpperCase()}</span></p>
        <p>Remaining credit space warning: <span>{invoicePayoutProvider?.remainingCreditSpaceWarningPercent}%</span></p>
        <p>Credit space warning silenced until: <span>{formatDateTimeString(invoicePayoutProvider?.creditSpaceWarningSilencedUntilDate)}</span>
          <FontAwesomeIcon
            onClick={() => setSilenceCreditSpaceWarningModalVisible(true)}
            className={styles.inlineIcon}
            icon={faPen}
            color={Colors.euro_600}
          />
        </p> 
      </div>
    )
  }

  const renderLockedSwitch = () => {
    const isLocked = invoicePayoutProvider?.state === 'locked'
    return (
      <Switch
        id='lockSwitch'
        key='lockSwitch'
        checked={isLocked}
        label={isLocked ? 'Manual lock on' : 'Manual lock off'}
        onChange={() => handleLockStateChange()} />
    )
  }

  return (
    <div className={styles.container}>
      <h1>Invoice payout provider: {invoicePayoutProvider?.customerName}</h1>
      <div className={styles.actionBar}>
        <div className={styles.actionBarCell} style={{justifyContent: 'flex-start'}}>
          <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()} />
        </div>
        <div className={styles.actionBarCell} style={{justifyContent: 'flex-end'}}>
            <div style={{paddingBottom: 8}}>
              { renderLockedSwitch() }
            </div>
        </div>
      </div>
      <CardSection>
        <DetailsCard onClick={() => setEditModalVisible(true)}>
          <h6>General information</h6>
          {renderGeneralInformationCardBody()}
        </DetailsCard>
        <div className={styles.cardColumnContainer}>
          <DetailsCard onClick={() => setEditModalVisible(true)}>
            <h6>Credit information</h6>
            {renderCreditCardBody()}
          </DetailsCard>
          <DetailsCard iconType='Add' onClick={() => setAddCreditorModalVisible(true)}>
            <h6>Creditors</h6>
            <CreditorConnectionList
              creditors={invoicePayoutProvider?.creditors ?? []}
              onDeleteClick={handleDeleteCreditor}
            />
          </DetailsCard>
        </div>
      </CardSection>
      <CardSection>
        <DetailsCard >
          <h6>Payouts</h6>
          <InvoicePayoutListComponent invoicePayoutProviderId={invoicePayoutProvider?.id} />
        </DetailsCard>
      </CardSection>
      <Divider height={50}/>
      <ConfirmModal
        visible={confirmLockModalVisible}
        confirmText={`Are you sure you want to lock this invoice payout provider? Payouts will not be possible.`}
        onConfirmClick={handleLockConfirmed}
        onCancelClick={() => setConfirmLockModalVisible(false)}
      />
      <ConfirmModal
        visible={confirmDeleteCreditorModalVisible}
        confirmText={`Are you sure you want to remove ${creditorDeleteCandidate?.name} as a creditor?`}
        onConfirmClick={handleConfirmDeleteCreditor}
        onCancelClick={() => setConfirmDeleteCreditorModalVisible(false)}
      />
      <AddCreditorConnectionModal
        visible={addCreditorModalVisible}
        marketId={invoicePayoutProvider?.marketId ?? ''}
        existingCreditorConnectionModels={invoicePayoutProvider?.creditors ?? []}
        onAddClick={handleAddCreditor}
        onCancelClick={() => setAddCreditorModalVisible(false)}
      />
      <EditInvoicePayoutProviderModal
        visible={editModalVisible}
        initialValue={invoicePayoutProvider ?? undefined}
        onCancelClick={() => setEditModalVisible(false)}
        onSaveClick={handleUpdate}
      />
      <SilenceCreditSpaceWarningModal 
        visible={silenceCreditSpaceWarningModalVisible}
        onCancelClick={() => setSilenceCreditSpaceWarningModalVisible(false)}
        onSaveClick={handleSilenceCreditSpaceWarning}
      />
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default InvoicePayoutProviderDetails

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    },
    '& h5': {
      fontFamily: 'Aestetico-Regular',
      color: Colors.darkDefault,
    }
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: -8
  },
  actionBarCell: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 15,
    alignItems: 'center',
    width: '33%'
  },
  navLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10
  },
  cardColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10
  },
  largeButton: {
    fontSize: "xxx-large",
    alignSelf: "center",
    margin: "20px",
    flexGrow: 1,
    transition: '0.4s',
    '&:hover': {
      color: Colors.euro5,
      cursor: 'pointer',
    }
  },
  inlineIcon: {
    marginLeft: 5,
    '&:hover': {
      color: Colors.euro5,
      cursor: 'pointer',
    }
  }
})
