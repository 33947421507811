import { restConfig } from "../authConfig"
import { useAppDispatch } from "../redux/hooks"
import {
  protectCustomerFetched,
  protectCustomerFetchFailure,
  protectCustomersFetched,
  protectCustomersFetchFailure,
  loading,
  ruleCreated,
  ruleCreationFailure
} from "../redux/slices/protectCustomerSlice"
import {CreateOrUpdateProtectRuleModel, ProtectCustomerListItem, ProtectCustomerModel, ProtectRule} from "../redux/types"
import useFetchClient from "./useFetchClient"

const useProtectCustomerActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getProtectCustomer = (employerId: string) => {
    dispatch(loading())   

    const url = restConfig.routes.protectCustomers + '/' + employerId

    return fetchClient.get(url)
      .then(response => {
          dispatch(protectCustomerFetched(response.data as ProtectCustomerModel))
      })
      .catch(error => {
          console.log('getProtectCustomer error:', error)
          dispatch(protectCustomerFetchFailure(error))
      })
  }

  const getProtectCustomers = () => {
    dispatch(loading())
    const url = restConfig.routes.protectCustomers
    return fetchClient.get(url)
      .then(response => {
        dispatch(protectCustomersFetched(response.data as ProtectCustomerListItem[]))
      })
      .catch(error => {
        console.log('getProtectCustomers error:', error)
        dispatch(protectCustomersFetchFailure(error))
      })
  }

  const createRule = (rule: CreateOrUpdateProtectRuleModel) => {
    dispatch(loading())

    const url = restConfig.routes.protectRules

    return fetchClient.post(url, rule)
      .then(response => {
          dispatch(ruleCreated(response.data as ProtectRule))
      })
      .catch(error => {
          console.log('createRule error:', error)
          dispatch(ruleCreationFailure(error))
      })
  }

  return {
    getProtectCustomer,
    getProtectCustomers,
    createRule
  }
}

export default useProtectCustomerActions