import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EmployeeFilterParams, EmployeeListItemModel, EmployeeModel, PagedListItems } from '../types'

interface EmployeeState {
    loading: boolean
    employeeDetails: EmployeeModel | null
    employeesOnPage: EmployeeListItemModel[]
    pageIndex: number
    totalCount: number
    pageSize: number
    employerId: string | null | undefined
    searchTerm: string | null
    error: Error | string | null
}

const initialState: EmployeeState = {
    loading: false,
    employeeDetails: null,
    employeesOnPage: [],
    totalCount: 0,
    pageIndex: 1,
    pageSize: 20,
    employerId: undefined,
    searchTerm: null,
    error: null
}

export const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        employeesFetched: (state, action: PayloadAction<PagedListItems<EmployeeListItemModel>>) => {
            state.loading = false
            state.employeesOnPage = action.payload.items
            state.totalCount = action.payload.totalCount
            state.error = null
        },
        employeesFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employeeDetailsFetched: (state, action: PayloadAction<EmployeeModel>) => {
            state.loading = false
            state.employeeDetails = action.payload
            state.error = null
        },
        employeeDetailsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        filterChanged: (state, action: PayloadAction<EmployeeFilterParams>) => {
            state.searchTerm = action.payload.searchTerm
            state.employerId = action.payload.employerId
            state.pageIndex = 1
        },
    }
})

export const {
    loading,
    employeesFetched,
    employeesFetchFailure,
    employeeDetailsFetched,
    employeeDetailsFetchFailure,
    pageIndexChanged,
    filterChanged
} = employeeSlice.actions

export default employeeSlice.reducer
