import { faArrowLeft, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { useHistory, useParams } from "react-router-dom"
import Button from "../components/Button"
import CardSection from "../components/CardSection"
import DetailsCard from "../components/DetailsCard"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import CreateOrUpdateInAppMessageModal from "../components/InAppMessage/CreateOrUpdateInAppMessageModal"
import InAppMessageStatusBadge from "../components/InAppMessage/InAppMessageStatusBadge"
import LocalizedInAppMessageList from "../components/InAppMessage/LocalizedInAppMessageList"
import UpdateInAppMessageTranslationModal from "../components/InAppMessage/UpdateInAppMessageTranslationModal"
import NavLink from "../components/NavLink"
import { Colors } from "../constants/colors"
import { titleMarginBottom } from "../constants/layout"
import useInAppMessageActions from "../hooks/useInAppMessageActions"
import { useAppSelector } from "../redux/hooks"
import { CreateOrUpdateInAppMessageModel, InAppMessageTranslation } from "../redux/types"
import { formatDateTimeString } from "../utils/dateHelper"

const InAppMessageDetails = () => {
    const styles = useStyles()
    const params = useParams<{ id: string }>()
    const history = useHistory()

    const [updateTranslationModalVisible, setUpdateTranslationModalVisible] = useState(false)
    const [updateModalVisible, setUpdateModalVisible] = useState(false)

    const [translationToUpdate, setTranslationToUpdate] = useState<InAppMessageTranslation>()

    const loading = useAppSelector(state => state.inAppMessages.loading)
    const message = useAppSelector(state => state.inAppMessages.inAppMessage)

    const { getInAppMessage, updateInAppMessage, publishInAppMessage, unpublishInAppMessage, setTranslation } = useInAppMessageActions()

    useEffect(() => {
        if (message == null || message.id !== params.id) {
            getInAppMessage(params.id)
        }
      }, [message, params.id])

    const togglePublishState = () => {
        if (!!message) {
            if (message.published) {
                unpublishInAppMessage(message.id)
            } else {
                publishInAppMessage(message.id)
            }
        }
    }

    const handleInAppMessageUpdated = (model: CreateOrUpdateInAppMessageModel) => {
        if (!!message) {
            updateInAppMessage(message.id, model)
            setUpdateModalVisible(false)
        }
    }

    const handleTranslationUpdateClick = (translation: InAppMessageTranslation) => {
        setTranslationToUpdate(translation)
        setUpdateTranslationModalVisible(true)
    }

    const handleTranslationUpdated = (translation: InAppMessageTranslation) => {
        if (!!message) {
            setTranslation(message.id, translation)
            setUpdateTranslationModalVisible(false)
            setTranslationToUpdate(undefined)
        }
    }

    const renderTranslations = () => {
        return <DetailsCard>
          <h6>Translations</h6>
          <LocalizedInAppMessageList
            messages={message?.translations ?? []}
            onEditClick={handleTranslationUpdateClick}
          />
        </DetailsCard>
      }

    return (
        <div className={styles.container}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: titleMarginBottom, columnGap: 20}}>
                <h1>In-app message: {message?.internalName}</h1>
                {message && <InAppMessageStatusBadge inAppMessage={message} />}
            </div>
            <div className={styles.actionBar}>
                <div className={styles.actionBarCell} style={{justifyContent: 'flex-start'}}>
                <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()}/>
                </div>
                <div className={styles.actionBarCell} style={{justifyContent: 'flex-end'}}>
                <Button title={message?.published ? 'Unpublish': 'Publish'} disabled={message == null} onClick={togglePublishState}/>
                </div>
            </div>
            <CardSection>
                <DetailsCard onClick={() => {setUpdateModalVisible(true)}}>
                <h6>General information</h6>
                <p>Internal name: <span>{message?.internalName}</span></p>
                <p>Recipient employer: <span>{message?.employerName ?? 'All'}</span></p>
                <p>Valid from: <span>{formatDateTimeString(message?.validFrom)}</span></p>
                <p>Valid to: <span>{message?.validTo == null ? '∞' : formatDateTimeString(message?.validTo)}</span></p>
                <p>Sticky: <span>{message?.sticky ? 'true' : 'false'}</span></p>
                <p>Min app version: <span>{message?.minAppVersion}</span></p>
                <p>Priority: <span>{message?.priority}</span></p>
                <p>Url: <span>{message?.url}</span></p>
                <p>Icon: <span>{message?.icon} <a className={styles.externalLink} target='blank' href={`https://feathericons.com/?query=${message?.icon}`}><FontAwesomeIcon icon={faExternalLinkAlt} /></a></span></p>
                <p>Published: <span>{message?.published ? 'true' : 'false'}</span></p>
                <p>Read count: <span>{message?.readCount}</span></p>
                </DetailsCard>
            </CardSection>
            <CardSection>
                {renderTranslations()}
            </CardSection>
            <CreateOrUpdateInAppMessageModal
                initialValue={message ?? undefined}
                visible={updateModalVisible}
                onAddClick={handleInAppMessageUpdated}
                onCancelClick={() => setUpdateModalVisible(false)}
            />
            <UpdateInAppMessageTranslationModal
                initialValue={translationToUpdate}
                visible={updateTranslationModalVisible}
                onSaveClick={handleTranslationUpdated}
                onCancelClick={() => setUpdateTranslationModalVisible(false)}
            />
            <FullScreenLoadingIndicator visible={loading}/>
        </div>
    )
}

export default InAppMessageDetails

const useStyles = createUseStyles({
    container: {
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: titleMarginBottom,
        columnGap: 20
    },
    actionBar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '1rem'
    },
    actionBarCell: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: 15,
      alignItems: 'center',
      width: '33%'
    },
    externalLink: {
        marginLeft: 5,
        cursor: 'pointer',
            '&:hover': {
            color: Colors.euro_600,
        },
    }
  })
