import {createUseStyles} from "react-jss"
import {Language, LearningCourseManagementModel} from "../../redux/types"
import CourseCard from "./CourseCard";
import {Link} from "react-router-dom";

interface Props {
  courses: LearningCourseManagementModel[]
  language: Language
}

const CourseList = ({courses, language}: Props) => {
  const styles = useStyles()

  return (
    <div className={styles.courseList}>
      {
        courses.map((item) => {
          return (
            <Link key={item.id} to={`/learning/course/${item.id}`}>
              <div style={{maxWidth: 400}}>
                <CourseCard course={item} language={language}/>
              </div>
            </Link>
          )
        })
      }
    </div>
  )
}

export default CourseList

const useStyles = createUseStyles({
  courseList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '10px'
  }
})
