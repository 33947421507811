import { restConfig } from "../authConfig"
import { useAppDispatch } from "../redux/hooks"
import { loading, reconciliationDetailsFetched, reconciliationDetailsFetchFailure, reconciliationIssueHandled, reconciliationIssueHandleFailure, reconciliationsFetched, reconciliationsFetchFailure, reconciliationsWithIssuesCountFetched, reconciliationsWithIssuesCountFetchFailure } from "../redux/slices/reconciliationSlice"
import { ReconciliationListItemModel, ReconciliationModel } from "../redux/types"
import { getPaginationDataFromResponse } from "../utils/responseHelper"
import { createUrlWithQueryString } from "../utils/url"
import useFetchClient from "./useFetchClient"

const useReconciliationActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getReconciliations = (pageIndex: number, pageSize: number) => {
        dispatch(loading())   

        const params = {
            pageNumber: pageIndex,
            pageSize: pageSize
        }
        const url = createUrlWithQueryString(restConfig.routes.reconciliations, params)

        return fetchClient.get(url)
            .then(response => {
                const paginationData = getPaginationDataFromResponse(response.raw)
                dispatch(reconciliationsFetched({ items: response.data as ReconciliationListItemModel[], totalCount: paginationData?.totalItemCount ?? 0}))
            })
            .catch(error => {
                console.log('getReconciliations error:', error)
                dispatch(reconciliationsFetchFailure(error))
            })
    }

    const getReconciliationsWithIssuesCount = () => {
        dispatch(loading())   

        const url = restConfig.routes.reconciliations + "/withissuescount"

        return fetchClient.get(url)
            .then(response => {
                dispatch(reconciliationsWithIssuesCountFetched(response.data.count))
            })
            .catch(error => {
                console.log('getReconciliationsWithIssuesCount error:', error)
                dispatch(reconciliationsWithIssuesCountFetchFailure(error))
            })
    }

    const getReconciliationDetails = (id: string) => {
        dispatch(loading())   

        const url = restConfig.routes.reconciliations + `/${id}`

        return fetchClient.get(url)
            .then(response => {
                dispatch(reconciliationDetailsFetched(response.data as ReconciliationModel))
            })
            .catch(error => {
                console.log('getReconciliationDetails error:', error)
                dispatch(reconciliationDetailsFetchFailure(error))
            })
    }

    const handleReconciliationIssue = (reconciliationIssueId: string, reconciliationId: string, comment: string) => {
        dispatch(loading())   

        const url = restConfig.routes.reconciliations + `/${reconciliationId}/issue/${reconciliationIssueId}`

        return fetchClient.post(url, { comment: comment })
            .then(response => {
                dispatch(reconciliationIssueHandled(response.data as ReconciliationModel))
                getReconciliationsWithIssuesCount()
            })
            .catch(error => {
                console.log('handleReconciliationIssue error:', error)
                dispatch(reconciliationIssueHandleFailure(error))
            })
    }

    return {
        getReconciliations,
        getReconciliationsWithIssuesCount,
        getReconciliationDetails,
        handleReconciliationIssue
    }
}

export default useReconciliationActions