import {useEffect, useState} from 'react'
import {createUseStyles} from 'react-jss'
import {useHistory, useParams} from "react-router-dom";
import CardSection from '../components/CardSection'
import DetailsCard from '../components/DetailsCard'
import {formatDateTimeString} from '../utils/dateHelper'
import {useAppSelector} from '../redux/hooks';
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator';
import useCampaignActions from "../hooks/useCampaignActions";
import {
  CampaignRecipientType,
  CampaignState,
  CampaignTranslation,
  CampaignType,
  CreateOrUpdateCampaignModel
} from "../redux/types";
import RecipientList from "../components/Campaign/RecipientList";
import LocalizedMessageList from "../components/Campaign/LocalizedMessageList";
import Button from "../components/Button";
import CreateOrUpdateCampaignModal from "../components/Campaign/CreateOrUpdateCampaignModal";
import Alert from 'react-bootstrap/Alert';
import UpdateCampaignTranslationModal from "../components/Campaign/UpdateCampaignTranslationModal";
import UpdateCampaignRecipientsModal from "../components/Campaign/UpdateCampaignRecipientsModal";
import NavLink from "../components/NavLink";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Colors} from "../constants/colors";
import { titleMarginBottom } from '../constants/layout';

const CampaignDetails = () => {
  const styles = useStyles()
  const history = useHistory()
  const params = useParams<{ id: string }>()

  const [updateCampaignModalVisible, setUpdateCampaignModalVisible] = useState(false)
  const [updateTranslationModalVisible, setUpdateTranslationModalVisible] = useState(false)
  const [updateRecipientsModalVisible, setUpdateRecipientsModalVisible] = useState(false)
  const [campaignModel, setCampaignModel] = useState<CreateOrUpdateCampaignModel>({
    name: '',
    type: CampaignType.FreePayoutCampaign,
    executionTime: '',
    recipientType: CampaignRecipientType.ActiveEmployees,
    recipients: [],
    numberOfFreePayouts: 1
  })
  const [updatedTranslation, setUpdatedTranslation] = useState<CampaignTranslation | null>(null)

  const {getCampaign, updateCampaign, setCampaignTranslation, launchCampaign, abortCampaign} = useCampaignActions()

  const loading = useAppSelector(state => state.campaigns.loading)
  const campaign = useAppSelector(state => state.campaigns.campaign)
  const error = useAppSelector(state => state.campaigns.error)
  const [showLaunchError, setShowLaunchError] = useState(false)

  const [actions, setActions] = useState({
    canEdit: false,
    canLaunch: false,
    canAbort: false
  })

  const notSpecified = 'Not specified'

  useEffect(() => {
    if (campaign == null || campaign.id !== params.id) {
      getCampaign(params.id)
    }

    if (campaign) {
      setCampaignModel({
        name: campaign.name,
        type: campaign.type,
        executionTime: campaign.executionTime,
        recipientType: campaign.recipientType,
        recipients: campaign.recipients?.map(x => x.id) ?? [],
        numberOfFreePayouts: campaign.numberOfFreePayouts,
      })
    }

    if (!!error) {
      setShowLaunchError(true)
    } else {
      setShowLaunchError(false)
    }

    setActions({
      canEdit: !!campaign && [CampaignState.New, CampaignState.Failed, CampaignState.Aborted].includes(campaign.state),
      canLaunch: !!campaign && [CampaignState.New, CampaignState.Aborted].includes(campaign.state),
      canAbort: !!campaign && [CampaignState.New, CampaignState.Launched, CampaignState.Failed].includes(campaign.state)
    })
  }, [campaign, error, params.id])

  const handleUpdateCampaign = (model: CreateOrUpdateCampaignModel) => {
    if (!!campaign) {
      setCampaignModel(model)
      updateCampaign(campaign.id, model)
    }
    setUpdateCampaignModalVisible(false)
  }

  const handleUpdateRecipients = (recipients: string[]) => {
    if (!!campaign) {
      const model = {...campaignModel, recipients: recipients}
      setCampaignModel(model)
      updateCampaign(campaign.id, model)
    }
    setUpdateRecipientsModalVisible(false)
  }

  const handleUpdateTranslation = (message: CampaignTranslation) => {
    if (!!campaign) {
      setCampaignTranslation(campaign.id, message)
    }
    setUpdateTranslationModalVisible(false)
  }

  const handleLaunchCampaign = () => {
    if (!!campaign) {
      launchCampaign(campaign.id)
    }
  }

  const handleAbortCampaign = () => {
    if (!!campaign) {
      abortCampaign(campaign.id)
    }
  }

  const handleEditTranslation = (message: CampaignTranslation | undefined) => {
    setUpdatedTranslation(message || null)
    setUpdateTranslationModalVisible(true)
  }

  const renderRecipients = () => {
    if (campaign?.recipientType === CampaignRecipientType.Individuals) {
      return <DetailsCard onClick={actions.canEdit ? () => setUpdateRecipientsModalVisible(true) : undefined}>
        <h6>Recipients</h6>
        <RecipientList recipients={campaign?.recipients}/>
      </DetailsCard>
    }

    if (campaign?.recipientType === CampaignRecipientType.ActiveEmployees) {
      return <DetailsCard>
        <h6>Recipients</h6>
        <p><span>All active employees.</span> Recipients are determined during campaign execution.
          The campaign targets all active users with at least one active employment.
        </p>
      </DetailsCard>
    }
  }

  const renderCampaignInformation = () => {
    switch (campaign?.state) {
      case CampaignState.Launched:
      case CampaignState.Ongoing:
        return <Alert
          key='launchAlert'
          variant='warning'>
          <p>Campaign is launched, and will execute at {formatDateTimeString(campaign?.executionTime)}.</p>
          <p>If you need to modify the campaign you need to abort it first.</p>
        </Alert>
      case CampaignState.Completed:
        return <Alert
          key='successAlert'
          variant='success'>
          Campaign is completed
        </Alert>
      case CampaignState.Failed:
        return <Alert
          key='failedAlert'
          variant='danger'>
          <p>Campaign failed. It can be re-launched by first aborting it.</p>
          <p>Before re-launching, you need to investigate what went wrong (e.g. check logs).</p>
        </Alert>
    }
  }

  const renderLaunchError = () => {
    if (showLaunchError) {
      return <Alert
        key='launchError'
        variant='danger'
        onClose={() => setShowLaunchError(false)}
        dismissible>
        {error}
      </Alert>
    }
  }

  const renderTranslations = () => {
    return <DetailsCard>
      <h6>Translations</h6>
      <LocalizedMessageList
        messages={campaign?.messages}
        canEdit={actions.canEdit}
        onEditClick={handleEditTranslation}
      />
    </DetailsCard>
  }

  return (
    <div className={styles.container}>
      <h1>Campaign: {campaign?.name}</h1>
      <div className={styles.actionBar}>
        <div className={styles.actionBarCell} style={{justifyContent: 'flex-start'}}>
          <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()}/>
        </div>
        <div className={styles.actionBarCell} style={{justifyContent: 'flex-end'}}>
          <Button title='Launch' disabled={!actions.canLaunch} onClick={() => handleLaunchCampaign()}/>
          <Button title='Abort' disabled={!actions.canAbort} onClick={() => handleAbortCampaign()}/>
        </div>
      </div>
      {renderCampaignInformation()}
      {renderLaunchError()}
      <CardSection>
        <DetailsCard onClick={actions.canEdit ? () => setUpdateCampaignModalVisible(true) : undefined}>
          <h6>General information</h6>
          <p>Type: <span>{campaign?.type || notSpecified}</span></p>
          <p>Name: <span>{campaign?.name || notSpecified}</span></p>
          <p>Execution time: <span>{formatDateTimeString(campaign?.executionTime)}</span></p>
          <p>State: <span>{campaign?.state || notSpecified}</span></p>
          <p>Recipient type: <span>{campaign?.recipientType || notSpecified}</span></p>
        </DetailsCard>
        {campaign?.type === CampaignType.FreePayoutCampaign &&
            <DetailsCard>
                <h6>Free Payout Campaign</h6>
                <p>Number of free payouts: <span>{campaign?.numberOfFreePayouts || notSpecified}</span></p>
            </DetailsCard>}
      </CardSection>
      <CardSection>
        {renderRecipients()}
      </CardSection>
      <CardSection>
        {renderTranslations()}
      </CardSection>
      <CreateOrUpdateCampaignModal
        initialValue={campaign ?? undefined}
        visible={updateCampaignModalVisible}
        onAddClick={handleUpdateCampaign}
        onCancelClick={() => setUpdateCampaignModalVisible(false)}
      />
      <UpdateCampaignTranslationModal
        initialValue={updatedTranslation ?? undefined}
        visible={updateTranslationModalVisible}
        onSaveClick={handleUpdateTranslation}
        onCancelClick={() => setUpdateTranslationModalVisible(false)}
      />
      <UpdateCampaignRecipientsModal
        initialValue={campaign?.recipients}
        visible={updateRecipientsModalVisible}
        onSaveClick={handleUpdateRecipients}
        onCancelClick={() => setUpdateRecipientsModalVisible(false)}
      />
      <FullScreenLoadingIndicator visible={loading}/>
    </div>
  )
}

export default CampaignDetails

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    },
    '& h5': {
      fontFamily: 'Aestetico-Regular',
      color: Colors.darkDefault,
    }
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  actionBarCell: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 15,
    alignItems: 'center',
    width: '33%'
  }
})
