import { FC, useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import Form from 'react-bootstrap/Form'
import { Colors } from '../constants/colors'
import { cardPadding } from '../constants/layout'
import S2 from './typography/S2'

interface Props {
    id?: string
    label?: string
    options: SelectOption[]
    selectedValue?: string
    onSelect?: (value: string) => void
}

interface SelectOption {
    name: string
    value: string
}

const Select = ({
    id = "select",
    label,
    options = [],
    selectedValue,
    onSelect
}: Props) => {
    const styles = useStyles()
    const [value, setValue] = useState(selectedValue)

    useEffect(() => {
      setValue(selectedValue)
    }, [selectedValue])

    const handleOnChange = (value: string) => {
        setValue(value)
        onSelect && onSelect(value)
    }

    const renderLabel = () => {
        return label && (
          <S2 className={styles.label}>{label}</S2>
        )
    }

    const renderOptions = () => {
        return options?.map((option) => {
            return (
                <option key={option.value} value={option.value}>{option.name}</option>
            )
        })
    }

    return (
        <>
        {renderLabel()}
        <div className={`${styles.container}`}>
            <Form.Control id={id} as="select" onChange={(event) => handleOnChange(event.target.value)} value={value} className={styles.input}>
                {renderOptions()}
            </Form.Control>
        </div>
        </>
    )
}

export default Select

const useStyles = createUseStyles({
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      borderRadius: 5,
      backgroundColor: Colors.darkGray2,
      transition: '0.4s',
      '&:hover': {
        backgroundColor: Colors.darkGrayHover,
      }
    },
    input: {
      width: '100%',
      border: 'none',
      borderRadius: 5,
      backgroundColor: 'transparent',
      fontFamily: 'Aestetico-Regular',
      fontSize: 16,
      height: 46,
      padding: {
        top: cardPadding * 0.5,
        bottom: cardPadding * 0.5,
        right: cardPadding * 0.5,
        left: cardPadding * 0.5,
      },
      '&:focus': {
        border: 'none',
        backgroundColor: Colors.darkGray3,
        outline: 'none',
      },
      '&:active': {
        backgroundColor: Colors.darkGray3,
      },
    },
    label: {
      marginBottom: 8,
      marginTop: 8
    }
  })
