import {createUseStyles} from "react-jss"
import {formatDateTimeString} from '../../utils/dateHelper'
import {Link} from "react-router-dom"
import {Colors} from "../../constants/colors"
import {Campaign, CampaignState} from "../../redux/types"
import Card from "../Card"
import Badge from 'react-bootstrap/Badge';

interface Props {
  campaigns: Campaign[]
}

const CampaignList = ({campaigns}: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        <div className={styles.cell}>
          <h6>Name</h6>
        </div>
        <div className={styles.cell}>
          <h6>Type</h6>
        </div>
        <div className={styles.cell}>
          <h6>Execution time</h6>
        </div>
        <div className={styles.cell}>
          <h6>Status</h6>
        </div>
      </Card>
    )
  }

  const getBadgeVariant = (item: Campaign) => {
    switch (item.state) {
      case CampaignState.Launched:
      case CampaignState.Ongoing:
        return "warning"
      case CampaignState.Completed:
        return "success"
      case CampaignState.Failed:
        return "danger"
      case CampaignState.Aborted:
        return "secondary"
      default:
        return "primary"
    }
  }

  const renderListItems = () => {
    return campaigns?.map((item, index) => {
      return (
        <Link to={`/campaigns/${item.id}`} key={`campaign_${index}`}>
          <Card className={`${styles.container} card`}>
            <div className={styles.cell}>
              {item.name}
            </div>
            <div className={styles.cell}>
              {item.type}
            </div>
            <div className={styles.cell}>
              {formatDateTimeString(item.executionTime)}
            </div>
            <div className={`${styles.cell}`}>
              <Badge variant={getBadgeVariant(item)}>{item.state}</Badge>
            </div>
          </Card>
        </Link>
      )
    })
  }

  return (
    <div className={styles.campaignList}>
      {renderHeader()}
      {renderListItems()}
    </div>
  )
}

export default CampaignList

const useStyles = createUseStyles({
  campaignList: {
    marginTop: '30px',
    marginBottom: '30px'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    margin: '10px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    marginBottom: '20px'
  },
  cell: {
    flex: 1
  }
})