import {createUseStyles} from "react-jss"
import {Colors} from "../../constants/colors"
import {Language, LearningCourseCreateOrUpdateModel, LearningCourseManagementModel} from "../../redux/types"
import Card from "../Card"
import {multilingual as m} from "../../utils/multilingualStringHelper";
import Badge from "react-bootstrap/Badge";

interface Props {
  course: LearningCourseManagementModel | LearningCourseCreateOrUpdateModel
  language: Language
}

const CourseCard = ({course, language}: Props) => {
  const styles = useStyles()

  const courseManagementModel = course as LearningCourseManagementModel
  
  const renderBadges = () => {
    return (
      <h5>
        <div className={styles.badges}>
          {courseManagementModel.isPublished ?
            <Badge variant={"success"}>Published</Badge> :
            <Badge variant={"secondary"}>Unpublished</Badge>
          }
          {courseManagementModel.employerId &&
              <Badge variant={"warning"}>Employer limited</Badge>}
          {courseManagementModel.modules &&
              <Badge variant={"info"}>{courseManagementModel.modules.length} modules</Badge>}
          <Badge variant={"info"}>Order: {courseManagementModel.orderNumber}</Badge>
          <Badge variant={"info"}>Rating: {courseManagementModel.rating} ({courseManagementModel.ratingCount} ratings)</Badge>
          {courseManagementModel.isTopRated && <Badge variant={"light"}>Top rated</Badge>}
          {courseManagementModel.isPopular && <Badge variant={"light"}>Popular</Badge>}
          {courseManagementModel.isNew && <Badge variant={"light"}>New</Badge>}
        </div>
      </h5>
    )
  }

  return (
    <Card className={`${styles.courseCard} card`}>
      <img className={styles.image} src={course.imageUrl} alt={course.imageUrl}/>
      <h5>{m(language, course.title)}</h5>
      <p>{m(language, course.description)}</p>
      {renderBadges()}
    </Card>
  )
}

export default CourseCard

const useStyles = createUseStyles({
  courseCard: {
    borderRadius: 8,
    display: 'flex',
    padding: 14,
    gap: 8,
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  image: {
    backgroundColor: Colors.grayBackground,
    height: '130px',
    borderRadius: 8,
    objectFit: 'cover',
  },
  badges: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    flexDirection: 'row-reverse'
  }
})
