import { useState, useEffect } from 'react'
import useEmployerSelectorActions from '../../hooks/useEmployerSelectorActions'
import SearchAndSelectField, { SearchResultItem } from '../SearchAndSelectField'

interface Props {
    onEmployerSelected: (employer: SearchResultItem | null) => void
    value: string
 }

const EmployerSelector = ({ onEmployerSelected = () => '', value}: Props) => {
    const [employerResultItems, setEmployersResultItems] = useState<SearchResultItem[]>()
    const { search, employers } = useEmployerSelectorActions()

    useEffect(() => {
        if (employers) {
            const items = employers.map((item) => {
                return {name: item.name ?? '', value: item.id}
            })
            setEmployersResultItems(items)
        } else{
            setEmployersResultItems([])   
        }              
    }, [employers])

    const handleSearchTermChange = (searchTerm: string) => {
        search(searchTerm, 10)
    }
    
    const handleEmployerSelected = (selectedItem: SearchResultItem | null) => {
        onEmployerSelected && onEmployerSelected(selectedItem ?? null)
    }

    return (
        <>
            <SearchAndSelectField
                placeholder='Search employer'
                id='employerSearch'
                onSearchTermChange={handleSearchTermChange}
                onSelect={handleEmployerSelected}
                value={value}
                searchResult={employerResultItems ?? []}
            /> 
        </>
    )
}

export default EmployerSelector