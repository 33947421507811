import  { useEffect, useState, } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory, useParams } from 'react-router-dom';
import Card from '../components/Card'
import CardSection from '../components/CardSection'
import DetailsCard from '../components/DetailsCard'
import { Colors } from '../constants/colors'
import Button from '../components/Button'
import AcceptAmlFlaggedEntityModal from '../components/Aml/AcceptAmlFlaggedEntityModal'
import { formatDateTimeString } from '../utils/dateHelper'
import { useAppSelector } from '../redux/hooks'
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator'
import useCompanyAmlActions from '../hooks/useCompanyAmlActions'
import { titleMarginBottom } from '../constants/layout';
import NavLink from '../components/NavLink';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const CompanyAmlDetails = () => {
  const styles = useStyles()
  const { id } = useParams<{id: string}>();
  const history = useHistory();

  const [acceptModalVisible, setAcceptModalVisible] = useState(false)
  const { getAmlFlaggedCompanyDetails, acceptAmlFlaggedCompany } = useCompanyAmlActions()
  
  const loading = useAppSelector(state => state.amlFlaggedCompany.loading)
  const employerDetails = useAppSelector(state => state.amlFlaggedCompany.companyDetails)

  useEffect(() => {
    if (employerDetails == null || employerDetails.id != id) {
      getAmlFlaggedCompanyDetails(id)
    }
  }, [])

  const handleOnAccept = (comment: string) => {
    acceptAmlFlaggedCompany(id, comment)
    setAcceptModalVisible(false)
  }

  const renderTransitionHistoryHeader = () => {
    return (
      <Card className={styles.listContainer}>
        <div className={styles.listCell}>
          <p>Date</p>
        </div>
        <div className={styles.listCell}>
          <p>From</p>
        </div>
        <div className={styles.listCell}>
          <p>To</p>
        </div>
      </Card>
    )
  }

  const renderTransitionHistoryList = () => {
    return employerDetails?.amlDetails.stateTransitions?.map((transition, index) => {
      return (
        <Card className={styles.listContainer} key={`transition${index}`}>
          <div className={styles.listCell}>
            <p><span>{formatDateTimeString(transition.date)}</span></p>
          </div>
          <div className={styles.listCell}>
            <p><span>{transition.from}</span></p>
          </div>
          <div className={styles.listCell}>
            <p><span>{transition.to}</span></p>
          </div>
        </Card>
      )
    })
  }

  const renderManualAcceptHistoryHeader = () => {
    return (
      <Card className={styles.listContainer}>
        <div className={styles.listCell}>
          <p>Date</p>
        </div>
        <div className={styles.listCell}>
          <p>Comment</p>
        </div>
      </Card>
    )
  }

  const renderManualAcceptHistoryList = () => {
    return employerDetails?.amlDetails.manualAccepts?.map((accept, index) => {
      return (
        <Card className={styles.listContainer} key={`accept${index}`}>
          <div className={styles.listCell}>
            <p><span>{formatDateTimeString(accept.date)}</span></p>
          </div>
          <div className={styles.listCell}>
            <p><span>{accept.comment}</span></p>
          </div>
        </Card>
      )
    })
  }

  const renderReports = () => {
    return employerDetails?.amlDetails.reports?.map((report, index) => {
      return (
        <Card className={styles.card} key={`report${index}`}>
          <pre id="json">{ report }</pre>
        </Card>
      )
    })  
  }

  const notSpecified = 'not specified'

  return (
    <div className={styles.container}>
      <h1>Sanction details: {employerDetails?.name}</h1> 
      <div className={styles.actionBar}>
        <div className={styles.actionBarCell} style={{justifyContent: 'flex-start'}}>
            <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()} />
          </div>
          <div className={styles.actionBarCell} style={{justifyContent: 'flex-end'}}>
              <div style={{paddingBottom: 8}}>
                { employerDetails?.amlDetails.state == 'flagged' &&  <Button className={styles.actionButton} title='Accept' onClick={() => setAcceptModalVisible(true)} />  }
              </div>
          </div>
      </div>
      <CardSection>
        <DetailsCard>
          <h6>General information</h6>
          <p>Name: <span>{employerDetails?.name}</span></p>
          <p>Company registration number: <span>{employerDetails?.companyRegistrationNumber || notSpecified}</span></p>
          <p>State: <span>{employerDetails?.amlDetails.state}</span></p>
        </DetailsCard>
      </CardSection>
      <CardSection>
        <DetailsCard>
          <h6>State transitions</h6>
          {renderTransitionHistoryHeader()}
          {renderTransitionHistoryList()}
        </DetailsCard>
        <DetailsCard>
          <h6>Manual accept history</h6>
          {renderManualAcceptHistoryHeader()}
          {renderManualAcceptHistoryList()}
        </DetailsCard>
      </CardSection>
      <CardSection>
        <DetailsCard>
          <h6>Reports</h6>
          {renderReports()}
        </DetailsCard>
      </CardSection>
      <AcceptAmlFlaggedEntityModal      
        visible={acceptModalVisible}
        onSaveClick={handleOnAccept}
        onCancelClick={() => setAcceptModalVisible(false)}
      />
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default CompanyAmlDetails

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    },
    '& h2': {
      paddingTop: '30px'
    }
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    transition: '0.4s'
  },
  listCell: {
    flex: 1
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'left',
    textAlign: 'left',
    padding: '5px 5px',
    margin: '10px 0',
    backgroundColor: Colors.darkGrayCard,
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  actionBarCell: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 15,
    alignItems: 'center',
    width: '33%'
  },
  actionButton: {
    margin: '5px'
  }
})