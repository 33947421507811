import {useEffect, useState} from 'react'
import Modal from '../Modal'
import InputField from '../InputField'
import {CreateOrUpdateProtectRuleModel, ProtectRule, ProtectRuleType} from '../../redux/types'
import {validateTimeSpan} from '../../utils/validation'
import Select from '../Select'

interface Props {
    employerId: string
    initialValue?: ProtectRule
    onSaveClick?: (model: CreateOrUpdateProtectRuleModel) => void
    onCancelClick?: () => void
    visible: boolean
}

const defaultValidationState = {
    nameValid: true,
    hysteresisValid: true,
    triggerValueValid: true,
    withdrawalLimitValid: true,
    minSampleThresholdValid: true,
    compareWindowSizeValid: true,
    currentWindowSizeValid: true
}

const EditProtectRuleModal = ({
    employerId,
    initialValue,
    onSaveClick,
    onCancelClick,
    visible
}: Props) => {
    const [model, setModel] = useState<CreateOrUpdateProtectRuleModel>()

    const [valid, setValid] = useState<{
        nameValid: boolean
        hysteresisValid: boolean
        triggerValueValid: boolean
        withdrawalLimitValid: boolean
        minSampleThresholdValid: boolean
        compareWindowSizeValid: boolean
        currentWindowSizeValid: boolean
    }>(defaultValidationState)

    useEffect(() => {
        if (initialValue) {
            setModel({
                id: initialValue.id,
                customerId: employerId,
                name: initialValue.name,
                type: initialValue.type,
                hysteresis: initialValue.hysteresis,
                triggerValue: initialValue.triggerValue,
                currentWindowSize: initialValue.currentWindowSize,
                compareWindowSize: initialValue.compareWindowSize,
                skipDays: initialValue.skipDays,
                withdrawalLimit: initialValue.withdrawalLimit,
                minSampleThreshold: initialValue.minSampleThreshold
            })
        } else {
            setModel({
                customerId: employerId,
                name: '',
                type: ProtectRuleType.PayoutVolumeZScoreRule,
                hysteresis: '01:00:00',
                compareWindowSize: '365.00:00:00',
                triggerValue: 3
            })
        }
    }, [initialValue, employerId])

    const handleSave = () => {
        if (model) {
            if (!validate(model))
                return

            onSaveClick && onSaveClick(model)
            setValid(defaultValidationState)
        }
    }

    const handleClose = () => {
        onCancelClick && onCancelClick()
        setValid(defaultValidationState)
    }

    const handlePropChange = (propName: string, value: any) => {
        if (model) {
            setModel({...model, [propName]: value === '' ? null : value})
        }
    }

    const validate = (model: CreateOrUpdateProtectRuleModel) => {
        let result = true
        let validClone = {...valid}

        if (!model.name || model.name.length === 0) {
            validClone = {...validClone, nameValid: false}
            result = false
        }

        if (!validateTimeSpan(model.hysteresis)) {
            validClone = {...validClone, hysteresisValid: false}
            result = false
        }

        if (!model.triggerValue) {
            validClone = {...validClone, triggerValueValid: false}
            result = false
        }

        if (model?.type === ProtectRuleType.PayoutVolumeZScoreRule || model?.type === ProtectRuleType.ActivationRateZScoreRule || model?.type === ProtectRuleType.InvoicePayoutVolumeZScoreRule) {
            if (!model.compareWindowSize || !validateTimeSpan(model.compareWindowSize)) {
                validClone = {...validClone, compareWindowSizeValid: false}
                result = false
            }
            if (model.minSampleThreshold != null && model.minSampleThreshold < 0) {
                validClone = {...validClone, minSampleThresholdValid: false}
                result = false
            }
        }

        if (model?.type === ProtectRuleType.OverdrawsRule) {
            if (!model.withdrawalLimit || model.withdrawalLimit < 0) {
                validClone = {...validClone, withdrawalLimitValid: false}
                result = false
            }
        }

        if (model?.type === ProtectRuleType.AbnormalEarningsRule ||
          model?.type === ProtectRuleType.PayoutVolumeRateRule ||
          model?.type === ProtectRuleType.AveragePayoutAmountRule) {
            if (!model.currentWindowSize || !validateTimeSpan(model.currentWindowSize)) {
                validClone = {...validClone, currentWindowSizeValid: false}
                result = false
            }
        }

        setValid(validClone)
        return result
    }

    const getTypeOptions = () => {
        return Object.keys(ProtectRuleType).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    return (
        <Modal
            title={initialValue != null ? 'Edit rule' : 'Add rule'}
            show={visible}
            okButtonTitle='Save'
            cancelButtonTitle='Cancel'
            onHide={() => handleClose()}
            onOkClick={() => handleSave()}
            onCancelClick={() => handleClose()}
        >
            {initialValue == null &&
            <Select
                id='type'
                key='type'
                label='Type'
                options={getTypeOptions()}
                selectedValue={model?.type?.toString() ?? ''}
                onSelect={(value) => handlePropChange('type', ProtectRuleType[value as keyof typeof ProtectRuleType])}
            />
            }
            <InputField
                id='name'
                value={model?.name ?? ''}
                valid={valid.nameValid}
                onChange={(event) => handlePropChange('name', event.target.value)}
                label='Name'
                placeholder=''
            />
            <InputField
                id='hysteresis'
                valid={valid.hysteresisValid}
                value={model?.hysteresis ?? ''}
                onChange={(event) => handlePropChange('hysteresis', event.target.value)}
                label='Hysteresis'
                placeholder='01:00:00'
            />

            <InputField
                id='triggerValue'
                value={model?.triggerValue?.toString() ?? ''}
                valid={valid.triggerValueValid}
                onChange={(event) => handlePropChange('triggerValue', parseFloat(event.target.value.replace(',', '.')))}
                label='Trigger value'
                placeholder='3'
            />

            {(model?.type === ProtectRuleType.PayoutVolumeZScoreRule || model?.type === ProtectRuleType.ActivationRateZScoreRule || model?.type === ProtectRuleType.InvoicePayoutVolumeZScoreRule) &&
                <>
                    <InputField
                        id='minSampleThreshold'
                        value={model?.minSampleThreshold?.toString() ?? ''}
                        valid={valid.minSampleThresholdValid}
                        onChange={(event) => handlePropChange('minSampleThreshold', event.target.value)}
                        label='Min sample threshold'
                        placeholder='0'
                    />
                    <InputField
                        id='compareWindowSize'
                        value={model?.compareWindowSize?.toString() ?? ''}
                        valid={valid.compareWindowSizeValid}
                        onChange={(event) => handlePropChange('compareWindowSize', event.target.value)}
                        label='Sliding window size'
                        placeholder='365.00:00:00'
                    />
                    <InputField
                        id='skipDays'
                        value={model?.skipDays?.toString() ?? ''}
                        onChange={(event) => handlePropChange('skipDays', parseInt(event.target.value))}
                        label='Skip days'
                        placeholder='0'
                    />
                </>
            }


            {(model?.type === ProtectRuleType.OverdrawsRule) &&
            <InputField
                id='withdrawalLimit'
                value={model?.withdrawalLimit?.toString() ?? ''}
                valid={valid.withdrawalLimitValid}
                onChange={(event) => handlePropChange('withdrawalLimit', parseFloat(event.target.value.replace(',', '.')))}
                label='Withdrawal limit'
                placeholder='20000'
            />
            }

            {(model?.type === ProtectRuleType.AbnormalEarningsRule ||
                model?.type === ProtectRuleType.PayoutVolumeRateRule ||
                model?.type === ProtectRuleType.AveragePayoutAmountRule ||
                model?.type === ProtectRuleType.InvoicePayoutRateRule ||
                model?.type === ProtectRuleType.InvoicePayoutVolumeRateRule ||
                model?.type === ProtectRuleType.InvoicePayoutRateForRecipientRule ||
                model?.type === ProtectRuleType.InvoicePayoutVolumeRateForRecipientRule) &&
              <InputField
                id='currentWindowSize'
                value={model?.currentWindowSize?.toString() ?? ''}
                valid={valid.currentWindowSizeValid}
                onChange={(event) => handlePropChange('currentWindowSize', event.target.value)}
                label='Current window size'
                placeholder='1.00:00:00'
              />
            }
        </Modal>
    )
}

export default EditProtectRuleModal
