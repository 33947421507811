import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CompanyModel, CompanyMonitorDetails } from '../companyMonitorTypes'

interface CompanyMonitorState {
    loading: boolean
    companiesWithUnhandledChanges: CompanyModel[]
    details: CompanyMonitorDetails | null
    error: Error | string | null
}

const initialState: CompanyMonitorState = {
    loading: false,
    companiesWithUnhandledChanges: [],
    details: null,
    error: null
}

export const companyMonitorSlice = createSlice({
    name: 'companyMonitor',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        companiesWithUnhandledChangesFetched: (state, action: PayloadAction<CompanyModel[]>) => {
            state.loading = false
            state.companiesWithUnhandledChanges = action.payload
            state.error = null
        },
        companiesWithUnhandledChangesFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        companyMonitorDetailsFetched: (state, action: PayloadAction<CompanyMonitorDetails>) => {
            state.loading = false
            state.details = action.payload
            state.error = null
        },
        companyMonitorDetailsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        addRepresentativeFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        deleteRepresentativeFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        companyReportChangeHandleFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        companyReportChangeCommentFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
    }
})

export const {
    loading,
    companiesWithUnhandledChangesFetched,
    companiesWithUnhandledChangesFetchFailure,
    companyMonitorDetailsFetched,
    companyMonitorDetailsFetchFailure,
    addRepresentativeFailure,
    deleteRepresentativeFailure,
    companyReportChangeHandleFailure,
    companyReportChangeCommentFailure
} = companyMonitorSlice.actions
export default companyMonitorSlice.reducer
