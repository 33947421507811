import CardSection from "../CardSection"
import DetailsCard from "../DetailsCard"
import InputField from "../InputField"

interface Props {
    filterValue: string
    onFilterChange: (value: string) => void
}

const CustomerFilter = ({filterValue, onFilterChange}: Props) => {
 
    return (
        <CardSection>
            <DetailsCard>
            <InputField
                id="customerSearchField"
                placeholder="Search customers..."
                value={filterValue || ''}
                onEnterKeyOrBlur={value => onFilterChange(value)}
            />
            </DetailsCard>
        </CardSection>
    )
}

export default CustomerFilter