import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {InAppMessage} from '../types'

interface InAppMessageState {
  loading: boolean
  inAppMessage: InAppMessage | null
  inAppMessagesOnPage: InAppMessage[]
  error: Error | string | null
}

const initialState: InAppMessageState = {
  loading: false,
  inAppMessage: null,
  inAppMessagesOnPage: [],
  error: null
}

export const inAppMessageSlice = createSlice({
  name: 'inAppMessageSlice',
  initialState,
  reducers: {
    loading: state => {
      state.loading = true
      state.error = null
    },
    inAppMessageFetched: (state, action: PayloadAction<InAppMessage>) => {
      state.loading = false
      state.inAppMessage = action.payload
      state.error = null
    },
    inAppMessageFetchFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
    inAppMessagesFetched: (state, action: PayloadAction<InAppMessage[]>) => {
      state.loading = false
      state.inAppMessagesOnPage = action.payload
      state.error = null
    },
    inAppMessagesFetchFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
    inAppMessageCreated: (state, action: PayloadAction<InAppMessage>) => {
      state.loading = false
      state.inAppMessage = action.payload
      state.error = null

      const inAppMessagesOnPage = [...state.inAppMessagesOnPage ?? []]
      inAppMessagesOnPage.push(action.payload)
      state.inAppMessagesOnPage = inAppMessagesOnPage
    },
    inAppMessageCreateFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
    inAppMessageUpdated: (state, action: PayloadAction<InAppMessage>) => {
      state.loading = false
      state.inAppMessage = action.payload
      state.error = null

      let index = state.inAppMessagesOnPage?.findIndex((item) => {
        return item.id === action.payload.id
      })

      if (index === -1)
        return

      const inAppMessagesOnPage = [...state.inAppMessagesOnPage ?? []]
      inAppMessagesOnPage[index as number] = action.payload
      state.inAppMessagesOnPage = inAppMessagesOnPage
    },
    inAppMessageUpdateFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    }
  }
})

export const {
  loading,
  inAppMessageFetched,
  inAppMessageFetchFailure,
  inAppMessagesFetched,
  inAppMessagesFetchFailure,
  inAppMessageCreated,
  inAppMessageCreateFailure,
  inAppMessageUpdated,
  inAppMessageUpdateFailure
} = inAppMessageSlice.actions

export default inAppMessageSlice.reducer