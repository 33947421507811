import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PagedListItems, ProtectIssue, ProtectIssueFilterParams } from '../types'

interface ProtectIssueState {
    loading: boolean
    issueDetails: ProtectIssue | null
    issuesOnPage: ProtectIssue[]
    pageIndex: number
    totalCount: number
    pageSize: number 
    filter: ProtectIssueFilterParams | null
    error: Error | string | null
}

const initialState: ProtectIssueState = {
    loading: false,
    issueDetails: null,
    issuesOnPage: [],
    totalCount: 0,
    pageIndex: 1,
    pageSize: 15,
    filter: null,
    error: null
}

export const protectIssueSlice = createSlice({
    name: 'protectIssueSlice',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        issuesFetched: (state, action: PayloadAction<PagedListItems<ProtectIssue>>) => {
            state.loading = false
            state.issuesOnPage = action.payload.items
            state.totalCount = action.payload.totalCount
            state.error = null
        },
        issuesFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        issueFetched: (state, action: PayloadAction<ProtectIssue>) => {
            state.loading = false
            state.issueDetails = action.payload
            state.error = null
        },
        issueFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        issueUpdated: (state, action: PayloadAction<ProtectIssue>) => {
            state.loading = false
            state.issueDetails = action.payload
            state.error = null

            let index = state.issuesOnPage?.findIndex((item) => {
                return item.id === action.payload.id
            })

            if (index === -1)
                return

            const issuesOnPage = [...state.issuesOnPage ?? []]
            issuesOnPage[index as number] = action.payload
            state.issuesOnPage = issuesOnPage
        },
        issueUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        filterChanged: (state, action: PayloadAction<ProtectIssueFilterParams>) => {
            state.filter = action.payload
            state.pageIndex = 1
        },
    }
})

export const { 
    loading, 
    issuesFetched, 
    issuesFetchFailure,
    issueFetched,
    issueFetchFailure,
    issueUpdated,
    issueUpdateFailure,
    pageIndexChanged, 
    filterChanged 
} = protectIssueSlice.actions

export default protectIssueSlice.reducer