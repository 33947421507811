import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from '../Modal'
import InputField from '../InputField'
import Select from '../Select'
import {
    UpdateEmployerModel,
    EmployerModel,
    EmployeeActivationType,
    PayrollFileFormat,
    PayrollSystemImportFileFormat,
    EmployeeDataParserType,
    ShiftDataParserType,
    CalendarMonthPayoutBasisReportGenerationType,
    PayrollFileEmployeeIdentifierType,
    EmployeeDeactivationType,
    AdminFlowType,
    PayrollSystem
} from '../../redux/types'
import DatePicker from '../DatePicker'
import S1 from '../typography/S1'
import Switch from '../Switch'

interface Props {
    initialValue?: EmployerModel
    onSaveClick?: (updateModel: UpdateEmployerModel) => void
    onCancelClick?: () => void
    visible: boolean
}

const EditEmployerInfoModal = ({
    initialValue,
    onSaveClick,
    onCancelClick,
    visible
}: Props) => {
    const styles = useStyles()
    const [employerInfo, setEmployerInfo] = useState<UpdateEmployerModel>()

    useEffect(() => {
        if (initialValue) {
            setEmployerInfo({
                appAbbreviation: initialValue.appAbbreviation,
                creditLimit: initialValue.creditLimit?.amount,
                remainingCreditSpaceWarningPercent: initialValue.remainingCreditSpaceWarningPercent,
                employeeActivationType: initialValue.employeeActivationType,
                employeeDeactivationType: initialValue.employeeDeactivationType,
                defaultPayoutTaxInPercent: initialValue.defaultPayoutTaxInPercent,
                defaultTaxTableIdentifier: initialValue.defaultTaxTableIdentifier,
                defaultFixedTaxRatePercent: initialValue.defaultFixedTaxRatePercent,
                payoutTaxImmutable: initialValue.payoutTaxImmutable,
                payrollFileFormat: initialValue.payrollFileFormat,
                payrollSystem: initialValue.payrollSystem,
                enablePayrollFileAdjustment: initialValue.enablePayrollFileAdjustment,
                payrollSystemImportFileFormat: initialValue.payrollSystemImportFileFormat,
                employeeDataParserType: initialValue.employeeDataParserType,
                shiftDataParserType: initialValue.shiftDataParserType,
                payrollFileEmployeeIdentifierType: initialValue.payrollFileEmployeeIdentifierType,
                enablePayrollSystemBankAccountImportFile: initialValue.enablePayrollSystemBankAccountImportFile,
                payrollSystemBankAccountImportFileCustomField: initialValue.payrollSystemBankAccountImportFileCustomField,
                disablePaydayDeductionFileGeneration: initialValue.disablePaydayDeductionFileGeneration,
                wagesPayrollCodeForPaydayDeductions: initialValue.wagesPayrollCodeForPaydayDeductions,
                salaryPayrollCodeForPaydayDeductions: initialValue.salaryPayrollCodeForPaydayDeductions,
                wagesPayrollCodeForCalendarMonthPayoutBasis: initialValue.wagesPayrollCodeForCalendarMonthPayoutBasis,
                salaryPayrollCodeForCalendarMonthPayoutBasis: initialValue.salaryPayrollCodeForCalendarMonthPayoutBasis,
                vacationPayPayrollCode: initialValue.vacationPayPayrollCode,
                defaultVacationPayPercent: initialValue.defaultVacationPayPercent,
                calendarMonthPayoutBasisReportGenerationType: initialValue.calendarMonthPayoutBasisReportGenerationType,
                calenderMonthPayoutBasisReportGenerationDayOfMonth: initialValue.calenderMonthPayoutBasisReportGenerationDayOfMonth,
                employeeActivationFreezeDaysBeforePayday: initialValue.employeeActivationFreezeDaysBeforePayday,
                launchDate: initialValue.launchDate,
                newEmployeeMessageEnabled: initialValue.newEmployeeMessageEnabled,
                employeeActivationReminderEnabled: initialValue.employeeActivationReminderEnabled,
                employeeActivationReminderIntervalDays: initialValue.employeeActivationReminderIntervalDays,
                plannedShiftsEnabled: initialValue.plannedShiftsEnabled,
                newEmployeesReportEnabled: initialValue.newEmployeesReportEnabled,
                adminFlowType: initialValue.adminFlowType,
                freePayoutCountOnEmployeeActivation: initialValue.freePayoutCountOnEmployeeActivation,
                payrollFileExecutionDateOneDayBeforePayment: initialValue.payrollFileExecutionDateOneDayBeforePayment,
                allowShiftsAndDeviationsOutsideCurrentEmploymentBounds: initialValue.allowShiftsAndDeviationsOutsideCurrentEmploymentBounds,
                manualPayrollFileRowCreationEnabled: initialValue.manualPayrollFileRowCreationEnabled,
                employerInitiatedPayoutsEnabled: initialValue.employerInitiatedPayoutsEnabled,
                employerInitiatedPayoutTaxPercent: initialValue.employerInitiatedPayoutTaxPercent,
                employerInitiatedPayoutClientConfiguration: initialValue.employerInitiatedPayoutClientConfiguration,
                employeeMinAgeInYears: initialValue.employeeMinAgeInYears
            })
        }
    }, [initialValue])

    const handleOnSave = () => {
        if (employerInfo) {
            onSaveClick && onSaveClick(employerInfo)
        }
    }

    const handleOnClose = () => {
        onCancelClick && onCancelClick()
    }

    const handleOnChange = (propName: string, value: any) => {
        if (employerInfo) {
            setEmployerInfo({...employerInfo, [propName]: value === '' ? null : value})
        }
    }

    const getEmployeeActivationTypeOptions = () => {
        return Object.keys(EmployeeActivationType).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    const getEmployeeDeactivationTypeOptions = () => {
        return Object.keys(EmployeeDeactivationType).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    const getPayrollFileFormatOptions = () => {
        return Object.keys(PayrollFileFormat).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    const getPayrollSystemOptions = () => {
        return Object.keys(PayrollSystem).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    const getPayrollSystemImportFileFormatOptions = () => {
        return Object.keys(PayrollSystemImportFileFormat).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    const getEmployeeDataParserTypeOptions = () => {
        return Object.keys(EmployeeDataParserType).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    const getShiftDataParserTypeOptions = () => {
        return Object.keys(ShiftDataParserType).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    const getPayrollFileEmployeeIdentifierTypeOptions = () => {
        return Object.keys(PayrollFileEmployeeIdentifierType).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    const getCalendarMonthPayoutBasisReportGenerationTypeOptions = () => {
        return Object.keys(CalendarMonthPayoutBasisReportGenerationType).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    const getAdminFlowTypeOptions = () => {
        return Object.keys(AdminFlowType).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    return (
        <Modal
            title='Edit employer'
            show={visible}
            okButtonTitle='Save'
            cancelButtonTitle='Cancel'
            onHide={() => handleOnClose()}
            onOkClick={() => handleOnSave()}
            onCancelClick={() => handleOnClose()}
        >
            <S1 className={styles.sectionTitle}>General</S1>
            <InputField
                id='appAbbreviation'
                value={employerInfo?.appAbbreviation ?? ''}
                onChange={(event) => handleOnChange('appAbbreviation', event.target.value)}
                label='Abbreviation in app'
                placeholder=''
              />
            <Select
                id='adminFlowType'
                key='adminFlowType'
                label='Admin flow'
                options={getAdminFlowTypeOptions()}
                selectedValue={employerInfo?.adminFlowType.toString()}
                onSelect={(value) => handleOnChange('adminFlowType', value)}
            />
            <Select
                id='employeeActivationType'
                key='employeeActivationType'
                label='Employee activation type'
                options={getEmployeeActivationTypeOptions()}
                selectedValue={employerInfo?.employeeActivationType.toString()}
                onSelect={(value) => handleOnChange('employeeActivationType', value)}
            />
            <Select
                id='employeeDeactivationType'
                key='employeeDeactivationType'
                label='Employee deactivation type'
                options={getEmployeeDeactivationTypeOptions()}
                selectedValue={employerInfo?.employeeDeactivationType.toString()}
                onSelect={(value) => handleOnChange('employeeDeactivationType', value)}
            />
            <InputField
                id='defaultTaxTableIdentifier'
                value={employerInfo?.defaultTaxTableIdentifier?.toString() ?? ''}
                label='Default tax table'
                onChange={(event) => handleOnChange('defaultTaxTableIdentifier', event.target.value)}
                placeholder=''
            />
            <InputField
                id='defaultFixedTaxRatePercent'
                value={employerInfo?.defaultFixedTaxRatePercent?.toString() ?? ''}
                label='Default fix income tax in percent'
                onChange={(event) => handleOnChange('defaultFixedTaxRatePercent', event.target.value)}
                placeholder=''
            />
            <InputField
                id='defaultPayoutTaxInPercent'
                value={employerInfo?.defaultPayoutTaxInPercent.toString() ?? ''}
                label='Default payout tax in percent'
                onChange={(event) => handleOnChange('defaultPayoutTaxInPercent', event.target.value)}
                placeholder=''
            />
            <Switch
                id='payoutTaxImmutable'
                key='payoutTaxImmutable'
                checked={employerInfo?.payoutTaxImmutable ?? false}
                label='Payout tax immutable'
                onChange={() => handleOnChange('payoutTaxImmutable', !(employerInfo?.payoutTaxImmutable ?? false))}
            />
            <Select
                id='payrollSystem'
                key='payrollSystem'
                label='Payroll system'
                options={getPayrollSystemOptions()}
                selectedValue={employerInfo?.payrollSystem.toString()}
                onSelect={(value) => handleOnChange('payrollSystem', value)}
            />
            <Select
                id='payrollFileFormat'
                key='payrollFileFormat'
                label='Payroll file format'
                options={getPayrollFileFormatOptions()}
                selectedValue={employerInfo?.payrollFileFormat.toString()}
                onSelect={(value) => handleOnChange('payrollFileFormat', value)}
            />
            <Switch
                id='enablePayrollFileAdjustment'
                key='enablePayrollFileAdjustment'
                checked={employerInfo?.enablePayrollFileAdjustment ?? false}
                label='Enable payroll file adjustment'
                onChange={() => handleOnChange('enablePayrollFileAdjustment', !(employerInfo?.enablePayrollFileAdjustment ?? false))}
            />
            <Select
                id='payrollFileEmployeeIdentifierType'
                key='payrollFileEmployeeIdentifierType'
                label='Payroll file employee identification'
                options={getPayrollFileEmployeeIdentifierTypeOptions()}
                selectedValue={employerInfo?.payrollFileEmployeeIdentifierType.toString()}
                onSelect={(value) => handleOnChange('payrollFileEmployeeIdentifierType', value)}
            />
            <Select
                id='payrollSystemImportFileFormat'
                key='payrollSystemImportFileFormat'
                label='Payroll system import file format'
                options={getPayrollSystemImportFileFormatOptions()}
                selectedValue={employerInfo?.payrollSystemImportFileFormat.toString()}
                onSelect={(value) => handleOnChange('payrollSystemImportFileFormat', value)}
            />
            <Switch
                id='enablePayrollSystemBankAccountImportFile'
                key='enablePayrollSystemBankAccountImportFile'
                checked={employerInfo?.enablePayrollSystemBankAccountImportFile ?? false}
                label='Enable payroll system bank account import file'
                onChange={() => handleOnChange('enablePayrollSystemBankAccountImportFile', !(employerInfo?.enablePayrollSystemBankAccountImportFile ?? false))}
            />
            <InputField
              id={'payrollSystemBankAccountImportFileCustomField'}
              value={employerInfo?.payrollSystemBankAccountImportFileCustomField ?? ''}
              onChange={(event) => handleOnChange('payrollSystemBankAccountImportFileCustomField', event.target.value)}
              label='Custom field for bank account import'
              placeholder=''
            />
            <Select
                id='employeeDataParserType'
                key='employeeDataParserType'
                label='Employee data parser type'
                options={getEmployeeDataParserTypeOptions()}
                selectedValue={employerInfo?.employeeDataParserType.toString()}
                onSelect={(value) => handleOnChange('employeeDataParserType', value)}
            />
            <Select
                id='shiftDataParserType'
                key='shiftDataParserType'
                label='Shift data parser type'
                options={getShiftDataParserTypeOptions()}
                selectedValue={employerInfo?.shiftDataParserType.toString()}
                onSelect={(value) => handleOnChange('shiftDataParserType', value)}
            />
            <Switch
                id='disablePaydayDeductionFileGeneration'
                key='disablePaydayDeductionFileGeneration'
                checked={employerInfo?.disablePaydayDeductionFileGeneration ?? false}
                label='Disable payday deduction file generation'
                onChange={() => handleOnChange('disablePaydayDeductionFileGeneration', !(employerInfo?.disablePaydayDeductionFileGeneration ?? false))}
            />
            <InputField
                id='wagesPayrollCodeForPaydayDeductions'
                value={employerInfo?.wagesPayrollCodeForPaydayDeductions ?? ''}
                onChange={(event) => handleOnChange('wagesPayrollCodeForPaydayDeductions', event.target.value)}
                label='Employer deduction payroll code for wages'
                placeholder=''
              />
            <InputField
                id='salaryPayrollCodeForPaydayDeductions'
                value={employerInfo?.salaryPayrollCodeForPaydayDeductions ?? ''}
                onChange={(event) => handleOnChange('salaryPayrollCodeForPaydayDeductions', event.target.value)}
                label='Employer deduction payroll code for salaries'
                placeholder=''
            />
            <InputField
                id='wagesPayrollCodeForCalendarMonthPayoutBasis'
                value={employerInfo?.wagesPayrollCodeForCalendarMonthPayoutBasis ?? ''}
                onChange={(event) => handleOnChange('wagesPayrollCodeForCalendarMonthPayoutBasis', event.target.value)}
                label='Calendar month payout basis payroll code for wages'
                placeholder=''
              />
            <InputField
                id='salaryPayrollCodeForCalendarMonthPayoutBasis'
                value={employerInfo?.salaryPayrollCodeForCalendarMonthPayoutBasis ?? ''}
                onChange={(event) => handleOnChange('salaryPayrollCodeForCalendarMonthPayoutBasis', event.target.value)}
                label='Calendar month payout basis payroll code for salaries'
                placeholder=''
              />
            <InputField
              id='vacationPayPayrollCode'
              value={employerInfo?.vacationPayPayrollCode ?? ''}
              onChange={(event) => handleOnChange('vacationPayPayrollCode', event.target.value)}
              label='Vacation pay payroll code'
              placeholder=''
            />
            <InputField
              id='defaultVacationPayPercent'
              numeric={true}
              value={employerInfo?.defaultVacationPayPercent?.toString() ?? ''}
              onChange={(event) => handleOnChange('defaultVacationPayPercent', event.target.value)}
              label='Default vacation pay percentage'
              placeholder=''
            />
            <Select
                id='calendarMonthPayoutBasisReportGenerationType'
                key='calendarMonthPayoutBasisReportGenerationType'
                label='Calendar month payout basis report generation'
                options={getCalendarMonthPayoutBasisReportGenerationTypeOptions()}
                selectedValue={employerInfo?.calendarMonthPayoutBasisReportGenerationType.toString()}
                onSelect={(value) => handleOnChange('calendarMonthPayoutBasisReportGenerationType', value)}
            />
            <InputField
                id='calenderMonthPayoutBasisReportGenerationDayOfMonth'
                numeric={true}
                value={employerInfo?.calenderMonthPayoutBasisReportGenerationDayOfMonth?.toString() ?? ''}
                onChange={(event) => handleOnChange('calenderMonthPayoutBasisReportGenerationDayOfMonth', event.target.value)}
                label='Day of month for early calendar month payout basis report generation'
                placeholder=''
              />
            <InputField
                id='employeeActivationFreezeDaysBeforePayday'
                numeric={true}
                value={employerInfo?.employeeActivationFreezeDaysBeforePayday?.toString() ?? ''}
                onChange={(event) => handleOnChange('employeeActivationFreezeDaysBeforePayday', event.target.value)}
                label='Activation freeze days before payday'
                placeholder=''
              />
              <InputField
                id='freePayoutCountOnEmployeeActivation'
                numeric={true}
                value={employerInfo?.freePayoutCountOnEmployeeActivation?.toString() ?? '0'}
                onChange={(event) => handleOnChange('freePayoutCountOnEmployeeActivation', event.target.value)}
                label='Free withdrawal count on activation'
                placeholder=''
              />
            <DatePicker
                id='launchDate'
                label='Launch date'
                includeTime={true}
                value={employerInfo?.launchDate}
                onChange={value => {
                    handleOnChange('launchDate', value)
                }}
            />
            <Switch
              id='newEmployeeMessageEnabled'
              key='newEmployeeMessageEnabled'
              checked={employerInfo?.newEmployeeMessageEnabled ?? false}
              label='Send welcome message to new employees'
              onChange={() => handleOnChange('newEmployeeMessageEnabled', !(employerInfo?.newEmployeeMessageEnabled ?? false))}
            />
            <Switch
              id='employeeActivationReminderEnabled'
              key='employeeActivationReminderEnabled'
              checked={employerInfo?.employeeActivationReminderEnabled ?? false}
              label='Send activation reminders to employees'
              onChange={() => handleOnChange('employeeActivationReminderEnabled', !(employerInfo?.employeeActivationReminderEnabled ?? false))}
            />
            <InputField
              id='employeeActivationReminderIntervalDays'
              numeric={true}
              value={employerInfo?.employeeActivationReminderIntervalDays?.toString() ?? ''}
              onChange={(event) => handleOnChange('employeeActivationReminderIntervalDays', event.target.value)}
              label='Activation reminder interval (days)'
              placeholder=''
            />
            <Switch
              id='plannedShiftsEnabled'
              key='plannedShiftsEnabled'
              checked={employerInfo?.plannedShiftsEnabled ?? false}
              label='Planned shifts enabled'
              onChange={() => handleOnChange('plannedShiftsEnabled', !(employerInfo?.plannedShiftsEnabled ?? false))}
            />
            <Switch
              id='newEmployeesReportEnabled'
              key='newEmployeesReportEnabled'
              checked={employerInfo?.newEmployeesReportEnabled ?? false}
              label='New employees report enabled'
              onChange={() => handleOnChange('newEmployeesReportEnabled', !(employerInfo?.newEmployeesReportEnabled ?? false))}
            />
            <Switch
              id='payrollFileExecutionDateOneDayBeforePayment'
              key='payrollFileExecutionDateOneDayBeforePayment'
              checked={employerInfo?.payrollFileExecutionDateOneDayBeforePayment ?? false}
              label='Payroll file execution date one day before payment'
              onChange={() => handleOnChange('payrollFileExecutionDateOneDayBeforePayment', !(employerInfo?.payrollFileExecutionDateOneDayBeforePayment ?? false))}
            />
            <Switch
              id='allowShiftsAndDeviationsOutsideCurrentEmploymentBounds'
              key='allowShiftsAndDeviationsOutsideCurrentEmploymentBounds'
              checked={employerInfo?.allowShiftsAndDeviationsOutsideCurrentEmploymentBounds ?? false}
              label='Allow shifts and deviations outside current employment bounds'
              onChange={() => handleOnChange('allowShiftsAndDeviationsOutsideCurrentEmploymentBounds', !(employerInfo?.allowShiftsAndDeviationsOutsideCurrentEmploymentBounds ?? false))}
            />
            <Switch
              id='manualPayrollFileRowCreationEnabled'
              key='manualPayrollFileRowCreationEnabled'
              checked={employerInfo?.manualPayrollFileRowCreationEnabled ?? false}
              label='Manual payroll file row creation enabled'
              onChange={() => handleOnChange('manualPayrollFileRowCreationEnabled', !(employerInfo?.manualPayrollFileRowCreationEnabled ?? false))}
            />
            <Switch
              id='employerInitiatedPayoutsEnabled'
              key='employerInitiatedPayoutsEnabled'
              checked={employerInfo?.employerInitiatedPayoutsEnabled ?? false}
              label='Employer initiated payouts enabled'
              onChange={() => handleOnChange('employerInitiatedPayoutsEnabled', !(employerInfo?.employerInitiatedPayoutsEnabled ?? false))}
            />
            <InputField
              id='employerInitiatedPayoutTaxPercent'
              numeric={true}
              value={employerInfo?.employerInitiatedPayoutTaxPercent?.toString() ?? ''}
              onChange={(event) => handleOnChange('employerInitiatedPayoutTaxPercent', event.target.value)}
              label='Employer initiated payout tax percent (0-100)'
              placeholder=''
            />
            <InputField
              id='employeeMinAgeInYears'
              numeric={true}
              value={employerInfo?.employeeMinAgeInYears?.toString() ?? ''}
              onChange={(event) => handleOnChange('employeeMinAgeInYears', event.target.value)}
              label='Employee min age'
              placeholder=''
            />
            <div style={{marginTop: 30}}>
                <S1 className={styles.sectionTitle}>Credit settings</S1>
                <InputField
                    id='creditLimit'
                    value={employerInfo?.creditLimit?.toString() ?? ''}
                    label={`Credit limit (${initialValue?.effectivePayoutSettings?.currency?.toUpperCase()})`}
                    onChange={(event) => handleOnChange('creditLimit', event.target.value)}
                    placeholder=''
                />
                <InputField
                    id='remainingCreditSpaceWarningPercent'
                    numeric={true}
                    value={employerInfo?.remainingCreditSpaceWarningPercent?.toString() ?? ''}
                    onChange={(event) => handleOnChange('remainingCreditSpaceWarningPercent', event.target.value)}
                    label='Remaining credit space warning percent'
                    placeholder=''
                />
            </div>

        </Modal>
    )
}

export default EditEmployerInfoModal

const useStyles = createUseStyles({
    container: {
        alignItems: 'center',
        textAlign: 'center',
        padding: '12px 20px',
        margin: '10px 0',
    },
    sectionTitle: {
        marginBottom: 15
    }
})
