import { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory, useParams } from "react-router-dom";
import CardSection from '../components/CardSection'
import DetailsCard from '../components/DetailsCard'
import { useAppSelector } from '../redux/hooks';
import { PaydayDataModel } from '../redux/types';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavLink from '../components/NavLink';
import { formatDateString } from '../utils/dateHelper'

const EmployeePaydayDataDetails = () => {
  const styles = useStyles()
  const params = useParams<{employerId: string, paydayId: string}>()
  const history = useHistory();

  const userDetails = useAppSelector(state => state.user.userDetails)
  const [paydayData, setPaydayData] = useState<PaydayDataModel>()

  useEffect(() => {
    if (userDetails != null) {
      const dataForEmployer = userDetails.employerPaydayData.find(x => x.employerId === params.employerId)
      if (dataForEmployer != null) {
        const paydayData = dataForEmployer.paydays.find(x => x.payday.id === params.paydayId)
        if (paydayData != null)
          setPaydayData(paydayData)
      }
    } 
  }, [])

  return (
    <div className={styles.container}>
      <h1>Payday: {formatDateString(paydayData?.payday.paymentDate ?? '')}</h1>
      <div className={styles.actionBar}>
        <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()} />
      </div>
      <CardSection>
      <DetailsCard>
          <h6>State</h6>
          <p>Locked: <span>{paydayData?.payday.locked ? 'yes' : 'no'}</span></p>
          <p>Completed: <span>{paydayData?.payday.completed ? 'yes' : 'no'}</span></p>
          <p>Frozen due to activation freeze period: <span>{paydayData?.payday.frozenByActivation ? 'yes' : 'no'}</span></p>
          <p>Frozen due to payout basis generation: <span>{paydayData?.payday.frozenByEarlyPayoutBasisGeneration ? 'yes' : 'no'}</span></p>
        </DetailsCard>
        <DetailsCard>
          <h6>Income & tax</h6>
          <p>Pay calculation type: <span>{paydayData?.payCalculationType}</span></p>
          <p>Gross total income: <span>{paydayData?.income.grossTotalAmount.amount.toLocaleString('sv-SE')} {paydayData?.income.grossTotalAmount.currencyCode.toUpperCase()}</span></p>
          <p>Net total income: <span>{paydayData?.income.netTotalAmount.amount.toLocaleString('sv-SE')} {paydayData?.income.netTotalAmount.currencyCode.toUpperCase()}</span></p>
          {paydayData?.salary.containsSalary &&
            <>
            <p>Gross salary: <span>{paydayData?.salary.currentGrossAmount?.amount.toLocaleString('sv-SE')} {paydayData?.salary.currentGrossAmount?.currencyCode.toUpperCase()}</span></p>
            <p>Net salary: <span>{paydayData?.salary.currentNetAmount?.amount.toLocaleString('sv-SE')} {paydayData?.salary.currentNetAmount?.currencyCode.toUpperCase()}</span></p>
            </>
          }
          <p>Gross shift income: <span>{paydayData?.completedShifts.aggregatedGrossWages.amount.toLocaleString('sv-SE')} {paydayData?.completedShifts.aggregatedGrossWages.currencyCode.toUpperCase()}</span></p>
          <p>Net shift income: <span>{paydayData?.completedShifts.aggregatedNetWages.amount.toLocaleString('sv-SE')} {paydayData?.completedShifts.aggregatedNetWages.currencyCode.toUpperCase()}</span></p>
          <p>Gross deviations: <span>{paydayData?.deviations.aggregatedGrossAmount.amount.toLocaleString('sv-SE')} {paydayData?.deviations.aggregatedGrossAmount.currencyCode.toUpperCase()}</span></p>
          <p>Net deviations: <span>{paydayData?.deviations.aggregatedNetAmount.amount.toLocaleString('sv-SE')} {paydayData?.deviations.aggregatedNetAmount.currencyCode.toUpperCase()}</span></p>
          <p>Tax: <span>{((paydayData?.income.grossTotalAmount.amount ?? 0) - (paydayData?.income.netTotalAmount.amount ?? 0)).toLocaleString('sv-SE')} {paydayData?.income.grossTotalAmount.currencyCode.toUpperCase()}</span></p>
        </DetailsCard>
        <DetailsCard>
          <h6>Planned shifts</h6>
          <p>Gross shift income: <span>{paydayData?.plannedShifts?.aggregatedGrossWages.amount.toLocaleString('sv-SE')} {paydayData?.plannedShifts?.aggregatedGrossWages.currencyCode.toUpperCase()}</span></p>
          <p>Net shift income: <span>{paydayData?.plannedShifts?.aggregatedNetWages.amount.toLocaleString('sv-SE')} {paydayData?.plannedShifts?.aggregatedNetWages.currencyCode.toUpperCase()}</span></p>
        </DetailsCard>
        <DetailsCard>
          <h6>Payouts & fees</h6>
          <p>Total payout amount: <span>{paydayData?.payoutHistory.aggregatedAmount.amount.toLocaleString('sv-SE')} {paydayData?.payoutHistory.aggregatedAmount.currencyCode.toUpperCase()}</span></p>
          <p>Payout amount in month before payday: <span>{paydayData?.payoutHistory.aggregatedAmountMonthBeforePayday.amount.toLocaleString('sv-SE')} {paydayData?.payoutHistory.aggregatedAmountMonthBeforePayday.currencyCode.toUpperCase()}</span></p>
          <p>Payout amount in month of payday: <span>{((paydayData?.payoutHistory.aggregatedAmount.amount ?? 0) - (paydayData?.payoutHistory.aggregatedAmountMonthBeforePayday.amount ?? 0)).toLocaleString('sv-SE')} {paydayData?.payoutHistory.aggregatedAmount.currencyCode.toUpperCase()}</span></p>
          <p>Payout count: <span>{paydayData?.payoutHistory.payoutCount}</span></p> 
          <p>Fees: <span>{paydayData?.payoutHistory.aggregatedFeeAmount.amount.toLocaleString('sv-SE')} {paydayData?.payoutHistory.aggregatedFeeAmount.currencyCode.toUpperCase()}</span></p>
          <p>Total debt: <span>{((paydayData?.payoutHistory.aggregatedAmount.amount ?? 0) + (paydayData?.payoutHistory.aggregatedFeeAmount.amount ?? 0)).toLocaleString('sv-SE')} {paydayData?.payoutHistory.aggregatedAmount.currencyCode.toUpperCase()}</span></p>
        </DetailsCard>
        <DetailsCard>
          <h6>Availability</h6>
          <p>Reserved: <span>{paydayData?.payoutAvailability.reservedAmount.amount.toLocaleString('sv-SE')} {paydayData?.payoutAvailability.reservedAmount.currencyCode.toUpperCase()}</span></p>
          <p>Available: <span>{paydayData?.payoutAvailability.availableAmount.amount.toLocaleString('sv-SE')} {paydayData?.payoutAvailability.availableAmount.currencyCode.toUpperCase()}</span></p>
          <p>Remaining payout count: <span>{paydayData?.payoutAvailability.availablePayoutCount ?? '∞'}</span></p>
        </DetailsCard>
        <DetailsCard>
          <h6>Preliminary pay slip</h6>
          <p>Gross pay: <span>{paydayData?.preliminaryPayslipData?.grossPay?.amount.toLocaleString('sv-SE')} {paydayData?.preliminaryPayslipData?.grossPay?.currencyCode.toUpperCase()}</span></p>
          <p>Gross advances: <span>{paydayData?.preliminaryPayslipData?.grossAdvances?.amount.toLocaleString('sv-SE')} {paydayData?.preliminaryPayslipData?.grossAdvances?.currencyCode.toUpperCase()}</span></p>
          <p>Tax: <span>{paydayData?.preliminaryPayslipData?.tax?.amount.toLocaleString('sv-SE')} {paydayData?.preliminaryPayslipData?.tax?.currencyCode.toUpperCase()}</span></p>
          <p>Net advances: <span>{paydayData?.preliminaryPayslipData?.netAdvances?.amount.toLocaleString('sv-SE')} {paydayData?.preliminaryPayslipData?.netAdvances?.currencyCode.toUpperCase()}</span></p>
          <p>Fees: <span>{paydayData?.preliminaryPayslipData?.fees?.amount.toLocaleString('sv-SE')} {paydayData?.preliminaryPayslipData?.fees?.currencyCode.toUpperCase()}</span></p>
          <p>Payout: <span>{paydayData?.preliminaryPayslipData?.payAfterDeductions?.amount.toLocaleString('sv-SE')} {paydayData?.preliminaryPayslipData?.payAfterDeductions?.currencyCode.toUpperCase()}</span></p>
        </DetailsCard>
      </CardSection>
    </div>
  )
}

export default EmployeePaydayDataDetails

const useStyles = createUseStyles({
  container: {
    '& h4': {
      marginTop: '15px'
    }
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
