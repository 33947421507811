import { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'

interface Props {
  children: ReactNode
}

const CardSection = ({ children }: Props) => {
  const styles = useStyles()

  return (
    <div className={styles.container}>
      {children}
    </div>
  )
}

export default CardSection

const useStyles = createUseStyles({
  container: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 10,
    flexWrap: 'wrap',
  },
})
