import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PagedListItems, PaydayFilterParams, PaydayListItemModel, PaydayModel } from '../types'

interface PaydayState {
    loading: boolean
    paydayDetails: PaydayModel | null
    paydaysOnPage: PaydayListItemModel[]
    pageIndex: number
    totalCount: number
    pageSize: number 
    filter: PaydayFilterParams | null
    error: Error | string | null
}

const initialState: PaydayState = {
    loading: false,
    paydayDetails: null,
    paydaysOnPage: [],
    totalCount: 0,
    pageIndex: 1,
    pageSize: 15,
    filter: null,
    error: null
}

export const paydaySlice = createSlice({
    name: 'payday',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        paydaysFetched: (state, action: PayloadAction<PagedListItems<PaydayListItemModel>>) => {
            state.loading = false
            state.paydaysOnPage = action.payload.items
            state.totalCount = action.payload.totalCount
            state.error = null
        },
        paydaysFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        paydayDetailsFetched: (state, action: PayloadAction<PaydayModel>) => {
            state.loading = false
            state.paydayDetails = action.payload
            state.error = null
        },
        paydayDetailsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        filterChanged: (state, action: PayloadAction<PaydayFilterParams>) => {
            state.filter = action.payload
            state.pageIndex = 1
        },
    }
})

export const { 
    loading, 
    paydaysFetched, 
    paydaysFetchFailure, 
    paydayDetailsFetched, 
    paydayDetailsFetchFailure, 
    pageIndexChanged, 
    filterChanged 
} = paydaySlice.actions

export default paydaySlice.reducer