import { PayDeviationModel } from '../redux/types';
import { getPaginationDataFromResponse } from '../utils/responseHelper'
import useFetchClient from "./useFetchClient";
import { useAppDispatch } from "../redux/hooks";
import { createUrlWithQueryString } from "../utils/url";
import { restConfig } from "../authConfig";
import { loading, payDeviationsFetched, payDeviationsFetchFailure } from '../redux/slices/payDeviationSlice';

const usePayDeviationActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()
 
  const getPayDeviations = (employeeId: string, paydayId: string | null, pageIndex: number, pageSize: number) => {
    dispatch(loading())   

    const params = {
      employeeId: employeeId,
      paydayId: paydayId,
      pageNumber: pageIndex,
      pageSize: pageSize
    }
    const url = createUrlWithQueryString(restConfig.routes.payDeviations, params)

    return fetchClient.get(url)
      .then(response => {
          const paginationData = getPaginationDataFromResponse(response.raw)
          dispatch(payDeviationsFetched({ items: response.data as PayDeviationModel[], totalCount: paginationData?.totalItemCount ?? 0}))
      })
      .catch(error => {
          console.log('getPayDeviations error:', error)
          dispatch(payDeviationsFetchFailure(error))
      })
  }

  return {
    getPayDeviations
  }
}

export default usePayDeviationActions