import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from '@azure/msal-react'
import {msalInstance} from '../services/msalService'
import {createUseStyles} from 'react-jss'
import Button from '../components/Button'
import {loginRequest} from '../authConfig'
import Dashboard from "../components/Dashboard/Dashboard";
import {titleMarginBottom} from "../constants/layout";
import PrivateRoute from '../navigation/PrivateRoute'

const Start = () => {
  const styles = useStyles()
  const {instance} = useMsal()
  const account = msalInstance.getActiveAccount()

  return (
    <div className={styles.container}>
      <AuthenticatedTemplate>
        <PrivateRoute>
          <h1>Welcome {account?.name ?? ''}</h1>
          <Dashboard/>
        </PrivateRoute>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h1>Welcome to Cappy BackOffice</h1>
        <Button title="Sign in" onClick={() => instance.loginRedirect(loginRequest)}/>
      </UnauthenticatedTemplate>
    </div>
  )
}

export default Start

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    }
  }
})
