import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    CreditorConnectionModel,
    EmployerModel,
    EmployerNameModel, CustomerPortalUser,
    PayScheduleModel
} from '../types'

interface EmployerState {
    loading: boolean
    employerNames: EmployerNameModel[]
    employerDetails: EmployerModel | null
    createdEmployerId: string | null
    error: Error | string | null
}

const initialState: EmployerState = {
    loading: false,
    employerNames:  [],
    employerDetails: null,
    createdEmployerId: null,
    error: null
}

export const employerSlice = createSlice({
    name: 'employer',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        employerDetailsFetched: (state, action: PayloadAction<EmployerModel>) => {
            state.loading = false
            state.employerDetails = action.payload
            state.error = null
        },
        employerDetailsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employerNamesFetched: (state, action: PayloadAction<EmployerNameModel[]>) => {
            state.loading = false
            state.employerNames = action.payload
            state.error = null
        },
        employerNamesFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employerCreated: (state, action: PayloadAction<string>) => {
            state.loading = false
            state.createdEmployerId = action.payload
            state.error = null
        },
        employerCreationHandled: state => {
            state.createdEmployerId = null
        },
        employerCreateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employerInfoUpdated: (state, action: PayloadAction<EmployerModel>) => {
            state.loading = false
            state.employerDetails = action.payload
            state.error = null
        },
        employerInfoUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employerPayoutSettingsUpdated: (state, action: PayloadAction<EmployerModel>) => {
            state.loading = false
            state.employerDetails = action.payload
            state.error = null
        },
        employerPayoutSettingsUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employerCreditSpaceWarningSilenced: (state, action: PayloadAction<EmployerModel>) => {
            state.loading = false
            state.employerDetails = action.payload
            state.error = null
        },
        employerCreditSpaceWarningSilenceFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employerLockStateSet: (state, action: PayloadAction<boolean>) => {
            state.loading = false
            state.employerDetails = {...state.employerDetails as EmployerModel, state: action.payload ? 'locked' : 'active' }
            state.error = null
        },
        employerLockStateSetFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        
        bankAccountSet: (state, action: PayloadAction<{bankAccountId: string, bankAccountNumber: string}>) => {
            state.loading = false
            state.employerDetails = {
                ...state.employerDetails as EmployerModel,
                bankAccount: {id: action.payload.bankAccountId, bankAccountNumber: action.payload.bankAccountNumber}
              }
            state.error = null
        },
        bankAccountSetFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employerCreditorAdded: (state, action: PayloadAction<CreditorConnectionModel>) => {
            state.loading = false
            state.employerDetails = {
                ...state.employerDetails as EmployerModel,
                creditors: [action.payload, ...state.employerDetails?.creditors ?? []]
              }
            state.error = null
        },
        employerCreditorAddFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employerCreditorDeleted: (state, action: PayloadAction<string>) => {
            var index = state.employerDetails?.creditors.findIndex((item) => {
                return item.id === action.payload
            })
            const creditors = [...state.employerDetails?.creditors ?? []]
            creditors.splice(index ?? 0, 1)

            state.loading = false
            state.employerDetails = {
                ...state.employerDetails as EmployerModel,
                creditors: creditors
              }
            state.error = null
        },
        employerCreditorDeleteFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employerLogoUploaded: (state, action: PayloadAction<EmployerModel>) => {
            state.loading = false
            state.employerDetails = action.payload
            state.error = null
        },
        employerLogoUploadFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employerLogoDeleted: (state, action: PayloadAction<EmployerModel>) => {
            state.loading = false
            state.employerDetails = action.payload
            state.error = null
        },
        employerLogoDeleteFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
    },
    extraReducers: {
        'paySchedule/payScheduleCreated': (state, action: PayloadAction<PayScheduleModel>) => {
            state.employerDetails = null
        },
        'paySchedule/payScheduleUpdated': (state, action: PayloadAction<PayScheduleModel>) => {
            state.employerDetails = null
        }
    }
})

export const {
    loading,
    employerDetailsFetched,
    employerDetailsFetchFailure,
    employerNamesFetched,
    employerNamesFetchFailure,
    employerCreated,
    employerCreationHandled,
    employerCreateFailure,
    employerInfoUpdated,
    employerInfoUpdateFailure,
    employerPayoutSettingsUpdated,
    employerPayoutSettingsUpdateFailure,
    employerCreditSpaceWarningSilenced,
    employerCreditSpaceWarningSilenceFailure,
    employerLockStateSet,
    employerLockStateSetFailure,
    bankAccountSet,
    bankAccountSetFailure,
    employerCreditorAdded,
    employerCreditorAddFailure,
    employerCreditorDeleted,
    employerCreditorDeleteFailure,
    employerLogoUploaded,
    employerLogoUploadFailure,
    employerLogoDeleted,
    employerLogoDeleteFailure
} = employerSlice.actions
export default employerSlice.reducer
