import { useState } from "react"
import { PayRunFilterParams } from "../../redux/types"
import CardSection from "../CardSection"
import DetailsCard from "../DetailsCard"
import { SearchResultItem } from "../SearchAndSelectField"
import Switch from "../Switch"
import UserSelector from "../UserSelector"

interface Props {
    filterValue: PayRunFilterParams
    onFilterChange: (value: PayRunFilterParams) => void
}

const PayRunListFilter = ({filterValue, onFilterChange}: Props) => {
    const [includeCompleted, setIncludeCompleted] = useState(filterValue.includeCompletedPayRuns)

    const handleIncludeCompletedChange = () => {
        var newValue = !filterValue.includeCompletedPayRuns
        setIncludeCompleted(newValue)
        onFilterChange({...filterValue, includeCompletedPayRuns: newValue})
    }
    
    const handleUserSelected = (selectedUser: SearchResultItem | null) => {
        onFilterChange({...filterValue, userId: selectedUser?.value || null, userName: selectedUser?.name ?? null})
    }

    return (
        <CardSection>
            <DetailsCard>
                <UserSelector 
                    onUserSelected={handleUserSelected} 
                    value={filterValue.userName || ''}
                />
                <Switch 
                    id='includeCompleted'
                    key='includeCompleted'
                    checked={includeCompleted} 
                    label='Include completed pay runs' 
                    onChange={() => handleIncludeCompletedChange()} 
                /> 
            </DetailsCard>
        </CardSection>
    )
}

export default PayRunListFilter