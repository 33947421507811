import {faArrowLeft, faTrash} from "@fortawesome/free-solid-svg-icons"
import {useEffect, useState} from "react"
import {createUseStyles} from "react-jss"
import {useHistory, useParams} from "react-router-dom"
import CardSection from "../components/CardSection"
import DetailsCard from "../components/DetailsCard"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import NavLink from "../components/NavLink"
import {titleMarginBottom} from "../constants/layout"
import useProtectRuleActions from "../hooks/useProtectRuleActions"
import {useAppSelector} from "../redux/hooks"
import {
  ProtectAveragePayoutAmountResult,
  CreateOrUpdateProtectRuleModel,
  ProtectMaxSalaryResult,
  ProtectPayoutVolumeRateResult,
  ProtectAbnormalEarningsResult,
  ProtectPayoutSizeResult,
  ProtectRuleType,
  ProtectZScoreResult,
  ProtectMaxInvoicePayoutAmountResult,
  ProtectInvoicePayoutRateResult,
  ProtectInvoicePayoutVolumeRateResult,
  ProtectInvoicePayoutRateForRecipientResult,
  ProtectInvoicePayoutVolumeRateForRecipientResult
} from "../redux/types"
import EditProtectRuleModal from "../components/Protect/EditProtectRuleModal"
import ZScoreChart from "../components/Protect/ZScoreChart"
import Button from "../components/Button"
import ConfirmModal from "../components/ConfirmModal"
import {formatDateString} from "../utils/dateHelper"

const ProtectRuleDetails = () => {
  const styles = useStyles()
  const params = useParams<{ id: string }>()
  const history = useHistory();
  const {getRule, updateRule, deleteRule} = useProtectRuleActions()

  const loading = useAppSelector(state => state.protectRules.loading)
  const ruleDetails = useAppSelector(state => state.protectRules.ruleDetails)

  const [confirmDeleteRuleModalVisible, setConfirmDeleteRuleModalVisible] = useState(false)
  const [editRuleModalVisible, setEditRuleModalVisible] = useState(false)

  useEffect(() => {
    if (ruleDetails == null || ruleDetails.id !== params.id) {
      getRule(params.id)
    }
  }, [])

  const handleUpdateRule = (updateModel: CreateOrUpdateProtectRuleModel) => {
    setEditRuleModalVisible(false)
    updateRule(updateModel)
  }

  const handleDeleteRule = () => {
    if (ruleDetails) {
      deleteRule(ruleDetails?.id)
    }
    setConfirmDeleteRuleModalVisible(false)
  }

  const renderZScoreRuleContent = () => {
    if (ruleDetails?.type === ProtectRuleType.PayoutVolumeZScoreRule ||
      ruleDetails?.type === ProtectRuleType.ActivationRateZScoreRule ||
      ruleDetails?.type === ProtectRuleType.InvoicePayoutVolumeZScoreRule) {
      return (
        <DetailsCard>
          <ZScoreChart
            ruleData={JSON.parse(ruleDetails.rawResult) as ProtectZScoreResult}
            triggerValue={ruleDetails.triggerValue || 0}
          />
        </DetailsCard>
      )
    }
  }

  const renderOverdrawsRuleContent = () => {
    if (ruleDetails?.type !== ProtectRuleType.OverdrawsRule) {
      return
    }

    const data = JSON.parse(ruleDetails.rawResult) as ProtectPayoutSizeResult;
    if (!data) {
      return
    }

    return (
      data.repaymentDateResults?.map((item, index) => {
        return (
          <DetailsCard key={`date_${index}`}>
            <h6>{formatDateString(item.repaymentDate)}</h6>
            <p>{item.description}</p>
            {item.overdraws.length > 0 &&
                <ul>
                  {item.overdraws.map((overdraw, index) => {
                    return (
                      <li><p key={`overdraw_${index}`}><span>{overdraw.amount}</span> by user with
                        id <span>{overdraw.userId}</span></p></li>
                    )
                  })
                  }
                </ul>
            }
          </DetailsCard>
        )
      })
    )
  }

  const renderAbnormalEarningsRuleContent = () => {
    if (ruleDetails?.type !== ProtectRuleType.AbnormalEarningsRule) {
      return
    }

    const data = JSON.parse(ruleDetails.rawResult) as ProtectAbnormalEarningsResult;
    if (!data) {
      return
    }

    return (
      <DetailsCard>
        <h6>Abnormal earnings</h6>
        {data.abnormalEarnings.length > 0 &&
            <ul>
              {data.abnormalEarnings.map((result, index) => {
                return (
                  <li><p key={`abnormalEarnings_${index}`}><span>{result.amount.amount}</span> by employee with
                    id <span>{result.employeeId}</span></p></li>
                )
              })
              }
            </ul>
        }
      </DetailsCard>
    )
  }

  const renderPayoutVolumeRateRuleContent = () => {
    if (ruleDetails?.type !== ProtectRuleType.PayoutVolumeRateRule) {
      return
    }

    const data = JSON.parse(ruleDetails.rawResult) as ProtectPayoutVolumeRateResult;
    if (!data) {
      return
    }

    return (
      <DetailsCard>
        <h6>Current Payout Volume</h6>
        <p>Current volume: <span>{data.currentVolume}</span></p>
      </DetailsCard>
    )
  }

  const renderAveragePayoutAmountRuleContent = () => {
    if (ruleDetails?.type !== ProtectRuleType.AveragePayoutAmountRule) {
      return
    }

    const data = JSON.parse(ruleDetails.rawResult) as ProtectAveragePayoutAmountResult;
    if (!data) {
      return
    }

    return (
      <DetailsCard>
        <h6>Current Average Amount</h6>
        <p>Current average amount: <span>{data.currentAverageAmount}</span></p>
      </DetailsCard>
    )
  }

  const renderMaxSalaryRuleContent = () => {
    if (ruleDetails?.type !== ProtectRuleType.MaxSalaryRule) {
      return
    }

    const data = JSON.parse(ruleDetails.rawResult) as ProtectMaxSalaryResult;
    if (!data) {
      return
    }

    return (
      <DetailsCard>
        <h6>Salaries over TriggerValue</h6>
        {data.salaries.length > 0 &&
            <ul>
              {data.salaries.map((result, index) => {
                return (
                  <li><p key={`salaries_${index}`}>Employee with id <span>{result.employeeId}</span> has a gross salary
                    of <span>{result.grossSalary.amount}</span></p></li>
                )
              })
              }
            </ul>
        }
      </DetailsCard>
    )
  }

  const renderMaxInvoicePayoutAmountRuleContent = () => {
    if (ruleDetails?.type !== ProtectRuleType.MaxInvoicePayoutAmountRule) {
      return
    }

    const data = JSON.parse(ruleDetails.rawResult) as ProtectMaxInvoicePayoutAmountResult;
    if (!data) {
      return
    }

    return (
      <DetailsCard>
        <h6>Invoice payouts over TriggerValue</h6>
        {data.invoicePayouts.length > 0 &&
            <ul>
              {data.invoicePayouts.map((result, index) => {
                return (
                  <li><p key={`invoicePayout_${index}`}>Invoice payout with id <span>{result.invoicePayoutId}</span> has
                    an amount
                    of <span>{result.amount.amount}</span></p></li>
                )
              })
              }
            </ul>
        }
      </DetailsCard>
    )
  }

  const renderInvoicePayoutRateRuleContent = () => {
    if (ruleDetails?.type !== ProtectRuleType.InvoicePayoutRateRule) {
      return
    }

    const data = JSON.parse(ruleDetails.rawResult) as ProtectInvoicePayoutRateResult;
    if (!data) {
      return
    }

    return (
      <DetailsCard>
        <h6>Current count: {data.currentCount}</h6>
      </DetailsCard>
    )
  }

  const renderInvoicePayoutVolumeRateRuleContent = () => {
    if (ruleDetails?.type !== ProtectRuleType.InvoicePayoutVolumeRateRule) {
      return
    }

    const data = JSON.parse(ruleDetails.rawResult) as ProtectInvoicePayoutVolumeRateResult;
    if (!data) {
      return
    }

    return (
      <DetailsCard>
        <h6>Current volume: {data.currentVolume}</h6>
      </DetailsCard>
    )
  }

  const renderInvoicePayoutRateForRecipientRuleContent = () => {
    if (ruleDetails?.type !== ProtectRuleType.InvoicePayoutRateForRecipientRule) {
      return
    }

    const data = JSON.parse(ruleDetails.rawResult) as ProtectInvoicePayoutRateForRecipientResult;
    if (!data) {
      return
    }

    return (
      <DetailsCard>
        <h6>Recipient accounts with count over TriggerValue</h6>
        {data.stats.length > 0 &&
            <ul>
              {data.stats.map((result, index) => {
                return (
                  <li>
                    <p key={`recipient_${index}`}>
                      Recipient with account
                      number <span>{result.recipientAccount}</span> has <span>{result.count}</span> invoice payouts
                    </p>
                  </li>
                )
              })
              }
            </ul>
        }
      </DetailsCard>
    )
  }

  const renderInvoicePayoutVolumeRateForRecipientRuleContent = () => {
    if (ruleDetails?.type !== ProtectRuleType.InvoicePayoutVolumeRateForRecipientRule) {
      return
    }

    const data = JSON.parse(ruleDetails.rawResult) as ProtectInvoicePayoutVolumeRateForRecipientResult;
    if (!data) {
      return
    }

    return (
      <DetailsCard>
        <h6>Recipient accounts with volume over TriggerValue</h6>
        {data.stats.length > 0 &&
            <ul>
              {data.stats.map((result, index) => {
                return (
                  <li>
                    <p key={`recipient_${index}`}>
                      Recipient with account
                      number <span>{result.recipientAccount}</span> has a total invoice payout amount
                      of <span>{result.volume}</span>
                    </p>
                  </li>
                )
              })
              }
            </ul>
        }
      </DetailsCard>
    )
  }

  return (
    <div className={styles.container}>
      <h1
        className={ruleDetails?.deleted ? styles.deleted : ''}>{ruleDetails && `${ruleDetails.customerName} - ${ruleDetails.name}`}</h1>
      <div className={styles.actionBar}>
        <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()}/>
        <Button title='Delete rule' icon={faTrash} onClick={() => setConfirmDeleteRuleModalVisible(true)}/>
      </div>
      <CardSection>
        <DetailsCard onClick={() => setEditRuleModalVisible(true)}>
          <h6>Details</h6>
          <p>Type: <span>{ruleDetails?.type}</span></p>
          <p>Triggered: <span>{ruleDetails?.triggered ? 'yes' : 'no'}</span></p>
          <p>Hysteresis: <span>{ruleDetails?.hysteresis}</span></p>
          <p>Trigger value: <span>{ruleDetails?.triggerValue}</span></p>
          {ruleDetails?.minSampleThreshold != null &&
              <p>Min sample threshold: <span>{ruleDetails.minSampleThreshold}</span></p>
          }
          {ruleDetails?.currentWindowSize &&
              <p>Current window size: <span>{ruleDetails.currentWindowSize}</span></p>
          }
          {ruleDetails?.compareWindowSize &&
              <p>Compare window size: <span>{ruleDetails.compareWindowSize}</span></p>
          }
          {!!ruleDetails?.skipDays &&
              <p>Skip days: <span>{ruleDetails.skipDays}</span></p>
          }
          {ruleDetails?.withdrawalLimit &&
              <p>Withdrawal limit: <span>{ruleDetails.withdrawalLimit}</span></p>
          }
          <p>Deleted: <span>{ruleDetails?.deleted ? 'yes' : 'no'}</span></p>
        </DetailsCard>
      </CardSection>
      <CardSection>
        {renderZScoreRuleContent()}
        {renderOverdrawsRuleContent()}
        {renderAbnormalEarningsRuleContent()}
        {renderPayoutVolumeRateRuleContent()}
        {renderAveragePayoutAmountRuleContent()}
        {renderMaxSalaryRuleContent()}
        {renderMaxInvoicePayoutAmountRuleContent()}
        {renderInvoicePayoutRateRuleContent()}
        {renderInvoicePayoutVolumeRateRuleContent()}
        {renderInvoicePayoutRateForRecipientRuleContent()}
        {renderInvoicePayoutVolumeRateForRecipientRuleContent()}

      </CardSection>
      <FullScreenLoadingIndicator visible={loading}/>
      <EditProtectRuleModal
        employerId={ruleDetails?.customerId ?? ''}
        visible={editRuleModalVisible}
        initialValue={ruleDetails ?? undefined}
        onCancelClick={() => setEditRuleModalVisible(false)}
        onSaveClick={handleUpdateRule}
      />
      <ConfirmModal
        visible={confirmDeleteRuleModalVisible}
        confirmText={'Are you sure you want to delete this rule?'}
        onConfirmClick={handleDeleteRule}
        onCancelClick={() => setConfirmDeleteRuleModalVisible(false)}
      />
    </div>
  )
}

export default ProtectRuleDetails

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    }
  },
  deleted: {
    textDecoration: 'line-through'
  },
  actionBar: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  investigationContainer: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
    alignItems: 'right'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    columnGap: 10
  }
})
