import {restConfig} from "../authConfig";
import {useAppDispatch} from "../redux/hooks";
import {
  loading,
  campaignFetched,
  campaignFetchFailure,
  campaignsFetched,
  campaignsFetchFailure,
  campaignUpdated,
  campaignUpdateFailure,
  campaignCreated,
  campaignCreateFailure
} from "../redux/slices/campaignSlice";
import {
  Campaign, CampaignTranslation,
  CreateOrUpdateCampaignModel
} from '../redux/types';

import useFetchClient from "./useFetchClient";

const useCampaignActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getCampaigns = () => {
    dispatch(loading())

    return fetchClient.get(restConfig.routes.campaigns)
      .then(response => {
        dispatch(campaignsFetched(response.data as Campaign[]))
      })
      .catch(error => {
        console.log('getCampaigns error:', error)
        dispatch(campaignsFetchFailure(error))
      })
  }

  const getCampaign = async (id: string) => {
    dispatch(loading())

    const url = restConfig.routes.campaigns + `/${id}`

    return fetchClient.get(url)
      .then(response => {
        dispatch(campaignFetched(response.data as Campaign))
      })
      .catch(error => {
        console.log('getCampaign error:', error)
        dispatch(campaignFetchFailure(error))
      })
  }

  const createCampaign = async (model: CreateOrUpdateCampaignModel) => {
    dispatch(loading())

    return fetchClient.post(restConfig.routes.campaigns, model)
      .then(response => {
        dispatch(campaignCreated(response.data as Campaign))
      })
      .catch(error => {
        console.log('createCampaign error:', error)
        dispatch(campaignCreateFailure(error))
      })
  }

  const updateCampaign = async (id: string, model: CreateOrUpdateCampaignModel) => {
    dispatch(loading())

    const url = restConfig.routes.campaigns + `/${id}`

    return fetchClient.post(url, model)
      .then(response => {
        dispatch(campaignUpdated(response.data as Campaign))
      })
      .catch(error => {
        console.log('updateCampaign error:', error)
        dispatch(campaignUpdateFailure(error))
      })
  }

  const setCampaignTranslation = async (id: string, message: CampaignTranslation) => {
    dispatch(loading())

    const url = restConfig.routes.campaigns + `/${id}/translation`

    return fetchClient.post(url, message)
      .then(response => {
        dispatch(campaignUpdated(response.data as Campaign))
      })
      .catch(error => {
        console.log('setCampaignTranslation error:', error)
        dispatch(campaignUpdateFailure(error))
      })
  }

  const launchCampaign = async (id: string) => {
    dispatch(loading())

    const url = restConfig.routes.campaigns + `/${id}/launch`

    return fetchClient.post(url)
      .then(response => {
        dispatch(campaignUpdated(response.data as Campaign))
      })
      .catch(error => {
        console.log('launchCampaign error:', error)
        dispatch(campaignUpdateFailure(error))
      })
  }

  const abortCampaign = async (id: string) => {
    dispatch(loading())

    const url = restConfig.routes.campaigns + `/${id}/abort`

    return fetchClient.post(url)
      .then(response => {
        dispatch(campaignUpdated(response.data as Campaign))
      })
      .catch(error => {
        console.log('abortCampaign error:', error)
        dispatch(campaignUpdateFailure(error))
      })
  }


  return {
    getCampaign,
    getCampaigns,
    createCampaign,
    updateCampaign,
    setCampaignTranslation,
    launchCampaign,
    abortCampaign
  }
}

export default useCampaignActions