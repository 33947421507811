import React, {ReactNode} from 'react'
import {createUseStyles} from 'react-jss'
import {Colors} from '../../constants/colors'
import Card from '../Card'
import MetricChart from "./MetricChart";
import {MonetaryTimeSeries, NumericTimeSeries} from "../../redux/types";

interface Props {
  title: string
  subTitle?: string
  children: ReactNode
  standalone?: boolean
  chartMetric?: NumericTimeSeries | MonetaryTimeSeries
}

const DashboardMetricCard = ({title, subTitle, children, standalone, chartMetric}: Props) => {
  const styles = useStyles()

  return (
    <Card className={`${styles.container} ${standalone && styles.standalone} ${chartMetric && styles.withShadow}`}>
      {chartMetric &&
          <div style={{position: 'absolute', height: '100%', width: '100%'}}>
              <MetricChart label={title} metric={chartMetric}/>
          </div>
      }
      <div className={styles.cardContent}>
        <div className={standalone ? styles.standaloneTitle : styles.title}>{title}</div>
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
        <div className={styles.body}>
          {children}
        </div>
      </div>
    </Card>
  )
}

export default DashboardMetricCard

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#4B4B4B',
    flex: 1,
    display: 'flex',
    minWidth: 240,
    maxWidth: 350
  },
  standalone: {
    backgroundColor: Colors.darkGrayCard,
    borderColor: Colors.darkGrayBorder,
  },
  withShadow: {
    textShadow: '0px 0px 4px rgba(0, 0, 0, 1)'
  },
  cardContent: {
    pointerEvents: 'none',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
    padding: 25
  },
  title: {
    fontSize: 13,
    textTransform: 'uppercase'
  },
  standaloneTitle: {
    color: Colors.euro_600,
    fontSize: 15,
    textTransform: 'uppercase'
  },
  subTitle: {
    fontSize: 12,
    color: Colors.gray3
  },
  body: {
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
  }
})
