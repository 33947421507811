import { restConfig } from "../authConfig"
import { AmlFlaggedCompanyListItemModel, CompanyAmlDetailsModel } from "../redux/companyMonitorTypes"
import { useAppDispatch } from "../redux/hooks"
import { 
    amlFlaggedCompanyAccepted, 
    amlFlaggedCompanyAcceptFailure, 
    amlFlaggedCompanyDetailsFetched,
    amlFlaggedCompanyDetailsFetchFailure, 
    amlFlaggedCompaniesFetched, 
    amlFlaggedCompanyFetchFailure, 
    loading } from "../redux/slices/amlFlaggedCompanySlice"
import useFetchClient from "./useFetchClient"

const useCompanyAmlActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getAmlFlaggedCompanies = () => {
        dispatch(loading())   

        const url = restConfig.routes.aml + '/flaggedcompanies'

        return fetchClient.get(url)
            .then(response => {
                dispatch(amlFlaggedCompaniesFetched(response.data as AmlFlaggedCompanyListItemModel[]))
            })
            .catch(error => {
                console.log('getAmlFlaggedCompanies error:', error)
                dispatch(amlFlaggedCompanyFetchFailure(error))
            })
    }

    const getAmlFlaggedCompanyDetails = (id: string) => {
        dispatch(loading())   

        const url = restConfig.routes.aml + `/companies/${id}`

        return fetchClient.get(url)
            .then(response => {
                dispatch(amlFlaggedCompanyDetailsFetched(response.data as CompanyAmlDetailsModel))
            })
            .catch(error => {
                console.log('getAmlFlaggedCompanyDetails error:', error)
                dispatch(amlFlaggedCompanyDetailsFetchFailure(error))
            })
    }

    const acceptAmlFlaggedCompany = (id: string, comment: string) => {
        dispatch(loading())   

        const url = restConfig.routes.aml + `/companies/${id}/accept`

        return fetchClient.post(url, { comment: comment })
            .then(response => {
                dispatch(amlFlaggedCompanyAccepted(response.data as CompanyAmlDetailsModel))
            })
            .catch(error => {
                console.log('acceptAmlFlaggedCompany error:', error)
                dispatch(amlFlaggedCompanyAcceptFailure(error))
            })
    }

    return {
        getAmlFlaggedCompanies,
        getAmlFlaggedCompanyDetails,
        acceptAmlFlaggedCompany
    }
}

export default useCompanyAmlActions