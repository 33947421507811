import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from '../Modal'
import InputField from '../InputField'
import Select from '../Select'
import { CreateNewCustomerModel, Language } from '../../redux/types'
import useMarketActions from '../../hooks/useMarketActions'
import { useAppSelector } from '../../redux/hooks'
import { Colors } from '../../constants/colors'
import useCustomerActions from '../../hooks/useCustomerActions'

interface Props {
    onAddClick?: (employer: CreateNewCustomerModel) => void
    onCancelClick?: () => void
    visible: boolean
}

const AddCustomerModal = ({ onAddClick, onCancelClick, visible }: Props) => {
    const styles = useStyles()
    const [customer, setCustomer] = useState<CreateNewCustomerModel>({companyRegistrationNumber: '', name: '', legalName: '', marketId: '', language: Language.sv})
    const [isCompanyRegistrationNumberUnique, setIsCompanyRegistrationNumberUnique] = useState<boolean>(true)
    const [isCompanyRegistrationNumberValid, setIsCompanyRegistrationNumberValid] = useState<boolean>(true)
    const {companyRegistrationNumberExists} = useCustomerActions()

    const { getMarkets } = useMarketActions();
    const markets = useAppSelector(state => state.market.markets)

    useEffect(() => {
        if (visible && markets.length === 0)
            getMarkets()
    }, [visible, markets])

    useEffect(() => {
        if (markets && markets.length > 0 && !customer.marketId)
            setCustomer({...customer, marketId: markets[0].id})
      }, [markets])

    const validateBeforeAdd = () => {
        if (customer.companyRegistrationNumber.length !== 11 || (customer.companyRegistrationNumber.length > 6 && customer.companyRegistrationNumber[6] !== '-')) {
            setIsCompanyRegistrationNumberValid(false)
            return
        } else {
            setIsCompanyRegistrationNumberValid(true)    
        }

        companyRegistrationNumberExists(customer.companyRegistrationNumber, customer.marketId, (exists: boolean) => {
            if (exists) {
                setIsCompanyRegistrationNumberUnique(false)
            } else {
                setIsCompanyRegistrationNumberUnique(true) 
                handleOnAdd()
            }
        })
    }

    const handleOnAdd = () => {
        if (customer)
            onAddClick && onAddClick(customer)       
    }

    const handleOnClose = () => {
        setCustomer({companyRegistrationNumber: '', name: '', legalName: '', marketId: '', language: Language.sv})
        onCancelClick && onCancelClick()
    }

    const handleOnChange = (propName: string, value: any) => {
        setCustomer({...customer, [propName]: value})
    }

    const getLanguageOptions = () => {
        return Object.keys(Language).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    const getMarketOptions = () => {
        return markets?.map((market) => { return { name: market.country.toString(), value: market.id } });
    }

    return (
        <Modal
            title='Create new customer'
            show={visible}
            okButtonTitle='Save'
            cancelButtonTitle='Cancel'
            onHide={() => handleOnClose()}
            onOkClick={() => validateBeforeAdd()}
            onCancelClick={() => handleOnClose()}
        >
            <InputField
                id='companyRegistrationNumber'
                valid={isCompanyRegistrationNumberUnique}
                value={customer.companyRegistrationNumber}
                onChange={(event) => handleOnChange('companyRegistrationNumber', event.target.value)}
                label='Company registration number'
                placeholder='Ex. 556677-8899'
              />
            <InputField
                id='legalName'
                value={customer.legalName}
                onChange={(event) => handleOnChange('legalName', event.target.value)}
                label='Legal name'
                placeholder=''
              />
            <InputField
                id='name'
                value={customer.name}
                onChange={(event) => handleOnChange('name', event.target.value)}
                label='Display name'
                placeholder=''
              />
            <Select 
                id='market'
                key='market'
                label='Market'
                options={getMarketOptions()} 
                selectedValue={customer.marketId}
                onSelect={(value) => handleOnChange('marketId', value)}
            />
            <Select 
                id='language'
                key='language'
                label='Language'
                options={getLanguageOptions()} 
                selectedValue={customer.language.toString()}
                onSelect={(value) => handleOnChange('language', Language[value as keyof typeof Language])}
            />
            {!isCompanyRegistrationNumberUnique && 
            <div className={styles.errorMessage}>
                An customer with the same company registration number already exists
            </div>
            }
            {!isCompanyRegistrationNumberValid && 
            <div className={styles.errorMessage}>
                Incorrect company registration number format
            </div>
            }
        </Modal>
    )
}

export default AddCustomerModal

const useStyles = createUseStyles({
    container: {
        alignItems: 'center',
        textAlign: 'center',
        padding: '12px 20px',
        margin: '10px 0',
    },
    errorMessage: {
        marginTop: 20,
        color: Colors.rubel_700
    }
})