import {
  CreateNewEmployerModel,
  EmployerModel,
  PayoutSettings,
  UpdateEmployerModel,
  CreditorConnectionModel,
  EmployerNameModel, UpdateCustomerPortalUserModel, CustomerPortalUser,
} from '../redux/types';
import useFetchClient from "./useFetchClient";
import { useAppDispatch } from "../redux/hooks";
import { restConfig } from "../authConfig";
import {
  bankAccountSet,
  bankAccountSetFailure,
  employerCreated,
  employerCreateFailure,
  employerCreditorAdded,
  employerCreditorAddFailure,
  employerCreditorDeleted,
  employerCreditorDeleteFailure,
  employerDetailsFetched,
  employerDetailsFetchFailure,
  employerInfoUpdated,
  employerInfoUpdateFailure,
  employerLockStateSet,
  employerLockStateSetFailure,
  employerPayoutSettingsUpdated,
  employerPayoutSettingsUpdateFailure,
  loading,
  employerLogoUploaded,
  employerLogoUploadFailure,
  employerLogoDeleted,
  employerLogoDeleteFailure,
  employerNamesFetched,
  employerNamesFetchFailure,
  employerCreditSpaceWarningSilenced,
  employerCreditSpaceWarningSilenceFailure
} from "../redux/slices/employerSlice";

const useEmployerActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getEmployerDetails = (employerId: string) => {
    dispatch(loading())
    const url = restConfig.routes.employers + `/${employerId}`

    return fetchClient.get(url)
      .then(response => {
          dispatch(employerDetailsFetched(response.data as EmployerModel))
      })
      .catch(error => {
          console.log('getEmployer error:', error)
          dispatch(employerDetailsFetchFailure(error))
      })
  }

  const getEmployerNames = () => {
    dispatch(loading())
    const url = restConfig.routes.employers + '/names'

    return fetchClient.get(url)
      .then(response => {
          dispatch(employerNamesFetched(response.data as EmployerNameModel[]))
      })
      .catch(error => {
          console.log('getEmployerNames error:', error)
          dispatch(employerNamesFetchFailure(error))
      })
  }

  const createEmployer = (employer: CreateNewEmployerModel) => {
    dispatch(loading())
    return fetchClient.post(restConfig.routes.employers, employer)
      .then(response => {
          dispatch(employerCreated(response.data as string))
      })
      .catch(error => {
          console.log('createEmployer error:', error)
          dispatch(employerCreateFailure(error))
      })
  }

  const updateEmployerInfo = (employerId: string, updateModel: UpdateEmployerModel) => {
    dispatch(loading())
    const url = restConfig.routes.employers + '/' + employerId

    return fetchClient.post(url, updateModel)
      .then(response => {
          dispatch(employerInfoUpdated(response.data as EmployerModel))
      })
      .catch(error => {
          console.log('updateEmployerInfo error:', error)
          dispatch(employerInfoUpdateFailure(error))
      })
  }

  const silenceCreditSpaceWarning = (employerId: string, days: number) => {
    dispatch(loading())
    const url = restConfig.routes.employers + `/${employerId}/creditspacewarning`

    return fetchClient.post(url, { days })
      .then(response => {
          dispatch(employerCreditSpaceWarningSilenced(response.data as EmployerModel))
      })
      .catch(error => {
          console.log('silenceCreditSpaceWarning error:', error)
          dispatch(employerCreditSpaceWarningSilenceFailure(error))
      })
  }

  const updatePayoutSettings = (employerId: string, payoutSettings: PayoutSettings) => {
    dispatch(loading())
    const url = restConfig.routes.employers + `/${employerId}/payoutsettings`

    return fetchClient.post(url, payoutSettings)
      .then(response => {
          dispatch(employerPayoutSettingsUpdated(response.data as EmployerModel))
      })
      .catch(error => {
          console.log('updatePayoutSettings error:', error)
          dispatch(employerPayoutSettingsUpdateFailure(error))
      })
  }

  const setBankAccount = (employerId: string, bankAccountId: string, bankAccountNumber: string) => {
    dispatch(loading())
    const url = restConfig.routes.employers + `/${employerId}/bankaccount`

    return fetchClient.post(url, { bankAccountId: bankAccountId })
      .then(response => {
          dispatch(bankAccountSet({bankAccountId: bankAccountId, bankAccountNumber: bankAccountNumber}))
      })
      .catch(error => {
          console.log('setBankAccount error:', error)
          dispatch(bankAccountSetFailure(error))
      })
  }

  const setLockState = (employerId: string, locked: boolean) => {
    dispatch(loading())
    const url = restConfig.routes.employers + `/${employerId}/${locked ? 'lock' : 'unlock'}`

    return fetchClient.post(url)
      .then(response => {
          dispatch(employerLockStateSet(locked))
      })
      .catch(error => {
          console.log('setLockState error:', error)
          dispatch(employerLockStateSetFailure(error))
      })
  }

  const addEmployerCreditor = (employerId: string, creditor: CreditorConnectionModel) => {
    dispatch(loading())
    const url = restConfig.routes.employers + `/${employerId}/creditors`

    return fetchClient.post(url, { creditorId: creditor.id, priority: creditor.priority})
      .then(response => {
          dispatch(employerCreditorAdded(creditor))
      })
      .catch(error => {
          console.log('addEmployerCreditor error:', error)
          dispatch(employerCreditorAddFailure(error))
      })
  }

  const deleteEmployerCreditor = (employerId: string, creditorId: string) => {
    dispatch(loading())
    const url = restConfig.routes.employers + `/${employerId}/creditors/${creditorId}`

    return fetchClient.delete(url)
      .then(response => {
          dispatch(employerCreditorDeleted(creditorId))
      })
      .catch(error => {
          console.log('deleteEmployerCreditor error:', error)
          dispatch(employerCreditorDeleteFailure(error))
      })
  }

  const uploadEmployerLogo = (employerId: string, theme: string, file: File) => {
    dispatch(loading())
    const url = restConfig.routes.employers + `/${employerId}/logos/${theme}`

    return fetchClient.postFile(url, file)
      .then(response => {
        dispatch(employerLogoUploaded(response.data as EmployerModel))
      })
      .catch(error => {
        console.log('uploadEmployerLogo error:', error)
        dispatch(employerLogoUploadFailure(error))
      })
  }

  const deleteEmployerLogo = (employerId: string, theme: string) => {
    dispatch(loading())
    const url = restConfig.routes.employers + `/${employerId}/logos/${theme}`

    return fetchClient.delete(url)
      .then(response => {
        dispatch(employerLogoDeleted(response.data as EmployerModel))
      })
      .catch(error => {
        console.log('deleteEmployerLogo error:', error)
        dispatch(employerLogoDeleteFailure(error))
      })
  }

  return {
    getEmployerDetails,
    getEmployerNames,
    createEmployer,
    silenceCreditSpaceWarning,
    setBankAccount,
    setLockState,
    updatePayoutSettings,
    updateEmployerInfo,
    addEmployerCreditor,
    deleteEmployerCreditor,
    uploadEmployerLogo,
    deleteEmployerLogo
  }
}

export default useEmployerActions
