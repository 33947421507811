import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { Colors } from "../../constants/colors"
import { ProtectRule } from "../../redux/types"
import Card from "../Card"

interface Props {
    rules: ProtectRule[]
}

const RuleList = ({ rules }: Props) => {
    const styles = useStyles()

    const renderHeader = () => {
        return (
          <Card className={`${styles.container} ${styles.header} card`}>
            <div className={styles.cell}>
              <h6>Name</h6>
            </div>
            <div className={styles.cell}>
              <h6>Type</h6>
            </div>
            <div className={`${styles.cell} ${styles.iconCell}`}>
              <h6>Triggered</h6>
            </div>
          </Card>
        )    
      }
    
      const renderListItems = () => {
        return rules?.filter(x => !x.deleted).map((item, index) => {
          return (
            <Link to={`/protect/rules/${item.id}`} key={`rule_${index}`}>
              <Card className={`${styles.container} card`}>
                <div className={styles.cell}>
                  {item.name}
                </div>
                <div className={styles.cell}>
                  {item.type}
                </div>
                <div className={`${styles.cell} ${styles.iconCell}`}>
                  {renderTriggeredIndicator(item)}
                </div>
              </Card>
            </Link>
          )
        })
      }

      const renderTriggeredIndicator = (rule: ProtectRule) => {
        return (
          <FontAwesomeIcon
            icon={faCircle}
            color={rule.triggered ? Colors.red : Colors.green}
            title={rule.triggered ? 'Triggered' : 'No'}
          />
        ) 
      }

    return (
        <div>
          {renderHeader()}
          {renderListItems()}
        </div>
      )
}

export default RuleList

const useStyles = createUseStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: 'left',
      padding: '3px 20px',
      margin: '10px 0',
      transition: '0.4s',
      '&:hover': {
        backgroundColor: Colors.darkGrayHover,
      }
    },
    header: {
      margin: '30px 0 20px 0',
    },
    cell: {
      flex: 1
    },
    iconCell: {
      textAlign: 'center',
      flex: '0 0 80px'
    }
  })