import Card from '../Card'
import {createUseStyles} from 'react-jss'
import {Colors} from '../../constants/colors'
import {AmlReportModel} from '../../redux/companyMonitorTypes';
import Badge from "react-bootstrap/Badge";
import {faFileContract} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import ReportModal from "./ReportModal";

interface Props {
  amlReport: AmlReportModel
}

const CompanyReportAmlReportCard = ({amlReport}: Props) => {
  const styles = useStyles()
  const [reportModalVisible, setReportModalVisible] = useState(false)

  const isFlagged = amlReport.isSanction

  return (
    <>
      <Card className={`${styles.card} card ${isFlagged && styles.warning}`}
            onClick={() => setReportModalVisible(true)}>
        <FontAwesomeIcon className={styles.largeIcon}
                         icon={faFileContract}
                         color={Colors.white}
        />
        <h4>AML report</h4>
        {amlReport.isSanction && <h5><Badge variant={"danger"}>Company is sanction flagged</Badge></h5>}
      </Card>

      <ReportModal
        title='Company AML report'
        visible={reportModalVisible}
        reportContent={amlReport.report}
        onCloseClick={() => setReportModalVisible(false)}
      />
    </>
  )
}
export default CompanyReportAmlReportCard

const useStyles = createUseStyles({
  card: {
    height: '100%',
    backgroundColor: '#4B4B4B',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    borderRadius: 8,
    padding: 14,
    gap: 8,
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  warning: {
    boxShadow: '0 0 5px 0 #FF3333',
    '&:hover': {
      boxShadow: '0 0 30px 0 #FF3333',
    }
  },
  largeIcon: {
    fontSize: "xxx-large"
  },
})
