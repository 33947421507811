import { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect'
import usePayDeviationActions from '../../hooks/usePayDeviationActions'
import PayDeviationList from './PayDeviationList'
import { filterChanged, pageIndexChanged } from '../../redux/slices/payDeviationSlice'

interface Props { 
  employeeId?: string
}

const PayDeviationListComponent = ({ employeeId  }: Props) => {
  const styles = useStyles()
  const dispatch = useAppDispatch()
  const { getPayDeviations } = usePayDeviationActions()

  const payDeviationsOnPage = useAppSelector(state => state.payDeviation.payDeviationsOnPage)
  const totalCount = useAppSelector(state => state.payDeviation.totalCount)
  const pageIndex = useAppSelector(state => state.payDeviation.pageIndex)
  const pageSize = useAppSelector(state => state.payDeviation.pageSize)
  const filterEmployeeId = useAppSelector(state => state.payDeviation.employeeId)
  const filterPaydayId = useAppSelector(state => state.payDeviation.paydayId)

  useEffect(() => {
    if (employeeId && employeeId !== filterEmployeeId)
      dispatch(filterChanged({ employeeId: employeeId, paydayId: ''}))
  }, [employeeId])

  useDidUpdateEffect(() => {
    if (filterEmployeeId) {
      getPayDeviations(filterEmployeeId, filterPaydayId, pageIndex, pageSize)
    }
  }, [filterEmployeeId, filterPaydayId, pageIndex])

  const onPageChange = (pageIndex: number) => {
    dispatch(pageIndexChanged(pageIndex))
  }

  const onPaydayFilterChange = (name: string, value: string) => {
    dispatch(filterChanged({ employeeId: filterEmployeeId as string, paydayId: value}))
  }


  return (
    <div className={styles.container}>
      {/* <ShiftFilter 
        filterValue={searchTerm || ''}
        onFilterChange={onFilterChange}
      />  */}
      <PayDeviationList 
        payDeviationsOnPage={payDeviationsOnPage}
        pageIndex={pageIndex}
        pageSize={pageSize} 
        totalCount={totalCount}
        onPageChange={onPageChange}
      />
    </div>
  )
}

export default PayDeviationListComponent

const useStyles = createUseStyles({
  container: {},
})
