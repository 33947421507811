import { restConfig } from "../authConfig";
import { useAppDispatch } from "../redux/hooks";
import { 
  loading,
  userDetailsFetched, 
  userDetailsFetchFailure, 
  userLockStateSet, 
  userLockStateSetFailure, 
  userPayoutSettingsUpdated, 
  userPayoutSettingsUpdateFailure, 
  usersFetched, 
  usersFetchFailure, 
  freePayoutCreated, 
  freePayoutCreateFailure, 
  userPhoneNumberVerificationStateUpdated, 
  userPhoneNumberVerificationStateUpdateFailure, 
  userPhoneNumberUpdated,
  userPhoneNumberUpdateFailure
} from "../redux/slices/userSlice";
import { PayoutSettings, UserListItemModel, UserModel, CreateFreePayoutModel } from '../redux/types';
import { getPaginationDataFromResponse } from "../utils/responseHelper";
import { createUrlWithQueryString } from "../utils/url";
import useFetchClient from "./useFetchClient";

const useUserActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getUsers = (searchTerm: string, pageIndex: number, pageSize: number) => {
    dispatch(loading())

    const params = {
      query: searchTerm,
      pageNumber: pageIndex,
      pageSize: pageSize
    }
    const url = createUrlWithQueryString(restConfig.routes.users, params)

    return fetchClient.get(url)
      .then(response => {
          const paginationData = getPaginationDataFromResponse(response.raw)
          dispatch(usersFetched({ items: response.data as UserListItemModel[], totalCount: paginationData?.totalItemCount ?? 0}))
      })
      .catch(error => {
          console.log('getUsers error:', error)
          dispatch(usersFetchFailure(error))
      })
  }

  const getUserDetails = async (id: string) => {
    dispatch(loading())

    const url = restConfig.routes.users + `/${id}`

    return fetchClient.get(url)
      .then(response => {
          dispatch(userDetailsFetched(response.data as UserModel))
      })
      .catch(error => {
          console.log('getUserDetails error:', error)
          dispatch(userDetailsFetchFailure(error))
      })
  }

  const setPayoutSettings = async (userId: string, payoutSettings: PayoutSettings) => {
    dispatch(loading())

    const url = restConfig.routes.users + `/${userId}/payoutsettings`

    return fetchClient.post(url, payoutSettings)
      .then(response => {
          dispatch(userPayoutSettingsUpdated(response.data as UserModel))
      })
      .catch(error => {
          console.log('setPayoutSettings error:', error)
          dispatch(userPayoutSettingsUpdateFailure(error))
      })
  }

  const setLockState = async (userId: string, setAsLocked: boolean) => {
    dispatch(loading())

    const url = restConfig.routes.users + `/${userId}/${setAsLocked ? 'lock' : 'unlock'}`

    return fetchClient.post(url)
      .then(response => {
          dispatch(userLockStateSet(response.data as UserModel))
      })
      .catch(error => {
          console.log('setLockState error:', error)
          dispatch(userLockStateSetFailure(error))
      })
  }

  const setPhoneNumberVerificationState = async (userId: string, disabled: boolean) => {
    dispatch(loading())

    const url = restConfig.routes.users + `/${userId}/phonenumberverification`

    return fetchClient.post(url, { 'disabled': disabled })
      .then(response => {
          dispatch(userPhoneNumberVerificationStateUpdated(response.data as UserModel))
      })
      .catch(error => {
          console.log('setPhoneVerificationState error:', error)
          dispatch(userPhoneNumberVerificationStateUpdateFailure(error))
      })
  }

  const createFreePayout = (userId: string, freePayout: CreateFreePayoutModel) => {
    dispatch(loading())

    const url = restConfig.routes.users + `/${userId}/freepayouts`

    return fetchClient.post(url, freePayout)
      .then(response => {
          dispatch(freePayoutCreated(response.data as UserModel))
      })
      .catch(error => {
          console.log('createFreePayout error:', error)
          dispatch(freePayoutCreateFailure(error))
      })
  }

  const setPhoneNumber = async (userId: string, phoneNumber: string) => {
    dispatch(loading())

    const url = restConfig.routes.users + `/${userId}/phonenumber`

    return fetchClient.post(url, { 'phoneNumber': phoneNumber })
      .then(response => {
          dispatch(userPhoneNumberUpdated(response.data as UserModel))
      })
      .catch(error => {
          console.log('setPhoneNumber error:', error)
          dispatch(userPhoneNumberUpdateFailure(error))
      })
  }

  return {
    getUsers,
    getUserDetails,
    setPayoutSettings,
    setLockState,
    setPhoneNumberVerificationState,
    setPhoneNumber,
    createFreePayout
  }
}

export default useUserActions