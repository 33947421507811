import {createUseStyles} from 'react-jss'
import {CompanyReportModel} from '../../redux/companyMonitorTypes';
import React from "react";
import CompanyReportRepresentativeCard from "./CompanyReportRepresentativeCard";
import CompanyReportAmlReportCard from "./CompanyReportAmlReportCard";
import CompanyReportCreditReportCard from "./CompanyReportCreditReportCard";

interface Props {
  report: CompanyReportModel
}

const CompanyReport = ({report}: Props) => {
  const styles = useStyles()

  return (
    <div className={styles.container}>
      <h4>Report basis</h4>

      <div className={styles.cardContainer}>
        <div className={styles.cardWrapper}><CompanyReportCreditReportCard creditReport={report.creditReport}/></div>
        <div className={styles.cardWrapper}><CompanyReportAmlReportCard amlReport={report.amlReport}/></div>
      </div>

      <h4>Representatives</h4>
      <div className={styles.cardContainer}>
        {report.representatives.map((representative, index) => (
          <div className={styles.cardWrapper}>
            <CompanyReportRepresentativeCard representative={representative} key={index}/>
          </div>
        ))
        }
      </div>
    </div>
  )
}

export default CompanyReport

const useStyles = createUseStyles({
  container: {},
  cardContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10
  },
  cardWrapper: {
    minWidth: 240,
    flexBasis: '30%',
    flexGrow: 1,
  }
})
