import React from 'react'

const getSourceVersion = () => {
  const sourceVersion = process.env.REACT_APP_SOURCE_VERSION || 'UnknownVersion'
  return sourceVersion && sourceVersion.length && sourceVersion.length > 8 && sourceVersion.substring(0,8)
}

const About = () => {
  return (
    <div>
      <h1>About</h1>
      <p>About the Cappy service...</p>
      <h3>Version</h3>
      <p>{getSourceVersion()}</p>
    </div>
  )
}

export default About
