import {restConfig} from "../authConfig"
import {useAppDispatch} from "../redux/hooks"
import {
  failure,
  learningCategoriesFetched,
  learningCategoryCreated,
  learningCategoryUpdated,
  learningCourseCreated,
  learningCoursesFetched,
  learningCourseUpdated,
  learningModuleCreated,
  learningModuleUpdated,
  loading
} from "../redux/slices/learningSlice"
import {
  LearningCategoryCreateOrUpdateModel,
  LearningCategoryManagementModel,
  LearningCourseCreateOrUpdateModel,
  LearningCourseManagementModel,
  LearningModuleCreateOrUpdateModel,
  LearningModuleManagementModel
} from '../redux/types'
import useFetchClient from "./useFetchClient"

const useLearningActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getLearningCategories = () => {
    dispatch(loading())

    const url = restConfig.routes.learning + '/categories'

    return fetchClient.get(url)
      .then(response => {
        dispatch(learningCategoriesFetched(response.data as LearningCategoryManagementModel[]))
      })
      .catch(error => {
        console.log('getLearningCategories error:', error)
        dispatch(failure(error))
      })
  }

  const createLearningCategory = (model: LearningCategoryCreateOrUpdateModel) => {
    dispatch(loading())

    const url = restConfig.routes.learning + '/categories'

    return fetchClient.post(url, model)
      .then(response => {
        dispatch(learningCategoryCreated(response.data as LearningCategoryManagementModel))
      })
      .catch(error => {
        console.log('createLearningCategory error:', error)
        dispatch(failure(error))
      })
  }

  const updateLearningCategory = (id: string, model: LearningCategoryCreateOrUpdateModel) => {
    dispatch(loading())

    const url = restConfig.routes.learning + `/categories/${id}`

    return fetchClient.put(url, model)
      .then(response => {
        dispatch(learningCategoryUpdated(response.data as LearningCategoryManagementModel))
      })
      .catch(error => {
        console.log('updateLearningCategory error:', error)
        dispatch(failure(error))
      })
  }

  const getLearningCourses = () => {
    dispatch(loading())

    const url = restConfig.routes.learning + '/courses'

    return fetchClient.get(url)
      .then(response => {
        dispatch(learningCoursesFetched(response.data as LearningCourseManagementModel[]))
      })
      .catch(error => {
        console.log('getLearningCourses error:', error)
        dispatch(failure(error))
      })
  }

  const createLearningCourse = async (model: LearningCourseCreateOrUpdateModel, onCreated?: (model: LearningCourseManagementModel) => void) => {
    dispatch(loading())

    const url = restConfig.routes.learning + '/courses'

    return fetchClient.post(url, model)
      .then(response => {
        dispatch(learningCourseCreated(response.data as LearningCourseManagementModel))
        return response
      })
      .then(response => onCreated && onCreated(response.data as LearningCourseManagementModel))
      .catch(error => {
        console.log('createLearningCourse error:', error)
        dispatch(failure(error))
      })
  }

  const updateLearningCourse = async (id: string, model: LearningCourseCreateOrUpdateModel) => {
    dispatch(loading())

    const url = restConfig.routes.learning + `/courses/${id}`

    return fetchClient.put(url, model)
      .then(response => {
        dispatch(learningCourseUpdated(response.data as LearningCourseManagementModel))
      })
      .catch(error => {
        console.log('updateLearningCourse error:', error)
        dispatch(failure(error))
      })
  }

  const createLearningModule = async (courseId: string, model: LearningModuleCreateOrUpdateModel, onCreated?: (model: LearningModuleManagementModel) => void) => {
    dispatch(loading())

    const url = restConfig.routes.learning + `/courses/${courseId}/modules`

    return fetchClient.post(url, model)
      .then(response => {
        dispatch(learningModuleCreated(response.data as LearningModuleManagementModel))
        return response
      })
      .then(response => onCreated && onCreated(response.data as LearningModuleManagementModel))
      .catch(error => {
        console.log('createLearningModule error:', error)
        dispatch(failure(error))
      })
  }

  const updateLearningModule = async (courseId: string, moduleId: string, model: LearningModuleCreateOrUpdateModel) => {
    dispatch(loading())

    const url = restConfig.routes.learning + `/courses/${courseId}/modules/${moduleId}`

    return fetchClient.put(url, model)
      .then(response => {
        dispatch(learningModuleUpdated(response.data as LearningModuleManagementModel))
      })
      .catch(error => {
        console.log('updateLearningModule error:', error)
        dispatch(failure(error))
      })
  }

  const publishLearningCourse = async (id: string) => {
    dispatch(loading())

    const url = restConfig.routes.learning + `/courses/${id}/publish`

    return fetchClient.post(url)
      .then(response => {
        dispatch(learningCourseUpdated(response.data as LearningCourseManagementModel))
      })
      .catch(error => {
        console.log('publishLearningCourse error:', error)
        dispatch(failure(error))
      })
  }

  const unpublishLearningCourse = async (id: string) => {
    dispatch(loading())

    const url = restConfig.routes.learning + `/courses/${id}/unpublish`

    return fetchClient.post(url)
      .then(response => {
        dispatch(learningCourseUpdated(response.data as LearningCourseManagementModel))
      })
      .catch(error => {
        console.log('unpublishLearningCourse error:', error)
        dispatch(failure(error))
      })
  }

  const publishLearningModule = async (id: string) => {
    dispatch(loading())

    const url = restConfig.routes.learning + `/modules/${id}/publish`

    return fetchClient.post(url)
      .then(response => {
        dispatch(learningModuleUpdated(response.data as LearningModuleManagementModel))
      })
      .catch(error => {
        console.log('publishLearningModule error:', error)
        dispatch(failure(error))
      })
  }

  const unpublishLearningModule = async (id: string) => {
    dispatch(loading())

    const url = restConfig.routes.learning + `/modules/${id}/unpublish`

    return fetchClient.post(url)
      .then(response => {
        dispatch(learningModuleUpdated(response.data as LearningModuleManagementModel))
      })
      .catch(error => {
        console.log('unpublishLearningModule error:', error)
        dispatch(failure(error))
      })
  }

  return {
    getLearningCategories,
    createLearningCategory,
    updateLearningCategory,
    getLearningCourses,
    createLearningCourse,
    updateLearningCourse,
    createLearningModule,
    updateLearningModule,
    publishLearningCourse,
    unpublishLearningCourse,
    publishLearningModule,
    unpublishLearningModule
  }
}

export default useLearningActions
