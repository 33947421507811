import {EmployeeListItemModel} from "../../redux/types";

export interface ColumnDefinition {
  title: string
  extractor: (employee: EmployeeListItemModel) => string
  style?: 'Cell' | 'Badge'
}

export const DefaultColumnDefinitions: ColumnDefinition[] = [
  {
    title: 'Name',
    extractor: (item: EmployeeListItemModel) => `${item.givenName} ${item.familyName}`
  },
  {
    title: 'Employment number',
    extractor: (item: EmployeeListItemModel) => item.externalId || ''
  },
  {
    title: 'Identification number',
    extractor: (item: EmployeeListItemModel) => item.identificationNumber || ''
  },
  {
    title: 'Status',
    extractor: (item: EmployeeListItemModel) => item.status || '',
    style: 'Badge'
  }
]

export const AllColumnDefinitions: ColumnDefinition[] = [
  {
    title: 'Name',
    extractor: (item: EmployeeListItemModel) => `${item.givenName} ${item.familyName}`
  },
  {
    title: 'Employment number',
    extractor: (item: EmployeeListItemModel) => item.externalId || ''
  },
  {
    title: 'Identification number',
    extractor: (item: EmployeeListItemModel) => item.identificationNumber || ''
  },
  {
    title: 'Email',
    extractor: (item: EmployeeListItemModel) => item.email || ''
  },
  {
    title: 'Phone',
    extractor: (item: EmployeeListItemModel) => item.phoneNumber || ''
  },
  {
    title: 'Employer',
    extractor: (item: EmployeeListItemModel) => item.employerName || ''
  },
  {
    title: 'Status',
    extractor: (item: EmployeeListItemModel) => item.status || '',
    style: 'Badge'
  }
]
