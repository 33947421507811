import { restConfig } from "../authConfig";
import { useAppDispatch } from "../redux/hooks";
import { loading, nordeaAccessTokenCreated, nordeaAccessTokenCreateFailure, nordeaFrozenTransfersFetched, nordeaFrozenTransfersFetchFailure, nordeaPaymentVerificationInitiated, nordeaPaymentVerificationInitiationFailure, nordeaSigningTokenCreated, nordeaSigningTokenCreateFailure, nordeaTokensFetched, nordeaTokensFetchFailure } from "../redux/slices/nordeaTokenSlice";
import { FrozenBankTransfer, TokenModel } from '../redux/types';
import useFetchClient from "./useFetchClient";

const useNordeaIntegrationActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const fetchTokens = async () => {
    dispatch(loading())

    const url = restConfig.routes.nordea + '/tokens'

    return fetchClient.get(url)
      .then(response => {
          dispatch(nordeaTokensFetched(response.data as TokenModel[]))
      })
      .catch(error => {
          console.log('fetchTokens error:', error)
          dispatch(nordeaTokensFetchFailure(error))
      })
  }

  const fetchFrozenTransfers = async () => {
    dispatch(loading())

    const url = restConfig.routes.nordea + '/frozenTransfers'

    return fetchClient.get(url)
      .then(response => {
          dispatch(nordeaFrozenTransfersFetched(response.data as FrozenBankTransfer[]))
      })
      .catch(error => {
          console.log('fetchFrozenTransfers error:', error)
          dispatch(nordeaFrozenTransfersFetchFailure(error))
      })
  }

  const initiateAccessToken = async (authorizerId: string) => {
    dispatch(loading())

    const url = restConfig.routes.nordea + `/accessToken?authorizerId=${authorizerId}`

    return fetchClient.post(url)
      .then(response => {
          dispatch(nordeaAccessTokenCreated(response.data as TokenModel[]))
      })
      .catch(error => {
          console.log('initiateAccessToken error:', error)
          dispatch(nordeaAccessTokenCreateFailure(error))
      })
  }

  const initiateSigningKey = async (authorizerId: string, isDefault: boolean) => {
    dispatch(loading())

    const url = restConfig.routes.nordea + `/signingKey?authorizerId=${authorizerId}&isDefault=${isDefault}`

    return fetchClient.post(url)
      .then(response => {
          dispatch(nordeaSigningTokenCreated(response.data as TokenModel[]))
      })
      .catch(error => {
          console.log('initiateSigningKey error:', error)
          dispatch(nordeaSigningTokenCreateFailure(error))
      })
  }

  const initiatePaymentVerification = async (id: string, signingTokenKey: string) => {
    dispatch(loading())

    const url = restConfig.routes.nordea + `/paymentVerification/${id}?signingTokenKey=${signingTokenKey}` 

    return fetchClient.post(url)
      .then(response => {
          dispatch(nordeaPaymentVerificationInitiated())
      })
      .catch(error => {
          console.log('initiatePaymentVerification error:', error)
          dispatch(nordeaPaymentVerificationInitiationFailure(error))
      })
  }

  return {
    fetchTokens,
    fetchFrozenTransfers,
    initiateAccessToken,
    initiateSigningKey,
    initiatePaymentVerification
  }
}

export default useNordeaIntegrationActions
