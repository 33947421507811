import {useState, ChangeEvent } from 'react'
import Modal from '../Modal'
import InputField from '../InputField'

interface Props {
  reconciliationIssueId?: string
  reconciliationId?: string
  onSaveClick?: (comment: string) => void
  onCancelClick?: () => void
  onHideClick?: () => void
  visible: boolean
}

const ReconciliationIssueHandlingModal = (props: Props) => {
  const [comment, setComment] = useState('')

  const handleOnSave = () => {
    props.onSaveClick && props.onSaveClick(comment)
    setComment('')
  }

  const handleOnCancel = () => {
    props.onCancelClick && props.onCancelClick()
  }

  const handleOnHide = () => {
    props.onHideClick && props.onHideClick()
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value)  
  }

	return (
		<Modal
			title='Set issue as handled'
			show={props.visible}
			onHide={() => handleOnHide()}
			onOkClick={() => handleOnSave()}
			onCancelClick={() => handleOnCancel()}
		>
			<InputField
        id={`reconciliationIssueComment${props.reconciliationIssueId}`}
        value={comment}
        onChange={handleOnChange}
        placeholder='Enter your comment here'
        label='Comment'
      />
		</Modal>
	)
}

export default ReconciliationIssueHandlingModal
