import {createUseStyles} from 'react-jss'
import React from "react";
import {titleMarginBottom} from "../constants/layout";
import usePayRunDashboardActions from "../components/PayRunDashboard/usePayRunDashboardActions";
import CardSection from "../components/CardSection";
import Card from "../components/Card";
import {Colors} from "../constants/colors";
import EmployerPayRunList from "../components/PayRunDashboard/EmployerPayRunList";
import PayRunList from "../components/PayRunDashboard/PayRunList";
import {Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInfoCircle, faMoneyBill, faReceipt, faRetweet, faSnowflake, faStop} from '@fortawesome/free-solid-svg-icons'
import usePayRunDashboardAutoUpdate from "../components/PayRunDashboard/usePayRunDashboardAutoUpdate";
import {Toolbar, ToolbarItem} from "../components/Learning/Toolbar";
import Divider from "../components/Divider";
import {formatMoney} from "../utils/moneyHelper";
import {capitalize} from "../utils/stringHelper";


const PayRunDashboard = () => {
  const styles = useStyles()

  const {
    incompleteEmployerPayRunPage,
    incompletePayRunsPage,
    frozenPayRunsPage,
    setIncompletePayRunsFilter,
    setIncompletePayRunsPageIndex,
    setFrozenPayRunsFilter,
    setFrozenPayRunsPageIndex,
  } = usePayRunDashboardActions()

  const {
    autoUpdateInterval,
    setAutoUpdateInterval
  } = usePayRunDashboardAutoUpdate()

  return (
    <div>
      <div className={styles.pageHeader}>
        <h1>Ongoing pay runs</h1>

        <Toolbar label={"Auto update"}>
          <ToolbarItem icon={faStop} title={"Off"} display={"iconAndText"}
                       disabled={!autoUpdateInterval}
                       onClick={() => {
                         setAutoUpdateInterval(null)
                       }}/>
          <ToolbarItem icon={faRetweet} title={"5s"} display={"iconAndText"}
                       disabled={autoUpdateInterval === 5}
                       onClick={() => {
                         setAutoUpdateInterval(5)
                       }}/>
          <ToolbarItem icon={faRetweet} title={"1m"} display={"iconAndText"}
                       disabled={autoUpdateInterval === 60}
                       onClick={() => {
                         setAutoUpdateInterval(60)
                       }}/>
        </Toolbar>
      </div>


      <CardSection>

        <Card className={styles.card}>
          <div className={styles.cardHeader}>
            <h3>Employer pay runs ({incompleteEmployerPayRunPage.employerPayRuns.length})</h3>
            {!incompleteEmployerPayRunPage.loading &&
                <FontAwesomeIcon icon={faReceipt} size="2x" color={Colors.white}/>}
            {incompleteEmployerPayRunPage.loading && <Spinner animation={"border"}/>}
          </div>
          <EmployerPayRunList employerPayRunPage={incompleteEmployerPayRunPage}/>
        </Card>

        <Card className={styles.card}>
          <div className={styles.cardHeader}>
            <h3>Frozen pay runs ({frozenPayRunsPage.totalCount})</h3>
            {!frozenPayRunsPage.loading && <FontAwesomeIcon icon={faSnowflake} size="2x" color={Colors.white}/>}
            {frozenPayRunsPage.loading && <Spinner animation={"border"}/>}
          </div>
          <PayRunList
            payRunPage={frozenPayRunsPage}
            onFilterChange={setFrozenPayRunsFilter}
            onPageChange={setFrozenPayRunsPageIndex}
            columns={[
              {title: "Employer", accessor: (x) => x.employerName},
              {title: "Salary", accessor: (x) => formatMoney(x.salaryAfterDeductions)},
            ]}
          />
        </Card>
      </CardSection>

      <Divider height={10}/>

      <Card className={styles.card}>
        <div className={styles.cardHeader}>
          <h3>Incomplete pay payouts ({incompletePayRunsPage.totalCount})</h3>
          {!incompletePayRunsPage.loading && <FontAwesomeIcon icon={faMoneyBill} size="2x" color={Colors.white}/>}
          {incompletePayRunsPage.loading && <Spinner animation={"border"}/>}
        </div>
        <PayRunList
          payRunPage={incompletePayRunsPage}
          onFilterChange={setIncompletePayRunsFilter}
          onPageChange={setIncompletePayRunsPageIndex}
          columns={[
            {title: "Employer", accessor: (x) => x.employerName},
            {title: "Salary", accessor: (x) => formatMoney(x.salaryAfterDeductions)},
            {title: "Pay Transfer State", accessor: (x) => capitalize(x.payTransferState)}
          ]}
        />
      </Card>

      <Divider height={10}/>

      <Card className={styles.card}>
        <div className={styles.cardHeader}>
          <h3>Info</h3>
          <FontAwesomeIcon icon={faInfoCircle} size="2x" color={Colors.white}/>
        </div>
        <h6>Pause pay run</h6>
        <p>
          Pause an ongoing pay transfer for a pay run. This is useful when a PayRun fails to be
          completed for some reason, and the system keeps creating new PayoutAttempts with no
          chance of success. The PayRun must be in the PayoutPending state to be paused.
        </p>
        <p>
          Depending on timing, a new PayoutAttempt may be created after the PayRun is paused,
          and this is fine. The paused PayRun/PayoutAttempt will not be processed by the PayPayoutProcessor
          until it is resumed.
        </p>

        <h6>Manually complete pay run</h6>
        <p>
          In some cases, a PayRun can not be completed by the system. For example, this might occur
          if a user has no valid Swish number or recipient bank account. In these cases, the wages/salary
          will have to be returned to the employer manually for further processing. In order to be able
          to close out the PayRun, the pay transfer must be marked as completed manually.
        </p>
        <p>
          The initiator of the manual completion must ensure that the funds are returned to the
          employer, and that they are informed - the system will consider the pay transfer of this PayRun
          completed.
        </p>
      </Card>
    </div>
  )
}

export default PayRunDashboard

const useStyles = createUseStyles({
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: titleMarginBottom
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: 400,
    padding: 25,
    backgroundColor: Colors.darkGrayCard,
    borderColor: Colors.darkGrayBorder,
    borderWidth: 2,
    borderRadius: 10
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10,
  }
})
