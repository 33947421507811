import { useAppSelector } from "../redux/hooks"
import useCompanyRepresentativeAmlActions from "./useCompanyRepresentativeAmlActions"
import useCompanyAmlActions from "./useCompanyAmlActions"
import useProtectIssueActions from "./useProtectIssueActions"
import useReconciliationActions from "./useReconciliationActions"
import useCompanyMonitorActions from "./useCompanyMonitorActions"
import useCommunicationOptOutActions from "./useCommunicationOptOutActions"

const useInitialDataFetch = () => {
    const { getAmlFlaggedCompanyRepresentatives } = useCompanyRepresentativeAmlActions()
    const { getAmlFlaggedCompanies } = useCompanyAmlActions()
    const { getReconciliationsWithIssuesCount } = useReconciliationActions()
    const { getIssues } = useProtectIssueActions()
    const { getCompaniesWithUnhandledChanges } = useCompanyMonitorActions()
    const { getOptOuts } = useCommunicationOptOutActions()

    const protectIssuesPageSize = useAppSelector(state => state.protectIssues.pageSize)

    const fetchData = () => {
        getAmlFlaggedCompanyRepresentatives()
        getAmlFlaggedCompanies()
        getReconciliationsWithIssuesCount()
        getIssues(false, 1, protectIssuesPageSize)
        getCompaniesWithUnhandledChanges()
        getOptOuts()
    }

    return {
        fetchData
    }
}

export default useInitialDataFetch